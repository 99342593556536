import PropTypes from 'prop-types';

function ValidationDrawer(props) {
  const {
    val,
  } = props;

  const getInputContents = () => (
    <>
      {
          val.type === 'rule'
            ? (
              <>
                <div className="validation-drawer-div__errormsg">
                  <div>
                    Rule:
                  </div>
                  <div className="validation-drawer-div__maxValue" id={val.emptyValue ? 'errorIcon' : null}>
                    {val.value}
                  </div>
                </div>

              </>
            )
            : (
              <>
                <div>
                  <div className="validation-drawer-div__errormsg">
                    <div>
                      Validation Type:
                    </div>
                    <div>
                      {val.validationType}
                    </div>
                  </div>
                  <div className="validation-drawer-div__errormsg">
                    <div>
                      Regex:
                    </div>
                    <div className="validation-drawer-div__maxValue" id={val.emptyValue ? 'errorIcon' : null}>
                      {val.value}
                    </div>
                  </div>
                </div>
              </>
            )
        }

      <div className="validation-drawer-div__errormsg">
        <div>
          Error Message:
        </div>
        <div className="validation-drawer-div__maxValue">
          {val.errorMsg}
        </div>
      </div>

    </>
  );

  return (
    <>

      {
        getInputContents()
      }

    </>
  );
}

export default ValidationDrawer;

ValidationDrawer.propTypes = {
  val: PropTypes.object.isRequired,

};
