import { cloneDeep } from 'lodash';
import { createParentMap } from '../../utils/gotoUtils';
import { getParentNodes } from '../../utils/workflowTraversal';
import { getInitialModuleOfSuperModule } from '../../utils/helper';

const getPreviousModuleOptions = (selectedNodeId, selectedWorkflow) => {
  const parentMap = createParentMap(selectedWorkflow);
  const parents = getParentNodes(selectedNodeId, parentMap, selectedWorkflow);
  const filteredParents = parents.filter((parent) => !parent.includes('condition'));
  const parentModules = selectedWorkflow.modules
    .filter((module) => filteredParents.includes(module.id));
  const transformedParentModules = parentModules.map((item) => ({
    label: item.name,
    value: item.id,
    displayLabel: item.name,
  }));
  return transformedParentModules;
};

export const setPreviousStepInSupermoduleLibrary = (
  initialStep,
  library,
  superModule,
) => {
  const clonnedLibrary = cloneDeep(library);
  if (superModule.previousStep !== undefined) {
    const initialModuleId = getInitialModuleOfSuperModule(
      initialStep,
      library,
      superModule,
    );
    const initialModuleIndex = clonnedLibrary.modules.findIndex(
      (module) => module.id === initialModuleId,
    );
    const index = initialModuleIndex !== -1 ? initialModuleIndex : 0;
    clonnedLibrary.modules[index].previousStep = superModule.previousStep;
  }
  return clonnedLibrary;
};

export default getPreviousModuleOptions;
