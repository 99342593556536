import HVError, { errorCodes, errorMessages } from '../utils/error';
import generateUniqueID from '../utils/generateUniqueId';
import { replaceAll } from '../utils/helper';
import MODULE_COUNTRY_PICKER from '../constants/miscellaneous';

export const generateWorkflowUiConfigFromModuleConfigs = (moduleConfig) => {
  try {
    const libraryUiConfig = moduleConfig?.library?.uiFormatting;
    return Object.keys(libraryUiConfig || {})?.length ? libraryUiConfig : null;
  } catch (err) {
    // TODO: throw defined errors
    throw new HVError({
      code: err?.code || errorCodes.somethingWentWrong,
      message: errorMessages.errorGeneratingWorkflowUiConfigFromModuleConfigs,
      originalError: err instanceof HVError ? err.originalError : err,
    });
  }
};

export const generateWorkflowTextConfigFromModuleConfigs = (moduleConfig) => {
  try {
    const libraryTextConfig = moduleConfig?.library?.textFormatting;
    return Object.keys(libraryTextConfig || {})?.length ? libraryTextConfig : null;
  } catch (err) {
    throw new HVError({
      code: err?.code || errorCodes.somethingWentWrong,
      message: errorMessages.errorGeneratingWorkflowTextConfigFromModuleConfigs,
      originalError: err instanceof HVError ? err.originalError : err,
    });
  }
};

const generateBaseStructureForWorkflowConfig = ({
  initialStep,
  moduleName,
  description,
  conditionalVariables,
  conditions,
  libraryModules,
  uiConfigSource,
}) => ({
  conditionalVariables,
  conditions,
  modules: [
    {
      type: 'countries',
      subType: 'countries',
      id: MODULE_COUNTRY_PICKER,
      nextStep: initialStep,
      properties: {
        countriesSupported: ['ind'],
      },
    },
    ...libraryModules,
  ],
  properties: {
    name: moduleName,
    description,
    uiConfigSource,
    moduleBuilder: {},
    sdkVersions: {
      mobile: {
        maximum: '10.0.0',
        minimum: '0.3.0',
      },
      web: {
        maximum: '10.0.0',
        minimum: '5.0.0',
      },
    },
  },
});

export const getModuleIdAndPathFromWorkflowKey = (workflowKey) => {
  const [moduleId, propertyKey] = workflowKey.split('[+]');
  return { moduleId, propertyKey };
};

const generateOutputs = (uiOutputs, variables) => {
  try {
    return variables.reduce((acc, variable) => {
      const { name = '', path = '' } = variable;
      const uiData = uiOutputs.find(({ key }) => key === name) || {};
      const { description = '', displayName = name, type = 'outputItem' } = uiData;
      const id = generateUniqueID();
      acc[id] = {
        name,
        type,
        path,
        description,
        displayName,
      };
      return acc;
    }, {});
  } catch (err) {
    throw new HVError({
      code: err?.code || errorCodes.somethingWentWrong,
      message: errorMessages.errorGeneratingOutputs,
      originalError: err instanceof HVError ? err.originalError : err,
    });
  }
};

const generateInputData = (
  inputData,
) => {
  const data = {};
  inputData.forEach((item) => {
    const id = generateUniqueID();
    const {
      configSectionName, uiConfigSectionName, workflowKey, ...uiConfigData
    } = item;
    data[id] = {
      workflowKey,
      configSectionName,
      config: {},
      uiConfigSectionName,
      uiConfig: uiConfigData,
    };
  });
  return data;
};

const segregateModuleInputs = (inputs, configurations) => {
  const builderPropertiesInputData = [];
  const modulePropertiesInputData = [];
  inputs.forEach((input) => {
    if (input.workflowKey.startsWith('builderProperties[-]')) {
      builderPropertiesInputData.push({
        ...input,
        configSectionName: 'builderProperties',
        uiConfigSectionName: 'inputs',
      });
    } else {
      modulePropertiesInputData.push({
        ...input,
        configSectionName: 'properties',
        uiConfigSectionName: 'inputs',
      });
    }
  });
  configurations.forEach((configuration) => {
    if (configuration.workflowKey.startsWith('builderProperties[-]')) {
      builderPropertiesInputData.push({
        ...configuration,
        configSectionName: 'builderProperties',
        uiConfigSectionName: 'configurations',
      });
    } else {
      modulePropertiesInputData.push({
        ...configuration,
        configSectionName: 'properties',
        uiConfigSectionName: 'configurations',
      });
    }
  });
  return {
    builderPropertiesInputData,
    modulePropertiesInputData,
  };
};

const processGeneratedWorkflowFromModule = (generatedWorkflow) => {
  const workflowStringified = JSON.stringify(generatedWorkflow);
  let workflowWithPropertiesReplaced = replaceAll(
    workflowStringified,
    'builderProperties[-]',
    'builderProperties.',
  );
  generatedWorkflow.modules.forEach((module) => {
    workflowWithPropertiesReplaced = replaceAll(
      workflowWithPropertiesReplaced,
      `${module.id}[+]`,
      `${module.id}.`,
    );
  });
  const workflowWithExitNodes = replaceAll(
    workflowWithPropertiesReplaced,
    'EXIT_POINT',
    'approve',
  );
  return JSON.parse(workflowWithExitNodes);
};

export const generateWorkflowConfigFromModuleConfigs = (moduleConfig, moduleUiConfig) => {
  try {
    const {
      library,
      initialStep,
      properties: moduleProperties,
      builderProperties = {},
      subType,
      variables,
    } = moduleConfig;

    const {
      moduleName = '', description = '', icon = '', sections = {},
    } = moduleUiConfig;
    const { inputs = [], configurations = [], outputs = [] } = sections;
    const {
      conditionalVariables = {},
      conditions = {},
      modules: libraryModules = [],
      uiFormatting = {},
    } = library || {};
    const uiConfigSource = Object.keys(uiFormatting || {})?.length > 0 ? 'custom' : 'default';
    const baseWorkflowConfig = generateBaseStructureForWorkflowConfig({
      initialStep,
      moduleName,
      description,
      conditionalVariables,
      conditions,
      libraryModules,
      uiConfigSource,
    });
    const {
      builderPropertiesInputData,
      modulePropertiesInputData,
    } = segregateModuleInputs(inputs, configurations);
    baseWorkflowConfig.properties.moduleBuilder = {
      subType,
      moduleInputs: generateInputData(modulePropertiesInputData, moduleProperties),
      output: generateOutputs(outputs, variables),
      builderProperties: generateInputData(builderPropertiesInputData, builderProperties),
      moduleIcon: icon,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    const processedWorkflow = processGeneratedWorkflowFromModule(baseWorkflowConfig);

    return processedWorkflow;
  } catch (err) {
    throw new HVError({
      code: err?.code || errorCodes.somethingWentWrong,
      message: errorMessages.errorGeneratingWorkflowConfigFromModuleConfigs,
      originalError: err instanceof HVError ? err.originalError : err,
    });
  }
};
