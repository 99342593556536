const getMatchedModuleAndConditionNames = (workflow, childrenNodeIds) => {
  const matchedNames = [];
  workflow.modules.forEach((module) => {
    if (childrenNodeIds.includes(module.id)) {
      matchedNames.push(module.name);
    }
  });
  Object.keys(workflow.conditions).forEach((conditionId) => {
    if (childrenNodeIds.includes(conditionId)) {
      matchedNames.push(workflow.conditions[conditionId].name);
    }
  });

  return matchedNames;
};
export default getMatchedModuleAndConditionNames;
