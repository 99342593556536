import { Navigate } from 'react-router-dom';

const workflowDetailsPageComponentPermissions = {
  requiredPermissions: {
    viewPermissions: ['workflowDetailsPage.view'],
    interactPermissions: ['workflowDetailsPage.interact'],
  },
  redirect: () => <Navigate to="/" replace />,
};

const workflowDetailsPagePermissions = {
  workflowDetailsPage: workflowDetailsPageComponentPermissions,
};

export default workflowDetailsPagePermissions;
