import PropTypes from 'prop-types';

function HighlightedText({ text, highlight, className }) {
  if (!highlight.trim()) return <span>{text}</span>;

  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  const getKey = (part, index) => `highlight-${index}-${part}`;

  return (
    <span>
      {parts.map((part, index) => (
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={getKey(part, index)} className={className}>
            {part}
          </span>
        ) : (
          <span key={getKey(part, index)} style={{ textTransform: 'none' }}>
            {part}
          </span>
        )
      ))}
    </span>
  );
}

HighlightedText.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default HighlightedText;
