const getPreviousStepMap = (originalModules) => originalModules.reduce((mapSoFar, module) => {
  const { previousStep: originalPrevStepId } = module;
  let finalPrevStepId;
  if (originalPrevStepId === '') finalPrevStepId = '';
  else if (!originalPrevStepId) return mapSoFar;
  else {
    const prevStepModule = originalModules.find((m) => m.id === originalPrevStepId);
    if (!prevStepModule) return mapSoFar;
    finalPrevStepId = prevStepModule.superModuleId && typeof prevStepModule?.superModuleId === 'string'
      ? prevStepModule.superModuleId
      : prevStepModule.id;
  }

  if (module?.superModuleId && typeof module?.superModuleId === 'string') {
    return {
      ...mapSoFar,
      [module.superModuleId]: {
        ...(mapSoFar[module.superModuleId] || {}),
        [module.mappingId]: finalPrevStepId,
      },
    };
  }
  return {
    ...mapSoFar,
    [module.id]: {
      default: finalPrevStepId,
    },
  };
}, {});

export default getPreviousStepMap;
