import { useMemo } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../Common/TextInput';
import { fetchCurrentValueFromWorkflow, getSelectedModule } from '../InputsToModule/utils/updateWorkflow';

function TextInputWrapper(props) {
  const {
    element, isDisabled, selectedWorkflow, selectedNodeId, updateValueInWorkflow,
  } = props;

  const {
    workflowKey,
    text: label,
    errortext: errorText,
    placeholder, regex,
    default: defaultValue,
    unsetIfRegexFail = false,
    infoButton = null,
  } = element;

  const defaultInput = useMemo(() => {
    const selectedModule = getSelectedModule(selectedWorkflow, selectedNodeId);
    const currentValue = fetchCurrentValueFromWorkflow(
      selectedModule,
      workflowKey,
    );
    const valueFetchedFromWorkflow = typeof currentValue === 'string';
    const fallbackValue = typeof defaultValue === 'string' ? defaultValue : null;
    const value = valueFetchedFromWorkflow ? currentValue : fallbackValue;
    return value;
  }, [defaultValue, selectedNodeId, selectedWorkflow, workflowKey]);

  const matchesRegex = (inputString, regexPattern) => {
    const regexExp = new RegExp(regexPattern);
    return regexExp.test(inputString);
  };

  const onSave = (text) => {
    const isValid = matchesRegex(text, regex || '.*');
    if (unsetIfRegexFail === true && !isValid) {
      updateValueInWorkflow(workflowKey, null);
    } else {
      updateValueInWorkflow(workflowKey, text);
    }
  };

  return (
    <div className="text-input-wrapper">
      <TextInput
        key={defaultInput}
        defaultInput={defaultInput}
        label={label}
        errorText={errorText}
        placeholder={placeholder}
        regex={regex}
        onSave={onSave}
        isDisabled={isDisabled}
        infoButton={infoButton}
      />
    </div>
  );
}

TextInputWrapper.propTypes = {
  element: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool,
  updateValueInWorkflow: PropTypes.func.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
};

TextInputWrapper.defaultProps = {
  isDisabled: false,
};

export default TextInputWrapper;
