const checkOutputsAddedInModuleVersion = (
  configurations,
) => {
  const {
    currentModuleConfig = {},
    latestModuleConfig = {},
  } = configurations || {};
  const currentOutputs = currentModuleConfig?.variables;
  const latestOutputs = latestModuleConfig?.variables;

  const currentVersionOutputMap = currentOutputs.reduce(
    (map, variable) => ({
      ...map,
      [variable.name]: variable.path,
    }),
    {},
  );

  const addedOutputs = latestOutputs.filter(
    (output) => !currentVersionOutputMap[output.name],
  );

  if (addedOutputs.length > 0) {
    return {
      hasDifferences: true,
      logs: addedOutputs.map((output) => (
        <span key={output.name}>
          Output
          {' '}
          <b>
            &quot;
            {output.name}
            &quot;
          </b>
          {' '}
          has been added
        </span>
      )),
      extraData: {
        addedOutputs,
      },
    };
  }

  return {
    hasDifferences: false,
    logs: [],
    extraData: {},
  };
};

export default checkOutputsAddedInModuleVersion;
