import PropTypes from 'prop-types';

function TabItem({
  text, selected, onSelect,
}) {
  return (
    <button
      type="button"
      className={`options-ribbon__configurations__tab ${selected ? 'options-ribbon__active' : ''}`}
      onClick={onSelect}
    >
      {text}
    </button>
  );
}

TabItem.propTypes = {
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default TabItem;
