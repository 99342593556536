import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import './PrimaryButton.scss';
import './SecondaryButton.scss';

function Button(props) {
  const {
    children,
    onClick,
    disabled,
    className,
    type,
    isLoading,
    buttonType,
  } = props;

  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={
        buttonType === 'secondary'
          ? `secondary-button ${className}`
          : `primary-button ${className}`
      }
      onClick={onClick}
      disabled={disabled}
    >
      <>
        {
          isLoading
            ? (
              <CircularProgress
                size="1rem"
                sx={
                buttonType === 'secondary'
                  ? { color: '#554EF1' }
                  : { color: '#ffffff' }
              }
              />
            )
            : null
        }
        {children}
      </>
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  isLoading: PropTypes.bool,
  buttonType: PropTypes.oneOf(['primary', 'secondary']),
};

Button.defaultProps = {
  disabled: false,
  className: '',
  type: 'button',
  isLoading: false,
  buttonType: 'primary',
};

export default Button;
