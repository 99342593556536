import {
  startOfDay,
  endOfDay,
  addDays,
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
} from 'date-fns';

export const formatDate = (date) => {
  const formatedDate = new Date(date);
  const day = formatedDate.toLocaleString('en-US', { day: 'numeric' });
  const month = formatedDate.toLocaleString('en-US', { month: 'short' });
  const year = formatedDate.toLocaleString('en-US', { year: '2-digit' });
  return `${day} ${month} '${year}`;
};

export const formatDateForAnalytics = (date) => new Date(date).toLocaleDateString().split('/').reverse()
  .join('/');

export const checkTodayOrYesterday = (start, end) => {
  const startDate = startOfDay(new Date(start));
  const endDate = endOfDay(new Date(end));

  if (startDate.getDate() === endDate.getDate()) {
    if (startDate.getDate() === startOfDay(new Date()).getDate()) {
      return -1;
    }

    if (startDate.getDate() === startOfDay(addDays(new Date(), -1)).getDate()) {
      return -2;
    }
  }
  return false;
};

export const formatDateForApplicationTable = (date) => {
  const formatedDate = new Date(date);
  const time = formatedDate.toLocaleString('en-US', {
    hour12: true, hour: 'numeric', minute: 'numeric',
  }).toLowerCase();
  const day = formatedDate.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  return `${time}, ${day}`;
};

export const formatDateWithDateMonth = (date) => {
  const formatedDate = new Date(date);
  const day = formatedDate.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  return `${day}`;
};

const getCompleteDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export const formatDateForDropdown = (start, end) => {
  const startDate = startOfDay(new Date(start));
  const endDate = endOfDay(new Date(end));

  if (getCompleteDate(startDate) === getCompleteDate(endDate)) {
    if (getCompleteDate(startDate) === getCompleteDate(startOfDay(new Date()))) {
      return 'Today';
    }
    if (getCompleteDate(startDate) === getCompleteDate(startOfDay(addDays(new Date(), -1)))) {
      return 'Yesterday';
    }
    return `${formatDate(start)} - ${formatDate(end)}`;
  }

  if (getCompleteDate(endDate) === getCompleteDate(endOfDay(new Date()))) {
    if (getCompleteDate(startDate) === getCompleteDate(startOfDay(addDays(new Date(), -7)))) {
      return 'Last 7 Days';
    }
    if (getCompleteDate(startDate) === getCompleteDate(startOfDay(addDays(new Date(), -30)))) {
      return 'Last 30 Days';
    }
  }

  if (
    getCompleteDate(startDate) === getCompleteDate(startOfWeek(addWeeks(new Date(), -1)))
    && getCompleteDate(endDate) === getCompleteDate(endOfWeek(addWeeks(new Date(), -1)))
  ) {
    return 'Last Week';
  }

  if (
    getCompleteDate(startDate) === getCompleteDate(startOfMonth(new Date()))
    && getCompleteDate(endDate) === getCompleteDate(endOfDay(new Date()))
  ) {
    return 'This Month';
  }

  if (
    getCompleteDate(startDate) === getCompleteDate(startOfMonth(addMonths(new Date(), -1)))
    && getCompleteDate(endDate) === getCompleteDate(endOfMonth(addMonths(new Date(), -1)))
  ) {
    return 'Last Month';
  }

  return `${formatDate(start)} - ${formatDate(end)}`;
};

export const getCurrentTimeStamp = () => Date.now();
