import { truncate } from 'lodash';
import CustomizableToolTip from '../../Common/CustomizableToolTip';

const customStyles = {
  tooltip: {
    backgroundColor: 'grey',
    color: 'white',
    padding: '2px 6px',
    fontSize: '12px',
  },
  arrow: {
    backgroundColor: 'white',
    border: '1px solid #dadde9',
  },
};

export const getLabel = (value, options) => {
  const {
    label, value: itemValue, items: subItems, displayLabel: mainLabel,
  } = options;
  if (value === itemValue) return mainLabel || label;
  let displayLabel = null;
  if (subItems?.length) {
    (options.items || []).forEach((item) => {
      const returnedLabel = getLabel(value, item);
      if (displayLabel === null) displayLabel = returnedLabel;
    });
  }
  return displayLabel;
};

export const getMenuItem = ({
  options, cb, isStartNode = false, mainLabel = '', addCustomInput = false,
}) => {
  const {
    label: itemLabel, items: subOptions, value = null, ...rest
  } = options;
  const maxLength = 20;
  const shortLabel = truncate(itemLabel, { length: maxLength });
  const label = isStartNode ? mainLabel : shortLabel;
  if (subOptions?.length) {
    const subItems = [];
    (options.items || []).forEach((item) => {
      subItems.push(getMenuItem({ options: item, cb }));
    });
    if (isStartNode && addCustomInput) {
      subItems.push(
        getMenuItem({
          options: {
            label: 'Custom Input',
            value: 'builder_customInput',
          },
          cb,
        }),
      );
    }
    return {
      label: label.length > 19 ? (
        <CustomizableToolTip customStyles={customStyles} title={itemLabel} placement="top">
          {label}
        </CustomizableToolTip>
      ) : (
        label
      ),
      items: subItems,
      ...rest,
    };
  }
  return {
    label: label.length > 19 ? (
      <CustomizableToolTip customStyles={customStyles} title={itemLabel} placement="top">
        {label}
      </CustomizableToolTip>
    ) : (
      label
    ),
    ...rest,
    callback: () => { cb(value); },
  };
};
