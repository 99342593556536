import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import compareModuleVersions from './utils/compareVersions';
import WarningIcon from '../../../assests/icons/warningIcon.svg';
import SearchIcon from '../../../assests/icons/searchIcon.svg';
import './VersionUpdateModalContent.scss';
import DisplayVersionUpdateModules from './DisplayVersionUpdateModules';
import ModuleVersionUpdateConfirmation from './ModuleVersionUpdateConfirmation';

function VersionUpdateModalContent(props) {
  const {
    modulesTobeUpdated,
    versionedModules,
    selectedModuleIndex,
    selectModuleToBeUpdated,
    setShowModuleUpdateModal,
    setHasFetchModuleUpdatesError,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [updateConsent, setUpdateConsent] = useState(false);

  const moduleUpdateDetails = useMemo(
    () => {
      try {
        return (modulesTobeUpdated || []).map((module) => {
          const versionedModule = versionedModules[module.subType];
          const currentVersion = module.version;
          const latestVersion = versionedModule?.latestVersion || 'v1';

          const moduleComparisonResults = compareModuleVersions(
            currentVersion,
            latestVersion,
            versionedModule,
          );

          return {
            moduleId: module.id,
            moduleName: module.name || '',
            currentVersion,
            latestVersion,
            versionChangeDetails: moduleComparisonResults?.comparisonResults || [],
            showBreakingChangeFlag: moduleComparisonResults?.hasBreakingChange ?? false,
          };
        })
          .filter(
            (module) => module.versionChangeDetails.some((detail) => detail?.logs?.length > 0),
          );
      } catch (error) {
        // TODO: to be handled using global error handler
        setHasFetchModuleUpdatesError(true);
        return [];
      }
    },
    [modulesTobeUpdated, versionedModules],
  );

  const filteredModuleUpdateDetails = useMemo(
    () => (moduleUpdateDetails
      .filter((module) => (module?.moduleName || '').toLowerCase().includes(searchQuery.toLowerCase())
      || (module?.moduleId || '').toLowerCase().includes(searchQuery.toLowerCase()))
    || []),
    [moduleUpdateDetails, searchQuery],
  );

  const shouldShowWarning = useMemo(() => {
    if (selectedModuleIndex !== null) {
      return Boolean(moduleUpdateDetails[selectedModuleIndex]?.showBreakingChangeFlag);
    }
    return (filteredModuleUpdateDetails || []).some((module) => module.showBreakingChangeFlag);
  }, [moduleUpdateDetails, filteredModuleUpdateDetails, selectedModuleIndex]);

  const selectedModule =
    selectedModuleIndex !== null ?
      moduleUpdateDetails[selectedModuleIndex] :
      null;

  const onModuleUpdateCancel = () => {
    setUpdateConsent(false);
    selectModuleToBeUpdated(null);
    setShowModuleUpdateModal(false);
  };

  const onModuleUpdateConfirm = () => {
  };

  return (
    <>
      <div className="version-update">
        {selectedModuleIndex === null && (
        <div className="version-update__search">
          <img className="version-update__search-icon" src={SearchIcon} alt="Search" />
          <input
            type="text"
            placeholder="Search by module name or module ID"
            className="version-update__search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        )}

        {shouldShowWarning && (
        <div className="version-update__warning">
          <img className="version-update__warning-icon" src={WarningIcon} alt="Results API integration warning" />
          <p className="version-update__warning-text">
            Super-module updates where sub-modules are removed/added could lead to
            results API integration failures. Inform your client of the same if
            they are integrated with results API.
          </p>
        </div>
        )}

        { selectedModuleIndex === null ? (
          <DisplayVersionUpdateModules
            filteredModuleUpdateDetails={filteredModuleUpdateDetails}
            selectModuleToBeUpdated={selectModuleToBeUpdated}
            moduleUpdateDetails={moduleUpdateDetails}
          />
        ) : (
          <ModuleVersionUpdateConfirmation
            selectedModule={selectedModule}
            onModuleUpdateCancel={onModuleUpdateCancel}
            updateConsent={updateConsent}
            setUpdateConsent={setUpdateConsent}
            onModuleUpdateConfirm={onModuleUpdateConfirm}
          />
        )}
      </div>
    </>
  );
}

VersionUpdateModalContent.propTypes = {
  modulesTobeUpdated: PropTypes.array.isRequired,
  versionedModules: PropTypes.object.isRequired,
  selectModuleToBeUpdated: PropTypes.func.isRequired,
  selectedModuleIndex: PropTypes.number,
  setShowModuleUpdateModal: PropTypes.func.isRequired,
  setHasFetchModuleUpdatesError: PropTypes.func.isRequired,
};

VersionUpdateModalContent.defaultProps = {
  selectedModuleIndex: null,
};

export default VersionUpdateModalContent;
