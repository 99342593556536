import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import generateUniqueID from '../../../utils/generateUniqueId';

import ModuleVariable from './ModuleVariable';
import isValidData from './validate';

function ModuleVariables(props) {
  const {
    onVariablesChange, variables, nameLabel, pathLabel, addBtnLabel, canDeleteOutputVariable,
  } = props;
  const addNewVariable = (variableList) => {
    const newVariablesList = cloneDeep(variableList || []);
    newVariablesList.push({
      id: generateUniqueID(),
      data: { name: '', path: '' },
    });
    onVariablesChange(newVariablesList);
  };

  const deleteVariable = (id, variableList) => {
    const index = (variableList || []).findIndex(({ id: currentId }) => currentId === id);
    if (index === -1 || !canDeleteOutputVariable(variableList[index].name)) return;
    const newVariablesList = cloneDeep(variableList || []);
    newVariablesList.splice(index, 1);
    onVariablesChange(newVariablesList);
  };

  const onChange = (newValue, id, key, variableList) => {
    const index = (variableList || []).findIndex(({ id: currentId }) => currentId === id);
    if (index === -1) return;
    const newVariablesList = cloneDeep(variableList || []);
    if (index < newVariablesList.length) newVariablesList[index].data[key] = newValue;
    onVariablesChange(newVariablesList);
  };

  const isValid = (variableList) => {
    const data = variableList || [];
    return isValidData(data);
  };

  const isKeyValid = (currentKey, currentVariables) => {
    if (currentKey === '') return false;
    const similarKeys = (currentVariables || []).filter((item) => item.data.name === currentKey);
    return similarKeys.length === 1;
  };

  return (
    <div className="workflowOutput__bottomContent_body">
      {
        isValid(variables) ?
          (variables || []).map(({ id, data }) => (
            <ModuleVariable
              key={id}
              name={data.name}
              path={data.path}
              nameTitle={nameLabel}
              pathTitle={pathLabel}
              onNameChange={(value) => { onChange(value, id, 'name', variables); }}
              onPathChange={(value) => { onChange(value, id, 'path', variables); }}
              onDelete={() => { deleteVariable(id, variables); }}
              isKeyNameValid={isKeyValid(data.name, variables)}
              errorText={isKeyValid(data.name, variables) ? '' : 'invalid key'}
            />
          ))
          : <p>Opps! Workflow has invalid data</p>
      }
      {
        isValid(variables) ? <button type="button" onClick={() => { addNewVariable(variables); }} className="workflowInput__bottomContent_button">{addBtnLabel}</button> : null
      }
    </div>
  );
}

ModuleVariables.propTypes = {
  variables: PropTypes.any.isRequired,
  onVariablesChange: PropTypes.func.isRequired,
  nameLabel: PropTypes.string.isRequired,
  pathLabel: PropTypes.string.isRequired,
  addBtnLabel: PropTypes.string.isRequired,
  canDeleteOutputVariable: PropTypes.func.isRequired,
};

export default ModuleVariables;
