const operatorsAllowed = [
  {
    label: 'is equal to',
    value: '==',
    displayLabel: '==',
  },
  {
    label: 'is not equal to',
    value: '!=',
    displayLabel: '!=',
  },
  {
    label: 'is greater than or equal to',
    value: '>=',
    displayLabel: '>=',
  },
  {
    label: 'is less than or equal to',
    value: '<=',
    displayLabel: '<=',

  },
  {
    label: 'is less than',
    value: '<',
    displayLabel: '<',

  },
  {
    label: 'is greater than',
    value: '>',
    displayLabel: '>',
  },
];

export default operatorsAllowed;
