import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import HunterIcon from '../../assests/icons/hunterIcon.svg';
import useApiHooks from '../../hooks/api/useApiHooks';
import languageCodes from '../../components/Branding/utils/languageCodeMapping';
import LanguageSelectModal from '../../components/ViewWorkflow/LanguageSelectModal';
import { selectDefaultTextConfig, selectSelectedLanguage } from '../../reducers/editBranding';

function TryOnHunter({ workflowId }) {
  const jwtToken = useSelector((state) => state.user.appIdKeyToken);
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const defaultTextConfigList = useSelector(selectDefaultTextConfig);
  const selectedLanguage = useSelector(selectSelectedLanguage);

  const { fetchAppIdToken } = useApiHooks();

  const appId = currentAppId || '55a3a6';
  const [isLoading, setIsLoading] = useState(false);
  const [showLanguageSelectModal, setShowLanguageSelectModal] = useState(false);
  const [language, setLanguage] = useState(selectedLanguage || 'en');

  const languagesSupported = useMemo(() => {
    const textConfigKeys = Object.keys(defaultTextConfigList || {})
      .filter((configName) => configName.endsWith('text_config') && languageCodes[configName.split('_')[1] || '']);
    return textConfigKeys.map((key) => {
      const languageCode = key.split('_')[1] || '';
      return {
        id: languageCode,
        name: languageCodes[languageCode],
        value: languageCode,
      };
    });
  }, [defaultTextConfigList]);

  const onTryWorkflow = async () => {
    setIsLoading(true);
    const { isSuccessful: isFetchAppIdTokenSuccessful } = await fetchAppIdToken();
    if (isFetchAppIdTokenSuccessful) {
      setShowLanguageSelectModal(true);
    } else {
      setIsLoading(false);
    }
  };

  const tryOnHunter = (currWorkflowId, currSelectedLanguage, currAppId, currJwtToken) => {
    const hunterUrl = process.env.REACT_APP_HUNTER_URL;
    const url = new URL(`${hunterUrl}/session-manager/`);
    url.searchParams.append('workflowId', currWorkflowId);
    url.searchParams.append('language', currSelectedLanguage);
    url.searchParams.append('appId', currAppId);
    url.searchParams.append('authToken', currJwtToken);

    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment === 'dev') {
      url.searchParams.append('env', environment);
    }

    setShowLanguageSelectModal(false);
    setIsLoading(false);
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div>
      <Button className="try-workflow__option" disabled={isLoading} onClick={onTryWorkflow}>
        <img className="try-workflow__option-icon" src={HunterIcon} alt="Hunter Icon" />
        <span className="try-workflow__option-text">Hunter</span>
        {
          isLoading
            ? <CircularProgress size="1rem" sx={{ color: 'rgba(5, 5, 82, 0.8)' }} />
            : null
        }
      </Button>
      {showLanguageSelectModal && (
        <LanguageSelectModal
          languagesSupported={languagesSupported}
          onProceed={() => tryOnHunter(workflowId, language, appId, jwtToken)}
          onChangeLanguage={setLanguage}
          showLanguageSelectModal={showLanguageSelectModal}
          selectedLanguage={language}
          onClose={() => {
            setShowLanguageSelectModal(false);
            setIsLoading(false);
          }}
        />
      )}
    </div>
  );
}

TryOnHunter.propTypes = {
  workflowId: PropTypes.string.isRequired,
};

export default TryOnHunter;
