import { useState } from 'react';
import PropTypes from 'prop-types';
import curl2Json from '@bany/curl-to-json';
import { Tooltip } from '@mui/material';
import closeIcon from '../../../assests/icons/CloseIcon.svg';
import errorIcon from '../../../assests/icons/Error.svg';
import infoIcon from '../../../assests/icons/infoIcon.svg';
import { useShowCustomAlert } from '../../../hooks/custom/useCustomAlert';

function ImportCurlModal({ toggleModal, openConfigModal, updateValueInWorkflow }) {
  const [curlInput, setCurlInput] = useState('');
  const [curlValid, setCurlValid] = useState(false);
  const [modifiedCurlInput, setModifiedCurlInput] = useState('');
  const [curlError, setCurlError] = useState('');
  const showCustomAlert = useShowCustomAlert();

  function formatCurl(curlValue) {
    return curlValue.trim()
      .replace(/<[^>]*>/g, (match) => match
        .replace(/=/g, '')
        .replace(/'/g, ''))
      .replace(/<</g, '<')
      .replace(/>>/g, '>');
  }

  const validateCurl = (curlValue) => {
    try {
      const parsedValue = curl2Json(formatCurl(curlValue));
      setModifiedCurlInput(parsedValue);
      setCurlValid(true);
    } catch (error) {
      if (error.message) {
        setCurlError(error.message);
        setCurlValid(false);
      } else {
        setCurlValid(false);
      }
    }
  };

  const handleInputChange = (event) => {
    setCurlInput(event.target.value);
    validateCurl(event.target.value);
  };

  function determineRequestType(json) {
    switch (json.method) {
      case 'POST':
        return json.form && json.form.length > 0 ? 'multipart_post' : 'json_post';
      case 'PUT':
        return json.form && json.form.length > 0 ? 'multipart_put' : 'json_put';
      case 'GET':
        return 'json_get';
      default:
        return 'unknown_method';
    }
  }

  const setConfigValues = (json) => {
    const apiType = determineRequestType(json);
    updateValueInWorkflow('apiType', apiType);
    if (json.url && json.url !== 'null') {
      updateValueInWorkflow('url', json.url);
    } else if (json.location) {
      updateValueInWorkflow('url', json.location);
    } else {
      updateValueInWorkflow('url', '');
    }
  };

  const handleSubmit = () => {
    openConfigModal(modifiedCurlInput);
    toggleModal();
    setConfigValues(modifiedCurlInput);
    showCustomAlert({ alertSeverity: 'success', message: 'Your API has been successfully imported' });
  };

  return (
    <div className="import_curl_modal__drawer_background">
      <div className="import_curl_modal__drawer">
        <div className="import_curl_modal__drawer__heading_container">
          <div>
            <div>Import cURL</div>
            <span className="import_curl_modal__drawer__heading_container__sub">
              Import an API by pasting a cURL or JSON
            </span>
            <Tooltip
              title={(
                <span>
                  When pasting a cURL to import an API, ensure the appropriate format is used.
                  For example:
                  <br />
                  {'curl -X POST "https://api.example.com/submit" \\'}
                  <br />
                  {'-H "Content-Type: application/json" \\'}
                  <br />
                  {'-d \'{"name": "John", "age": 30}\' '}
                  <br />
                </span>
              )}
              arrow
              className="import_curl_modal__drawer__heading_container__tooltip"
            >
              <img src={infoIcon} alt="info" />
            </Tooltip>
          </div>
          <button
            type="button"
            className="import_curl_modal__drawer__heading_container__close_button"
            onClick={toggleModal}
          >
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <div className="import_curl_modal__drawer__body">
          <textarea
            value={curlInput}
            onChange={handleInputChange}
            placeholder="Paste your cURL or JSON here"
            rows="10"
            cols="50"
            className={`import_curl_modal__drawer__textarea ${!curlValid && curlInput.trim() ? 'import_curl_modal__drawer__textarea--warning' : ''}`}
          />
          {(!curlValid && curlInput.trim()) ? (
            <div className="import_curl_modal__drawer__warning">
              <img src={errorIcon} alt="warning" />
              <span>Invalid cURL </span>
              <span>{curlError}</span>
            </div>
          ) : null}
        </div>
        <div className="import_curl_modal__bottom_content">
          <div className="import_curl_modal__bottom_content__button">
            <button
              type="button"
              className="import_curl_modal__bottom_content__button__secondary_button"
              onClick={toggleModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="import_curl_modal__bottom_content__button__primary_button"
              onClick={handleSubmit}
              disabled={!curlValid}
            >
              Import cURL
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ImportCurlModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  openConfigModal: PropTypes.func.isRequired,
  updateValueInWorkflow: PropTypes.func,
};

ImportCurlModal.defaultProps = {
  updateValueInWorkflow: () => { },
};

export default ImportCurlModal;
