import PropTypes from 'prop-types';
import Toggle from '../FormModule/Common/Toggle';
import Dropdown from './editorComponents/Dropdown';
import TextInput from '../ImportWorkflow/TextInput';
import ConfigureValidationDrawer from '../FormModule/Common/ConfigureValidationDrawer';
import JSONArrayInput from './editorComponents/JSONArrayInput';

import './ModuleInputsEditPanel.scss';

function ModuleConfigurationGenericEditor(props) {
  const {
    properties, setPropertyValue, getDynamicDropdownItems, currInputConfig, menuOptions,
  } = props;

  const displayModulePropertyEditor = (property) => {
    const {
      type, label, key, location, default: defaultValue,
    } = property;

    switch (type) {
      case 'toggle':
        return (
          <Toggle
            label={label}
            value={currInputConfig[location][key] ?? defaultValue}
            onChange={(updatedValue) => setPropertyValue(key, location, updatedValue)}
          />
        );
      case 'dropDown':
        return (
          <Dropdown
            label={label}
            value={currInputConfig[location][key] || defaultValue}
            onChange={(updatedValue) => setPropertyValue(key, location, updatedValue)}
            items={property.values}
            allowCustomInput={property.allowCustomInput || false}
          />
        );
      case 'textbox':
        return (
          <TextInput
            label={label}
            value={currInputConfig[location][key] || defaultValue}
            onChange={(updatedValue) => setPropertyValue(key, location, updatedValue)}
          />
        );
      case 'condition':
        return (
          <ConfigureValidationDrawer
            defaultValue={{ type: 'rule', value: currInputConfig[location][key] || defaultValue }}
            hideRegexTab
            onChange={(updatedValue) => setPropertyValue(key, location, updatedValue.value)}
            dropDownOptions={menuOptions.items}
            hideErrorMessageInput
            texts={{ title: label }}
          />
        );
      case 'JSONArrayInput':
        return (
          <JSONArrayInput
            label={label}
            value={currInputConfig[location][key] || defaultValue}
            onChange={(updatedValue) => setPropertyValue(key, location, updatedValue)}
          />
        );
      case 'dynamicDropdown':
        return (
          <Dropdown
            label={label}
            value={currInputConfig[location][key] || defaultValue}
            onChange={(updatedValue) => setPropertyValue(key, location, updatedValue)}
            items={getDynamicDropdownItems(property.dynamicItemKey)}
            allowCustomInput={property.allowCustomInput || false}
          />
        );
      default:
        return null;
    }
  };

  return (properties || []).map((property) => (
    <div key={property.key} className="module_builder_property__input_container">
      {displayModulePropertyEditor(property)}
    </div>
  ));
}

ModuleConfigurationGenericEditor.propTypes = {
  properties: PropTypes.array.isRequired,
  setPropertyValue: PropTypes.func.isRequired,
  getDynamicDropdownItems: PropTypes.func.isRequired,
  currInputConfig: PropTypes.object.isRequired,
  menuOptions: PropTypes.object.isRequired,
};

export default ModuleConfigurationGenericEditor;
