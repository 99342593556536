/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const logsSlice = createSlice({
  name: 'logs',
  initialState: {
    workflow: [],
  },
  reducers: {
    appendLog: (state, action) => {
      const { log } = action.payload;
      state.workflow = [...state.workflow || [], log];
    },
  },
});

export const selectWorkflowLogs = (state) => state.workflow;

// Action creators are generated for each case reducer function
export const {
  appendLog,
} = logsSlice.actions;
export default logsSlice.reducer;
