import { getCurrentTimeStamp } from '../utils/dateFormats';
import pushLog from './pushLog';

const operations = {
  SET_MODULE_PROPERTY: 'SET_MODULE_PROPERTY',
  UNSET_MODULE_PROPERTY: 'UNSET_MODULE_PROPERTY',
  ADD_NEW_MODULE: 'ADD_NEW_MODULE',
  ADD_CONDITION: 'ADD_CONDITION',
  UPDATE_CONDITION_RULE: 'UPDATE_CONDITION_RULE',
  DELETE_MODULE: 'DELETE_MODULE',
  DELETE_CONDITION: 'DELETE_CONDITION',
  ADD_FORM_COMPONENT: 'ADD_FORM_COMPONENT',
  DELETE_FORM_COMPONENT: 'DELETE_FORM_COMPONENT',
  DRAG_FORM_COMPONENT: 'DRAG_FORM_COMPONENT',
  SET_FORM_COMPONENT_PROPERTY: 'SET_FORM_COMPONENT_PROPERTY',
  UPDATE_GOTO: 'UPDATE_GOTO',
  UPDATE_END_STATE: 'UPDATE_END_STATE',
  SWAP_CONDITION_BRANCHES: 'SWAP_CONDITION_BRANCHES',
  ADD_CONDITIONAL_VARIABLE: 'ADD_CONDITIONAL_VARIABLE',
  DELETE_CONDITIONAL_VARIABLE: 'DELETE_CONDITIONAL_VARIABLE',
  EDIT_CONDITIONAL_VARIABLE: 'EDIT_CONDITIONAL_VARIABLE',
  UPDATE_CONDITIONAL_VARIABLE: 'UPDATE_CONDITIONAL_VARIABLE',
};

export const logPropertyUpdateForModule = ({
  id, workflowKey, oldValue, newValue,
}) => {
  pushLog({
    operation: operations.SET_MODULE_PROPERTY,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      workflowKey,
      oldValue,
      newValue,
    },
  });
};

export const logPropertyUnsetForModule = ({ id, workflowKey, oldValue }) => {
  pushLog({
    operation: operations.UNSET_MODULE_PROPERTY,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      workflowKey,
      oldValue,
    },
  });
};

export const logAddNode = ({
  id, parentId, childId, subType,
}) => {
  pushLog({
    operation: operations.ADD_NEW_MODULE,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      parentId,
      childId,
      subType,
    },
  });
};

export const logAddCondition = ({
  id,
  parentId,
  childId,
}) => {
  pushLog({
    operation: operations.ADD_CONDITION,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      parentId,
      childId,
    },
  });
};

export const addConditionalVariableLog = ({ id }) => ({
  operation: operations.ADD_CONDITIONAL_VARIABLE,
  id,
  timeStamp: getCurrentTimeStamp(),
});

export const deleteConditionalVariableLog = ({ id }) => ({
  operation: operations.DELETE_CONDITIONAL_VARIABLE,
  id,
  timeStamp: getCurrentTimeStamp(),
});

export const editConditionalVariableLog = ({ id }) => ({
  operation: operations.EDIT_CONDITIONAL_VARIABLE,
  id,
  timeStamp: getCurrentTimeStamp(),
});

export const updateConditionalVariableLog = ({ id }) => ({
  operation: operations.UPDATE_CONDITIONAL_VARIABLE,
  id,
  timeStamp: getCurrentTimeStamp(),
});

export const logDeleteNode = ({
  id, parentId,
}) => {
  pushLog({
    id,
    operation: operations.DELETE_MODULE,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      parentId,
    },
  });
};

export const logConditionRuleUpdate = ({
  id,
  oldRule,
  newRule,
}) => {
  pushLog({
    operation: operations.UPDATE_CONDITION_RULE,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      oldRule,
      newRule,
    },
  });
};

export const logDeleteCondition = ({
  id, parentId,
}) => {
  pushLog({
    operation: operations.DELETE_CONDITION,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      parentId,
    },
  });
};

export const logAddFormComponent = ({
  id, pathArray, componentId, componentType,
}) => {
  pushLog({
    operation: operations.ADD_FORM_COMPONENT,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      pathArray,
      componentId,
      componentType,
    },
  });
};

export const logDeleteFormComponent = ({
  id, pathArray,
}) => {
  pushLog({
    operation: operations.DELETE_FORM_COMPONENT,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      pathArray,
    },
  });
};

export const logDragFormComponent = ({
  id, fromPathArray, toPathArray,
}) => {
  pushLog({
    operation: operations.DRAG_FORM_COMPONENT,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      fromPathArray,
      toPathArray,
    },
  });
};

export const logSetFormComponentProperty = ({
  id, pathArray, newComponent,
}) => {
  pushLog({
    operation: operations.SET_FORM_COMPONENT_PROPERTY,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      pathArray,
      newComponent,
    },
  });
};

export const logUpdateGoto = ({
  id, parentBranch, oldGotoStep, newGotoStep,
}) => {
  pushLog({
    operation: operations.UPDATE_GOTO,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      parentBranch,
      oldGotoStep,
      newGotoStep,
    },
  });
};

export const logEndStateUpdate = ({
  id, parentBranch, oldEndState, newEndState,
}) => {
  pushLog({
    operation: operations.UPDATE_END_STATE,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      parentBranch,
      oldEndState,
      newEndState,
    },
  });
};

export const logCreateConditionalVariable = ({
  id, conditionalVariable,
}) => {
  pushLog({
    operation: operations.ADD_CONDITIONAL_VARIABLE,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      conditionalVariable,
    },
  });
};

export const logSwapBranchesInCondition = ({
  id, newIfTrueBranch, newIfFalseBranch,
}) => {
  pushLog({
    operation: operations.SWAP_CONDITION_BRANCHES,
    id,
    timeStamp: getCurrentTimeStamp(),
    extras: {
      newIfTrueBranch, newIfFalseBranch,
    },
  });
};
