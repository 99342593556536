import PropTypes from 'prop-types';
import DebouncedJsonInput from '../../FormModule/Common/DebouncedJsonInput';

function JSONArrayInput(props) {
  const {
    label,
    value,
    onChange,
  } = props;

  return (
    <div className="module-builder-inputs-container-dropdown">
      <DebouncedJsonInput
        label={label}
        formatAsJson
        onChange={onChange}
        defaultValue={value}
        readOnly={false}
        placeholder="Enter a JSON array"
        isDisabled={false}
      />
    </div>
  );
}

JSONArrayInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default JSONArrayInput;
