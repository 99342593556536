// copied this code from NewConditionDrawer/utils here
// delete the previous one after conditiona are refactored

import operatorsAllowed from './operatorsAllowed';

const splitRuleUnit = (rule) => {
  const operators = operatorsAllowed
    .map((op) => op.value)
    .sort((a, b) => b.length - a.length);

  const operator = operators.find((op) => rule.includes(op));

  if (!operator) {
    throw new Error('Invalid comparison operator in the rule.');
  }

  const [left, right] = rule.split(operator).map((part) => part.trim());

  return {
    left,
    operator,
    right,
  };
};

export default splitRuleUnit;
