// import React from 'react';
import PropTypes from 'prop-types';
import './Workflow.scss';
import closeButton from '../assests/icons/closeButton.svg';

function DeleteNodeModal({ onClose, dependencies }) {
  return (
    <div className="add_node_overlay">
      <div className="delete_node_modal">
        <div className="add_node_modal_header">
          <span>
            Please remove the following dependencies
          </span>
          <button type="button" className="closeModalButton" onClick={onClose}>
            <img
              src={closeButton}
              alt="close"
            />
          </button>
        </div>
        <div className="add_node_modal_content">
          {dependencies.moduleDependencies.map((moduleId) => (
            <div key={moduleId}>
              {moduleId}
            </div>
          ))}
          {dependencies.conditionDependencies.map((condition) => (
            <div key={condition}>
              {condition}
            </div>
          ))}
          {dependencies.workflowOutputDependencies.map((outputName) => (
            <div key={outputName}>
              {`Workflow output: ${outputName}`}
            </div>
          ))}
          {dependencies.conditionalVariableDependencies.map((varId) => (
            <div key={varId}>
              {`ConditionalVariable: ${varId}`}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

DeleteNodeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  dependencies: PropTypes.object.isRequired,
};

export default DeleteNodeModal;
