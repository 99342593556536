import { cloneDeep } from 'lodash';

const replaceNextStepInConditions = (workflow, replacementMap) => {
  const editedWorkflow = cloneDeep(workflow);
  const { conditions = {} } = editedWorkflow;
  Object.keys(conditions).forEach((conditionId) => {
    const { if_true: ifTrueId, if_false: ifFalseId } = conditions[conditionId];
    if (replacementMap[ifTrueId]) {
      conditions[conditionId].if_true = replacementMap[ifTrueId];
    }
    if (replacementMap[ifFalseId]) {
      conditions[conditionId].if_false = replacementMap[ifFalseId];
    }
  });
  return editedWorkflow;
};

export default replaceNextStepInConditions;
