import { getNextStepForModule } from '../components/utils';

export const findParentIdWithoutGoTo = (children, selectedWorkflow) => {
  const foundChild = children.find((child) => {
    const { id } = child;
    const selectedModule = selectedWorkflow.modules.find((module) => module.id === id);
    let selectedCondition;
    if (!selectedModule) {
      selectedCondition = selectedWorkflow.conditions[id];
      const nextNodeType = selectedCondition?.next_node_type?.[child.path];
      return selectedCondition && !nextNodeType;
    }
    const nextNodeType = selectedModule.next_node_type?.[child.path];
    // Check if the selectedModule exists and its next_node_type is not empty
    return selectedModule && !nextNodeType;
  });
    // Return the id of the found child, or null if none is found
  return foundChild ? foundChild.id : null;
};

export const getParentNodes = (currentId, parentMap, selectedWorkflow) => {
  const parents = [];
  const visited = new Set();
  const traverseParentNodes = (id) => {
    if (visited.has(id)) {
      return;
    }
    visited.add(id);
    if (parentMap[id]) {
      const allParents = parentMap[id];

      if (allParents.length > 1) {
        const parentIdWithoutGoTo = findParentIdWithoutGoTo(allParents, selectedWorkflow);
        if (parentIdWithoutGoTo) {
          parents.push(parentIdWithoutGoTo);
          traverseParentNodes(parentIdWithoutGoTo);
        }
      } else if (allParents.length === 1) {
        parents.push(allParents[0].id);
        traverseParentNodes(allParents[0].id);
      }
    }
  };
  traverseParentNodes(currentId);

  return parents;
};
export const getChildrenNodesFromNode = (nodeId, workflow, endStates) => {
  const { modules, conditions } = workflow;
  const visitedNodes = new Set();
  const childrenNodes = [];

  const exploreNode = (node) => {
    if (visitedNodes.has(node) || endStates[node]) {
      return;
    }

    visitedNodes.add(node);
    childrenNodes.push(node);

    const module = modules.find((mod) => mod.id === node);
    if (module) {
      const nextSteps = getNextStepForModule(module);
      nextSteps.forEach(({ nextStep }) => {
        exploreNode(nextStep);
      });
    }

    const condition = conditions[node];
    if (condition) {
      if (condition.if_true) {
        exploreNode(condition.if_true);
      }
      if (condition.if_false) {
        exploreNode(condition.if_false);
      }
    }
  };

  exploreNode(nodeId);

  return childrenNodes;
};
