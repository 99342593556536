import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

function Menu({
  open, onClose, anchorPosition, children,
}) {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (open && !event.target.closest('.menu')) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [open, onClose]);

  if (!open) return null;

  return createPortal(
    <div
      ref={menuRef}
      className="menu"
      style={{
        top: anchorPosition?.top ?? 0,
        left: anchorPosition?.left ?? 0,
      }}
    >
      {children}
    </div>,
    document.body,
  );
}

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorPosition: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

function MenuItem({ onClick, disabled, children }) {
  const handleClick = (event) => {
    event.stopPropagation();
    onClick(event);
  };

  return (
    <div className="menuItems">
      <button
        className="action-items"
        style={{ color: children === 'Delete' ? 'rgba(191, 38, 0, 1)' : 'black' }}
        type="button"
        onClick={handleClick}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  );
}

MenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

MenuItem.defaultProps = {
  disabled: false,
};

Menu.Item = MenuItem;

export default Menu;
