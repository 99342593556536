const findAllDocumentsInSelectedCountries = (countries, config) => {
  const documentsSupported = {};

  countries.forEach((country) => {
    documentsSupported[country] = config[country].documentIds;
  });

  return documentsSupported;
};

export default findAllDocumentsInSelectedCountries;
