import { useState } from 'react';
import PropTypes from 'prop-types';
import closeIcon from '../../assests/icons/close.svg';
import './Conditions.scss';
import RuleGenerator from '../ViewWorkflow/RuleGenerator/RuleGenerator';
import operatorsAllowed from '../ViewWorkflow/ConditionalVariablesV2/utils/operatorsAllowed';

function ConditionsDrawer(props) {
  const {
    onClose,
    rule,
    onConditionSave,
    dropDownOptions,
    allowDefaultRules,
    preDefinedValues = [],
  } = props;
  const [condition, setCondition] = useState(rule || "'NONE_NONE' == 'NONE_NONE'");
  const [isRuleValid, setIsRuleValid] = useState(true);

  const hasDefaultRuleValue = (cond) => cond.includes("'NONE_NONE'");

  const leftSideDropdownElements = dropDownOptions?.filter((item) => item.label !== 'Predefined Values');
  const rightSideDropdownElements = dropDownOptions;

  return (
    <div className="condition_drawer__overlay">
      <div className="condition_drawer__modal">
        <div className="condition_drawer__title">
          <div>
            Condition
          </div>
          <div>
            <button
              type="button"
              className="condition_drawer__title_save"
              onClick={() => onConditionSave(condition)}
              disabled={!isRuleValid || (!allowDefaultRules && hasDefaultRuleValue(condition))}
            >
              Save
            </button>
            <button
              type="button"
              className="condition_drawer__title_close"
              onClick={onClose}
            >
              <img src={closeIcon} alt="close" />
            </button>
          </div>
        </div>
        <div className="condition_drawer__content">
          <RuleGenerator
            rule={condition}
            onRuleChange={(ruleObj) => {
              setCondition(ruleObj.rule);
              setIsRuleValid(ruleObj.isRuleValid);
            }}
            leftSideDomain={leftSideDropdownElements}
            rightSideDomain={rightSideDropdownElements}
            operatorsAllowed={operatorsAllowed}
            id="ruleGenerator"
            preDefinedValues={preDefinedValues}
          />
        </div>
      </div>
    </div>
  );
}

ConditionsDrawer.defaultProps = {
  preDefinedValues: [],
};

ConditionsDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  rule: PropTypes.string.isRequired,
  onConditionSave: PropTypes.func.isRequired,
  dropDownOptions: PropTypes.array.isRequired,
  allowDefaultRules: PropTypes.bool.isRequired,
  preDefinedValues: PropTypes.array,
};

export default ConditionsDrawer;
