// copied this code from NewConditionDrawer/utils here
// delete the previous one after conditiona are refactored

const ifComesAfter = (first, second) => {
  const convertToNumber = (colName) => {
    let num = 0;
    for (let i = 0; i < colName.length; i += 1) {
      num = num * 26 + (colName.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
    }
    return num;
  };

  const num1 = convertToNumber(first);
  const num2 = convertToNumber(second);
  return (num1 > num2);
};

const next = (colName) => {
  let carry = true;
  let newColName = '';

  for (let i = colName.length - 1; i >= 0; i -= 1) {
    let charCode = colName.charCodeAt(i) - 'A'.charCodeAt(0) + 1;

    if (carry) {
      charCode += 1;
      carry = false;
    }

    if (charCode > 26) {
      charCode = 1;
      carry = true;
    }

    newColName = String.fromCharCode('A'.charCodeAt(0) + charCode - 1) + newColName;
  }

  if (carry) {
    newColName = `A${newColName}`;
  }

  return newColName;
};

const getNextVariableName = (existingVariables) => {
  let lastVariable = existingVariables.length ? existingVariables[0] : '0';
  existingVariables.forEach((variable) => {
    if (ifComesAfter(variable, lastVariable)) lastVariable = variable;
  });
  return lastVariable === '0' ? 'A' : next(lastVariable);
};

export default getNextVariableName;
