/* eslint-disable no-restricted-syntax */

// copied this code from NewConditionDrawer/utils here
// delete the previous one after conditions are refactored
export const areAllVariablesDeclared = (expression, rulesMap) => {
  if (!expression || !rulesMap) return false;
  const isValidVariable = (token) => /^[A-Za-z_][A-Za-z0-9_]*$/.test(token);
  const variables = expression
    .split(/(\(|\)|&&|\|\||!)/)
    .map((token) => token.trim())
    .filter((token) => token !== '' && isValidVariable(token));

  for (const variable of variables) {
    if (!rulesMap[variable]) return false;
  }
  return true;
};

// copied this code from NewConditionDrawer/utils here
// delete the previous one after conditions are refactored
export const areParenthesesBalanced = (expression) => {
  const isOperator = (token) => ['&&', '||', '!'].includes(token);
  const isValidVariable = (token) => /^[A-Za-z_][A-Za-z0-9_]*$/.test(token);
  const exp = `(${expression})`;
  const tokens = exp
    .split(/(\(|\)|&&|\|\||!)/)
    .map((token) => token.trim())
    .filter((token) => token !== '');

  let noOfOperands = 0;
  const stack = [];
  let prevToken = null;

  for (const token of tokens) {
    if (token === '(') {
      if (prevToken === ')') return false;
      stack.push(token);
    } else if (token === ')') {
      if (prevToken === '(') return false;
      if (stack.length === 0) return false;

      while (stack.length > 0 && stack[stack.length - 1] !== '(') {
        const popped = stack.pop();
        if (popped === undefined) return false;

        if (isOperator(popped)) {
          if (popped !== '!') noOfOperands -= 1;
        } else {
          noOfOperands += 1;
        }
      }

      stack.pop(); // Remove '('
    } else if (isOperator(token)) {
      stack.push(token);
    } else {
      if (isValidVariable(prevToken)) return false;
      noOfOperands += 1;
    }

    if (noOfOperands < 0) return false;
    prevToken = token;
  }

  if (stack.length !== 0 || noOfOperands !== 1) return false;
  return true;
};

export const areOperatorsValid = (expression) => {
  for (let i = 0; i < expression.length; i += 1) {
    if (expression[i] === '&' && expression[i + 1] !== '&') {
      return false;
    }
    if (expression[i] === '|' && expression[i + 1] !== '|') {
      return false;
    }
    if (expression[i] === '&' || expression[i] === '|') i += 1;
  }
  return true;
};

const validateRule = (rule, ruleUnitMap) => areParenthesesBalanced(rule)
    && areAllVariablesDeclared(rule, ruleUnitMap)
    && areOperatorsValid(rule);

export default validateRule;
