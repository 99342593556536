import PropTypes from 'prop-types';
import closeIcon from '../../../assests/icons/CloseIcon.svg';

function ConfirmationModal({ isOpen, onClose, onConfirm }) {
  if (!isOpen) return null;

  return (
    <div className="import_curl_modal__drawer_background">
      <div className="import_curl_modal__drawer">
        <div className="import_curl_modal__drawer__heading_container">
          <div>Override Existing API Configuration?</div>
          <button type="button" onClick={onClose} className="import_curl_modal__drawer__heading_container__close_button">
            <img src={closeIcon} alt="close-icon" />
          </button>
        </div>
        <div className="import_curl_modal__drawer__body">
          <p className="import_curl_modal__drawer__body__content">
            By continuing to import your API,
            any configurations made previously will be overwritten.
          </p>
        </div>
        <div className="import_curl_modal__bottom_content">
          <div className="import_curl_modal__bottom_content__button">
            <button type="button" onClick={onClose} className="import_curl_modal__bottom_content__button__secondary_button">Cancel</button>
            <button type="button" onClick={onConfirm} className="import_curl_modal__bottom_content__button__primary_button">Continue to Import</button>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onConfirm: () => {},
};

export default ConfirmationModal;
