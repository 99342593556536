import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import DropDown from '../DropDown';

function TypedInput({
  value: currentValue,
  onChange,
  inputLabel,
  typeLabel,
  menuOptions,
}) {
  const getDataType = (value) => {
    const type = typeof value;
    if (['string', 'number', 'boolean'].includes(type)) return type;
    return 'string';
  };
  const getAllowedDataTypes = (value) => {
    const possibleDataTypes = ['string'];
    if (!Number.isNaN(parseFloat(value, 10))) possibleDataTypes.push('number');
    if (['true', 'false', true, false].includes(value)) possibleDataTypes.push('boolean');
    return possibleDataTypes;
  };

  const getDatatypeList = (allowedDataTypes) => {
    const list = [
      {
        label: 'String',
        value: 'string',
        displayLabel: 'String',
      },
    ];
    if (allowedDataTypes.includes('number')) {
      list.push({
        label: 'Number',
        value: 'number',
        displayLabel: 'Number',
      });
    }
    if (allowedDataTypes.includes('boolean')) {
      list.push({
        label: 'Bool',
        value: 'boolean',
        displayLabel: 'Bool',
      });
    }
    return list;
  };

  const [dataType, setDataType] = useState(getDataType(currentValue));
  const [allowedDataTypes, setAllowedDataTypes] = useState(getAllowedDataTypes(currentValue));

  useEffect(() => {
    const options = getAllowedDataTypes(currentValue);
    setAllowedDataTypes(options);
  }, [currentValue]);

  const convertToType = (value, type) => {
    let typedData = value;
    if (type === 'number') {
      const floatVal = parseFloat(value, 10);
      typedData = Number.isNaN(floatVal) ? 0 : floatVal;
    } else if (type === 'boolean') typedData = value === 'true';
    else typedData = `${value}`;
    return typedData;
  };

  const updateDataType = (finalDataType) => {
    setDataType(finalDataType);
    const typedData = convertToType(currentValue, finalDataType);
    onChange(typedData);
  };

  const handleTextChange = (updatedValue) => {
    const value = typeof updatedValue !== 'string' ? '' : updatedValue;
    const options = getAllowedDataTypes(value);
    const finalDataType = options.includes(dataType) ? dataType : 'string';
    const typedData = convertToType(value, finalDataType);
    onChange(typedData);
    setAllowedDataTypes(options);
    setDataType(finalDataType);
  };

  return (
    <>
      <div className="workflow_output_field_value_item">
        <DropDown
          allowCustomInput
          noSelectLabel={inputLabel}
          textBoxPlaceholder="Select"
          defaultValue={currentValue || null}
          onChange={(data) => {
            const value = data.value === null ? '' : data.value;
            handleTextChange(value);
          }}
          items={menuOptions}
          data-test-id="TypedInput:NestedDropdownWithInputsForValue"
          maxLength={35}
        />
      </div>
      <div className="workflow_output_field_typeSelectionDiv">
        <DropDown
          allowCustomInput={false}
          noSelectLabel={typeLabel}
          textBoxPlaceholder="Enter Value"
          defaultValue={dataType || null}
          onChange={(data) => {
            updateDataType(data.value);
          }}
          items={{
            label: 'Type',
            items: getDatatypeList(allowedDataTypes),
          }}
          data-test-id="TypedInput:NestedDropdownWithInputsForType"
        />
      </div>
    </>
  );
}

TypedInput.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  inputLabel: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  menuOptions: PropTypes.object.isRequired,

};

export default TypedInput;
