import { cloneDeep } from 'lodash';

const processModuleBuilderProperties = (workflow) => {
  const editedWorkflow = cloneDeep(workflow);
  const { moduleBuilder = {} } = editedWorkflow?.properties || {};
  if (Object.keys(moduleBuilder).length > 0) {
    const { moduleInputs = [], builderProperties = [] } = moduleBuilder;
    const updatedModuleInputs = moduleInputs.map((input) => ({
      ...input,
      config: {},
    }));
    const updatedBuilderProperties = builderProperties.map((property) => ({
      ...property,
      config: {},
    }));
    editedWorkflow.properties.moduleBuilder = {
      ...moduleBuilder,
      moduleInputs: updatedModuleInputs,
      builderProperties: updatedBuilderProperties,
    };
  }
  return editedWorkflow;
};

export default processModuleBuilderProperties;
