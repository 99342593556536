import { cloneDeep, set } from 'lodash';

const setModuleBuilderConfigurations = (workflow, data, path) => {
  const updatedWorkflow = cloneDeep(workflow);
  set(updatedWorkflow, path, data);
  return { updatedWorkflow, success: true };
};

export const updateLastModifiedTimestamp = (workflow, updatedAt) => {
  const updatedWorkflow = cloneDeep(workflow);
  set(updatedWorkflow, 'properties.moduleBuilder.updatedAt', updatedAt);
  return { workflow: updatedWorkflow, success: true };
};

export default setModuleBuilderConfigurations;
