import axios from 'axios';

const getWorkflowById = async (id, currentAppId) => {
  try {
    const dataRes = await axios({
      method: 'POST', // TODO: This should be changed to 'GET' instead of 'POST'
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/id`,
      headers: { appId: currentAppId },
      data: { workflowId: id },
    });
    const workflow = dataRes.data.result;
    return workflow;
  } catch (error) {
    return false;
  }
};

export default getWorkflowById;
