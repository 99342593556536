const processPath = (resumeId, originalModules) => {
  if (!resumeId) return null;
  const module = originalModules.find((m) => m.id === resumeId);
  if (!module) return null;
  return module.superModuleId && typeof module.superModuleId === 'string'
    ? module.superModuleId
    : module.id;
};

const getResumeFromMap =
(originalConditions, originalModules) => {
  const entries = Object.entries(originalConditions || {});
  return entries.reduce((mapSoFar, [conditionId, condition]) => {
    const trueResumeId = processPath(condition?.ifTrueConfigs?.resumeFrom, originalModules);
    const falseResumeId = processPath(condition?.ifFalseConfigs?.resumeFrom, originalModules);

    return {
      ifTrueConfigs: {
        ...mapSoFar.ifTrueConfigs,
        ...(trueResumeId && { [conditionId]: trueResumeId }),
      },
      ifFalseConfigs: {
        ...mapSoFar.ifFalseConfigs,
        ...(falseResumeId && { [conditionId]: falseResumeId }),
      },
    };
  }, { ifTrueConfigs: {}, ifFalseConfigs: {} });
};

export default getResumeFromMap;
