import deepEqualIgnoringArrayOrder from '../../../../../utils/deepEqual';

const createLogsForInputsRemoved = (removedInputs, latestModuleInputsMap) => removedInputs
  .map((input) => (
    !(input.workflowKey in latestModuleInputsMap) ? (
      <span key={input.workflowKey}>
        Input
        {' '}
        <b>
          &quot;
          {input.workflowKey}
          &quot;
        </b>
        {' '}
        has been removed
      </span>
    ) : (
      <span key={input.workflowKey}>
        Input
        {' '}
        <b>
          &quot;
          {input.workflowKey}
          &quot;
        </b>
        {' '}
        has been changed
      </span>
    )
  ));

const checkInputsRemovedInModuleVersion = (
  configurations,
) => {
  const {
    currentUiConfig = {},
    latestUiConfig = {},
  } = configurations || {};
  const currentModuleInputs = [
    ...(currentUiConfig?.sections?.inputs || []),
    ...(currentUiConfig?.sections?.configurations || []),
  ].filter((input) => input?.workflowKey?.includes('[+]'));
  const latestModuleInputs = [
    ...(latestUiConfig?.sections?.inputs || []),
    ...(latestUiConfig?.sections?.configurations || []),
  ].filter((input) => input?.workflowKey?.includes('[+]'));

  const latestModuleInputsMap = latestModuleInputs
    .reduce((acc, input) => {
      acc[input?.workflowKey] = input;
      return acc;
    }, {});

  const removedOrChangedModuleInputs = currentModuleInputs
    .filter((input) => !(input?.workflowKey in latestModuleInputsMap)
      || !deepEqualIgnoringArrayOrder(input, latestModuleInputsMap[input?.workflowKey]));

  if (removedOrChangedModuleInputs.length > 0) {
    return {
      hasDifferences: true,
      logs: createLogsForInputsRemoved(removedOrChangedModuleInputs, latestModuleInputsMap),
      extraData: {
        removedOrChangedModuleInputs,
      },
    };
  }

  return {
    hasDifferences: false,
    logs: [],
    extraData: {},
  };
};

export default checkInputsRemovedInModuleVersion;
