import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './SingleSelectDropdownStyles.scss';
import dropDownIcon from '../../assests/icons/dropDownIcon.svg';

function SingleSelectDropdown({
  items,
  onElementSelect,
  defaultValue,
  overrideDropdownStyles,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultValue);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    onElementSelect(item);
    setIsOpen(false);
  };

  const getSelectedItemName =
    (itemList, selectedItemId, defaultName) => itemList
      .find((item) => item.id === selectedItemId)?.name || defaultName;

  return (
    <div className="single-select-dropdown">
      <button type="button" className={`single-select-dropdown__dropdown-header ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
        {getSelectedItemName(items, selectedItem, defaultValue)}
        <img src={dropDownIcon} alt="open" />
      </button>
      {isOpen && (
        <ul className={`single-select-dropdown__dropdown-menu ${overrideDropdownStyles}`}>
          {items.map((item) => (
            <li key={item.id}>
              <button type="button" onClick={() => handleItemClick(item.value)}>
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

SingleSelectDropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  onElementSelect: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  overrideDropdownStyles: PropTypes.string,
};

SingleSelectDropdown.defaultProps = {
  defaultValue: 'Select here',
  overrideDropdownStyles: '',
};

export default SingleSelectDropdown;
