/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import kebabMenuIcon from '../../assests/icons/kebabMenuIcon.svg';
import '../../containers/Container.scss';
import DownloadWorkflow from './DownloadWorkflow';
import ConditionalVariables from './ConditionalVariablesButton';
import PublishToStaging from './PublishToStaging';

function MoreWorkflowOptions({
  allowedToShowDropDown: showOptions,
  showDropdown,
}) {
  const handleClick = () => {
    showDropdown(!showOptions);
  };

  return (
    <>
      <div className="more-options">
        <button type="button" className={`more-options__btn ${showOptions ? 'more-options__btn--clicked' : ''}`} onClick={handleClick}>
          <img src={kebabMenuIcon} alt="Options" />
        </button>
        {showOptions && (
          <>
            <div className="more-options__options">
              <DownloadWorkflow />
              <ConditionalVariables />
              <PublishToStaging />
            </div>
            <div
              className="more-options__bg"
              onClick={() => showDropdown(!showOptions)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') showDropdown(!showOptions);
              }}
              tabIndex="0"
              role="button"
              aria-expanded="true"
              aria-label="Close menu"
            />
          </>
        )}
      </div>
    </>
  );
}

MoreWorkflowOptions.propTypes = {
  allowedToShowDropDown: PropTypes.bool.isRequired,
  showDropdown: PropTypes.func.isRequired,
};

export default MoreWorkflowOptions;
