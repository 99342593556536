const moduleInputConfigurations = [
  {
    type: 'toggle',
    label: 'Toggle',
    baseStructure: {
      config: {},
      uiConfig: {
        type: 'toggle',
        values: ['yes', 'no'],
        on: 'yes',
        off: 'no',
        text: '',
        required: true,
        default: 'yes',
      },
    },
    properties: [
      {
        key: 'required',
        label: 'Required',
        type: 'toggle',
        location: 'uiConfig',
        default: true,
      },
      {
        key: 'text',
        label: 'Text',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'on',
        label: 'On Value',
        type: 'textbox',
        location: 'uiConfig',
        default: 'yes',
      },
      {
        key: 'off',
        label: 'Off Value',
        type: 'textbox',
        location: 'uiConfig',
        default: 'no',
      },
      {
        key: 'default',
        label: 'Default',
        type: 'dropDown',
        values: [
          {
            label: 'on',
            value: 'on',
          },
          {
            label: 'off',
            value: 'off',
          },
        ],
        location: 'uiConfig',
        default: 'on',
      },
      {
        key: 'enabled',
        label: 'Configure Enabled Rule',
        type: 'condition',
        location: 'uiConfig',
      },
      {
        key: 'visible',
        label: 'Configure Visibility Rule',
        type: 'condition',
        location: 'uiConfig',
      },
    ],
  },
  {
    type: 'singleSelectDropDown',
    label: 'Single Select Dropdown',
    baseStructure: {
      config: {},
      uiConfig: {
        type: 'singleSelectDropDown',
        required: true,
        default: null,
        title: '',
        values: [],
        labels: [],
        text: [],
      },
    },
    properties: [
      {
        key: 'required',
        label: 'Required',
        type: 'toggle',
        location: 'uiConfig',
        default: true,
      },
      {
        key: 'default',
        label: 'Default',
        type: 'dynamicDropdown',
        location: 'uiConfig',
        allowCustomInput: false,
        dynamicItemKey: 'uiConfig.values',
        default: null,
      },
      {
        key: 'title',
        label: 'Title',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'values',
        label: 'Configure dropdown values',
        type: 'JSONArrayInput',
        location: 'uiConfig',
        default: [],
      },
      {
        key: 'text',
        label: 'Configure labels for the dropdown values',
        type: 'JSONArrayInput',
        location: 'uiConfig',
        default: [],
      },
      {
        key: 'enabled',
        label: 'Configure Enabled Rule',
        type: 'condition',
        location: 'uiConfig',
      },
      {
        key: 'visible',
        label: 'Configure Visibility Rule',
        type: 'condition',
        location: 'uiConfig',
      },
    ],
  },
  {
    type: 'textbox',
    label: 'Textbox',
    baseStructure: {
      config: {},
      uiConfig: {
        type: 'textbox',
        default: '',
        required: true,
        text: '',
        placeholder: '',
      },
    },
    properties: [
      {
        key: 'required',
        label: 'Required',
        type: 'toggle',
        location: 'uiConfig',
        default: true,
      },
      {
        key: 'default',
        label: 'Default',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'text',
        label: 'Text',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'regex',
        label: 'Regex',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'placeholder',
        label: 'Placeholder',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'errortext',
        label: 'Error Text',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'enabled',
        label: 'Configure Enabled Rule',
        type: 'condition',
        location: 'uiConfig',
      },
      {
        key: 'visible',
        label: 'Configure Visibility Rule',
        type: 'condition',
        location: 'uiConfig',
      },
    ],
  },
  {
    type: 'inputToModule',
    label: 'Input to Module',
    baseStructure: {
      config: {},
      uiConfig: {
        type: 'inputToModule',
        default: '',
        required: true,
        title: '',
        sourceTitle: '',
        sourceLabel: '',
        variableTitle: '',
        variableLabel: '',
      },
    },
    properties: [
      {
        key: 'required',
        label: 'Required',
        type: 'toggle',
        location: 'uiConfig',
        default: true,
      },
      {
        key: 'default',
        label: 'Default',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'title',
        label: 'Title',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'sourceTitle',
        label: 'Source Title',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'sourceLabel',
        label: 'Source Label',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'variableTitle',
        label: 'Variable Title',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'variableLabel',
        label: 'Variable Label',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'enabled',
        label: 'Configure Enabled Rule',
        type: 'condition',
        location: 'uiConfig',
      },
      {
        key: 'visible',
        label: 'Configure Visibility Rule',
        type: 'condition',
        location: 'uiConfig',
      },
    ],
  },
];

export default moduleInputConfigurations;
