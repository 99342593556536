import PropTypes from 'prop-types';

function Divider({
  width = 110, leftMargin = -6, topMargin = '16px', bottomMargin = '0px',
}) {
  return (
    <hr style={{
      border: 'none',
      height: '1px',
      backgroundColor: 'rgb(221, 221, 221)',
      width: `${width}` || '110%',
      padding: 'unset',
      margin: 'unset',
      marginLeft: `${leftMargin}` || '-16px',
      marginTop: `${topMargin}` || '16px',
      marginBottom: `${bottomMargin}` || '0px',
    }}
    />
  );
}

export default Divider;

Divider.propTypes = {
  width: PropTypes.number.isRequired,
  leftMargin: PropTypes.number.isRequired,
  topMargin: PropTypes.string.isRequired,
  bottomMargin: PropTypes.string.isRequired,
};
