import { cloneDeep, set } from 'lodash';
import findIndexOfDocumentCountry from './findIndexOfDocumentCountry';

// TODO: refactor this function to remove redundant branches: https://gitlab.com/hvlabs/kyc/workflow-builder/-/merge_requests/144

const updateSelectedOptions = (selectedOptions, option, documentList) => {
  const index = findIndexOfDocumentCountry(selectedOptions, option.country, option.document);
  const countryIndex = selectedOptions
    .findIndex(
      (countryDocs) => option.country === countryDocs?.country,
    );

  const newSelectedOptions = cloneDeep(selectedOptions);

  if (index === -1) {
    // find if country is already present
    const filteredDocumentList = documentList.find(
      (country) => country.id === option.country,
    );
    if (countryIndex >= 0) {
      if (option.document) {
        newSelectedOptions[countryIndex].documents.push(option.document);
        newSelectedOptions[countryIndex].documentsName.push(option.documentName);
        const selectedDocument = filteredDocumentList.documents
          .find((doc) => doc.id === option.document);
        newSelectedOptions[countryIndex].configurations[option.document] = {
          sides: selectedDocument.sides,
        };
      } else if (newSelectedOptions[countryIndex].documents.length === 0) {
        const documents = filteredDocumentList.documents.map((item) => item.id);
        const documentsName = filteredDocumentList.documents.map((item) => item.name);
        newSelectedOptions[countryIndex].documents = documents;
        newSelectedOptions[countryIndex].documentsName = documentsName;
        newSelectedOptions[countryIndex].configurations = filteredDocumentList.documents
          .reduce((acc, doc) => ({
            ...acc,
            [doc.id]: { sides: doc.sides },
          }), {});
      } else {
        newSelectedOptions[countryIndex].documents = [];
        newSelectedOptions[countryIndex].documentsName = [];
        newSelectedOptions[countryIndex].configurations = {};
      }
    } else if (option.document) {
      const selectedDocument = filteredDocumentList.documents
        .find((doc) => doc.id === option.document);
      newSelectedOptions.push({
        country: option.country,
        countryName: option.countryName,
        documents: [option.document],
        documentsName: [option.documentName],
        configurations: {
          [option.document]: {
            sides: selectedDocument.sides,
          },
        },
      });
    } else {
      newSelectedOptions.push({
        country: option.country,
        documents: [],
        documentsName: [],
        countryName: option.countryName,
        configurations: {},
      });
    }
  } else {
    // a particular doc is deselected
    const documentIndex = newSelectedOptions[countryIndex].documents
      .findIndex((doc) => doc === option.document);
    if (option.newConfig) {
      set(newSelectedOptions[countryIndex], `configurations.${option.document}`, option.newConfig);
      if (newSelectedOptions[countryIndex].configurations[option.document].sides.length === 0) {
        newSelectedOptions[countryIndex].documents.splice(documentIndex, 1);
        newSelectedOptions[countryIndex].documentsName.splice(documentIndex, 1);
        delete newSelectedOptions[countryIndex].configurations[option.document];
      }
    } else {
      newSelectedOptions[countryIndex].documents.splice(documentIndex, 1);
      newSelectedOptions[countryIndex].documentsName.splice(documentIndex, 1);
      if (newSelectedOptions[countryIndex].configurations?.[option.document]) {
        delete newSelectedOptions[countryIndex].configurations[option.document];
      }
      // remove country that has 0 documents
      if (newSelectedOptions[countryIndex].documents.length === 0) {
        newSelectedOptions.splice(index, 1);
      }
    }
  }
  return newSelectedOptions;
};

export default updateSelectedOptions;
