import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import NestedDropdownWithInputs from '../ViewWorkflow/NestedDropdown/NestedDropdown';
import './ModuleInputsEditPanel.scss';
import TextInput from '../ImportWorkflow/TextInput';
import closeIcon from '../../assests/icons/CloseIcon.svg';

function ModuleOutputsDrawer({
  dropDownOptions,
  currOutput,
  onClose,
  onAddOutput,
}) {
  const [currOutputConfig, setCurrOutputConfig] = useState(currOutput || null);

  const canAddOutput = (output) => output.path
      && Boolean(output.name?.length)
      && !output.name.includes(' ')
      && Boolean(output.displayName?.length)
      && Boolean(output.description?.length);

  const setModuleOutputProperty = (property, value) => {
    setCurrOutputConfig((prevConfig) => ({ ...prevConfig, [property]: value }));
  };

  return (
    <div className="module_builder_inputs_drawer__overlay">
      <div className="module_builder_inputs_drawer__body">
        <div className="module_builder_inputs_drawer__headerContainer">
          <div className="module_builder_inputs_drawer__headerContent">
            Configure Module Output
          </div>
          <button type="button" className="module_builder_inputs_drawer__headerCloseButton" onClick={onClose}>
            <img
              src={closeIcon}
              alt="close"
            />
          </button>
        </div>
        <div className="module_builder__inputs_container">
          <div className="module_builder__inputs_container_dropdown">
            <div className="text-input-div">
              <Grid container alignItems="center" rowSpacing={1}>
                <Grid item xs={12}>Path</Grid>
                <Grid item xs={12}>
                  <NestedDropdownWithInputs
                    onChange={(selectedItem) => setModuleOutputProperty('path', selectedItem.value)}
                    noSelectLabel="Select Path for the Output Variable"
                    items={dropDownOptions}
                    defaultValue={currOutputConfig.path}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="module_builder__inputs_container_dropdown">
            <TextInput
              label="Key"
              isInvalid={!currOutputConfig.name?.length || currOutputConfig.name?.includes(' ')}
              onChange={(value) => setModuleOutputProperty('name', value)}
              value={currOutputConfig.name || ''}
            />
          </div>
          <div className="module_builder__inputs_container_dropdown">
            <TextInput
              label="Output Display Name"
              isInvalid={!currOutputConfig.displayName?.length}
              onChange={(value) => setModuleOutputProperty('displayName', value)}
              value={currOutputConfig.displayName || ''}
            />
          </div>
          <div className="module_builder__inputs_container_dropdown">
            <TextInput
              label="Output description"
              isInvalid={!currOutputConfig.description?.length}
              onChange={(value) => setModuleOutputProperty('description', value)}
              value={currOutputConfig.description || ''}
            />
          </div>
        </div>
        <div className="module_builder_inputs_drawer__bottomContent">
          <div className="module_builder_inputs_drawer__navButton">
            <button type="button" className="back-button" onClick={onClose}>
              Cancel
            </button>
            <button
              type="button"
              className="submit-button"
              onClick={() => onAddOutput(currOutputConfig)}
              disabled={!canAddOutput(currOutputConfig)}
            >
              Add Output
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}

ModuleOutputsDrawer.propTypes = {
  dropDownOptions: PropTypes.string.isRequired,
  currOutput: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddOutput: PropTypes.func.isRequired,
};

export default ModuleOutputsDrawer;
