export const checkNodesGettingDeleted = (oldWorkflow, updatedWorkflow) => {
  const { conditions: oldConditions } = oldWorkflow;
  const { conditions: newConditions } = updatedWorkflow;
  const newConditionIds = Object.keys(newConditions);
  const newModuleIds = updatedWorkflow.modules.map(({ id }) => id);
  const deletedModules = oldWorkflow.modules
    .filter(({ id }) => !newModuleIds.includes(id))
    .map(({ id, name: moduleName }) => ({ id, name: moduleName }));
  const deletedConditions = Object.keys(oldConditions)
    .filter((id) => !newConditionIds.includes(id))
    .map((id) => ({ id, name: oldConditions[id].name }));
  if (deletedModules.length || deletedConditions.length) {
    const deletedModulesStr = deletedModules.reduce(
      (acc, curr) => `${acc}\nmoduleId: ${curr.id} moduleName: ${curr.name || ''}`,
      '\n',
    );
    const deletedConditionsStr = deletedConditions.reduce(
      (acc, curr) => `${acc}\nconditionId: ${curr.id} conditionName: ${curr.name || ''}`,
      '\n',
    );
    const finalMessage = `Your action will remove following ${deletedModulesStr} ${deletedConditionsStr}`;
    return { showWarning: true, message: finalMessage };
  }
  return { showWarning: false, message: '' };
};

export const checkNodesGettingDeletedFromDynamicForm = (
  oldWorkflow,
  updatedWorkflow,
  sourceType,
) => {
  if (sourceType !== 'dynamicForm') return { showWarning: false, message: '' };
  return checkNodesGettingDeleted(oldWorkflow, updatedWorkflow);
};
