import { useEffect } from 'react';
import PropTypes from 'prop-types';
import './GenericModalStyles.scss';

function GenericModal({
  isOpen,
  onClose,
  children,
  'data-testid': dataTestId,
}) {
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    const handleClickOutside = (e) => {
      if (e.target.classList.contains('modal-overlay') && isOpen) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <div className={`modal-overlay ${isOpen ? 'open' : ''}`} data-testid={dataTestId}>
      {children}
    </div>
  );
}

GenericModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  'data-testid': PropTypes.string,
};

GenericModal.defaultProps = {
  'data-testid': 'modal_testID',
};

export default GenericModal;
