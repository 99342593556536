import { set } from 'lodash';
import convertToNodesEdges, { addUIPropertiesToNodes } from '../components/utils';
import { setModulePropertyInWorkflow, unsetModulePropertyInWorkflow } from '../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import { setDefaultUIConfigsForSuperModule, setDefaultTextConfigsForSuperModule } from '../containers/uiConfigOperations';
import { addNewNodeInWorkflow } from '../workflowOperations/operations/genericOperations';

export const getBasicWorkflowForModuleBuilder = (moduleName) => ({
  properties: {
    name: `${moduleName}_test`,
    description: 'Module Builder test workflow',
    builtOnBuilder: true,
    enableResumeWorkflow: false,
    redirectToMobile: 'optional',
    sdkVersions: {
      mobile: {
        maximum: '10.0.0',
        minimum: '0.3.0',
      },
      web: {
        maximum: '10.0.0',
        minimum: '5.0.0',
      },
    },
    useWebForm: true,
    web: {
      returnPartialSDKResponse: true,
    },
  },
  updatedAt: new Date().toISOString(),
  conditions: {
    condition_test: {
      rule: '"NONE_NONE" == "NONE_NONE"',
      if_true: 'approve',
      if_false: 'approve',
      next_node_type: {
        if_true: '',
        if_false: '',
      },
      name: 'condition_test',
    },
  },
  modules: [
    {
      type: 'countries',
      subType: 'countries',
      id: 'module_countryPicker',
      nextStep: 'condition_test',
      properties: {
        countriesSupported: [
          'ind',
        ],
      },
      next_node_type: {
        default: '',
      },
      name: 'Countries',
    },
  ],
  conditionalVariables: {},
});

export const addModuleInWorkflow = (
  basicWorkflow,
  versionedModules,
  moduleConfig,
  moduleUiConfig,
  defaultUiConfig,
  defaultTextConfig,
) => {
  const node = {
    ...moduleConfig,
    ...moduleUiConfig,
  };
  const addNodeBetween = {
    parent: basicWorkflow.modules[0].id,
    child: Object.keys(basicWorkflow.conditions)[0],
  };
  const { nodes: orderOfNodes } = convertToNodesEdges(basicWorkflow);
  const nodes = addUIPropertiesToNodes(orderOfNodes, versionedModules);
  const {
    workflow: updatedWorkflow, highLevelUiConfig, highLevelTextConfig, success,
  } =
    addNewNodeInWorkflow({
      node,
      addNodeBetween,
      nodes,
      workflowConfig: basicWorkflow,
      localOrderOfNodes: orderOfNodes,
      type: 'module',
    });
  if (!success) {
    throw new Error('Failed to add the new module in workflow');
  }
  let customUiConfig;
  let customTextConfig;
  if (Object.keys(highLevelUiConfig || {}).length) {
    customUiConfig = setDefaultUIConfigsForSuperModule(
      defaultUiConfig,
      highLevelUiConfig,
    );
    // set uiConfigSource to custom
    updatedWorkflow.properties.uiConfigSource = 'custom';
  }
  if (Object.keys(highLevelTextConfig || {}).length) {
    customTextConfig =
    setDefaultTextConfigsForSuperModule(defaultTextConfig, highLevelTextConfig);
    set(updatedWorkflow, 'properties.textConfigSource.en', 'custom');
  }
  return {
    updatedWorkflow,
    customUiConfig: customUiConfig || null,
    customTextConfig: customTextConfig || null,
  };
};

export const getWorkflowForModuleBuilderTesting =
  (moduleName, versionedModules, moduleConfig, moduleUiConfig, defaultUiConfig) => {
    const basicWorkflow = getBasicWorkflowForModuleBuilder(moduleName);
    // Generate the module config and uiConfig of the super module
    return addModuleInWorkflow(
      basicWorkflow,
      versionedModules,
      moduleConfig,
      moduleUiConfig,
      defaultUiConfig,
    );
  };

export const getLocalOrderOfNodes = (workflow) => {
  const { nodes: orderOfNodes } = convertToNodesEdges(workflow);
  return orderOfNodes;
};

export const updateModuleTestProperties = (
  workflowKey,
  value,
  moduleId,
  currModuleConfig,
  workflow,
) => {
  let updatedWorkflow;
  if (value === null) {
    updatedWorkflow = unsetModulePropertyInWorkflow(
      workflow,
      moduleId,
      workflowKey,
    );
  } else {
    updatedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      workflowKey,
      value,
      currModuleConfig,
    );
  }
  return updatedWorkflow;
};
