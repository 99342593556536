import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Checkbox.scss';

function Checkbox(props) {
  const {
    checked,
    onChange,
    label,
    className = '',
    disabled = false,
    id,
  } = props;

  const [isChecked, setIsChecked] = useState(typeof checked === 'boolean' ? checked : false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <label className={`custom-checkbox ${className}`} htmlFor={id}>
      <input
        type="checkbox"
        className="custom-checkbox__input"
        checked={isChecked}
        onChange={handleChange}
        disabled={disabled}
        id={id}
      />
      <span className="custom-checkbox__mark" />
      <span className="custom-checkbox__label">{label}</span>
    </label>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
  className: '',
  disabled: false,
};

export default Checkbox;
