import PropTypes from 'prop-types';
import Modal from './Modal';
import './ErrorModal.scss';
import generateUniqueID from '../../utils/generateUniqueId';

function ErrorModal({
  isOpen, onClose, errorData, onConfirm,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSave={onConfirm}
      headerText="Problems Found while Importing Flow"
      buttonText="Confirm and Import"
    >
      <div className="error-modal-content">
        {errorData?.map((section) => (
          <div key={generateUniqueID()} className="error-section">
            <p>{section.message}</p>
            <ul>
              {section.items.map((item) => (
                <li key={generateUniqueID()} className="error-items">
                  <div>
                    {item.description}
                  </div>
                  <div>
                    {item.actionText}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Modal>
  );
}

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errorData: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string.isRequired,
          actionText: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ErrorModal;
