import PropTypes from 'prop-types';
import setDefaultSelectedOptions from './utils/setDefaultSelectedOptions';
import MultiSelectDropDown from '../../Common/MultiSelectDropDown/MultiSelectDropDown';
import PropertiesTooltip from '../Tooltip/Tooltip';
import './MultiSelectDropDown.scss';

function MultiSelectDropdown(props) {
  const {
    element,
    isDisabled,
    updateValueInWorkflow,
    selectedWorkflow,
    selectedNodeId,
  } = props;

  const {
    values: options,
    label,
    title,
    infoButton = null,
    workflowKey,
  } = element;
  return (
    <div className="multi-select-wrapper-dropdown__container">
      <div className="multi-select-wrapper-dropdown__title">
        {title}
        <PropertiesTooltip infoButton={infoButton} />
      </div>
      <MultiSelectDropDown
        options={options}
        value={
          setDefaultSelectedOptions(
            selectedWorkflow,
            selectedNodeId,
            workflowKey,
          )
        }
        isDisabled={isDisabled}
        onChange={(value) => {
          updateValueInWorkflow(workflowKey, value);
        }}
        placeholder={label}
      />
    </div>
  );
}

MultiSelectDropdown.propTypes = {
  element: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool,
  updateValueInWorkflow: PropTypes.func.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
};

MultiSelectDropdown.defaultProps = {
  isDisabled: false,
};

export default MultiSelectDropdown;
