import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { fetchCurrentValueFromWorkflow, getSelectedModule } from '../InputsToModule/utils/updateWorkflow';
import { convertDurationToMillis, convertDurationInNewUnits } from '../../../utils/helper';
import DurationInput from './DurationInput';
import './Duration.scss';

function Duration(props) {
  const {
    element,
    isDisabled,
    updateValueInWorkflow,
    selectedWorkflow,
    selectedNodeId,
  } = props;

  const {
    workflowKey, default: defaultValue, unit, infoButton = null,
  } = element;

  const getDurationValue = (workflow, moduleId, key, unitOfStorage) => {
    const selectedModule = getSelectedModule(workflow, moduleId);
    const currentValue = fetchCurrentValueFromWorkflow(
      selectedModule,
      key,
    );
    const isValueDefinedInWorkflow = typeof currentValue === 'number';
    const fallbackValue = typeof defaultValue === 'number' ? defaultValue : 0;
    const value = isValueDefinedInWorkflow ? currentValue : fallbackValue;
    const finalValueInMillis = convertDurationToMillis(value, unitOfStorage);
    return finalValueInMillis;
  };

  const [durationInMillis, setDuration] = useState(
    getDurationValue(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
      unit,
    ),
  );

  useEffect(() => {
    const currentDurationValue = getDurationValue(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
      unit,
    );
    setDuration(currentDurationValue);
  }, [selectedNodeId, JSON.stringify(selectedWorkflow)]);

  useEffect(() => {
    const durationInUnits = convertDurationInNewUnits(durationInMillis, unit);
    updateValueInWorkflow(workflowKey, durationInUnits);
  }, [durationInMillis]);

  return (
    <DurationInput
      isDisabled={isDisabled}
      title={element.title}
      currentDurationInMillis={durationInMillis}
      onDurationChange={setDuration}
      unitsInWorkflow={unit}
      infoButton={infoButton}
    />
  );
}

Duration.propTypes = {
  element: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  updateValueInWorkflow: PropTypes.func.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
};

export default Duration;
