import {
  useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactFlow, {
  useNodesState, useEdgesState, Controls, useReactFlow,
} from 'reactflow';
import ELK from 'elkjs';
import { selectSelectedNode } from '../../reducers/workflow';
import './layout.scss';

const RELATIVE_X = 1 / 2;
const RELATIVE_Y = 1 / 5;
const PADDING_X = 0;
const PADDING_Y = 0;

const getLayoutedElements = async (nodes, edges) => {
  const elk = new ELK({});
  const newNodes = nodes.map((node) => ({
    ...node,
    width: node.style.width,
    height: node.style.height,
  }));
  const newEdges = edges.map((node) => (
    {
      ...node, sources: [node.source], targets: [node.target], interactionWidth: 50,
    }
  ));
  const graph = {
    id: 'root',
    layoutOptions: {
      'elk.algorithm': 'layered',
      'elk.spacing.nodeNode': '100',
      'elk.direction': 'DOWN',
      'elk.layered.spacing.nodeNodeBetweenLayers': '50',
      'elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
      'elk.layered.nodePlacement.favorStraightEdges': 'TRUE',
      'elk.layered.considerModelOrder.strategy': 'NODES_AND_EDGES',
      'org.eclipse.elk.edgeRouting': 'ORTHOGONAL',
    },
    children: newNodes,
    edges: newEdges,
  };
  const layout = await elk.layout(graph);
  // eslint-disable-next-line arrow-body-style
  const layoutNodes = layout.children.map((node) => {
    return { ...node, position: { x: node.x, y: node.y } };
  });
  return { nodes: layoutNodes, edges: layout.edges };
};

function ELKLayout({
  initialNodes,
  initialEdges,
  edgeTypes,
  nodeTypes,
  onNodeClick,
  resetDrawerType,
  highlightedNodes,
  isEscapePressed,
  searchValue,
}) {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const selectedNode = useSelector(selectSelectedNode);
  const { fitView, zoomOut } = useReactFlow();

  useLayoutEffect(() => {
    if (ref) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    }
  }, []);

  const translateExtent = useMemo(
    () => nodes.reduce(
      ([[left, top], [right, bottom]], { position }) => [
        [
          Math.min(left, (position ?? { x: Infinity }).x - width / 2 - PADDING_X),
          Math.min(top, (position ?? { y: Infinity }).y - height / 2 - PADDING_Y),
        ],
        [
          Math.max(right, (position ?? { x: -Infinity }).x + width / 2 + PADDING_X),
          Math.max(bottom, (position ?? { y: -Infinity }).y + height / 2 + PADDING_Y),
        ],
      ],
      [
        [Infinity, Infinity],
        [-Infinity, -Infinity],
      ],
    ),
    [nodes, height, width],
  );

  const onPaneClick = () => {
    resetDrawerType();
  };

  useEffect(() => {
    async function asyncFunc() {
      const { nodes: layoutedNodes, edges: layoutedEdges } = await getLayoutedElements(
        initialNodes,
        initialEdges,
      );
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    }
    asyncFunc();
  }, [initialNodes, initialEdges]);

  useEffect(() => {
    if (selectedNode && selectedNode.id) {
      fitView({
        nodes: [selectedNode],
        padding: 0.1,
        duration: 700,
        ease: 'ease-in',
        maxZoom: 1,
      });
    }
  }, [selectedNode, fitView]);

  useEffect(() => {
    if (isEscapePressed) {
      zoomOut({
        duration: 2000,
        ease: 'ease-out',
        maxZoom: 0.5,
      });
    }
  }, [isEscapePressed]);

  const getStartPosition = (startNode, pannelWidth, pannelHeight) => {
    const { x: startX, y: startY } = startNode || {};
    if (startNode) {
      return {
        x: pannelWidth * RELATIVE_X - 1 * startX,
        y: pannelHeight * RELATIVE_Y + startY,
        zoom: 1,
      };
    }
    return { x: 0, y: 0, zoom: 1 };
  };

  const viewPort = getStartPosition(nodes[0], width, height);
  const elements = nodes.map((node) => {
    const isSearched = highlightedNodes.find((highlightedNode) => highlightedNode.id === node.id);

    let updatedStyle = node.style;
    let highlight = false;

    if (highlightedNodes.length && !isSearched) {
      updatedStyle = {
        ...updatedStyle,
        filter: 'opacity(30%) blur(0.5px)',
        pointerEvents: 'none',
      };

      return {
        ...node,
        style: updatedStyle,
      };
    }

    if (highlightedNodes.length && isSearched) {
      highlight = true;

      return {
        ...node,
        data: {
          ...node.data,
          isSearched: highlight,
          searchValue,
        },
      };
    }

    return node;
  });

  const edgeElement = edges.map((edge) => {
    let updatedStyle = edge.style;
    if (highlightedNodes.length) {
      updatedStyle = {
        ...updatedStyle,
        filter: 'opacity(30%) blur(0.5px)',
        pointerEvents: 'none',
      };
    }

    return {
      ...edge,
      style: updatedStyle,
    };
  });

  return (
    <div className="layout" ref={ref}>
      {nodes?.length
        ? (
          <ReactFlow
            nodes={elements}
            edges={edgeElement}
            edgeTypes={edgeTypes}
            nodeTypes={nodeTypes}
            onNodeClick={onNodeClick}
            onPaneClick={onPaneClick}
            panOnScroll
            defaultViewport={viewPort}
            translateExtent={translateExtent}
          >
            <Controls showInteractive={false} />
          </ReactFlow>
        )
        : null}
    </div>
  );
}

ELKLayout.propTypes = {
  initialNodes: PropTypes.array.isRequired,
  initialEdges: PropTypes.array.isRequired,
  edgeTypes: PropTypes.object.isRequired,
  nodeTypes: PropTypes.object.isRequired,
  onNodeClick: PropTypes.func.isRequired,
  resetDrawerType: PropTypes.func.isRequired,
  highlightedNodes: PropTypes.array,
  isEscapePressed: PropTypes.bool,
  searchValue: PropTypes.string,
};

ELKLayout.defaultProps = {
  highlightedNodes: [],
  isEscapePressed: false,
  searchValue: '',
};

export default ELKLayout;
