import { cloneDeep, set } from 'lodash';

const setLottie = ({
  lottieUrl, lottieType, uiConfig,
}) => {
  const lottieKeyMap = {
    failure: 'endStateFailure',
    success: 'endStateSuccess',
    loading: 'endStateProcessing',
  };
  const lottieKey = lottieKeyMap[lottieType];
  let editedUiConfig = {};
  if (lottieUrl) {
    editedUiConfig = {
      ...cloneDeep(uiConfig),
      animation: {
        ...uiConfig.animation,
        [lottieKey]: lottieUrl,
      },
    };
  } else {
    editedUiConfig = {
      ...cloneDeep(uiConfig),
    };
    set(editedUiConfig, `animation.${lottieKey}`, '');
  }
  return { uiConfig: editedUiConfig, success: true };
};
export default setLottie;
