import PropTypes from 'prop-types';
import empty from '../../assests/icons/Empty.svg';

function EmptyScreen({ text }) {
  return (

    <div className="empty_container">
      <div className="empty">
        <img alt="empty" className="empty_image" src={empty} />
        <div className="empty_label">{text}</div>
      </div>
    </div>

  );
}
EmptyScreen.propTypes = {
  text: PropTypes.string.isRequired,
};
export default EmptyScreen;
