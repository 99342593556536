const checkSubModuleRemovedInModuleVersion = (
  configurations,
) => {
  const {
    currentModuleConfig = {},
    latestModuleConfig = {},
  } = configurations || {};
  const currentSubModules = currentModuleConfig?.library?.modules || [];
  const latestSubModuleIdList =
    latestModuleConfig?.library?.modules?.map((module) => module.id) || [];

  const removedSubModules = currentSubModules.filter(
    (subModule) => !latestSubModuleIdList.includes(subModule.id),
  );

  if (removedSubModules.length > 0) {
    return {
      hasDifferences: true,
      logs: removedSubModules.map((subModule) => (
        <span key={subModule.id}>
          Sub-module
          {' '}
          <b>
            &quot;
            {subModule.id}
            &quot;
          </b>
          {' '}
          has been removed
        </span>
      )),
      extraData: {
        removedSubModules,
      },
    };
  }

  return {
    hasDifferences: false,
    logs: [],
    extraData: {},
  };
};

export default checkSubModuleRemovedInModuleVersion;
