import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import Toggle from '../FormModule/Common/Toggle';
import DropDown from '../FormModule/Common/DropDown';
import IntTextInput from './IntTextInput/IntTextInput';
import { workflowOperationsObj as operations } from '../../workflowOperations';
import TextInput from '../Common/TextInput';
import isValidSDKVersion from '../../utils/isValidSdkVersion';
import { allowedExitOnEndStates } from '../constants';
import MultiSelectDropDownWithLabel from '../Common/MultiSelectDropDown/MultiSelectDropDownWithLabel';

// TODO: Create a config file for all these configurations
function DisplayWorkflowConfigurations({ workflowConfig }) {
  const regex = /^\d{1,2}\.\d{1,2}\.\d{1,2}(-[a-zA-Z]+)?$/;
  const updateRedirectToMobile = (value) => {
    updateWorkflowInState({}, true, {
      operation: operations.UNSET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.redirectToMobile',
      },
    });
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.web.redirectToMobile',
        value,
      },
    });
  };

  const onSetResumeWorkflow = (value) => {
    const { enable, duration } = value;
    const newWorkflow = cloneDeep(workflowConfig);
    if (typeof enable !== 'undefined') {
      updateWorkflowInState({}, true, {
        operation: operations.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: 'properties.enableResumeWorkflow',
          value: enable,
        },
      });
    }
    if (typeof duration !== 'undefined') {
      updateWorkflowInState({}, true, {
        operation: operations.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: 'properties.resumeWorkflowDuration',
          value: duration,
        },
      });
      newWorkflow.properties.resumeWorkflowDuration = duration;
    }
  };

  const onSetReturnPartialSDKResponse = (value) => {
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.web.returnPartialSDKResponse',
        value,
      },
    });
  };
  const setSDKVersionInWorkflow = (path, value) => {
    if (isValidSDKVersion(value)) {
      updateWorkflowInState({}, true, {
        operation: operations.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path,
          value,
        },
      });
    }
  };
  const onSetMobileSdkVersionMax = (value) => setSDKVersionInWorkflow('properties.sdkVersions.mobile.maximum', value);
  const onSetMobileSdkVersionMin = (value) => setSDKVersionInWorkflow('properties.sdkVersions.mobile.minimum', value);
  const onSetWebSdkVersionMax = (value) => setSDKVersionInWorkflow('properties.sdkVersions.web.maximum', value);
  const onSetWebSdkVersionMin = (value) => setSDKVersionInWorkflow('properties.sdkVersions.web.minimum', value);
  const onSetUseWebForm = (value) => {
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.useWebForm',
        value,
      },
    });
  };

  const isServerSideResumeEnabled = (workflow) => {
    const oldKey = workflow?.properties?.enableServerSideResume;
    const newKey = workflow?.properties?.serverSideResume?.enable;
    if (newKey !== undefined) return newKey === true;
    if (oldKey !== undefined) return oldKey === true;
    return false;
  };

  const getRedirectToMobileValue = (workflow) => {
    const oldKey = workflow.properties?.redirectToMobile;
    const newKey = workflow.properties?.web?.redirectToMobile;
    if (newKey !== undefined) return newKey;
    if (oldKey !== undefined) return oldKey;
    return '';
  };

  const updateServerSideResume = (value) => {
    updateWorkflowInState({}, true, {
      operation: operations.UNSET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.enableServerSideResume',
      },
    });
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.serverSideResume.enable',
        value,
      },
    });
  };

  const getExitOnEndStatesOptions = (endStates) => endStates.map((key) => (
    { value: key, label: key }
  ));

  const onSetExitOnEndStates = (endStates, enable) => {
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.serverSideResume.exitOnEndStates',
        value: endStates,
      },
    });
    // Doing this to ensure we use serverSideResume.enable to store the enable value
    updateServerSideResume(enable);
  };

  return (
    <>
      <div className="drawer_content__heading_default">CONFIGURATIONS OF THE WORKFLOW</div>
      <div className="edit-properties-div__property">
        <DropDown
          label="Redirect to mobile"
          options={[
            { key: 'yes', name: 'Yes' },
            { key: 'no', name: 'No' },
            { key: 'optional', name: 'Optional' },
          ]}
          defaultValue={getRedirectToMobileValue(workflowConfig)}
          onChange={(data) => {
            updateRedirectToMobile(data);
          }}
        />
      </div>
      <div className="edit-properties-div__property">
        <div className="edit-settings-div__toggle-container">
          <Toggle
            label="Resume workflow"
            onChange={(val) => {
              onSetResumeWorkflow({ enable: val });
            }}
            value={workflowConfig.properties?.enableResumeWorkflow === true}
          />
        </div>
      </div>
      {workflowConfig.properties?.enableResumeWorkflow ? (
        <IntTextInput
          label="Resume duration"
          onChange={(data) => {
            const duration = parseInt(data, 10);
            if (typeof duration === 'number' && duration > 0) onSetResumeWorkflow({ duration });
          }}
          defaultValue={workflowConfig.properties?.resumeWorkflowDuration}
        />
      ) : null}
      <div className="edit-properties-div__property">
        <div className="edit-settings-div__toggle-container">
          <Toggle
            label="Return partial SDK response"
            onChange={(val) => {
              onSetReturnPartialSDKResponse(val);
            }}
            value={workflowConfig.properties?.web?.returnPartialSDKResponse === true}
          />
        </div>
      </div>
      <div className="edit-properties-div__property">
        <div className="edit-settings-div__toggle-container">
          <Toggle
            label="Use web form"
            value={workflowConfig.properties?.useWebForm === true}
            onChange={(val) => {
              onSetUseWebForm(val);
            }}
          />
        </div>
      </div>
      <div className="edit-properties-div__property">
        <div className="edit-settings-div__toggle-container">
          <Toggle
            label="Enable Server Side Resume"
            value={isServerSideResumeEnabled(workflowConfig)}
            onChange={updateServerSideResume}
          />
        </div>
      </div>
      <div className="edit-properties-div__property">
        <MultiSelectDropDownWithLabel
          options={getExitOnEndStatesOptions(allowedExitOnEndStates)}
          onChange={(states) => onSetExitOnEndStates(
            states,
            isServerSideResumeEnabled(workflowConfig),
          )}
          value={
            workflowConfig?.properties?.serverSideResume?.exitOnEndStates || []
          }
          isDisabled={false}
          placeholder="Configure 'exit on end states'"
          label="Exit on end states"
        />
      </div>
      <div className="edit-properties-div__property">
        <TextInput
          label="Mobile Sdk Version Max"
          defaultInput={workflowConfig.properties?.sdkVersions?.mobile?.maximum}
          onSave={onSetMobileSdkVersionMax}
          placeholder="Enter Mobile SDK Max Version"
          errorText={`Please enter a valid SDK version here. If a valid value is not entered, the last value entered (${workflowConfig.properties?.sdkVersions?.mobile?.maximum || 'N/A'}) will be used.`}
          regex={regex}
          className="text-input-div__sdk-version"
        />
      </div>
      <div className="edit-properties-div__property">
        <TextInput
          label="Mobile Sdk Version Min"
          defaultInput={workflowConfig.properties?.sdkVersions?.mobile?.minimum}
          onSave={onSetMobileSdkVersionMin}
          placeholder="Enter Mobile SDK Min Version"
          errorText={`Please enter a valid SDK version here. If a valid value is not entered, the last value entered (${workflowConfig.properties?.sdkVersions?.mobile?.minimum || 'N/A'}) will be used.`}
          regex={regex}
          className="text-input-div__sdk-version"
        />
      </div>
      <div className="edit-properties-div__property">
        <TextInput
          label="Web Sdk Version Max"
          defaultInput={workflowConfig.properties?.sdkVersions?.web?.maximum}
          onSave={onSetWebSdkVersionMax}
          placeholder="Enter Web SDK Max Version"
          errorText={`Please enter a valid SDK version here. If a valid value is not entered, the last value entered (${workflowConfig.properties?.sdkVersions?.web?.maximum || 'N/A'}) will be used.`}
          regex={regex}
          className="text-input-div__sdk-version"
        />
      </div>
      <div className="edit-properties-div__property">
        <TextInput
          label="Web Sdk Version Min"
          defaultInput={workflowConfig.properties?.sdkVersions?.web?.minimum}
          onSave={onSetWebSdkVersionMin}
          placeholder="Enter Web SDK Min Version"
          errorText={`Please enter a valid SDK version here. If a valid value is not entered, the last value entered (${workflowConfig.properties?.sdkVersions?.web?.minimum || 'N/A'}) will be used.`}
          regex={regex}
          className="text-input-div__sdk-version"
        />
      </div>
    </>
  );
}

DisplayWorkflowConfigurations.propTypes = {
  workflowConfig: PropTypes.object.isRequired,
};

export default DisplayWorkflowConfigurations;
