import Button from '@mui/material/Button';
import { useState } from 'react';
import WorkflowConfigurationModal from './WorkflowConfigurationModal';
import BranchIcon from '../../assests/icons/branchIcon.svg';

function ConditionalVariablesButton() {
  const [showConditionalVariable, setShowConditionalVariables] = useState(false);

  const handleClick = () => {
    setShowConditionalVariables(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        className="more-options__option"
      >
        <img className="more-options__option-icon" src={BranchIcon} alt="Download Icon" />
        <span className="more-options__option-text">Conditional Variables</span>
      </Button>
      {
        showConditionalVariable
        && (
        <WorkflowConfigurationModal
          onClose={() => { setShowConditionalVariables(false); }}
          page="conditionalVariables"
        />
        )
      }
    </>
  );
}

export default ConditionalVariablesButton;
