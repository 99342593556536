import PropTypes from 'prop-types';

function ConditionalVariableName({ name, updateConditionalVariableName }) {
  return (
    <div className="conditionalVar__div">
      <div className="conditionalVar__name">
        Name
      </div>
      <input
        className="conditionalVar__name__input"
        value={name}
        placeholder="Ex: Driving License Number"
        onChange={(e) => updateConditionalVariableName(e.target.value)}
      />
    </div>
  );
}

export default ConditionalVariableName;

ConditionalVariableName.propTypes = {
  name: PropTypes.string.isRequired,
  updateConditionalVariableName: PropTypes.func.isRequired,
};
