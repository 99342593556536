import Button from '@mui/material/Button';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import GenericConfirmationModal from './GenericConfirmationModal/GenericConfirmationModal';
import useApiHooks from '../../hooks/api/useApiHooks';
import useShowErrorAlert, { useShowCustomAlert } from '../../hooks/custom/useCustomAlert';
import AlertCode from '../../constants/alertCodes';
import CodeIcon from '../../assests/icons/codeIcon.svg';

function PublishToStaging() {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const appIds = useSelector((state) => state.user.appIds);
  const clientId = useSelector((state) => state.user.clientId);
  const isWorkflowUnsaved = useSelector((state) => state.workflow.isEdited);
  const { search } = useLocation();
  const workflowId = new URLSearchParams(search).get('id');
  const showCustomAlert = useShowCustomAlert();
  const showErrorAlert = useShowErrorAlert();
  const { pushToStaging } = useApiHooks();
  const allowedAppIdTypes = ['STAGING'];
  const stagingAppIds = Object.keys(appIds)
    .filter((appId) => appIds[appId].clientId === clientId || !appIds[appId].clientId)
    .filter((appId) => allowedAppIdTypes.includes(appIds[appId].type));

  const publishWorkflowToStaging = async (allAppIds, selectedWorkflowId) => {
    let response = null;
    try {
      response = await pushToStaging(allAppIds, selectedWorkflowId);
      if (response && !response?.isSuccessful) {
        showErrorAlert({
          error: response.error,
          message: AlertCode.error.errorPublishingWorkflow,
        });
      } else {
        showCustomAlert({
          message: workflowId,
          alertSeverity: 'success',
        });
      }
    } catch (error) {
      showErrorAlert({
        error: response.error,
        message: AlertCode.error.errorPublishingWorkflow,
      });
    }
  };

  const onModalCancel = () => {
    setShowConfirmationModal(false);
  };

  const onModalConfirm = async () => {
    setShowConfirmationModal(false);
    setIsPublishing(true);
    publishWorkflowToStaging(stagingAppIds, workflowId)
      .then(() => {
        setIsPublishing(false);
      })
      .catch(() => {
        setIsPublishing(false);
      });
  };

  const showModal = () => {
    setShowConfirmationModal(true);
  };

  const descriptionTextArr = [
    {
      id: 'p1',
      text: `This action will push the workflow to ${stagingAppIds.join(',')}`,
    },
    {
      id: 'p2',
      text: 'If the workflow already exists, it will get overwritten.',
    },
    {
      id: 'p3',
      text: 'This is a highly sensitive operation and should not be used accidentally. Only proceed if you know exactly what you are doing.',
    },
  ];

  const isDisabled = isWorkflowUnsaved || stagingAppIds.length === 0;

  return (
    <>
      <Button
        variant="outlined"
        className={`more-options__option ${isDisabled ? 'more-options__option--disabled' : ''}`}
        onClick={showModal}
        disabled={isDisabled || isPublishing}
      >
        <img className="more-options__option-icon" src={CodeIcon} alt="Download Icon" />
        <span className="more-options__option-text">Publish to staging</span>
        {isPublishing ? (
          <CircularProgress size="1rem" sx={{ color: 'rgba(5, 5, 82, 0.8)' }} />
        ) : null}
      </Button>
      {showConfirmationModal ? (
        <GenericConfirmationModal
          titleText="Push to staging?"
          descriptionTextArr={descriptionTextArr}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
          onCancelClick={onModalCancel}
          onConfirmClick={onModalConfirm}
        />
      ) : null}
    </>
  );
}

export default PublishToStaging;
