import PropTypes from 'prop-types';
import { formComponentList as formComponentsConfig } from '../../../constants/dynamicFormComponents';
import {
  getConditionalVariables,
  getWorkflowInputVariables,
  getPredefinedValues,
  getModuleOutputs,
} from '../InputsToModule/utils';
import { fetchCurrentValueFromWorkflow, getSelectedModule } from '../InputsToModule/utils/updateWorkflow';
import ToggleWithCondition from '../../FormModule/Common/ToggleWithCondition';
import './ConditionalToggle.scss';

function ConditionalToggle(props) {
  const {
    description,
    isDisabled,
    updateValueInWorkflow,
    selectedWorkflow,
    selectedNodeId,
    versionedModules,
    orderOfModules,
  } = props;

  const {
    workflowKey, text, default: defaultValue, infoButton = null,
  } = description;

  const workflowInputs = getWorkflowInputVariables(selectedWorkflow);
  const conditionalVariables = getConditionalVariables(selectedWorkflow);
  const preDefinedValues = getPredefinedValues(selectedWorkflow, formComponentsConfig);
  const moduleOutputs = getModuleOutputs(
    orderOfModules,
    selectedNodeId,
    selectedWorkflow,
    formComponentsConfig,
    versionedModules,
    true,
  );
  const moduleBuilderProperties = selectedWorkflow?.properties?.moduleBuilder?.builderProperties
    || {};

  const getDefaultToggleValue = (workflow, moduleId, key) => {
    const module = getSelectedModule(workflow, moduleId);
    const currentValue = fetchCurrentValueFromWorkflow(module, key);
    const ToggleValue = currentValue || defaultValue;
    return ToggleValue;
  };
  const toggleValue = getDefaultToggleValue(selectedWorkflow, selectedNodeId, workflowKey);

  const updateValue = (value) => {
    if (value !== '' && value !== undefined && value !== null) {
      updateValueInWorkflow(workflowKey, value);
    }
  };

  return (
    <>
      <div className="description-toggle__label">
        <ToggleWithCondition
          label={text}
          onChange={updateValue}
          defaultValue={toggleValue}
          workflowInputs={workflowInputs}
          conditionalVariables={conditionalVariables}
          moduleOutputs={moduleOutputs}
          preDefinedValues={preDefinedValues}
          moduleBuilderProperties={moduleBuilderProperties}
          isDisabled={isDisabled}
          infoButton={infoButton}
        />
      </div>
    </>
  );
}

ConditionalToggle.propTypes = {
  description: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool,
  updateValueInWorkflow: PropTypes.func.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  versionedModules: PropTypes.object.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  orderOfModules: PropTypes.array.isRequired,
};

ConditionalToggle.defaultProps = {
  isDisabled: false,
};

export default ConditionalToggle;
