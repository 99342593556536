/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    appIds: {},
    currentAppId: '',
    permissions: null,
    role: '',
    appIdKeyToken: '',
    email: '',
    currentAppIdType: '',
    useCases: {},
    currentUseCase: '',
    useCaseAppIds: [],
    clientId: '',
  },
  reducers: {
    updateUserInfo: (state, action) => {
      const {
        permissions, role, email, useCases, appIds, clientId,
      } = action.payload;
      state.permissions = permissions;
      state.role = role;
      state.email = email;
      state.useCases = useCases;
      state.appIds = appIds;
      state.clientId = clientId;
      // TODO: Revisit this logic when toggling between appids is supported
      const [currentAppId] = Object.keys(appIds);
      state.currentAppId = currentAppId;
    },
    updateAppIdKeyToken: (state, action) => {
      const { token } = action.payload;
      state.appIdKeyToken = token;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserInfo, updateAppIdKeyToken,
} = userSlice.actions;
export default userSlice.reducer;
