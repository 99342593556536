/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const moduleBuilderSlice = createSlice({
  name: 'moduleBuilder',
  initialState: {
    enabled: false,
  },
  reducers: {
    toggleModuleBuilderMode: (state, action) => {
      const { mode } = action.payload;
      state.enabled = mode;
    },
  },
});

export const selectModuleBuilderMode = (state) => state.moduleBuilder.enabled;

// Action creators are generated for each case reducer function
export const {
  toggleModuleBuilderMode,
} = moduleBuilderSlice.actions;
export default moduleBuilderSlice.reducer;
