import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import downIcon from '../../assests/icons/dropDownIcon.svg';
import generateUniqueID from '../../utils/generateUniqueId';
import { CustomCheckboxWithLabel, CustomCheckboxGroup } from './CustomCheckboxComponents'; // Import the custom components
import EmptyScreen from './EmptyScreen';

function MultiSelectionDrawer(props) {
  const {
    data, selectedItems,
    onSelectItems, text, drawerTitle, nestedCheckboxSelection,
  } = props;
  const [openLabels, setOpenLabels] = useState({});
  const initialMainItemCheckedState = {};
  data.items.forEach((mainItem) => {
    if (nestedCheckboxSelection) {
      const allSubItemsChecked = mainItem.items.every(
        (subItem) => selectedItems.some(
          (selectedItem) => selectedItem.value === subItem.value,
        ),
      );
      initialMainItemCheckedState[mainItem.label] = allSubItemsChecked;
    } else if (selectedItems.some((item) => item.value === mainItem.value)) {
      initialMainItemCheckedState[mainItem.label] = true;
    }
  });
  const [mainItemChecked, setMainItemChecked] = useState({});

  const toggleLabel = (label) => {
    setOpenLabels((prevOpenLabels) => ({
      ...prevOpenLabels,
      [label]: !prevOpenLabels[label],
    }));
  };
  const handleMainCheckboxChange = (e, label, isChecked) => {
    e.stopPropagation();
    if (isChecked) {
      const subItems = data.items.find((item) => item.label === label)?.items || [];
      onSelectItems((prevSelected) => {
        const newItems = subItems
          .filter((subItem) => !prevSelected.some(
            (selectedItem) => selectedItem.value === subItem.value,
          ))
          .map((subItem) => ({
            id: generateUniqueID(),
            variable: subItem.displayLabel.replace(/ > /g, '_').replace(/\s/g, '_'),
            value: subItem.value,
          }));
        return [...prevSelected, ...newItems];
      });
    } else {
      onSelectItems((prevSelected) => prevSelected.filter(
        (item) => !data.items.find((mainItem) => mainItem.label === label)?.items.some(
          (subItem) => subItem.value === item.value,
        ),
      ));
    }
  };

  const handleCheckboxChange = (e, item, subItem = null) => {
    const isChecked = e.target.checked;
    if (subItem === null) {
      if (isChecked) {
        onSelectItems((prevSelected) => {
          const isAlreadySelected = prevSelected.some(
            (selectedItem) => selectedItem.value === item.value,
          );
          if (!isAlreadySelected) {
            const id = generateUniqueID();
            return [...prevSelected, {
              id,
              variable: item.label,
              value: item.value,
            }];
          }
          return prevSelected;
        });
      } else {
        onSelectItems((prevSelected) => prevSelected.filter(
          (prev) => prev.value !== item.value,
        ));
      }
      setMainItemChecked((prevMainItemChecked) => ({
        ...prevMainItemChecked,
        [item.label]: isChecked,
      }));
    } else {
      onSelectItems((prevSelected) => {
        const updatedSelected = isChecked
          ? [
            ...prevSelected,
            {
              id: generateUniqueID(),
              variable: subItem.displayLabel.replace(/ > /g, '_').replace(/\s/g, '_'),
              value: subItem.value,
            },
          ]
          : prevSelected.filter((prev) => prev.value !== subItem.value);
        return updatedSelected;
      });
    }
  };
  useEffect(() => {
    const mainItemCheckedState = {};
    if (nestedCheckboxSelection) {
      data.items.forEach((mainItem) => {
        const allSubItemsChecked = mainItem.items.every(
          (subItem) => selectedItems.some(
            (selectedItem) => selectedItem.value === subItem.value,
          ),
        );
        mainItemCheckedState[mainItem.label] = allSubItemsChecked;
      });
      setMainItemChecked(mainItemCheckedState);
    } else {
      setMainItemChecked((prevMainItemChecked) => {
        const updatedMainItemChecked = { ...prevMainItemChecked };

        data.items.forEach((selectedItem) => {
          if (selectedItems.some((option) => option.value === selectedItem.value)) {
            updatedMainItemChecked[selectedItem.label] = true;
          }
        });
        return updatedMainItemChecked;
      });
    }
  }, [selectedItems, data]);

  const renderLabels = () => (
    <Box display="flex" flexDirection="column" gap="12px" height="370px" overflow="scroll">
      {
     data.items
       .slice()
       .sort((a, b) => a.label.localeCompare(b.label))
       .map((item) => {
         const sortedItems = nestedCheckboxSelection ?
           item.items.slice().sort((a, b) => a.label.localeCompare(b.label)) : '';

         return (

           <button key={item.label} type="button" className="labelButton" onClick={() => toggleLabel(item.label)}>
             <div className={nestedCheckboxSelection ? 'labelContainer' : 'labelContainerWithHover'}>
               <div className="labelContainerLayout">
                 <div className="labelHeading">
                   <CustomCheckboxWithLabel
                     label={item.label}
                     checked={mainItemChecked[item.label]}
                     onChange={(e) => (nestedCheckboxSelection
                       ? handleMainCheckboxChange(e, item.label, e.target.checked)
                       : handleCheckboxChange(e, item))}
                   />
                 </div>
                 {drawerTitle === 'Module Outputs' && (
                   openLabels[item.label] ? (
                     <div className="upIcon">
                       <img alt="upIcon" src={downIcon} />
                     </div>
                   ) : (
                     <div className="downIcon">
                       <img alt="downIcon" src={downIcon} />
                     </div>
                   )
                 )}
               </div>
               {nestedCheckboxSelection && openLabels[item.label] && (
                 <div className="subLabelHeading">
                   <CustomCheckboxGroup
                     items={sortedItems}
                     selectedItems={selectedItems}
                     handleCheckboxChange={handleCheckboxChange}
                     toggleLabel={toggleLabel}
                     item={item}
                   />
                 </div>
               )}
             </div>
           </button>
         );
       })
}
    </Box>
  );

  return (
    <>
      <div className="customInput_heading_container">
        <div className="customInput_heading">{drawerTitle}</div>
        <div className="selectAll">
          {data.items.length > 0 && (
          <CustomCheckboxWithLabel
            checked={data.items.every((item) => mainItemChecked[item.label])}
            onChange={(e) => {
              data.items.forEach((item) => (
                nestedCheckboxSelection ? (
                  handleMainCheckboxChange(e, item.label, e.target.checked)
                ) : handleCheckboxChange(e, item)
              ));
            }}
            label="Select All"
          />
          )}
        </div>
      </div>
      <Box>
        {data.items.length > 0 ? renderLabels() : (
          <EmptyScreen text={text} />
        )}
      </Box>
    </>
  );
}

MultiSelectionDrawer.propTypes = {
  data: PropTypes.object.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onSelectItems: PropTypes.func.isRequired,
  drawerTitle: PropTypes.string.isRequired,
  nestedCheckboxSelection: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default MultiSelectionDrawer;
