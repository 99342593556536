const workflowListPageComponentPermissions = {
  requiredPermissions: {
    viewPermissions: ['workflowListPage.view'],
    interactPermissions: ['workflowListPage.interact'],
  },
  redirect: () => { window.location.href = `${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}/home`; },
};

const workflowListPagePermissions = {
  workflowListPage: workflowListPageComponentPermissions,
};

export default workflowListPagePermissions;
