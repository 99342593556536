import PropTypes from 'prop-types';
import closeIcon from '../../../assests/icons/close.svg';
import './ConditionalVariable.scss';

function ConditionalVariableTitleRibbon(props) {
  const {
    handleSave,
    handleClose,
    containsInvalidRule,
  } = props;

  return (
    <>
      <div className="conditionalVar__title__div">
        <div className="conditionalVar__title">
          New Conditional Variable
        </div>
        <div className="conditionalVar__title__close-div">
          <button
            type="button"
            className="conditionalVar__title__create"
            onClick={handleSave}
            disabled={containsInvalidRule}
          >
            Save
          </button>
          <button
            type="button"
            className="closeModalButton"
            onClick={handleClose}
          >
            <img src={closeIcon} alt="close" />
          </button>
        </div>
      </div>
    </>
  );
}

export default ConditionalVariableTitleRibbon;

ConditionalVariableTitleRibbon.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  containsInvalidRule: PropTypes.bool.isRequired,
};
