import PropTypes from 'prop-types';
import './Workflow.scss';
import { Handle, Position } from 'reactflow';
import gotoIcon from '../assests/icons/gotoIcon.svg';
import editIcon from '../assests/icons/editIcon.svg';
import HighlightedText from './HighlightedText';

function GotoNode({ data }) {
  const {
    id,
    name,
    parentBranch,
    parentId,
    parentPath,
    showEditNodeModal,
    nextStepFrom,
    isSearched = false,
    searchValue,
  } = data;

  const onEditGoto = (event, parentModuleId, parentModuleBranch, parentModulePath) => {
    event.stopPropagation();
    showEditNodeModal({
      id: parentModuleId,
      parentBranch: parentModuleBranch,
      parentPath: parentModulePath,
      nextStepFrom,
    });
  };

  return (
    <div className="custom_node_outer">
      <button
        type="button"
        className="custom_node_outer__sideButton"
        onClick={(e) => onEditGoto(e, parentId, parentBranch, parentPath)}
      >
        <img
          src={editIcon}
          alt="edit"
        />
      </button>
      <div className="custom_node">
        <div className="custom_node_inner">
          <Handle type="target" position={Position.Top} isConnectable />
          <div className="custom_node__icon">
            <img
              src={gotoIcon}
              alt={id}
              className="custom_edge_button__img"
            />
          </div>
          <div className="custom_node__body">
            {isSearched ? (
              <p className="custom_node__text_goto">
                Goto:&nbsp;
                <HighlightedText text={name} highlight={searchValue} className="custom_node__text_goto_highlighted" />
              </p>
            ) : (
              <p className="custom_node__text_goto">
                {`Goto: ${name}`}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

GotoNode.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GotoNode;
