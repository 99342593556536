import { moduleConfigValidation, moduleUiConfigValidation } from '../../schemas/moduleBuilderSchemas';
import HVError, { errorCodes, errorMessages } from '../../utils/error';

const validateJoiSchema = (schema, data) => {
  const { error = null } = schema.validate(data);
  if (error) {
    throw new HVError({
      code: error?.code || errorCodes.somethingWentWrong,
      message: error?.message || errorMessages.errorValidatingSchema,
      originalError: error,
    });
  }
};
export const validateJoiSchemaForModuleConfig = (moduleConfig) => {
  try {
    validateJoiSchema(
      moduleConfigValidation,
      moduleConfig,
    );
  } catch (error) {
    throw new HVError({
      code: error?.code,
      message: `Module validation error: ${error?.message}` || errorMessages.errorValidatingSchema,
      originalError: error instanceof HVError ? error.originalError : error,
    });
  }
};

export const validateJoiSchemaForModuleUiConfig = (moduleUiConfig) => {
  try {
    validateJoiSchema(
      moduleUiConfigValidation,
      moduleUiConfig,
    );
  } catch (error) {
    throw new HVError({
      code: error?.code,
      message: `Module UIconfig validation error: ${error?.message}` || errorMessages.errorValidatingSchema,
      originalError: error instanceof HVError ? error.originalError : error,
    });
  }
};
