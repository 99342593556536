import HVError, { errorCodes, errorMessages } from '../../utils/error';
import { containDuplicates } from './validateNoDuplicateIdsInModuleConfig';

const validateUniqueOutputNames = (moduleConfig) => {
  const outputNames = moduleConfig.variables.map(({ name }) => name);
  if (containDuplicates(outputNames)) {
    throw new HVError({
      code: errorCodes.invalidModuleConfig,
      message: errorMessages.foundDuplicatesInOutputNames,
    });
  }
};

export default validateUniqueOutputNames;
