import { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import deleteIcon from '../../../assests/icons/deleteRuleIcon.svg';
import getPredefinedValuesForRightSideDropdown from '../ConditionalVariablesV2/utils/populatePredefinedValues';
import DropDown from '../DropDown';

function Rule(props) {
  const {
    name,
    ruleUnit,
    onRuleUnitChange,
    leftSideDomain,
    rightSideDomain,
    splitRuleUnit,
    onDeleteRuleUnit,
    canDeleteRuleUnit,
    operatorsAllowed,
    preDefinedValues,
  } = props;

  const {
    left: lhs = "'NONE_NONE'",
    right: rhs = "'NONE_NONE'",
    operator = '==',
    error = null,
  } = useMemo(() => {
    try {
      const { left, operator: op, right } = splitRuleUnit(ruleUnit);
      return {
        left, operator: op, right,
      };
    } catch (err) {
      return {
        left: "'NONE_NONE'", operator: '==', right: "'NONE_NONE'", error: err,
      };
    }
  }, [ruleUnit]);

  useEffect(() => {
    if (error) alert(error);
  }, [error]);

  const [rhsDropdownOptions, setRhsDropdownOptions] = useState(
    getPredefinedValuesForRightSideDropdown(
      splitRuleUnit(ruleUnit).left || '',
      rightSideDomain,
      preDefinedValues,
    ),
  );

  const handleRuleChange = (currleftOperand, currOperator, currRightOperand) => {
    const leftOperand = currleftOperand || "'NONE_NONE'";
    const rightOperand = currRightOperand || "'NONE_NONE'";
    const operation = currOperator || '==';
    const newRule = `${leftOperand} ${operation} ${rightOperand}`;
    const rhsOptions = getPredefinedValuesForRightSideDropdown(
      leftOperand,
      rightSideDomain,
      preDefinedValues,
    );
    setRhsDropdownOptions(rhsOptions);
    onRuleUnitChange(newRule);
  };

  return (
    <div className="condition__content__var">
      <div className="condition__content__var__name">
        <span className="condition__content__var__name__var">
          {name}
        </span>
        <span className="condition__content__var__name__is">
          is
        </span>
      </div>
      <Grid container alignItems="center" rowSpacing={2} columnSpacing={1}>
        <Grid
          item
          xs={5}
        >
          <div className="condition__dropdown__wrapper">
            <DropDown
              items={{
                items: leftSideDomain,
                label: 'Select a value',
              }}
              allowCustomInput
              onChange={(val) => {
                handleRuleChange(val.value, operator, rhs);
              }}
              noSelectLabel="Select a value"
              defaultValue={lhs === "'NONE_NONE'" ? null : lhs}
              textBoxPlaceholder="For Strings, Use 'Single Quotes'"
              maxLength={40}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="condition__dropdown__wrapper">
            <DropDown
              items={{
                items: operatorsAllowed,
                label: 'Select operator',
              }}
              onChange={(val) => handleRuleChange(lhs, val.value, rhs)}
              noSelectLabel="Select a value"
              defaultValue={operator}
              textBoxPlaceholder="For Strings, Use 'Single Quotes'"
            />
          </div>
        </Grid>
        <Grid item xs={5}>
          <div className="condition__dropdown__wrapper">
            <DropDown
              items={{
                items: rhsDropdownOptions,
                label: 'Select a value',
              }}
              allowCustomInput
              noSelectLabel="Select a value"
              onChange={(val) => handleRuleChange(lhs, operator, val.value)}
              defaultValue={rhs === "'NONE_NONE'" ? null : rhs}
              textBoxPlaceholder="For Strings, Use 'Single Quotes'"
              maxLength={40}
            />
          </div>
        </Grid>
      </Grid>
      {
        canDeleteRuleUnit && (
          <div className="condition__content__var__delete-copy-div">
            <button
              type="button"
              className="condition__content__var__delete"
              onClick={onDeleteRuleUnit}
            >
              <img className="condition__content__var__delete-copy-icon" src={deleteIcon} alt="delete" />
            </button>
          </div>
        )
      }
    </div>
  );
}

Rule.propTypes = {
  name: PropTypes.string.isRequired,
  ruleUnit: PropTypes.string.isRequired,
  onRuleUnitChange: PropTypes.func.isRequired,
  leftSideDomain: PropTypes.array.isRequired,
  rightSideDomain: PropTypes.array.isRequired,
  splitRuleUnit: PropTypes.func.isRequired,
  onDeleteRuleUnit: PropTypes.func.isRequired,
  canDeleteRuleUnit: PropTypes.bool.isRequired,
  operatorsAllowed: PropTypes.array.isRequired,
  preDefinedValues: PropTypes.array,
};

Rule.defaultProps = {
  preDefinedValues: [],
};

export default Rule;
