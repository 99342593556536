import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './Workflow.scss';
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import closeButton from '../assests/icons/closeButton.svg';
import addIcon from '../assests/icons/addIcon.svg';
import genericModuleIcon from '../assests/icons/genericModuleIcon.svg';
import conditionIcon from '../assests/icons/condition.svg';
import { selectVersionedModules } from '../reducers/workflow';

function AddNodeModal({ onClose, addNewNode, addNewCondition }) {
  const [moduleNodes, setmoduleNodes] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [conditionModule, setconditionModule] = useState(null);
  const versionedModules = useSelector(selectVersionedModules);

  const conditionModuleHeading = 'If Else Condition';
  const inputRef = useRef();

  useEffect(() => {
    const endStates = [];
    const modules = [];
    inputRef.current.focus();
    Object.values(versionedModules).forEach((module) => {
      const { latestVersion = 'v1' } = module;
      const { config, uiConfig } = module[latestVersion];
      if (config.nodeDisplayType === 'output') { endStates.push(config); }
      if (uiConfig) {
        if (config.DRAWER_TYPE !== 'CONDITION') {
          const currentModuleNames = modules.map((mod) => mod.moduleName);
          if (!currentModuleNames.includes(uiConfig.moduleName)) {
            modules.push({
              version: latestVersion,
              // TODO: This sort of destructing should not be done and can lead to conflicts.
              ...config,
              ...uiConfig,
            });
          }
        } else if (config.nodeDisplayType === 'condition') {
          setconditionModule(config);
        }
      }
    });
    modules.sort((a, b) => a.moduleName?.localeCompare(b.moduleName));
    setmoduleNodes(modules);
  }, [versionedModules]);

  const moduleNodesToDisplay = useMemo(() => moduleNodes?.filter(
    ({ moduleName, description }) => moduleName.toLowerCase().includes(inputValue.toLowerCase())
      || description.toLowerCase().includes(inputValue.toLowerCase()),
  ), [moduleNodes, inputValue]);

  const isConditionMatching = useMemo(
    () => conditionModule
      && conditionModuleHeading.toLowerCase().includes(inputValue.toLowerCase()),
    [conditionModule, inputValue],
  );

  useEffect(() => {
    // Event listener for clicks outside the modal
    const handleOutsideClick = (e) => {
      if (!e.target.closest('.add_node_modal')) {
        onClose();
      }
    };
    // Event listener for the escape key press
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        onClose();
      } else if (e.key === 'Enter') {
        const moduleMatchCount = moduleNodesToDisplay.length;
        if (!moduleMatchCount && isConditionMatching) {
          addNewCondition();
        } else if (!isConditionMatching && moduleMatchCount === 1) {
          addNewNode(moduleNodesToDisplay[0]);
        }
      }
    };
    // Add event listeners when the modal is open
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscapeKey);

    // Clean up event listeners when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose, addNewNode, moduleNodesToDisplay, conditionModule, inputValue, addNewCondition]);

  const displayCondition = () => (
    <>
      <div className="modal_module_type_heading">CONDITION</div>
      <div className="modal_node">
        <div className="modal_node__icon">
          <img className="modal_node__icon__image" src={conditionIcon} alt="icon" />
        </div>
        <div className="modal_node__content">
          <div className="modal_node__content__heading">{conditionModuleHeading}</div>
          <div className="modal_node__content__subheading">{conditionModule.uiConfig.drawer.subHeading}</div>
        </div>
        <button type="button" className="modal_node__addButton" onClick={addNewCondition}>
          <img
            src={addIcon}
            alt="close"
          />
        </button>
      </div>
    </>
  );
  const displayModuleNodes = () => (
    <>
      <div className="modal_module_type_heading">MODULES</div>
      {
        moduleNodesToDisplay.filter((node) => node.type !== 'countries').map((state) => (
          <div key={`${state.subType}_${state.version}`} className="modal_node">
            <div className="modal_node__icon">
              <img className="modal_node__icon__image" src={state.icon || genericModuleIcon} alt="icon" />
            </div>
            <div className="modal_node__content">
              <div className="modal_node__content__heading">{state.moduleName}</div>
              <div className="modal_node__content__subheading">{state.description}</div>
            </div>
            <button type="button" className="modal_node__addButton" onClick={() => addNewNode(state)}>
              <img
                src={addIcon}
                alt="close"
              />
            </button>
          </div>
        ))
      }
    </>
  );
  const displayModalContent = (moduleNodesToDisplayParam) => {
    const shouldDisplayModules = moduleNodesToDisplayParam.length;
    if (isConditionMatching || shouldDisplayModules) {
      return (
        <>
          {isConditionMatching ? displayCondition() : null}
          {shouldDisplayModules ? displayModuleNodes() : null}
        </>
      );
    }
    return (
      <div className="modal_placeholder">
        Nothing to display!
      </div>
    );
  };

  return (
    <div className="add_node_overlay">
      <div className="add_node_modal">
        <div className="add_node_modal_header">
          <span>
            Add to Workflow
          </span>
          <button type="button" className="closeModalButton" onClick={onClose}>
            <img
              src={closeButton}
              alt="close"
            />
          </button>
        </div>
        <input
          className="add_node_modal_searchbox"
          type="text"
          placeholder="Search for a module or condition"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          ref={inputRef}
        />
        <div className="add_node_modal_content">
          {
            displayModalContent(moduleNodesToDisplay)
          }
        </div>
      </div>
    </div>
  );
}

AddNodeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  addNewNode: PropTypes.func.isRequired,
  addNewCondition: PropTypes.func.isRequired,
};

export default AddNodeModal;
