import { cloneDeep } from 'lodash';
import {
  logConditionRuleUpdate,
  logSwapBranchesInCondition,
} from '../../logger/logHighLevelWorkflowUpdates';

export const swapBranchesInCondition = (conditionId, workflowConfig) => {
  const editedWorkflow = cloneDeep(workflowConfig);
  // Find condition
  const selectedCondition = editedWorkflow.conditions[conditionId];
  if (selectedCondition) {
    const currentTrueBranch = selectedCondition.if_true;
    const currentFalseBranch = selectedCondition.if_false;
    const newCondition = cloneDeep(selectedCondition);
    newCondition.if_true = currentFalseBranch;
    newCondition.if_false = currentTrueBranch;
    const trueNextNodeType = newCondition?.next_node_type?.if_true || '';
    const falseNextNodeType = newCondition?.next_node_type?.if_false || '';
    newCondition.next_node_type = {
      if_true: falseNextNodeType,
      if_false: trueNextNodeType,
    };
    editedWorkflow.conditions[conditionId] = newCondition;
    logSwapBranchesInCondition({
      id: conditionId,
      newIfTrueBranch: newCondition.if_true,
      newIfFalseBranch: newCondition.if_false,
    });
  }
  return editedWorkflow;
};

export const updateConditionRule = (workflowConfig, conditionId, rule) => {
  const editedWorkflow = cloneDeep(workflowConfig);
  const selectedRule = editedWorkflow.conditions[conditionId];
  const oldRule = selectedRule.rule;
  if (selectedRule) selectedRule.rule = rule;
  logConditionRuleUpdate({
    id: conditionId,
    oldRule,
    newRule: rule,
  });
  return editedWorkflow;
};

// edit if_false or if_true reason in condition and
// remove the if_true or if_false reason key from condition if the value is empty
export const editIfTrueIfFalseReason = (workflowConfig, conditionId, type, value) => {
  const editedWorkflow = cloneDeep(workflowConfig);
  const condition = editedWorkflow.conditions[conditionId];

  if (condition) {
    if (value) {
      condition[`${type}_reason`] = value;
    } else {
      delete condition[`${type}_reason`];
    }
  }

  return editedWorkflow;
};

export const editResumeFrom = (workflowConfig, conditionId, branch, value) => {
  const updatedWorkflow = cloneDeep(workflowConfig);
  const condition = updatedWorkflow.conditions[conditionId];
  if (condition) {
    if (branch === 'if_true' && value) {
      condition.ifTrueConfigs = { resumeFrom: value };
    } else if (branch === 'if_false' && value) {
      condition.ifFalseConfigs = { resumeFrom: value };
    }
  } else {
    return { workflow: workflowConfig, success: false };
  }

  return { workflow: updatedWorkflow, success: true };
};
