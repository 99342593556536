/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import useDebounce from '../../../utils/useDebounce';
import CustomTooltip from '../../Common/CustomTooltip';

function DateRangeValidator(props) {
  const {
    defaultValue = null,
    onChange,
    label,
  } = props;

  const [validations, setValidations] = useState(defaultValue || null);

  const debouncedValidations = useDebounce(validations, 500);

  const prevValue = useRef(defaultValue || null);

  const defaultValidationValues = {
    startMonth: null,
    endMonth: null,
    errorMsg: '',
  };

  useEffect(() => {
    if (JSON.stringify(debouncedValidations) !== JSON.stringify(prevValue.current)) {
      onChange(debouncedValidations);
    }
    prevValue.current = debouncedValidations;
  }, [debouncedValidations]);

  const handleToggleChange = () => {
    setValidations(
      (currValidations) => {
        if (currValidations) return null;
        if (defaultValue) return defaultValue;
        return defaultValidationValues;
      },
    );
    // we donot want to wait 500 ms before changing the workflow when the toggle is switched
    // hence making the change in workflow explicitly here
    let validationToSet;
    if (validations) validationToSet = null;
    else if (defaultValue) validationToSet = defaultValue;
    else validationToSet = defaultValidationValues;

    onChange(validationToSet);
    prevValue.current = validationToSet;
  };

  const onValidationChange = (key, valueReceived) => {
    const value = typeof valueReceived === 'number' && Number.isNaN(valueReceived) ? null : valueReceived;
    setValidations((currValidations) => ({
      ...currValidations,
      [key]: value,
    }));
  };

  return (
    <div className="edit-settings-div__toggle-container">
      <Grid container alignItems="center" gap={1}>
        <Grid container alignItems="center">

          <Grid item xs={4}>
            <label htmlFor="showDateRange" className="edit-properties-div__label">
              {label}
            </label>
          </Grid>
          <Grid item xs={8} justifyContent="flex-end" className="edit-settings-div__toggle">
            <div className="toggle">
              <label htmlFor="showDateRange" className="edit-properties-div__label">
                <input
                  type="checkbox"
                  checked={!!validations}
                  onChange={handleToggleChange}
                  id="showDateRange"
                />
                <span className="slider round" />
              </label>
            </div>
          </Grid>
        </Grid>
        {validations && (
          <>
            <Grid container alignItems="center">
              <Grid item xs={4}>
                <label htmlFor="Start month" className="edit-properties-div__label">Start month</label>
              </Grid>
              <Grid item xs={7} justifyContent="flex-end" className="edit-settings-div__toggle">
                <input
                  type="number"
                  value={validations.startMonth}
                  className="edit-properties-div__text-input"
                  onChange={(e) => onValidationChange('startMonth', parseInt(e.target.value, 10))}
                  placeholder="Enter a valid value"
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <CustomTooltip
                  title={(
                    <>
                      Example: -1200 implies the start date is 100 years before today
                    </>
                  )}
                  placement="top"
                  arrow
                >
                  <div className="drawer_info__icon">
                    i
                  </div>
                </CustomTooltip>
              </Grid>
            </Grid>

            <Grid container alignItems="center">
              <Grid item xs={4}>
                <label htmlFor="End month" className="edit-properties-div__label">End month</label>
              </Grid>
              <Grid item xs={7} justifyContent="flex-end" className="edit-settings-div__toggle">
                <input
                  type="number"
                  value={validations.endMonth}
                  className="edit-properties-div__text-input"
                  onChange={(e) => onValidationChange('endMonth', parseInt(e.target.value, 10))}
                  placeholder="Enter a valid value"
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <CustomTooltip
                  title={(
                    <>
                      Example: -216 implies the end date is 18 years before today
                    </>
                  )}
                  placement="top"
                  arrow
                >
                  <div className="drawer_info__icon">
                    i
                  </div>
                </CustomTooltip>
              </Grid>
            </Grid>

            <Grid container alignItems="center">
              <Grid item xs={4}>
                <label htmlFor="End month" className="edit-properties-div__label">Error message</label>
              </Grid>
              <Grid item xs={8} justifyContent="flex-end" className="edit-settings-div__toggle">
                <input
                  type="text"
                  value={validations.errorMsg}
                  onChange={(e) => onValidationChange('errorMsg', e.target.value)}
                  placeholder="Set error message"
                  className="edit-properties-div__text-input"
                />
              </Grid>
            </Grid>
          </>
        )}

      </Grid>
    </div>
  );
}
DateRangeValidator.propTypes = {
  defaultValue: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default DateRangeValidator;
