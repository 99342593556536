/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import './CollapsibleDiv.scss'; // You can create this CSS file for styling
import { useState } from 'react';

import dropDownIcon from '../../assests/icons/dropDownIcon.svg';
import deleteIcon from '../../assests/icons/deleteIcon3.svg';

function CollapsibleDiv({
  title, children, showDelete, handleDelete, error,
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`collapsible-div ${error ? 'collapsible-div_error' : ''}`}>

      <div className="collapsible-header" onClick={toggleCollapse}>
        <div>
          <span className={`arrow ${isCollapsed ? 'collapsed' : 'expanded'}`}>
            <img src={dropDownIcon} alt="dropdown" className="drop-down-icon" />
          </span>
          {title}
        </div>
        {showDelete && (
        <button className="delete-icon" type="button" onClick={handleDelete}>
          <img src={deleteIcon} alt="delete" />
        </button>
        )}
      </div>
      <div className={`collapsible_content ${isCollapsed ? 'collapsible_content_collapsed' : ''}`}>{children}</div>
    </div>
  );
}

export default CollapsibleDiv;

CollapsibleDiv.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showDelete: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func,
  error: PropTypes.bool,
};

CollapsibleDiv.defaultProps = {
  handleDelete: () => {},
  error: false,
};
