import { nonModuleNodes } from '../components/FormModule/utils';
import compile from '../compilerDecompiler/compiler';
import convertToNodesEdges from '../components/utils';
import { formComponentList } from '../constants/dynamicFormComponents';

export const getModuleFromWorkflow = (workflow, moduleId) => (
  workflow?.modules.find((m) => m.id === moduleId)
);

export const getModuleTypeFromModuleConfig = (module, versionedModuleConfig, compiledWorkflow) => {
  let individualModuleConfig;
  if (module.superModuleId) {
    // module is a supermodule
    // get the supermoduleId, supermoduleType and mappingId from compiled workflow
    // fetch the supermodule config of the specific version from versioned modules
    // get the individual module config from the supermodule config
    const selectedModule = getModuleFromWorkflow(compiledWorkflow, module.id);
    const {
      superModuleType,
      superModuleId,
      mappingId,
    } = selectedModule;
    const superModuleVersion = compiledWorkflow.properties?.builder?.superModuleMetaData?.[superModuleId]?.version || 'v1';
    const superModuleConfig = versionedModuleConfig[superModuleType]?.[superModuleVersion]?.config;
    const superModuleConfigModules = superModuleConfig.library.modules;
    individualModuleConfig = superModuleConfigModules.find((m) => m.id === mappingId);
  }
  individualModuleConfig = versionedModuleConfig[module.nodeType]?.[module.version || 'v1']?.config;
  return individualModuleConfig?.type || individualModuleConfig?.configFile?.type;
};

export const getGroupedListOfModules = (
  selectedWorkflow,
  versionedModules,
  supportedScreens,
) => {
  if (!Object.keys(selectedWorkflow).length || !Object.keys(versionedModules).length) {
    return [];
  }
  const workflow = compile(selectedWorkflow, versionedModules, formComponentList);
  const { nodes = [] } = convertToNodesEdges(workflow);

  const listOfModules = nodes.map((node) => (
    !(nonModuleNodes.includes(node.nodeType))
      ? node : null)).filter((id) => id !== null);

  // Update module names
  listOfModules.forEach((module, index) => {
    let moduleName = module.name;
    if (module.superModuleId) {
      moduleName = getModuleFromWorkflow(selectedWorkflow, module.superModuleId)?.name;
    }
    Object.assign(listOfModules[index], { moduleName });
  });

  // Update moduleType
  listOfModules.forEach((module, index) => {
    const moduleType = getModuleTypeFromModuleConfig(
      module,
      versionedModules,
      workflow,
    );
    Object.assign(listOfModules[index], { moduleType });
    // remove countries module when only one country is selected
    if (module.moduleType === 'countries') {
      const filteredModule = selectedWorkflow.modules.filter((mod) => mod.id === module.id)[0];
      if (filteredModule?.properties.countriesSupported.length === 1) listOfModules[index] = null;
    }
  });

  const filteredListOfModules = listOfModules.filter(
    (module) => module?.moduleType && supportedScreens[module?.moduleType],
  );

  const listOfListOfModules = [];
  const idToArrMap = {};
  filteredListOfModules.forEach((module) => {
    const id = module.superModuleId || module.id;
    if (idToArrMap[id]) idToArrMap[id].push(module);
    else idToArrMap[id] = [module];
  });

  Object.entries(idToArrMap).forEach(([key, value]) => {
    listOfListOfModules.push({
      id: key,
      moduleName: value[0].moduleName,
      modules: value,
    });
  });
  return { listOfListOfModules, workflow };
};
