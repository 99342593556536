import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { Tooltip } from '@mui/material';
import sideIcon from '../../assests/icons/newChevronRightIcon.svg';
import downIcon from '../../assests/icons/newChevronDownIcon.svg';
import Menu from './Menu';
import addImg from '../../assests/icons/addInsideContainer.svg';

function ListFormModuleComponents(props) {
  const {
    listItems,
    handleOnClick,
    handleOnDelete,
    handleOnAdd,
    handleOnDrag,
    handleOnCopy,
    formComponentsConfig,
    selectedComponentPath,
    enableCopyButton,
    enableDeleteButton,
    enableAddButton,
    handleOnCopyToClipboard,
    dragState,
    onDragStateChange,
    openStates,
    toggleDropdown,
    level,
    parentContainerInfo,
    basePath,
  } = props;
  const [contextMenu, setContextMenu] = useState(null);
  const handleContextMenu = useCallback(
    (event, component) => {
      event.preventDefault();
      event.stopPropagation();
      setContextMenu({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
        component,
      });
    },
    [],
  );

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const clickHandler = (pathArr) => {
    handleOnClick(pathArr);
  };

  const copyClickHandler = (componentId) => {
    handleOnCopy(componentId);
    handleCloseContextMenu();
  };

  const copyToClipboardClickHandler = (componentId) => {
    handleOnCopyToClipboard(componentId);
    handleCloseContextMenu();
  };

  const addComponentHandler = (componentId) => {
    if (!openStates[componentId]) {
      toggleDropdown(componentId);
    }
    handleOnAdd(componentId);
  };

  const deleteClickHandler = (componentId) => {
    handleOnDelete(componentId);
    handleCloseContextMenu();
  };

  const handleDragStart = (id) => {
    if (openStates[id]) {
      toggleDropdown(id);
    }
    onDragStateChange({
      draggedComponentId: id,
      draggedOverComponentId: null,
      fromSection: basePath,
    });
  };
  const handleDragEnd = (event) => {
    event.preventDefault();
    onDragStateChange({ draggedComponentId: null, draggedOverComponentId: null });
  };
  const handleDragOver = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    const { dataTransfer } = event;
    dataTransfer.dropEffect = 'move';
    onDragStateChange({ draggedOverComponentId: id });
  };
  const handleDrop = (event, dropId) => {
    event.preventDefault();
    event.stopPropagation();
    if ((dragState.draggedComponentId !== dragState.draggedOverComponentId)) {
      const fromComponentObj = {
        fromComponentId: dragState.draggedComponentId,
        section: dragState.fromSection,
      };
      const toComponentObj = {
        toComponentId: dropId,
        section: basePath,
      };
      handleOnDrag(fromComponentObj, toComponentObj);
      onDragStateChange(
        { draggedComponentId: null, draggedOverComponentId: null, fromSection: null },
      );
    }
  };

  const getSelectedIndexForChild = (currentSelectedIndex, currentIndex) => {
    if (currentSelectedIndex?.length && currentSelectedIndex[0] === currentIndex) {
      const newSelectedIndexArr = [...currentSelectedIndex];
      newSelectedIndexArr.shift();
      return newSelectedIndexArr;
    }
    return [];
  };

  const isCurrentComponentSelected = (currentSelectedIndex, currentIndex) => {
    if (currentSelectedIndex.length === 1 && currentSelectedIndex[0] === currentIndex) return true;
    return false;
  };

  return (
    <div className="component-list-div-children">
      {
   (listItems || []).map((component, index) => {
     const key = (formComponentsConfig.filter(
       (comp) => comp.type === component.type,
     )[0]
     )?.primaryBrandingKey;
     const isDropdownOpen = openStates[component.id];
     if (component.type === 'vertical' || component.type === 'horizontal') {
       return (
         <>
           <div
             onDragOver={(event) => {
               event.stopPropagation();
               handleDragOver(event, component.id);
             }}
             onDrop={(event) => { event.stopPropagation(); handleDrop(event, component.id); }}
             className="component-individual__container"
           >
             {dragState?.draggedOverComponentId === component.id &&
          (
          <div className="component-list__drag-indicator" />
          )}
             <div
               className="component-list__border"
               style={{
                 opacity: isDropdownOpen ? '1' : '0',
                 height: isDropdownOpen ? 'calc(100% - 34px)' : '0',
                 marginLeft: `${14 + (level * 20)}px`,
               }}
             />
             <div
               key={`${component.id}`}
               role="menuitem"
               className={`component-list__item-container${isCurrentComponentSelected(selectedComponentPath, index) ? '__active' : ''}${isDropdownOpen ? '__open' : ''}`}
               tabIndex={0}
               onKeyUp={() => {}}
               draggable
               onDragStart={
              (event) => { event.stopPropagation(); handleDragStart(component.id); }
            }
               onContextMenu={(event) => handleContextMenu(event, component)}
               onClick={(event) => {
                 event.stopPropagation();
                 clickHandler([index]);
               }}
               onDragEnd={
                (event) => { handleDragEnd(event); }
              }
             >

               <div className={`container-individual${isCurrentComponentSelected(selectedComponentPath, index) ? '__active' : ''}${parentContainerInfo?.isContainerActive ? '__containerActive' : ''}`}>
                 <div className="sub-component-heading" style={{ marginLeft: `${(level) * (20)}px` }}>
                   <button type="submit" className="sub-component-list-div__dropdown-icon" onClick={() => toggleDropdown(component.id)}>
                     {isDropdownOpen
                       ? <img src={downIcon} alt="down" />
                       : <img src={sideIcon} alt="side" />}
                   </button>
                   <div
                     className={`sub-component-list-div__heading${isCurrentComponentSelected(selectedComponentPath, index) ? '__active' : ''}`}
                   >
                     {component?.text || component?.id}
                   </div>
                   <button
                     className="sub-component-add-btn"
                     disabled={!enableAddButton}
                     onClick={
                      (event) => { event.stopPropagation(); addComponentHandler(component.id); }
                    }
                     type="button"
                   >
                     <Tooltip
                       title={(
                         <span>
                           Add Element
                         </span>
              )}
                       arrow
                     >
                       <img src={addImg} alt="add" />
                     </Tooltip>

                   </button>
                 </div>
               </div>
               {isDropdownOpen && (
                 <ListFormModuleComponents
                   listItems={component?.subComponents}
                   handleOnClick={(path) => clickHandler([index, ...path])}
                   handleOnDelete={(componentId) => deleteClickHandler(componentId)}
                   handleOnAdd={(componentId) => handleOnAdd(componentId)}
                   handleOnCopy={(componentId) => copyClickHandler(componentId)}
                   handleOnCopyToClipboard={
                    (componentId) => copyToClipboardClickHandler(componentId)
                  }
                   selectedComponentPath={getSelectedIndexForChild(selectedComponentPath, index)}
                   formComponentsConfig={formComponentsConfig}
                   handleOnDrag={(fromComponentId, toComponentId) => {
                     handleOnDrag(fromComponentId, toComponentId);
                   }}
                   enableCopyButton={enableCopyButton}
                   enableDeleteButton={enableDeleteButton}
                   enableAddButton={enableAddButton}
                   dragState={dragState}
                   onDragStateChange={onDragStateChange}
                   openStates={openStates}
                   toggleDropdown={toggleDropdown}
                   level={level + 1}
                   parentContainerInfo={{
                     isInsideContainer: component.type === 'vertical' || component.type === 'horizontal',
                     isContainerActive: isCurrentComponentSelected(selectedComponentPath, index)
                     || parentContainerInfo?.isContainerActive,
                   }}
                   basePath={basePath}
                 />
               )}

             </div>
           </div>
         </>
       );
     }

     return (
       <>
         <div
           onDragOver={(event) => {
             event.stopPropagation();
             handleDragOver(event, component.id);
           }}
           onDrop={(event) => { event.stopPropagation(); handleDrop(event, component.id); }}
           className={`component-individual${isCurrentComponentSelected(selectedComponentPath, index) ? '__active' : ''}${parentContainerInfo?.isInsideContainer ? '__inside-container' : ''}${parentContainerInfo?.isContainerActive ? '__active' : ''}`}
         >
           {dragState?.draggedOverComponentId === component.id &&
          (
          <div className="component-list__drag-indicator" />
          )}
           <div
             key={`${component.id}`}
             role="menuitem"
             tabIndex={0}
             onKeyUp={() => {}}
             className={`component-name ${dragState?.draggedComponentId === component.id ? 'dragging' : ''}`}
             onClick={(event) => { event.stopPropagation(); clickHandler([index]); }}
             draggable
             onDragStart={
            (event) => { event.stopPropagation(); handleDragStart(component.id); }
          }
             style={{ marginLeft: `${(level) * 20}px` }}
             onContextMenu={(event) => handleContextMenu(event, component)}
             onDragEnd={
             (event) => { handleDragEnd(event); }
           }
           >
             <div className={`component-name-label${isCurrentComponentSelected(selectedComponentPath, index) ? '__active' : ''}`}>
               {component[key] || component.text || component.title || component.id}
             </div>
             <Menu
               open={contextMenu !== null}
               onClose={handleCloseContextMenu}
               anchorReference="anchorPosition"
               anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
             >
               <Menu.Item
                 onClick={() => copyToClipboardClickHandler(contextMenu?.component?.id)}
                 disabled={!enableCopyButton}
               >
                 Copy
               </Menu.Item>
               <Menu.Item
                 onClick={() => copyClickHandler(contextMenu?.component?.id)}
                 disabled={!enableCopyButton}
               >
                 Duplicate
               </Menu.Item>
               <Menu.Item
                 onClick={(event) => {
                   event.stopPropagation(); deleteClickHandler(contextMenu?.component?.id);
                 }}
                 disabled={!enableDeleteButton}
               >
                 Delete
               </Menu.Item>
             </Menu>
           </div>
         </div>
       </>
     );
   })
}
    </div>
  );
}

export default ListFormModuleComponents;

ListFormModuleComponents.propTypes = {
  listItems: PropTypes.array.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func.isRequired,
  handleOnDrag: PropTypes.func.isRequired,
  handleOnCopy: PropTypes.func.isRequired,
  handleOnCopyToClipboard: PropTypes.func.isRequired,
  formComponentsConfig: PropTypes.array.isRequired,
  selectedComponentPath: PropTypes.array.isRequired,
  enableCopyButton: PropTypes.func.isRequired,
  enableDeleteButton: PropTypes.func.isRequired,
  enableAddButton: PropTypes.func.isRequired,
  dragState: PropTypes.object.isRequired,
  onDragStateChange: PropTypes.func.isRequired,
  openStates: PropTypes.object.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  level: PropTypes.number.isRequired,
  parentContainerInfo: PropTypes.shape({
    isInsideContainer: PropTypes.bool.isRequired,
    isContainerActive: PropTypes.bool.isRequired,
  }).isRequired,
  basePath: PropTypes.string.isRequired,
};
