import PropTypes from 'prop-types';
import Description from '../Description/Description';
import Duration from '../Duration/Duration';
import Inputs from '../InputsToModule/Inputs';
import MultiSelectDropdown from '../MultiSelectDropDown/MultiSelectDropDown';
import CountriesSelect from '../SelectCountries/Countries';
import DocumentSelect from '../SelectDocuments/DocumentsSelect';
import SingleSelectDropDown from '../SingleSelectDropdown/SingleSelectDropDown';
import TextInputWrapper from '../TextInput/TextInputWrapper';
import Toggle from '../Toggle/Toggle';
import ConditionalToggle from '../ConditionalToggle/ConditionalToggle';
import JsonBox from '../JsonBox/JsonBox';

function InputComponent(props) {
  const {
    element,
    isDisabled,
    updateValueInWorkflow,
    selectedWorkflow,
    selectedNodeId,
    versionedModules,
    orderOfModules,
    moduleBuilderTestMode,
    documentList,
    documentOverrideOptions,
    handleCountriesSelection,
    handleDocumentsSelection,
  } = props;
  const { type: componentType } = element;

  const supportedTypesInModuleBuilder = ['toggle', 'singleSelectDropDown', 'textbox', 'inputToModule'];
  if (moduleBuilderTestMode && !supportedTypesInModuleBuilder.includes(componentType)) {
    return null;
  }
  switch (componentType) {
    case 'toggle':
      return (
        <Toggle
          description={element}
          isDisabled={isDisabled}
          updateValueInWorkflow={updateValueInWorkflow}
          selectedWorkflow={selectedWorkflow}
          selectedNodeId={selectedNodeId}
        />
      );
    case 'singleSelectDropDown':
      return (
        <SingleSelectDropDown
          options={element}
          isDisabled={isDisabled}
          updateValueInWorkflow={updateValueInWorkflow}
          selectedWorkflow={selectedWorkflow}
          selectedNodeId={selectedNodeId}
        />
      );
    case 'textbox':
      return (
        <TextInputWrapper
          element={element}
          isDisabled={isDisabled}
          selectedWorkflow={selectedWorkflow}
          selectedNodeId={selectedNodeId}
          updateValueInWorkflow={updateValueInWorkflow}
        />
      );
    case 'inputToModule':
      return (
        <Inputs
          input={element}
          isDisabled={isDisabled}
          updateValueInWorkflow={updateValueInWorkflow}
          selectedWorkflow={selectedWorkflow}
          selectedNodeId={selectedNodeId}
          versionedModules={versionedModules}
          orderOfModules={orderOfModules}
        />
      );
    case 'toggleWithCondition':
      return (
        (
          <ConditionalToggle
            description={element}
            isDisabled={isDisabled}
            updateValueInWorkflow={updateValueInWorkflow}
            selectedWorkflow={selectedWorkflow}
            selectedNodeId={selectedNodeId}
            versionedModules={versionedModules}
            orderOfModules={orderOfModules}
          />
        )
      );
    case 'duration':
      return (
        <Duration
          element={element}
          isDisabled={isDisabled}
          updateValueInWorkflow={updateValueInWorkflow}
          selectedWorkflow={selectedWorkflow}
          selectedNodeId={selectedNodeId}
        />
      );
    case 'multiSelectDropDown':
      return (
        (
          <MultiSelectDropdown
            element={element}
            isDisabled={isDisabled}
            updateValueInWorkflow={updateValueInWorkflow}
            selectedWorkflow={selectedWorkflow}
            selectedNodeId={selectedNodeId}
          />
        )
      );
    case 'documentsSupported':
      return (
        <DocumentSelect
          options={element}
          isDisabled={isDisabled}
          handleDocumentsSelection={handleDocumentsSelection}
          documentList={documentList}
          documentOverrideOptions={documentOverrideOptions}
          selectedWorkflow={selectedWorkflow}
          selectedNodeId={selectedNodeId}
        />
      );
    case 'countriesSupported':
      return (
        <CountriesSelect
          options={element}
          isDisabled={isDisabled}
          documentList={documentList}
          selectedWorkflow={selectedWorkflow}
          selectedNodeId={selectedNodeId}
          handleCountriesSelection={handleCountriesSelection}
        />
      );
    case 'description':
      return <Description text={element.text} />;
    case 'jsonBox':
      return (
        <JsonBox
          element={element}
          isDisabled={isDisabled}
          updateValueInWorkflow={updateValueInWorkflow}
          selectedWorkflow={selectedWorkflow}
          selectedNodeId={selectedNodeId}
        />
      );
    default:
      return null;
  }
}

InputComponent.propTypes = {
  element: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  updateValueInWorkflow: PropTypes.func.isRequired,
  versionedModules: PropTypes.object.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  orderOfModules: PropTypes.array.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  moduleBuilderTestMode: PropTypes.bool.isRequired,
  documentList: PropTypes.array.isRequired,
  documentOverrideOptions: PropTypes.array.isRequired,
  handleCountriesSelection: PropTypes.func.isRequired,
  handleDocumentsSelection: PropTypes.func.isRequired,
};

export default InputComponent;
