import { cloneDeep } from 'lodash';

const updateDocumentsInWorkflowOnCountrySelection = (
  workflow,
  finalCountriesSelected,
  currDocumentList,
) => {
  const selectedCountryIds = finalCountriesSelected.map((country) => country.id);
  const editedWorkflow = cloneDeep(workflow);

  workflow.modules.forEach((module, index) => {
    if (module.type === 'document') {
      const currentDocumentDetails = module.properties.documentsSupported;

      const updatedDocListOfSelCountries = Object.keys(currentDocumentDetails)
        .filter((countryIdOfDoc) => selectedCountryIds.includes(countryIdOfDoc));

      const updatedDocList = updatedDocListOfSelCountries.reduce((obj, countryId) => ({
        ...obj,
        [countryId]: currentDocumentDetails[countryId],
      }), {});

      selectedCountryIds.forEach((countryId) => {
        if (!updatedDocList[countryId]) {
          const docListOfCountry = currDocumentList
            .filter((document) => document.id === countryId);
          updatedDocList[countryId] = docListOfCountry[0]?.documents
            .map((document) => document.id);
        }
      });

      editedWorkflow.modules[index].properties
        .documentsSupported = updatedDocList;
    }
  });

  return editedWorkflow;
};

export default updateDocumentsInWorkflowOnCountrySelection;
