const getSelectedCountriesNameIdList = (selectedModule, countryNameIdGlobalList) => {
  const selectedCountryIdsInWorkflow = selectedModule.properties.countriesSupported;
  const selectedCountryNameIdList = countryNameIdGlobalList
    .filter(({ id }) => selectedCountryIdsInWorkflow.includes(id))
    .map(({ id, name }) => ({ id, name }));

  return (selectedCountryNameIdList.length > 0) ? selectedCountryNameIdList : [{ id: '', name: '' }];
};

export default getSelectedCountriesNameIdList;
