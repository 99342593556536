/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { formComponentsBasePath } from '../constants/dynamicFormComponents';

export const dynamicFormSlice = createSlice({
  name: 'dynamicForm',
  initialState: {
    componentToEdit: '',
    selectedComponentType: '',
    componentIndex: 0,
    subComponentIndex: -1,
    reloadSdk: false,
    selectedComponentPath: {
      // relative to sections[0], can take value like footer.components
      basePath: formComponentsBasePath.main,
      pathArray: [0],
    },
    defaultFormSections: [
      {
        id: 'basic_details',
        components: [
          {
            id: 'titleLabel',
            type: 'label',
            subType: 'title',
            text: 'Enter basic details',
          },
          {
            id: 'button_id',
            type: 'button',
            subType: 'primary',
            text: 'Default Button',
            onClick: { nextStep: '' },
          },
        ],
      },
    ],
  },
  reducers: {
    updateComponentToEdit: (state, action) => {
      const { componentToEdit } = action.payload;
      state.componentToEdit = componentToEdit;
    },
    updateComponentType: (state, action) => {
      const { selectedComponent } = action.payload;
      state.selectedComponentType = selectedComponent;
    },
    updateComponentIndex: (state, action) => {
      const { index } = action.payload;
      state.componentIndex = index;
    },
    updateReloadSdk: (state) => {
      state.reloadSdk = !state.reloadSdk;
    },
    updateSubComponentIndex: (state, action) => {
      const { subIndex } = action.payload;
      state.subComponentIndex = subIndex;
    },
    updateSelectedComponentPath: (state, action) => {
      const { pathArray, basePath } = action.payload;
      state.selectedComponentPath.pathArray = pathArray;
      state.selectedComponentPath.basePath = basePath;
    },
  },
});

export const {
  updateComponentToEdit,
  updateComponentType,
  updateComponentIndex,
  updateReloadSdk,
  updateSubComponentIndex,
  updateSelectedComponentPath,
} = dynamicFormSlice.actions;

export const selectSelectedComponentToEdit = (state) => state.dynamicForm.componentToEdit;
export const selectSelectedComponentType = (state) => state.dynamicForm.selectedComponentType;
export const selectedComponentIndex = (state) => state.dynamicForm.componentIndex;
export const selectReloadSdk = (state) => state.dynamicForm.reloadSdk;
export const selectedSubComponentIndex = (state) => state.dynamicForm.subComponentIndex;
export const selectedComponentPath = (state) => state.dynamicForm.selectedComponentPath;
export const selectDefaultFormSections = (state) => state.dynamicForm.defaultFormSections;

export default dynamicFormSlice.reducer;
