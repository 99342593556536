// only routing from here
import { useLocation } from 'react-router-dom';
import EditBrandingContainer from '../containers/Branding/EditBrandingContainer';

function Branding() {
  const { search } = useLocation();
  const workflowId = new URLSearchParams(search).get('id');

  return <EditBrandingContainer workflowId={workflowId} />;
}

export default Branding;
