import PropTypes from 'prop-types';
import './OutputDrawer.scss';

function OutputDrawer({ heading, data = [] }) {
  return (
    <div className="title-table">
      <div className="configuration-heading">
        {heading}
      </div>
      {
          data.length > 0 ? (
            <div className="title-table_container">
              <table>
                <thead>
                  <tr>
                    <th>Variable Name</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {(data || []).map((item) => (
                    <tr key={item.key}>
                      <td className="output-variable-name">{item.displayName}</td>
                      <td className="output-variable-description">{item.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="configuration_placeholder"> No Outputs to display here </div>
          )
        }

    </div>
  );
}

OutputDrawer.propTypes = {
  heading: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default OutputDrawer;
