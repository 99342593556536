import {
  getFormComponents, getModuleFromId, canDeleteComponent as isLastButton,
  getComponentFromPath, computeFinalDragPath,
} from '../containers/FormModule/helper';
import { findPathArrayForComponentId } from '../components/FormModule/utils';

export const calculatePathArray = (module, rootPath, componentId) => {
  const components = getFormComponents(module, rootPath);
  return findPathArrayForComponentId(componentId, components);
};

export const copyOperations = (workflow, moduleId, rootPath, componentId) => {
  const module = getModuleFromId(workflow, moduleId);
  return calculatePathArray(module, rootPath, componentId);
};

export const deleteOperations =
  (
    workflow,
    moduleId,
    rootPath,
    componentId,
    selectedComponentPathArray,
  ) => {
    const module = getModuleFromId(workflow, moduleId);
    const pathArray = calculatePathArray(module, rootPath, componentId);
    const currSelectedModule = getModuleFromId(workflow, moduleId);
    const canDelete = isLastButton(currSelectedModule, pathArray, rootPath);
    if (!canDelete) {
      alert('Can not delete this button as this is the last one');
      return { isSuccess: false };
    }
    const pathArrayforParent = pathArray.slice(0, pathArray.length - 1);
    const components = getFormComponents(module, rootPath);
    const component = getComponentFromPath(components, pathArrayforParent);
    const indexToAddInPathArray = component ? component.subComponents?.length : components.length;
    let newPathArray = pathArray;
    if (JSON.stringify(pathArray) === JSON.stringify(selectedComponentPathArray)) {
      if (indexToAddInPathArray === pathArray[pathArray.length - 1] + 1) {
        const updatedPathArray = pathArray.slice();
        if (updatedPathArray[updatedPathArray.length - 1] !== 0) {
          updatedPathArray[updatedPathArray.length - 1] -= 1;
        } else {
          updatedPathArray.pop();
        }
        newPathArray = updatedPathArray;
      }
    }
    const componentToBeDeleted = getComponentFromPath(components, pathArray);
    return {
      isSuccess: true, pathArray, newPathArray, componentToBeDeleted,
    };
  };

export const addOperations = (workflow, moduleId, rootPath, componentId) => {
  const module = getModuleFromId(workflow, moduleId);
  const components = getFormComponents(module, rootPath);
  const toPathArray = findPathArrayForComponentId(componentId, components);
  const component = getComponentFromPath(components, toPathArray);
  const indexToAddInPathArray = component ? component.subComponents.length : components.length;

  return { toPathArray, indexToAddInPathArray };
};

// TODO: Sanskar to rename these functions.
export const generateComponentPathArrays = (
  workflow,
  moduleId,
  fromComponentObj,
  toComponentObj,
) => {
  const { fromComponentId, section: fromRootPath } = fromComponentObj;
  const { toComponentId, section: toRootPath } = toComponentObj;
  const module = getModuleFromId(workflow, moduleId);
  const sourceComponents = getFormComponents(module, fromRootPath);
  const targetComponents = getFormComponents(module, toRootPath);
  const fromPathArray = findPathArrayForComponentId(fromComponentId, sourceComponents);
  const toPathArray = findPathArrayForComponentId(toComponentId, targetComponents);
  let updatedToPathArray = toPathArray;
  if (fromRootPath === toRootPath) {
    updatedToPathArray = computeFinalDragPath(targetComponents, fromPathArray, toPathArray);
  }
  return {
    fromPathArray,
    toPathArray: updatedToPathArray,
  };
};
