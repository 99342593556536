import { cloneDeep } from 'lodash';
import convertToNodesEdges from '../components/utils';

const removeUnvisitedNodesAndConditions = (workflow) => {
  const startNode = workflow?.modules[0]?.id;
  if (typeof startNode !== 'string') return workflow;
  const clonnedWorkflow = cloneDeep(workflow);
  const { nodes } = convertToNodesEdges(clonnedWorkflow);
  const visitedIds = (nodes || []).map((node) => node.id).filter((id) => !!id);
  // Removing conditions
  const allConditions = Object.keys(clonnedWorkflow.conditions || {});
  allConditions.forEach((conditionId) => {
    if (!visitedIds.includes(conditionId)) { delete clonnedWorkflow.conditions[conditionId]; }
  });
  // Removing nodes
  const newModuleList = clonnedWorkflow.modules.filter((module) => visitedIds.includes(module.id));
  clonnedWorkflow.modules = newModuleList;
  return clonnedWorkflow;
};

export default removeUnvisitedNodesAndConditions;
