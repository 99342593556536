const defaultAllowedComponentKeysForSubComponents = {
  label: [
    {
      key: 'workflowKey',
      value: 'id',
    },
    {
      key: 'workflowKey',
      value: 'text',
    },
    {
      key: 'workflowKey',
      value: 'width',
    },
    {
      key: 'workflowKey',
      value: 'height',
    },
    {
      key: 'uiKey',
      value: 'alignment',
    },
    {
      key: 'uiKey',
      value: 'font',
    },
    {
      key: 'uiKey',
      value: 'fontSize',
    },
    {
      key: 'uiKey',
      value: 'fontWeight',
    },
    {
      key: 'uiKey',
      value: 'color',
    },
    {
      key: 'uiKey',
      value: 'backgroundColor',
    },
    {
      key: 'uiKey',
      value: 'marginTop',
    },
    {
      key: 'uiKey',
      value: 'marginLeft',
    },
    {
      key: 'uiKey',
      value: 'marginBottom',
    },
    {
      key: 'uiKey',
      value: 'marginRight',
    },
    {
      key: 'uiKey',
      value: 'paddingTop',
    },
    {
      key: 'uiKey',
      value: 'paddingLeft',
    },
    {
      key: 'uiKey',
      value: 'paddingRight',
    },
    {
      key: 'uiKey',
      value: 'paddingBottom',
    },
  ],
  vertical: [
    {
      key: 'workflowKey',
      value: 'id',
    },
    {
      key: 'workflowKey',
      value: 'text',
    },
    {
      key: 'workflowKey',
      value: 'width',
    },
    {
      key: 'workflowKey',
      value: 'height',
    },
    {
      key: 'uiKey',
      value: 'alignment',
    },
    {
      key: 'uiKey',
      value: 'borderRadius',
    },
    {
      key: 'uiKey',
      value: 'backgroundColor',
    },
    {
      key: 'uiKey',
      value: 'borderColor',
    },
    {
      key: 'uiKey',
      value: 'marginTop',
    },
    {
      key: 'uiKey',
      value: 'marginLeft',
    },
    {
      key: 'uiKey',
      value: 'marginBottom',
    },
    {
      key: 'uiKey',
      value: 'marginRight',
    },
    {
      key: 'uiKey',
      value: 'paddingTop',
    },
    {
      key: 'uiKey',
      value: 'paddingLeft',
    },
    {
      key: 'uiKey',
      value: 'paddingRight',
    },
    {
      key: 'uiKey',
      value: 'paddingBottom',
    },
  ],
  horizontal: [
    {
      key: 'workflowKey',
      value: 'id',
    },
    {
      key: 'workflowKey',
      value: 'text',
    },
    {
      key: 'workflowKey',
      value: 'width',
    },
    {
      key: 'workflowKey',
      value: 'height',
    },
    {
      key: 'uiKey',
      value: 'alignment',
    },
    {
      key: 'uiKey',
      value: 'borderRadius',
    },
    {
      key: 'uiKey',
      value: 'backgroundColor',
    },
    {
      key: 'uiKey',
      value: 'borderColor',
    },
    {
      key: 'uiKey',
      value: 'marginTop',
    },
    {
      key: 'uiKey',
      value: 'marginLeft',
    },
    {
      key: 'uiKey',
      value: 'marginBottom',
    },
    {
      key: 'uiKey',
      value: 'marginRight',
    },
    {
      key: 'uiKey',
      value: 'paddingTop',
    },
    {
      key: 'uiKey',
      value: 'paddingLeft',
    },
    {
      key: 'uiKey',
      value: 'paddingRight',
    },
    {
      key: 'uiKey',
      value: 'paddingBottom',
    },
  ],
  button: [
    {
      key: 'workflowKey',
      value: 'id',
    },
    {
      key: 'workflowKey',
      value: 'text',
    },
    {
      key: 'workflowKey',
      value: 'width',
    },
    {
      key: 'workflowKey',
      value: 'height',
    },
    {
      key: 'uiKey',
      value: 'alignment',
    },
    {
      key: 'uiKey',
      value: 'font',
    },
    {
      key: 'uiKey',
      value: 'fontSize',
    },
    {
      key: 'uiKey',
      value: 'fontWeight',
    },
    {
      key: 'uiKey',
      value: 'color',
    },
    {
      key: 'uiKey',
      value: 'borderColor',
    },
    {
      key: 'uiKey',
      value: 'backgroundColor',
    },
    {
      key: 'uiKey',
      value: 'borderRadius',
    },
    {
      key: 'uiKey',
      value: 'marginTop',
    },
    {
      key: 'uiKey',
      value: 'marginLeft',
    },
    {
      key: 'uiKey',
      value: 'marginBottom',
    },
    {
      key: 'uiKey',
      value: 'marginRight',
    },
    {
      key: 'uiKey',
      value: 'paddingTop',
    },
    {
      key: 'uiKey',
      value: 'paddingLeft',
    },
    {
      key: 'uiKey',
      value: 'paddingRight',
    },
    {
      key: 'uiKey',
      value: 'paddingBottom',
    },
  ],
  checkbox: [
    {
      key: 'workflowKey',
      value: 'id',
    },
    {
      key: 'workflowKey',
      value: 'text',
    },
    {
      key: 'workflowKey',
      value: 'width',
    },
    {
      key: 'workflowKey',
      value: 'height',
    },
    {
      key: 'uiKey',
      value: 'alignment',
    },
    {
      key: 'uiKey',
      value: 'font',
    },
    {
      key: 'uiKey',
      value: 'fontSize',
    },
    {
      key: 'uiKey',
      value: 'fontWeight',
    },
    {
      key: 'uiKey',
      value: 'color',
    },
    {
      key: 'uiKey',
      value: 'borderColor',
    },
    {
      key: 'uiKey',
      value: 'backgroundColor',
    },
    {
      key: 'uiKey',
      value: 'borderRadius',
    },
    {
      key: 'uiKey',
      value: 'marginTop',
    },
    {
      key: 'uiKey',
      value: 'marginLeft',
    },
    {
      key: 'uiKey',
      value: 'marginBottom',
    },
    {
      key: 'uiKey',
      value: 'marginRight',
    },
    {
      key: 'uiKey',
      value: 'paddingTop',
    },
    {
      key: 'uiKey',
      value: 'paddingLeft',
    },
    {
      key: 'uiKey',
      value: 'paddingRight',
    },
    {
      key: 'uiKey',
      value: 'paddingBottom',
    },
  ],
  date: [
    {
      key: 'workflowKey',
      value: 'id',
    },
    {
      key: 'workflowKey',
      value: 'hint',
    },
    {
      key: 'workflowKey',
      value: 'title',
    },
    {
      key: 'workflowKey',
      value: 'value',
    },
    {
      key: 'workflowKey',
      value: 'dateRange',
    },
    {
      key: 'workflowKey',
      value: 'width',
    },
    {
      key: 'workflowKey',
      value: 'height',
    },
    {
      key: 'uiKey',
      value: 'alignment',
    },
    {
      key: 'uiKey',
      value: 'font',
    },
    {
      key: 'uiKey',
      value: 'fontSize',
    },
    {
      key: 'uiKey',
      value: 'fontWeight',
    },
    {
      key: 'uiKey',
      value: 'color',
    },
    {
      key: 'uiKey',
      value: 'borderColor',
    },
    {
      key: 'uiKey',
      value: 'backgroundColor',
    },
    {
      key: 'uiKey',
      value: 'borderRadius',
    },
    {
      key: 'uiKey',
      value: 'marginTop',
    },
    {
      key: 'uiKey',
      value: 'marginLeft',
    },
    {
      key: 'uiKey',
      value: 'marginBottom',
    },
    {
      key: 'uiKey',
      value: 'marginRight',
    },
    {
      key: 'uiKey',
      value: 'paddingTop',
    },
    {
      key: 'uiKey',
      value: 'paddingLeft',
    },
    {
      key: 'uiKey',
      value: 'paddingRight',
    },
    {
      key: 'uiKey',
      value: 'paddingBottom',
    },
  ],
  dropdown: [
    {
      key: 'workflowKey',
      value: 'id',
    },
    {
      key: 'workflowKey',
      value: 'title',
    },
    {
      key: 'workflowKey',
      value: 'hint',
    },
    {
      key: 'workflowKey',
      value: 'width',
    },
    {
      key: 'workflowKey',
      value: 'height',
    },
    {
      key: 'uiKey',
      value: 'alignment',
    },
    {
      key: 'uiKey',
      value: 'font',
    },
    {
      key: 'uiKey',
      value: 'fontSize',
    },
    {
      key: 'uiKey',
      value: 'fontWeight',
    },
    {
      key: 'uiKey',
      value: 'color',
    },
    {
      key: 'uiKey',
      value: 'borderColor',
    },
    {
      key: 'uiKey',
      value: 'backgroundColor',
    },
    {
      key: 'uiKey',
      value: 'borderRadius',
    },
    {
      key: 'uiKey',
      value: 'marginTop',
    },
    {
      key: 'uiKey',
      value: 'marginLeft',
    },
    {
      key: 'uiKey',
      value: 'marginBottom',
    },
    {
      key: 'uiKey',
      value: 'marginRight',
    },
    {
      key: 'uiKey',
      value: 'paddingTop',
    },
    {
      key: 'uiKey',
      value: 'paddingLeft',
    },
    {
      key: 'uiKey',
      value: 'paddingRight',
    },
    {
      key: 'uiKey',
      value: 'paddingBottom',
    },
    {
      key: 'workflowKey',
      value: 'items+labels',
    },
  ],
  text: [
    {
      key: 'workflowKey',
      value: 'id',
    },
    {
      key: 'workflowKey',
      value: 'hint',
    },
    {
      key: 'workflowKey',
      value: 'title',
    },
    {
      key: 'workflowKey',
      value: 'value',
    },
    {
      key: 'workflowKey',
      value: 'width',
    },
    {
      key: 'workflowKey',
      value: 'height',
    },
    {
      key: 'uiKey',
      value: 'alignment',
    },
    {
      key: 'uiKey',
      value: 'font',
    },
    {
      key: 'uiKey',
      value: 'fontSize',
    },
    {
      key: 'uiKey',
      value: 'fontWeight',
    },
    {
      key: 'uiKey',
      value: 'color',
    },
    {
      key: 'uiKey',
      value: 'borderColor',
    },
    {
      key: 'uiKey',
      value: 'backgroundColor',
    },
    {
      key: 'uiKey',
      value: 'borderRadius',
    },
    {
      key: 'uiKey',
      value: 'marginTop',
    },
    {
      key: 'uiKey',
      value: 'marginLeft',
    },
    {
      key: 'uiKey',
      value: 'marginBottom',
    },
    {
      key: 'uiKey',
      value: 'marginRight',
    },
    {
      key: 'uiKey',
      value: 'paddingTop',
    },
    {
      key: 'uiKey',
      value: 'paddingLeft',
    },
    {
      key: 'uiKey',
      value: 'paddingRight',
    },
    {
      key: 'uiKey',
      value: 'paddingBottom',
    },
    {
      key: 'workflowKey',
      value: 'blockCount',
    },
    {
      key: 'workflowKey',
      value: 'blockLength',
    },
  ],
  file: [
    {
      key: 'workflowKey',
      value: 'title',
    },
    {
      key: 'workflowKey',
      value: 'subTitle',
    },
    {
      key: 'workflowKey',
      value: 'errorTextFile',
    },
    {
      key: 'workflowKey',
      value: 'errorTextSizeMax',
    },
    {
      key: 'workflowKey',
      value: 'pickerTitle',
    },
  ],
  image: [
    {
      key: 'workflowKey',
      value: 'id',
    },
    {
      key: 'workflowKey',
      value: 'text',
    },
    {
      key: 'workflowKey',
      value: 'value',
    },
    {
      key: 'workflowKey',
      value: 'width',
    },
    {
      key: 'workflowKey',
      value: 'height',
    },
    {
      key: 'uiKey',
      value: 'alignment',
    },
    {
      key: 'uiKey',
      value: 'borderColor',
    },
    {
      key: 'uiKey',
      value: 'borderRadius',
    },
    {
      key: 'uiKey',
      value: 'marginTop',
    },
    {
      key: 'uiKey',
      value: 'marginLeft',
    },
    {
      key: 'uiKey',
      value: 'marginBottom',
    },
    {
      key: 'uiKey',
      value: 'marginRight',
    },
    {
      key: 'uiKey',
      value: 'paddingTop',
    },
    {
      key: 'uiKey',
      value: 'paddingLeft',
    },
    {
      key: 'uiKey',
      value: 'paddingRight',
    },
    {
      key: 'uiKey',
      value: 'paddingBottom',
    },
  ],
  divider: [
    {
      key: 'workflowKey',
      value: 'id',
    },
    {
      key: 'workflowKey',
      value: 'text',
    },
    {
      key: 'uiKey',
      value: 'color',
    },
    {
      key: 'uiKey',
      value: 'borderColor',
    },
    {
      key: 'uiKey',
      value: 'backgroundColor',
    },
    {
      key: 'uiKey',
      value: 'circleBorderColor',
    },
    {
      key: 'uiKey',
      value: 'marginTop',
    },
    {
      key: 'uiKey',
      value: 'marginLeft',
    },
    {
      key: 'uiKey',
      value: 'marginBottom',
    },
    {
      key: 'uiKey',
      value: 'marginRight',
    },
    {
      key: 'uiKey',
      value: 'paddingTop',
    },
    {
      key: 'uiKey',
      value: 'paddingLeft',
    },
    {
      key: 'uiKey',
      value: 'paddingRight',
    },
    {
      key: 'uiKey',
      value: 'paddingBottom',
    },
  ],
  loader: [
    {
      key: 'workflowKey',
      value: 'id',
    },
    {
      key: 'workflowKey',
      value: 'text',
    },
    {
      key: 'workflowKey',
      value: 'width',
    },
    {
      key: 'workflowKey',
      value: 'height',
    },
    {
      key: 'uiKey',
      value: 'alignment',
    },
    {
      key: 'uiKey',
      value: 'color',
    },
    {
      key: 'uiKey',
      value: 'marginTop',
    },
    {
      key: 'uiKey',
      value: 'marginLeft',
    },
    {
      key: 'uiKey',
      value: 'marginBottom',
    },
    {
      key: 'uiKey',
      value: 'marginRight',
    },
    {
      key: 'uiKey',
      value: 'paddingTop',
    },
    {
      key: 'uiKey',
      value: 'paddingLeft',
    },
    {
      key: 'uiKey',
      value: 'paddingRight',
    },
    {
      key: 'uiKey',
      value: 'paddingBottom',
    },
  ],
};

export const defaultEditConfiguration = [
  {
    basePath: 'components',
    title: 'UI COMPONENTS',
    allowedOperations: {
      root: {
        add: {
          enable: true,
        },
        delete: {
          enable: true,
        },
        reorder: {
          enable: true,
        },
        update: {
          enable: true,
        },
        paste: {
          enable: true,
        },
      },
    },
  },
  {
    basePath: 'footer.components',
    title: 'FOOTER',
    allowedOperations: {
      root: {
        add: {
          enable: true,
        },
        delete: {
          enable: true,
        },
        reorder: {
          enable: true,
        },
        update: {
          enable: true,
        },
        paste: {
          enable: true,
        },
      },
    },
  },
];

// WIP
const defaultAllowedOperationsForSuperModules = {
  root: {
    add: {
      enable: false,
    },
    delete: {
      enable: false,
    },
    reorder: {
      enable: false,
    },
    update: {
      enable: true,
      allowedComponentKeysForSubComponents: defaultAllowedComponentKeysForSubComponents,
      allowTypeEdit: false,
      allowSubTypeEdit: false,
    },
    paste: {
      enable: false,
    },
  },
};

export const defaultEditConfigurationForSuperModules = [
  {
    basePath: 'components',
    title: 'UI COMPONENTS',
    allowedOperations: defaultAllowedOperationsForSuperModules,
  },
  {
    basePath: 'footer.components',
    title: 'FOOTER',
    allowedOperations: defaultAllowedOperationsForSuperModules,
  },
];
