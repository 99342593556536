import PropTypes from 'prop-types';

function FileUpload({ title, onChange }) {
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        const jsonData = JSON.parse(contents);
        onChange(jsonData);
      };
      reader.readAsText(file);
    }
  };
  return (
    <div className="import_workflow__file_upload_container">
      <div className="import_workflow__label">
        {title}
      </div>
      <div className="import_workflow__label">
        <input type="file" name={`${title}`} onChange={handleChange} />
      </div>
    </div>
  );
}
export default FileUpload;

FileUpload.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
