export const removeAllTheExistingDivWrappers = (wrapperClassName) => {
  const wrappers = Array.from(document.getElementsByClassName(wrapperClassName));
  wrappers.forEach((wrapper) => {
    const parent = wrapper.parentNode;
    if (parent) {
      while (wrapper.firstChild) {
        parent.insertBefore(wrapper.firstChild, wrapper);
      }
      parent.removeChild(wrapper);
    }
  });
};

export const attachBorder = (domId, wrapperClassName, borderStyle) => {
  removeAllTheExistingDivWrappers(wrapperClassName);

  const customComponent = document.getElementById(domId);
  if (!customComponent) return;
  if (customComponent.parentNode.classList.contains(wrapperClassName)) return;

  const wrapper = document.createElement('div');
  wrapper.className = wrapperClassName;

  customComponent.parentNode.insertBefore(wrapper, customComponent);
  wrapper.appendChild(customComponent);
  wrapper.style.border = borderStyle;
};
