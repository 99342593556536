import { useState } from 'react';
import PropTypes from 'prop-types';
import CustomTooltip from '../Common/CustomTooltip';
import BaseDrawer from './BaseDrawer';
import '../Workflow.scss';
import SingleSelectDropdown from '../Common/SingleSelectDropdown';
import TextInput from '../ImportWorkflow/TextInput';

function GotoDrawer({
  selectedNode, items, updateGoto,
}) {
  const [activeTab, setActiveTab] = useState(1);
  const handleClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const onEditGoto = (updatedGotoModule, currentNode) => {
    const { parentBranch, parentId, parentPath } = currentNode;
    updateGoto(parentId, parentBranch || parentPath, updatedGotoModule);
  };

  return (
    <>
      <BaseDrawer
        heading="Go-To Module"
        subHeading="Module pointing to another module"
      >
        <div className="options-ribbon">
          <button
            type="button"
            className={`options-ribbon__tab ${activeTab === 1 ? 'options-ribbon__active' : ''}`}
            onClickCapture={() => handleClick(1)}
          >
            Properties
          </button>
          <button
            type="button"
            className={`options-ribbon__tab__info ${activeTab === 2 ? 'options-ribbon__active' : ''}`}
            disabled
          >
            UI Screens
          </button>
          <button
            type="button"
            className={`options-ribbon__tab__info ${activeTab === 3 ? 'options-ribbon__active' : ''}`}
            disabled
          >
            Info
          </button>
        </div>
        {activeTab === 1
          ? (
            <div className="goto-drawer__container">
              <div className="goto-drawer__sub_container">
                <div className="border-box__heading_goto">
                  ACTUAL MODULE ID
                  <CustomTooltip
                    title={(
                      <>
                        This is the module which the goto module points in the workflow.
                      </>
                  )}
                    placement="top"
                    arrow
                  >
                    <div className="drawer_info__icon">
                      i
                    </div>
                  </CustomTooltip>
                </div>
                <TextInput
                  placeholder={selectedNode.actualNode}
                  setDefaultValue
                  disabled
                />
                <div className="border-box__heading_goto">
                  ACTUAL MODULE NAME
                  <CustomTooltip
                    title={(
                      <>
                        This is the module which the goto module points in the workflow.
                      </>
                  )}
                    placement="top"
                    arrow
                  >
                    <div className="drawer_info__icon">
                      i
                    </div>
                  </CustomTooltip>
                </div>
                <TextInput
                  placeholder={selectedNode.name}
                  setDefaultValue
                  disabled
                />
              </div>
              <div className="goto-drawer__sub_container">
                <div className="drawer_content__heading_default">
                  EDIT MODULE TO GO-TO
                </div>
                <div className="goto-drawer__subheading">
                  <div className="goto-drawer__subheading__text">Select the module to go to from this node</div>
                  <SingleSelectDropdown
                    items={items}
                    onElementSelect={(value) => onEditGoto(value, selectedNode)}
                    defaultValue={selectedNode.name}
                    key={selectedNode.id}
                  />
                </div>
              </div>
            </div>
          )
          : <div>Work in progress</div>}
      </BaseDrawer>
    </>

  );
}

GotoDrawer.propTypes = {
  selectedNode: PropTypes.objectOf(PropTypes.any).isRequired,
  items: PropTypes.array.isRequired,
  updateGoto: PropTypes.func.isRequired,
};

export default GotoDrawer;
