const uiConfigPostProcessors = {
  toggle: [
    {
      action: 'UPDATE_VALUES',
      post: (config) => {
        const { on, off } = config;
        return {
          ...config,
          values: [on, off],
        };
      },
    },
    {
      action: 'UPDATE_DEFAULT',
      post: (config) => {
        const { default: defaultKey = 'on', ...restConfig } = config;
        return {
          ...restConfig,
          default: config[defaultKey],
        };
      },
      pre: (config) => {
        const { default: defaultValue, ...restConfig } = config;
        return {
          ...restConfig,
          default: config.on === defaultValue ? 'on' : 'off',
        };
      },
    },
  ],
  singleSelectDropdown: [
    {
      action: 'UPDATE_INVALID_DEFAULT',
      post: (config) => {
        const { default: defaultValue, values = [] } = config;
        if (values.includes(defaultValue)) {
          return config;
        }
        return {
          ...config,
          default: null,
        };
      },
    },
  ],
};

export default uiConfigPostProcessors;
