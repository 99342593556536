import { useState } from 'react';
import PropTypes from 'prop-types';

import chevronRightIcon from '../../../assests/icons/chevronRight.svg';
import './Accordion.scss';

function Accordion(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const {
    title, children, className,
  } = props;

  return (
    <div className={`accordion ${className}`}>
      <div className={`accordion__header ${isOpen ? 'accordion__header--open' : ''}`}>
        <button type="button" className="accordion__header-button" onClick={toggleAccordion}>
          <img
            src={chevronRightIcon}
            alt="toggle"
            className={`accordion__icon ${isOpen ? 'accordion__icon--open' : ''}`}
          />
        </button>
        <div className="accordion__title">{title}</div>
      </div>
      <div className={`accordion__content ${isOpen ? 'accordion__content--open' : ''}`}>
        {children}
      </div>
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.node.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Accordion.defaultProps = {
  className: '',
};

export default Accordion;
