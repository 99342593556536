import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import conditionDeleteIcon from '../../assests/icons/conditionDeleteIcon.svg';
import empty from '../../assests/icons/Empty.svg';
import plus from '../../assests/icons/Plus.svg';
import generateUniqueID from '../../utils/generateUniqueId';

function CustomInputDrawer(props) {
  const {
    customInputVariables, errorInCustomInput, setValueErrors, valueErrors,
    setVariableErrors, variableErrors, setErrorInCustomInput, selectedVariablesOutput,
    setCustomInputVariables, setSelectedVariablesOutput,
  } = props;

  const setVariableError = (id, errorMessage) => {
    setVariableErrors((prevErrors) => ({
      ...prevErrors,
      [id]: errorMessage,
    }));
  };

  const setValueError = (id, errorMessage) => {
    setValueErrors((prevErrors) => ({
      ...prevErrors,
      [id]: errorMessage,
    }));
  };

  const addNewOutputVariable = () => {
    const id = generateUniqueID();
    const newField = {
      id,
      variable: '',
      value: '',
    };
    setVariableError(id, 'Variable name cannot be empty');
    setValueError(id, 'Value cannot be empty');
    setCustomInputVariables((prevOutputs) => [...prevOutputs, newField]);
    setSelectedVariablesOutput((prevOutputs) => [...prevOutputs, newField]);
    setErrorInCustomInput(false);
  };

  const setOutputVariableName = (id, variable) => {
    setSelectedVariablesOutput((prevOutputs) => prevOutputs.map((output) => {
      if (output.id === id) return { ...output, variable };
      return output;
    }));
    setCustomInputVariables((prevOutputs) => prevOutputs.map((output) => {
      if (output.id === id) return { ...output, variable };
      return output;
    }));
  };
  const setOutputValue = (id, value) => {
    setSelectedVariablesOutput((prevOutputs) => prevOutputs.map((output) => {
      if (output.id === id) return { ...output, value };
      return output;
    }));
    setCustomInputVariables((prevOutputs) => prevOutputs.map((output) => {
      if (output.id === id) return { ...output, value };
      return output;
    }));
  };
  const onVariableNameChange = (id, newVariable) => {
    if (newVariable === '') {
      setVariableError(id, 'Variable name cannot be empty!');
    } else if (newVariable.includes(' ')) {
      setVariableError(id, 'Spaces are not allowed!');
    } else if (selectedVariablesOutput.find((output) => output.variable === newVariable)) {
      setVariableError(id, 'Variable already exists!');
    } else {
      setVariableError(id, '');
    }
    setOutputVariableName(id, newVariable);
  };
  const onValueChange = (id, newValue) => {
    if (newValue === '') {
      setValueError(id, 'Value cannot be empty!');
    } else {
      setValueError(id, '');
    }
    setOutputValue(id, newValue);
  };
  const deleteOutputVariable = (id) => {
    setVariableError(id, '');
    setValueError(id, '');
    setSelectedVariablesOutput((prevOutputs) => prevOutputs.filter((output) => output.id !== id));
    setCustomInputVariables((prevOutputs) => prevOutputs.filter((output) => output.id !== id));
  };
  const displayForm = () => (
    <div className="workflowOutput__bottomContent_custom_body">
      {
        customInputVariables?.map(({ variable, value, id }) => (
          <div key={id} className="workflow_output_field">
            <TextField
              label="variable name"
              variant="outlined"
              value={variable}
              error={errorInCustomInput ? variableErrors[id] : false}
              onChange={(event) => onVariableNameChange(id, event.target.value)}
              helperText={errorInCustomInput ? variableErrors[id] : ''}
              size="small"
              className="workflow_output_field_textfield"
            />
            <TextField
              label="value"
              variant="outlined"
              value={value}
              error={errorInCustomInput ? valueErrors[id] : false}
              onChange={(event) => onValueChange(id, event.target.value)}
              helperText={errorInCustomInput ? valueErrors[id] : ''}
              size="small"
              className="workflow_output_field_textfield"
            />
            <button type="button" onClick={() => deleteOutputVariable(id)} className="workflow_output_field_deleteButton">
              <img
                src={conditionDeleteIcon}
                alt="delete"
              />
            </button>
          </div>
        ))
      }

    </div>
  );

  return (
    <>
      <div className="customInput_heading_container">
        <div className="customInput_heading">
          Custom Inputs
        </div>

        {customInputVariables.length > 0 ? (
          <button type="button" className="customInput_addBtn" onClick={addNewOutputVariable}>
            <img alt="plus" src={plus} />
            <div>Add</div>
          </button>
        ) : ''}

      </div>
      {customInputVariables.length > 0 ? '' : (
        <div className="empty_container">
          <div className="empty">
            <img alt="empty" className="empty_image" src={empty} />
            <div className="empty_label">No Custom Inputs Added</div>
            <button type="button" className="customInput_addBtn" onClick={addNewOutputVariable}>
              <img alt="plus" src={plus} />
              <div>Add Custom Input</div>
            </button>
          </div>
        </div>
      )}
      {displayForm()}
    </>
  );
}
CustomInputDrawer.propTypes = {
  selectedVariablesOutput: PropTypes.array.isRequired,
  setSelectedVariablesOutput: PropTypes.func.isRequired,
  customInputVariables: PropTypes.array.isRequired,
  setCustomInputVariables: PropTypes.func.isRequired,
  errorInCustomInput: PropTypes.string.isRequired,
  setErrorInCustomInput: PropTypes.func.isRequired,
  valueErrors: PropTypes.object.isRequired,
  setValueErrors: PropTypes.func.isRequired,
  variableErrors: PropTypes.object.isRequired,
  setVariableErrors: PropTypes.func.isRequired,
};

export default CustomInputDrawer;
