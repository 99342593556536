const createUpdatedUIConfig = (
  moduleId,
  uiConfig,
  originalToClonedComponentIdMap,
  uiConfigData,
) => {
  const updatedUIConfig = { ...uiConfig, [moduleId]: { ...uiConfig[moduleId] } };
  Object.entries(originalToClonedComponentIdMap).forEach(([originalId, clonedId]) => {
    if (uiConfigData[originalId]) {
      updatedUIConfig[moduleId][clonedId] = uiConfigData[originalId];
    }
  });

  return updatedUIConfig;
};

export default createUpdatedUIConfig;
