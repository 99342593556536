import PropTypes, { object } from 'prop-types';

function ShowListOfModuleScreens(props) {
  const {
    modulesInWorkflow,
    screensToDisplay,
    supportedScreens,
    updateScreenNames,
    handleScreenSelect,
    selectedScreen,
    selectedModuleId,
  } = props;

  return (
    <div className="modules-list">
      <div className="modules-list__heading">WORKFLOW SCREENS</div>
      <div className="modules">
        {modulesInWorkflow.map((module) => (
          <div key={`${module.moduleName}`}>
            <button
              type="button"
              className="modules__individual-name"
              onClick={() => updateScreenNames(module)}
            >
              {module.moduleName}
            </button>
            {screensToDisplay.includes(module) ? (
              <div className="screen-div">
                {module.modules.map((innerModule) => {
                  const { moduleType } = innerModule;
                  const screensOfSelectedModule = supportedScreens[moduleType];
                  return screensOfSelectedModule
                    ? screensOfSelectedModule.map((screen) => (
                      <button
                        type="button"
                        key={`${screen.name}_${moduleType}`}
                        className={`screenNames ${
                          (selectedScreen === screen.state || selectedScreen === screen.name)
                          && innerModule.id === selectedModuleId ? 'screenNames_active' : ''
                        }`}
                        onClick={() => handleScreenSelect(screen.state, innerModule)}
                      >
                        {screen.name}
                      </button>
                    ))
                    : '';
                })}
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

ShowListOfModuleScreens.propTypes = {
  modulesInWorkflow: PropTypes.array.isRequired,
  screensToDisplay: PropTypes.array.isRequired,
  supportedScreens: PropTypes.instanceOf(object).isRequired,
  updateScreenNames: PropTypes.func.isRequired,
  handleScreenSelect: PropTypes.func.isRequired,
  selectedScreen: PropTypes.string.isRequired,
  selectedModuleId: PropTypes.string.isRequired,
};

export default ShowListOfModuleScreens;
