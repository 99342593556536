import getWorkflowById from '../../actions/getWorkflowById';
import decompile from '../../compilerDecompiler/decompiler';
import { convertOldNextNodeTypeKeysToNew } from '../../containers/workflowOperations';
import HVError, { errorCodes, errorMessages } from '../../utils/error';
import useFetchRequiredModules from '../../utils/fetchRequiredModules';
import { getRequiredModuleVersions } from '../../utils/helper';

const useFetchWorkflow = (currentAppId, workflowConfig, versionedModules) => {
  const { fetchRequiredModules } = useFetchRequiredModules(versionedModules);
  const fetchWorkflow = async (id) => {
    try {
      let workflow = workflowConfig;
      if (!workflowConfig || Object.keys(workflowConfig).length === 0) {
        const rawWorkflow = await getWorkflowById(id, currentAppId);
        const transformedWorkflow = convertOldNextNodeTypeKeysToNew(rawWorkflow);
        const requiredModuleVersions = getRequiredModuleVersions(rawWorkflow);
        // Get the versions of module configs that will be needed for decompilation
        const updatedVersionedModules = await fetchRequiredModules(requiredModuleVersions);
        // lowLevelWorkflow, moduleConfigs, libraries
        // Decompile the workflow here
        workflow = decompile(transformedWorkflow, updatedVersionedModules);
        return {
          workflow,
          rawWorkflow: transformedWorkflow,
          modules: updatedVersionedModules,
        };
      }
      // updateWorkflowInState(workflow, false);
      // TODO: Understand, Why do we need it?
    } catch (err) {
      throw new HVError({
        code: err?.code || errorCodes.somethingWentWrong,
        message: err?.message || errorMessages.errorFetchingWorkflow,
        originalError: err instanceof HVError ? err.originalError : err,
      });
    }
    return null;
  };

  return fetchWorkflow;
};

export default useFetchWorkflow;
