import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CollapsibleDiv from '../../Common/CollapsibleDiv';
import ConditionalVariableUnit from './ConditionalVariableUnit';
import DropDown from '../DropDown';

function IndividualConditionalVariable(props) {
  const {
    individualChildArray,
    updateChild,
    deleteChild,
    onAddElseIf,
    dropDownOptions,
    setContainsInvalidRule,
    preDefinedValues,
  } = props;

  const [ruleValidityArray, setRuleValidityArray] = useState(individualChildArray.map(() => true));
  const uuid = 'emptyString_b1ed2668-297a'; // this is a constant uuid that replaces the empty string
  useEffect(() => {
    const hasAnyInvalidRule = ruleValidityArray.includes(false);
    setContainsInvalidRule(hasAnyInvalidRule);
  }, [ruleValidityArray]);

  const setIsValidRuleEntered = (value, index) => {
    setRuleValidityArray((currArray) => {
      const newArray = [...currArray];
      newArray[index] = value;
      return newArray;
    });
  };

  const handleOnDelete = (index) => {
    setRuleValidityArray((currArray) => {
      const newArray = [...currArray];
      newArray.splice(index, 1);
      return newArray;
    });
    deleteChild(index);
  };

  const handleAdd = () => {
    setRuleValidityArray((currArray) => {
      const newArray = [...currArray];
      newArray.push(true);
      return newArray;
    });
    onAddElseIf();
  };
  const emptyStringOption = {
    label: 'Empty String',
    items: [
      {
        label: 'Set as an empty String',
        value: uuid,
        displayLabel: 'Set as an empty String',
      },
    ],
    disabled: false,
  };
  const updatedDropDownOptions = [...dropDownOptions, emptyStringOption];
  const displayIndividualConVar = (conVar, index, isRuleValid, preDefinedValuesProp) => {
    const title = index === 0 ? 'If' : 'Else If';
    return (
      <CollapsibleDiv
        title={title}
        showDelete={!(index === 0)}
        handleDelete={() => handleOnDelete(index)}
        key={conVar.id}
        error={!isRuleValid}
      >
        <ConditionalVariableUnit
          conVar={conVar}
          rootConditionalVarName={individualChildArray[0].name || null}
          onConVarChange={(updatedConVar) => updateChild(updatedConVar, index)}
          dropDownOptions={dropDownOptions}
          setIsValidRuleEntered={(value) => setIsValidRuleEntered(value, index)}
          preDefinedValues={preDefinedValuesProp}
        />
      </CollapsibleDiv>
    );
  };

  const onElseValueChange = (val) => {
    const lastIndex = individualChildArray.length - 1;
    const { value = '' } = val || {};
    const isEmptyString = value === uuid;
    let elseVal = !value ? "'NONE_NONE'" : value;
    if (isEmptyString) {
      elseVal = '';
    }
    const newChild = {
      ...individualChildArray[lastIndex],
      if_false: elseVal,
    };
    updateChild(newChild, lastIndex);
  };

  const getElseValue = (currChildArr) => {
    const val = currChildArr[currChildArr.length - 1].if_false;
    if (val === "'NONE_NONE'") {
      return null;
    } if (val === '') {
      return uuid;
    }
    return val;
  };

  return (
    <>
      {
      individualChildArray
        .map((conVar, index) => displayIndividualConVar(
          conVar,
          index,
          ruleValidityArray[index],
          preDefinedValues,
        ))
      }
      <button
        type="button"
        className="add-else-if"
        onClick={handleAdd}
      >
        Add Else if
      </button>
      <CollapsibleDiv
        title="Else"
        showDelete={false}
      >
        <div className="conditional-var_value">
          <span className="conditionalVar__name__value">
            {individualChildArray[0].name || 'conditionalVariable'}
          </span>
          <span className="then"> is </span>
          <div className="conditional-var-value-dropdown">
            <DropDown
              items={{ items: updatedDropDownOptions }}
              onChange={onElseValueChange}
              noSelectLabel="Select a value"
              defaultValue={getElseValue(individualChildArray)}
              allowCustomInput
              textBoxPlaceholder="value"
              maxLength={30}
            />
          </div>
        </div>
      </CollapsibleDiv>
    </>
  );
}

IndividualConditionalVariable.propTypes = {
  individualChildArray: PropTypes.array.isRequired,
  updateChild: PropTypes.func.isRequired,
  deleteChild: PropTypes.func.isRequired,
  onAddElseIf: PropTypes.func.isRequired,
  dropDownOptions: PropTypes.array.isRequired,
  setContainsInvalidRule: PropTypes.func.isRequired,
  preDefinedValues: PropTypes.array,
};

IndividualConditionalVariable.defaultProps = {
  preDefinedValues: [],
};

export default IndividualConditionalVariable;
