import PropTypes from 'prop-types';
import './Workflow.scss';
import { Handle, Position } from 'reactflow';
import decline from '../assests/icons/decline.svg';
import editIcon from '../assests/icons/editIcon.svg';

function DeclineNode({ type, data }) {
  // console.log(type);
  const {
    parentId, parentBranch, showEditNodeModal, parentPath, nextStepFrom,
  } = data;
  const editNode = (event) => {
    event.stopPropagation();
    showEditNodeModal({
      id: parentId, parentBranch, parentPath, nextStepFrom,
    });
  };
  return (
    <div className="custom_node">
      <button type="button" className="custom_node__sideButton_edit" onClick={editNode}>
        <img
          src={editIcon}
          alt="edit"
        />
      </button>
      <Handle type="target" position={Position.Top} isConnectable />
      <div className="custom_node__icon">
        <img
          src={decline}
          alt={type}
          className="custom_edge_button__img"
        />
      </div>
      <div className="custom_node__body">
        <p className="custom_node__heading">
          Decline
        </p>
      </div>
    </div>
  );
}

DeclineNode.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default DeclineNode;
