const setDefaultSelectedOptions = (selectedWorkflow, selectedNodeId, workflowKey) => {
  let defaultOptions = [];
  selectedWorkflow.modules.forEach((module) => {
    if (module.id === selectedNodeId) {
      if (module.properties[workflowKey]) {
        defaultOptions = module.properties[workflowKey];
      }
    }
  });
  return defaultOptions;
};

export default setDefaultSelectedOptions;
