import PropTypes from 'prop-types';
import { useState } from 'react';
import { Tooltip } from '@mui/material';
import addImg from '../../assests/icons/addIconButton.svg';
import { getFormComponents } from '../../containers/FormModule/helper';
import ListFormModuleComponents from './ListFormModuleComponents';
import pasteImg from '../../assests/icons/pasteIcon.svg';

function ListFormModule(props) {
  const {
    editConfigurations,
    formModule,
    handleOnClick,
    handleOnDelete,
    handleOnAdd,
    handleOnCopy,
    handleOnCopyToClipboard,
    handleOnDrag,
    handleOnPaste,
    formComponentsConfig,
    selectedComponentLocation,
    enableAddButton,
    enableCopyButton,
    enableDeleteButton,
    enablePasteButton,
  } = props;
  const {
    pathArray: selectedComponentPathArray,
    basePath: selectedComponentRootPath,
  } = selectedComponentLocation;
  const [dragState, setDragState] = useState({
    draggedComponentId: null,
    draggedOverComponentId: null,
    fromSection: null,
  });
  const [openStates, setOpenStates] = useState({});

  const toggleDropdown = (componentId) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [componentId]: !prevState[componentId],
    }));
  };
  const handleDragStateChange = (newDragState) => {
    setDragState((prevState) => ({
      ...prevState,
      ...newDragState,
    }));
  };
  return (
    <div className="component-list-div">
      {editConfigurations.map(({ basePath, title }) => (
        <>
          <div className="component-heading">
            <div className="component-actions-header">
              <div className="component-list-div__heading">
                {title}
              </div>
            </div>
            <div className="component-list__btnContainer">
              {enablePasteButton && (
              <button
                type="button"
                className="component-add-btn"
                onClick={(event) => {
                  event.stopPropagation();
                  handleOnPaste({ rootPath: basePath });
                }}
              >
                <Tooltip
                  title={(
                    <span>
                      Paste Element
                    </span>
              )}
                  arrow
                >
                  <img src={pasteImg} alt="paste" />
                </Tooltip>
              </button>
              )}
              <button disabled={!enableAddButton} type="button" className="component-add-btn" onClick={(event) => { event.stopPropagation(); handleOnAdd('', basePath); }}>
                <Tooltip
                  title={(
                    <span>
                      Add Element
                    </span>
              )}
                  arrow
                >
                  <img src={addImg} alt="add" />
                </Tooltip>
              </button>
            </div>
          </div>
          <ListFormModuleComponents
            listItems={getFormComponents(formModule, basePath)}
            handleOnClick={(pathArray) => handleOnClick(pathArray, basePath)}
            handleOnDelete={(componentId) => handleOnDelete(componentId, basePath)}
            handleOnCopy={(componentId) => handleOnCopy(componentId, basePath)}
            handleOnCopyToClipboard={
              (componentId) => {
                handleOnCopyToClipboard(componentId, basePath);
              }
            }
            handleOnAdd={(componentId) => handleOnAdd(componentId, basePath)}
            handleOnDrag={(fromComponentObj, toComponentObj) => {
              handleOnDrag(fromComponentObj, toComponentObj);
            }}
            formComponentsConfig={formComponentsConfig}
            selectedComponentPath={
              selectedComponentRootPath === basePath ?
                selectedComponentPathArray :
                []
            }
            enableCopyButton={enableCopyButton}
            enableDeleteButton={enableDeleteButton}
            enableAddButton={enableAddButton}
            dragState={dragState}
            onDragStateChange={handleDragStateChange}
            openStates={openStates}
            toggleDropdown={toggleDropdown}
            level={0}
            parentContainerInfo={{
              isInsideContainer: false,
              isContainerActive: false,
            }}
            basePath={basePath}
          />
        </>
      ))}
    </div>
  );
}

export default ListFormModule;

ListFormModule.propTypes = {
  formModule: PropTypes.object.isRequired,
  editConfigurations: PropTypes.array.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func.isRequired,
  handleOnCopy: PropTypes.func.isRequired,
  handleOnCopyToClipboard: PropTypes.func.isRequired,
  handleOnDrag: PropTypes.func.isRequired,
  handleOnPaste: PropTypes.func.isRequired,
  formComponentsConfig: PropTypes.array.isRequired,
  selectedComponentLocation: PropTypes.object.isRequired,
  enableAddButton: PropTypes.bool.isRequired,
  enableCopyButton: PropTypes.func.isRequired,
  enableDeleteButton: PropTypes.func.isRequired,
  enablePasteButton: PropTypes.func.isRequired,
};
