import PropTypes from 'prop-types';
import SearchIcon from '../../../assests/icons/searchIcon.svg';
import RightArrowIcon from '../../../assests/icons/rightArrow.svg';
import Accordion from '../../Common/Accordion';
import WarningIcon from '../../../assests/icons/warningIcon.svg';
import Button from '../../Common/Button';
import './VersionUpdateModalContent.scss';

function DisplayVersionUpdateModules(props) {
  const { filteredModuleUpdateDetails, selectModuleToBeUpdated, moduleUpdateDetails } = props;
  return (
    filteredModuleUpdateDetails.length === 0 ? (
      <div className="version-update__no-results">
        <img className="version-update__no-results-icon" src={SearchIcon} alt="No results" />
        <span className="version-update__no-results-text">No module updates found</span>
      </div>
    ) : (
      <div className="version-update__list">
        {filteredModuleUpdateDetails.map((module) => (
          <Accordion
            key={module.moduleId}
            className="version-update__item"
            title={(
              <div className="version-update__item-content">
                <div className="version-update__item-header">
                  <div className="version-update__item-header-wrapper">
                    <div className="version-update__item-title-wrapper">
                      <h3 className="version-update__item-title">
                        {module.moduleName}
                      </h3>
                      <div className="version-update__item-version">
                        (
                        {(module?.currentVersion || '').toUpperCase()}
                        <img src={RightArrowIcon} alt="Updates to" />
                        {(module?.latestVersion || '').toUpperCase()}
                        )
                      </div>
                      {module.showBreakingChangeFlag && (
                        <img className="version-update__item-breaking-change-icon" src={WarningIcon} alt="Breaking change" />
                      )}
                    </div>
                    <h4 className="version-update__item-subtitle">
                      {module.moduleId}
                    </h4>
                  </div>
                  <Button
                    buttonType="secondary"
                    className="version-update__update-button"
                    onClick={() => selectModuleToBeUpdated(
                      moduleUpdateDetails.findIndex((m) => m.moduleId === module.moduleId),
                    )}
                  >
                    Update
                  </Button>
                </div>
              </div>
              )}
          >
            <div className="version-update__item-details">
              {(module?.versionChangeDetails || []).map((detail) => (
                detail?.logs?.length > 0 && (
                  <div key={`${module.moduleId}-${detail.comparisonName}`} className="version-update__item-details-wrapper">
                    <div className="version-update__item-details-description">
                      {detail.description}
                    </div>
                    <ul className="version-update__item-details-list">
                      {detail.logs.map((log, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={`${module.moduleId}-${detail.comparisonName}-${index}`} className="version-update__item-details-list-item">
                          {log}
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              ))}
            </div>
          </Accordion>
        ))}
      </div>
    )
  );
}

DisplayVersionUpdateModules.propTypes = {
  filteredModuleUpdateDetails: PropTypes.array.isRequired,
  selectModuleToBeUpdated: PropTypes.func.isRequired,
  moduleUpdateDetails: PropTypes.array.isRequired,
};

export default DisplayVersionUpdateModules;
