import getNextVariableName from './getNextVariableName';

const prettifyRule = (rule) => {
  const usedAlphas = [];
  const calculatedRuleUnitMap = {};

  // using the existing logic to parse rules here
  const regex = /((?:(?:"(?:\\"|[^"])*"|'(?:\\'|[^'])*')|\d*\.?\d+|null|true|false|\w+(\.\w+)*)\s+(===?|!==?|[<>]=?|==)\s+(?:(?:(?<=\s)|^)(?=\s)|(?:(?:"(?:\\"|[^"])*"|'(?:\\'|[^'])*')|\d*\.?\d+|null|true|false|\w+(\.\w+)*))\s*(?:(?:(?<=\s)|$)(?=\s)|))/gi;

  const currPrettifiedRule = rule.replace(regex, (match) => {
    const letter = getNextVariableName(usedAlphas);
    usedAlphas.push(letter);
    calculatedRuleUnitMap[letter] = match.trim();
    return `${letter}`;
  });

  // Ensuring that operator && and || has one and only one space on both sides.
  const outputString = currPrettifiedRule.replace(/(\s)*(&&|\|\||&|\|)(\s)*/g, ' $2 ');

  return { calculatedRuleUnitMap, calculatedPrettifiedRule: outputString };
};

export default prettifyRule;
