import PropTypes from 'prop-types';
import '../../containers/Branding/EditIndividualScreens.scss';

function ShowListOfElements(props) {
  const {
    brandingElements,
    setSelectedElement,
    selectedElement,
    selectedWorkflow,
    selectedModuleId,
  } = props;

  const shouldDisplayElement = (element) => {
    if (!element || !element.textConfig) return false;
    if (!element.property) return true;
    const selectedModule = selectedWorkflow.modules
      .find((module) => module.id === selectedModuleId);
    return selectedModule?.properties?.[element.property];
  };

  return (
    <div className="elements-list-div">
      {brandingElements.map((element, index) => {
        const displayElement = shouldDisplayElement(element);
        return displayElement &&
          (
            <button
              key={element.name}
              className="elements-list"
              type="button"
              onClick={() => setSelectedElement(index)}
            >
              <div className={`elements-list__name ${element.name === selectedElement.name ? 'elements-list__name_selected' : ''}`}>
                {element.name}
              </div>
            </button>
          );
      })}

    </div>
  );
}

ShowListOfElements.propTypes = {
  brandingElements: PropTypes.object.isRequired,
  setSelectedElement: PropTypes.func.isRequired,
  selectedElement: PropTypes.object.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  selectedModuleId: PropTypes.string.isRequired,
};

export default ShowListOfElements;
