const generateLogs = (addedModuleInputs) => addedModuleInputs.map((input) => (
  <span key={input.workflowKey}>
    Input
    {' '}
    <b>
      &quot;
      {input.workflowKey}
      &quot;
    </b>
    {' '}
    has been added
  </span>
));

const checkInputsAddedInModuleVersion = (
  configurations,
) => {
  const {
    currentUiConfig = {},
    latestUiConfig = {},
  } = configurations || {};
  const currentInputs = [
    ...(currentUiConfig?.sections?.inputs || []),
    ...(currentUiConfig?.sections?.configurations || []),
  ];
  const currentModuleInputsMap = currentInputs
    .filter((input) => input?.workflowKey?.includes('[+]'))
    .reduce((acc, input) => {
      acc[input?.workflowKey] = input;
      return acc;
    }, {});

  const latestInputs = [
    ...(latestUiConfig?.sections?.inputs || []),
    ...(latestUiConfig?.sections?.configurations || []),
  ];
  const latestModuleInputs = latestInputs
    .filter((input) => input?.workflowKey?.includes('[+]'));

  const addedModuleInputs = latestModuleInputs.filter(
    (input) => !(input?.workflowKey in currentModuleInputsMap),
  );

  if (addedModuleInputs.length > 0) {
    return {
      hasDifferences: true,
      logs: generateLogs(addedModuleInputs),
      extraData: {
        addedModuleInputs,
      },
    };
  }

  return {
    hasDifferences: false,
    logs: [],
    extraData: {},
  };
};

export default checkInputsAddedInModuleVersion;
