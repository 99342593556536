import PropTypes from 'prop-types';
import './LoadingState.scss';

function LoadingState({ loadingStateInfo, size }) {
  const cols = Array.from(Array(loadingStateInfo.colSize).keys());
  const rows = Array.from(Array(loadingStateInfo.rowSize).keys());
  return (
    <div className="common__table_container">
      <div className="common__table_wrapper">
        <table className="emptyLoading">
          <thead>
            <tr>
              {cols.map((col) => (<th key={col}>{col}</th>))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row}>
                {cols.map((col) => (<td key={col}>{col}</td>))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="loader_container">
        <div className={`common_spinner ${size}`} />
      </div>
    </div>
  );
}

LoadingState.defaultProps = {
  size: 'small',
};

LoadingState.propTypes = {
  loadingStateInfo: PropTypes.object.isRequired,
  size: PropTypes.string,
};

export default LoadingState;
