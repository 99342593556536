import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import infoIcon from '../../../assests/icons/infoIcon.svg';

function PropertiesTooltip({ infoButton }) {
  if (!infoButton) return null;
  return (
    <Tooltip
      title={(
        <span>
          {infoButton?.description || infoButton?.title}
        </span>
      )}
      placement="bottom"
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
    >
      {(infoButton?.description || infoButton?.title) ? (
        <span>
          {(infoButton?.image) ? (
            <img src={infoButton?.image} alt="info" />
          ) : (
            <img src={infoIcon} alt="info" />
          )}
        </span>
      ) : null}
    </Tooltip>
  );
}

PropertiesTooltip.propTypes = {
  infoButton: PropTypes.object,
};

PropertiesTooltip.defaultProps = {
  infoButton: null,
};

export default PropertiesTooltip;
