const createMenuData = (transformedParentModules, parentModules) => ({
  label: 'Please Select',
  items: [
    {
      label: 'Parent Modules',
      items: transformedParentModules,
      disabled: parentModules?.length === 0,
    },
  ],
});

export default createMenuData;
