import tinycolor from 'tinycolor2';
import { cloneDeep } from 'lodash';

export const changeOpacity = (color, percentage) => {
  if (!color || !tinycolor(color).isValid()) {
    return null;
  }

  if (percentage === undefined || percentage === null || typeof percentage !== 'number') {
    return tinycolor(color).toHex8String();
  }
  const colorObject = tinycolor(color);
  const hexString = colorObject.toHexString();
  const opacity = colorObject.getAlpha() * percentage;
  const finalColor = tinycolor(hexString).setAlpha(opacity).toHex8String();
  return finalColor;
};

export const applyColorSchemeToUiConfig = (
  colorKeys,
  colors,
  uiConfig,
) => {
  const editedUiConfig = cloneDeep(uiConfig);
  const { primaryColor, accentColor, sdkBackgroundColor } = colors;
  const primaryOpacity = 0.8;
  const disabledButtonOpacity = 0.5;
  const hoverButtonOpacity = 0.05;

  Object.keys(editedUiConfig.colors).forEach((key) => {
    if (colorKeys.primaryColorKeys.includes(key)) {
      editedUiConfig.colors[key] = primaryColor;
    } else if (colorKeys.primaryColorSubsetKeys.includes(key)) {
      editedUiConfig.colors[key] = changeOpacity(primaryColor, primaryOpacity);
    } else if (colorKeys.accentColorDisabledButtonKeys.includes(key)) {
      editedUiConfig.colors[key] = changeOpacity(accentColor, disabledButtonOpacity);
    } else if (colorKeys.accentColorHoverButtonKeys.includes(key)) {
      editedUiConfig.colors[key] = changeOpacity(accentColor, hoverButtonOpacity);
    } else if (colorKeys.accentColorKeys.includes(key)) {
      editedUiConfig.colors[key] = accentColor;
    } else if (colorKeys.sdkBackgroundColorKeys.includes(key)) {
      editedUiConfig.colors[key] = sdkBackgroundColor;
    }
  });

  editedUiConfig.colors.primaryColor = primaryColor;
  editedUiConfig.colors.accentColor = accentColor;
  editedUiConfig.colors.sdkBackgroundColor = sdkBackgroundColor;

  return editedUiConfig;
};
