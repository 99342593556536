import { endStateKeys as endStates } from '../components/constants';
import { getNextStepForModule } from '../components/utils';

export const getNextStepsForModule2 = (module) => {
  const nextSteps = getNextStepForModule(module);
  // filter for endstates
  const finalNextSteps = nextSteps
    .filter(({ nextStep }) => !endStates.includes(nextStep))
    .map(({ nextStep, path }) => ({ id: nextStep, path }));
  return finalNextSteps;
};

export const createParentMap = (workflowConfig) => {
  const nextStepsMap = {};

  // Populate nextStepsMap
  // Get nextSteps
  if (workflowConfig?.module || workflowConfig?.conditions) {
    (workflowConfig?.modules || []).forEach((module) => {
      const moduleId = module.id;
      nextStepsMap[moduleId] = [];
      nextStepsMap[moduleId] = getNextStepsForModule2(module);
    });
    const conditions = Object.keys(workflowConfig?.conditions || {});
    conditions.forEach((conditionId) => {
      nextStepsMap[conditionId] = [];
      const condition = workflowConfig.conditions[conditionId];
      if (!endStates.includes(condition.if_true)) {
        nextStepsMap[conditionId].push({
          id: condition.if_true,
          path: 'if_true',
        });
      }
      if (!endStates.includes(condition.if_false)) {
        nextStepsMap[conditionId].push({
          id: condition.if_false,
          path: 'if_false',
        });
      }
    });
  }
  const parentMap = {};
  const nodes = Object.keys(nextStepsMap);
  nodes.forEach((parentId) => {
    const children = nextStepsMap[parentId];
    children.forEach((child) => {
      const { id, path } = child;
      if (parentMap[id]) parentMap[id].push({ id: parentId, path });
      else parentMap[id] = [{ id: parentId, path }];
    });
  });
  return parentMap;
};
