import { useState, useEffect, useRef } from 'react';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import searchIcon from '../../assests/icons/searchIcon.svg';
import chevronLeft from '../../assests/icons/chevronLeft.svg';
import chevronRight from '../../assests/icons/chevronRight.svg';
import TextInputWithButton from './TextInputWithBtn/TextInputWithBtn';
import chevronDown from '../../assests/icons/chevronDown.svg';
import selected from '../../assests/icons/selected.svg';
import emptyState from '../../assests/icons/emptyState.svg';
import { getLabel } from './NestedDropdown/helper';
import CustomizableToolTip from '../Common/CustomizableToolTip';

import './DropDown.scss';

const customStyles = {
  tooltip: {
    backgroundColor: 'grey',
    color: 'white',
    padding: '2px 6px',
    fontSize: '12px',
  },
  arrow: {
    backgroundColor: 'white',
    border: '1px solid #dadde9',
  },
};

function DropDown({
  items,
  onChange,
  defaultValue,
  textBoxPlaceholder,
  allowCustomInput,
  noSelectLabel,
  maxLength,
  onRemoveSelection,
}) {
  const sortItems = (initialItems) => initialItems.sort((a, b) => a.label?.localeCompare(b.label));
  const searchInputRef = useRef(null);
  const [currentItems, setCurrentItems] = useState(sortItems(items.items));
  const [navigationPath, setNavigationPath] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    getLabel(defaultValue, items) || defaultValue || noSelectLabel || 'Select a Value',
  );
  const [isCustomInput, setIsCustomInput] = useState(false);
  const [customInputValue, setCustomInputValue] = useState('');
  const dropdownRef = useRef(null);

  const handleItemClick = (item) => {
    if (item.value === 'builder_customInput') {
      setIsCustomInput(true);
      setDropdownVisible(false);
    } else if (item.items && item.items.length > 0) {
      setNavigationPath([...navigationPath, item]);
      setCurrentItems(sortItems(item.items));
      setSearchQuery('');
      setIsCustomInput(false);
    } else {
      setSelectedValue(item.displayLabel || item.label);
      setDropdownVisible(false);
      onChange(item);
      setIsCustomInput(false);
    }
  };

  const handleBackClick = () => {
    const newNavigationPath = [...navigationPath];
    newNavigationPath.pop();
    setCurrentItems(sortItems(
      newNavigationPath.length > 0 ?
        newNavigationPath[newNavigationPath.length - 1].items : items.items,
    ));
    setNavigationPath(newNavigationPath);
    setSearchQuery('');
    setIsCustomInput(false);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleValueChange = (newValue, fromCustomInput = false) => {
    setSelectedValue(newValue);
    onChange({
      value: newValue,
      isCustomInput: fromCustomInput,
    });
  };

  const handleCustomInputChange = (e) => {
    setCustomInputValue(e);
    handleValueChange(e, true);
  };

  const handleCustomInputSubmit = () => {
    setSelectedValue('Select a Value');
    setDropdownVisible(false);
    onChange({ label: customInputValue, value: customInputValue });
    setIsCustomInput(false);
    setCustomInputValue('');
    onRemoveSelection();
  };

  const filteredItems =
  currentItems.filter((item) => item.label?.toLowerCase().includes(searchQuery.toLowerCase()));

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
    setIsCustomInput(false);
  };
  useEffect(() => {
    const label = getLabel(defaultValue, items);
    if (label) {
      setSelectedValue(label);
      setIsCustomInput(false);
    } else if (defaultValue !== 'Select a Value' && defaultValue) {
      setIsCustomInput(true);
      setSelectedValue(defaultValue);
      setCustomInputValue(defaultValue);
    }
  }, [defaultValue, items]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [navigationPath, isDropdownVisible]);

  useEffect(() => {
    setCurrentItems(sortItems(items.items));
  }, [items]);

  return (
    <div className={`dropdown${isCustomInput ? '_custom-Input' : ''}`} ref={dropdownRef}>
      <div
        className="dropdown-header"
        tabIndex="0"
        role="button"
        onClick={isCustomInput ? undefined : toggleDropdown}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !isCustomInput) toggleDropdown();
        }}
      >
        {isCustomInput ? (
          <TextInputWithButton
            placeholder={textBoxPlaceholder}
            initialValue={customInputValue}
            onChange={handleCustomInputChange}
            onBtnClick={handleCustomInputSubmit}
            showBtn
          />
        ) : (
          <div className="dropdown-header_container">
            <div className={`${maxLength !== 25 ? 'long-text' : 'medium-text'}`}>
              {selectedValue}
            </div>
            <img src={chevronDown} alt="down icon" />
          </div>

        )}
      </div>
      {isDropdownVisible && (
        <div id="dropdown-content" className={`dropdown-content${navigationPath.length ? '' : '__first-section'}`}>
          {(navigationPath.length !== 0) && (
          <div className="search-bar-container">
            <img src={searchIcon} alt="search icon" />
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="search-bar"
              ref={searchInputRef}
            />
          </div>
          )}
          {navigationPath.length > 0 && (
          <div
            className="breadcrumb"
            role="button"
            onClick={handleBackClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleBackClick();
            }}
            tabIndex="0"
          >
            <img src={chevronLeft} alt="chevron left" />
            <span>{navigationPath[navigationPath.length - 1].label}</span>
          </div>
          )}

          <ul className="items-list">
            {filteredItems.length === 0 && (
            <div className="empty-message-container">
              <div className="empty-message-icon">
                <img src={emptyState} alt="empty" />
              </div>
              <div className="empty-message-text">No results found</div>
            </div>

            )}
            {filteredItems.map((item) => {
              const shortLabel = truncate(item.label, { length: maxLength });
              const label = shortLabel.length > maxLength - 1 ? (
                <CustomizableToolTip customStyles={customStyles} title={item.label} placement="top">
                  {shortLabel}
                </CustomizableToolTip>
              ) : (
                shortLabel
              );
              const isSelected = selectedValue === (item.displayLabel || item.label);
              return (
                <div className={`items-container  ${isSelected ? 'item-selected' : ''}`} key={item.value}>
                  <li
                    className={`item ${item.disabled ? 'disabled' : ''}`}
                  >
                    <button
                      className={`item-button ${item.disabled ? 'disabled' : ''}`}
                      onClick={() => !item.disabled && handleItemClick(item)}
                      disabled={item.disabled}
                      type="button"
                    >
                      {label}
                      {isSelected && <img className="tick-mark" src={selected} alt="selected" />}
                    </button>
                  </li>
                  {item.items && item.items.length > 0 && (
                  <img className="item-icon" src={chevronRight} alt="chevron right" />
                  )}
                </div>
              );
            })}
            {!navigationPath.length && allowCustomInput && (
            <div className="items-container">
              <li
                className="item"
              >
                <button
                  className="item-button"
                  onClick={() => handleItemClick({ label: 'Custom Input', value: 'builder_customInput' })}
                  type="button"
                >
                  Custom Input
                </button>
              </li>
              <img className="item-icon" src={chevronRight} alt="chevron right" />
            </div>
            )}
          </ul>

        </div>
      )}
    </div>
  );
}
DropDown.propTypes = {
  items: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  textBoxPlaceholder: PropTypes.string.isRequired,
  allowCustomInput: PropTypes.bool.isRequired,
  noSelectLabel: PropTypes.string,
  maxLength: PropTypes.number,
  onRemoveSelection: PropTypes.func,
};
DropDown.defaultProps = {
  noSelectLabel: 'Please Select',
  maxLength: 25,
  onRemoveSelection: () => {},
};

export default DropDown;
