import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';

function BodyParamToggle({ value: currentValue, onChange }) {
  const onToggle = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={currentValue}
      exclusive
      onChange={onToggle}
      aria-label="Selection"
      size="small"
    >
      <ToggleButton value="param">Request Parameters</ToggleButton>
      <ToggleButton value="body">Request Body</ToggleButton>
    </ToggleButtonGroup>
  );
}

BodyParamToggle.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BodyParamToggle;
