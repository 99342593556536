const filterDocumentsForCountries = (documentList, query, selectedCountries) => {
  const filteredOptions = documentList.filter(
    (country) => {
      if (query === '') {
        return selectedCountries.some(
          ({ name }) => name.toLowerCase() === country.name.toLowerCase(),
        );
      }
      return selectedCountries.some(
        ({ name }) => name.toLowerCase() === country.name.toLowerCase()
        && name.toLowerCase().includes(query.toLowerCase()),
      );
    },
  );
  return filteredOptions;
};

export default filterDocumentsForCountries;
