/* eslint-disable */
import CheckboxIcon from '../../assests/icons/checkbox.png'
export const CustomCheckbox = () => {
  return (
    <img className='checkbox' src={CheckboxIcon}/>
  )
}

export const CustomCheckbox2 = () =>{
    return (
        <input className="checkbox2" type="checkbox"/> 
    )
}