import PropTypes from 'prop-types';
import ColorPicker from '../Branding/ColorPicker/ColorPicker';
import DebouncedTextInput from './Common/DebouncedTextInput';
import DropDown from './Common/DropDown';
import Toggle from './Common/Toggle';

function GenericEditor(props) {
  const { specs, defaultValue, onChange } = props;
  const {
    type, label, options, toggleOptions, placeholder,
  } = specs;
  switch (type) {
    case 'text':
      return (
        <DebouncedTextInput
          label={label}
          onChange={onChange}
          defaultValue={defaultValue}
          placeholder={placeholder}
        />
      );
    case 'colorPicker':
      return (
        <ColorPicker
          name={label}
          color={defaultValue}
          placeholder={placeholder}
          onChange={(_, value) => {
            onChange(value);
          }}
        />
      );
    case 'toggle':
      return (
        <Toggle
          label={label}
          onChange={(value) => onChange(value ? toggleOptions.on : toggleOptions.off)}
          value={defaultValue === toggleOptions.on}
        />
      );
    case 'dropDown':
      return (
        <DropDown
          label={label}
          options={options}
          onChange={onChange}
          defaultValue={defaultValue}
          placeholder={placeholder}
        />
      );
    default:
      return null;
  }
}

GenericEditor.propTypes = {
  specs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.any.isRequired,
};

export default GenericEditor;
