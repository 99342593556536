/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { getSmoothStepPath } from 'reactflow';
import './Workflow.scss';

// const foreignObjectWidth = 120;
// const foreignObjectHeight = 40;
// const foreignObjectDisplaceFromPath = 1.1;
// const foreignObjectCenterOnPath = 2;

const foreignObjectSize = 25;

export default function ButtonEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) {
  const [edgePath, edgeCenterX, edgeCenterY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={100}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY + 10}
      >
        <div id={`${id}_button_edge`} className="branch-name">
          {data.branch}
        </div>
      </foreignObject>
      <foreignObject
        width={200}
        height={targetY - sourceY}
        x={edgeCenterX - 200 / 2}
        y={edgeCenterY - (targetY - sourceY) / 2}
        className="edgebutton-foreignobject"
      >
        <div className="react-flow__edge-path_div">
          <div id={`${id}_addModule_edge`} className="edge-without-box">
            <button
              type="button"
              className="edgebutton"
              onClick={() => data.showAddNodeModal(source, target, id)}
              aria-label="edge"
            />
          </div>
        </div>
      </foreignObject>
    </>
  );
}
