import React from 'react';
import PropTypes from 'prop-types';
import tableHeaders from './workflowTableUtils';
import CommonTableV2 from './Table/CommonTableV2';
import TableNameCell from './TableNameCell';
import TableModules from './TableModules';
import './WorkflowList.scss';

function WorkflowTable({ workflows, isLoading, onEditIconClick }) {
  return (
    <CommonTableV2 tableHeaders={tableHeaders} isLoading={isLoading}>
      {workflows.map(
        ({
          id, name, description, moduleNames,
        }) => (
          <React.Fragment key={id}>
            <TableNameCell
              id={id}
              name={name}
              onEditIconClick={onEditIconClick}
            />
            <div>{description}</div>
            <TableModules modules={moduleNames} />
          </React.Fragment>
        ),
      )}
    </CommonTableV2>
  );
}

WorkflowTable.propTypes = {
  workflows: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onEditIconClick: PropTypes.func.isRequired,
};

export default WorkflowTable;
