import { useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import getSelectedCountriesNameIdList from './utils/getSelectedCountriesNameIdList';
import CountriesPopup from './CountriesPopup';
import SelectedCountriesTable from './SelectedCountriesTable';
import '../SelectDocuments/DocumentsSelect.scss';
import './Countries.scss';
import { getModuleFromId } from '../../../containers/FormModule/helper';

function CountriesSelect(props) {
  const {
    options,
    isDisabled,
    documentList,
    selectedWorkflow,
    selectedNodeId,
    handleCountriesSelection,
  } = props;

  const selectedModule = getModuleFromId(selectedWorkflow, selectedNodeId);
  const countriesList = documentList.map(({ name, id }) => ({ id, name }));

  const [countriesSelected, setCountriesSelected] = useState(
    getSelectedCountriesNameIdList(selectedModule, countriesList),
  );
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the popup state
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleConfirmClick = () => {
    const finalCountriesSelected = countriesSelected.filter((country) => country.id !== '');
    if (finalCountriesSelected.length === 0) {
      alert('Please select atleast one country');
    } else {
      togglePopup();
      handleCountriesSelection(options.workflowKey, finalCountriesSelected);
    }
  };

  const handleSelectAllCountries = (selectAll) => {
    let newCountriesList = countriesList;
    if (!selectAll) {
      newCountriesList = [];
    }
    setCountriesSelected(newCountriesList);
  };

  const handleOptionChange = (option) => {
    const index = countriesSelected
      .findIndex(
        (country) => option.id === country.id,
      );
    const newCountriesSelected = cloneDeep(countriesSelected);
    if (index === -1) {
      newCountriesSelected.push(option);
    } else {
      newCountriesSelected.splice(index, 1);
    }
    if (newCountriesSelected.length === 0) newCountriesSelected.push({ id: '', name: '' });
    setCountriesSelected(newCountriesSelected);
  };

  const shouldSelectCheckBox = (option) => {
    const index = countriesSelected.findIndex((country) => country.id === option.id);
    if (index !== -1) return true;
    return false;
  };

  return (
    <div>
      <SelectedCountriesTable
        options={options}
        countriesSelected={getSelectedCountriesNameIdList(selectedModule, countriesList)}
        togglePopup={togglePopup}
        isDisabled={isDisabled}
      />
      {isOpen && (
      <CountriesPopup
        options={options}
        documentList={documentList}
        handleOptionChange={handleOptionChange}
        shouldSelectCheckBox={shouldSelectCheckBox}
        togglePopup={togglePopup}
        selectAllCountries={handleSelectAllCountries}
        handleConfirmClick={handleConfirmClick}
      />
      )}
    </div>

  );
}

CountriesSelect.propTypes = {
  options: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  documentList: PropTypes.array.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  handleCountriesSelection: PropTypes.func.isRequired,
};

CountriesSelect.defaultProps = {
  isDisabled: false,
};

export default CountriesSelect;
