import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import BaseDrawer from './BaseDrawer';
import { updateCloseBrandingWindow } from '../../reducers/editBranding';
import WorkflowProperties from './WorkflowProperties';
import { getGroupedListOfModules } from '../../utils/editBrandingUtils';
import { selectVersionedModules } from '../../reducers/workflow';

function DefaultDrawer({ workflowConfig, workflowId, workflowName }) {
  const [workflowCreatedAt, setWorkflowCreatedAt] = useState();
  const [workflowUpdatedAt, setWorkflowUpdatedAt] = useState();
  const [activeTab, setActiveTab] = useState(2);

  const supportedScreens = useSelector((state) => state.editBranding.screens);
  const versionedModules = useSelector(selectVersionedModules);
  const selectedWorkflow = useSelector((state) => state.workflow.selectedWorkflow);

  const dispatch = useDispatch();

  const closeBrandingWindow = useSelector(
    (state) => state.editBranding.closeBrandingWindow,
  );

  const handleClick = (tabNumber) => {
    if (tabNumber === 1) {
      dispatch(updateCloseBrandingWindow({ closeBrandingWindow: false }));
    }
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    if (closeBrandingWindow === true) {
      setActiveTab(2);
    }
  }, [closeBrandingWindow]);

  useEffect(() => {
    const {
      createdAt, updatedAt,
    } = workflowConfig;
    setWorkflowCreatedAt(createdAt);
    setWorkflowUpdatedAt(updatedAt);
  }, [workflowConfig]);

  const isUIScreensDisabled = useMemo(() => {
    const { listOfListOfModules } = getGroupedListOfModules(
      selectedWorkflow,
      versionedModules,
      supportedScreens,
    );
    return (!listOfListOfModules?.length);
  }, [selectedWorkflow, supportedScreens, versionedModules]);

  const renderTabElements = () => {
    if (activeTab === 1 && !closeBrandingWindow) {
      return (
        <Navigate to={`/view/ui-screens?id=${workflowId}`} />
      );
    }
    return (
      <WorkflowProperties
        workflowCreatedAt={workflowCreatedAt}
        workflowUpdatedAt={workflowUpdatedAt}
        workflowId={workflowId}
      />
    );
  };

  return (
    <BaseDrawer
      heading={workflowName}
    >
      <div className="options-ribbon">
        <button
          type="button"
          className={`options-ribbon__tab ${activeTab === 1 ? 'options-ribbon__active' : ''}`}
          onClickCapture={() => handleClick(1)}
          disabled={isUIScreensDisabled}
        >
          UI Screens
        </button>
        <button
          type="button"
          className={`options-ribbon__tab__workflow_info ${activeTab === 2 ? 'options-ribbon__active' : ''}`}
          onClickCapture={() => handleClick(2)}
        >
          Info
        </button>
      </div>
      {renderTabElements()}
    </BaseDrawer>

  );
}

DefaultDrawer.propTypes = {
  workflowConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  workflowId: PropTypes.string.isRequired,
  workflowName: PropTypes.string.isRequired,
};

export default DefaultDrawer;
