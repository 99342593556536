const calculateButtonPosition = (buttonRef) => {
  const buttonRect = buttonRef.current.getBoundingClientRect();

  const { top, left, height } = buttonRect;

  const scrollY = window.scrollY || window.pageYOffset;
  const scrollX = window.scrollX || window.pageXOffset;
  const topRelativeToViewport = top + scrollY + height;
  const leftRelativeToViewport = left + scrollX;

  return { top: topRelativeToViewport, left: leftRelativeToViewport };
};

export default calculateButtonPosition;
