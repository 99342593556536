/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useGetModulesStyle from './useGetModulesStyle';
import './WorkflowList.scss';
import { selectVersionedModules } from '../../reducers/workflow';

function TableModules({ modules }) {
  const versionedModules = useSelector(selectVersionedModules);

  const styleConfig = useGetModulesStyle();

  return (
    <div id="workflow_table__modules">
      {modules.map((module, idx) => {
        let moduleName;

        const moduleDetails = versionedModules[module]?.[versionedModules[module]?.latestVersion];
        if (moduleDetails?.uiConfig?.moduleName) {
          ({ moduleName } = moduleDetails.uiConfig);
        } else {
          moduleName = moduleDetails?.config?.uiConfig?.node?.heading;
        }
        if (styleConfig[module]) {
          return (
            <p
              key={idx}
              style={{ ...styleConfig[module] }}
            >
              {moduleName}
            </p>
          );
        }
        return null;
      })}
    </div>
  );
}

TableModules.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TableModules;
