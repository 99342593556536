import deepEqualIgnoringArrayOrder from '../../../../../utils/deepEqual';

const generateLogs = (
  removedOrChangedBuilderProperties,
  latestBuilderPropertiesMap,
) => removedOrChangedBuilderProperties
  .map((property) => (
    !(property.workflowKey in latestBuilderPropertiesMap) ? (
      <span key={property.workflowKey}>
        Builder property
        <b>
          &quot;
          {property.workflowKey.split('[-]')[1]}
          &quot;
        </b>
        has been removed
      </span>
    ) : (
      <span key={property.workflowKey}>
        Builder property
        {' '}
        <b>
          &quot;
          {property.workflowKey.split('[-]')[1]}
          &quot;
        </b>
        {' '}
        has been changed
      </span>
    )
  ));

const checkBuilderPropertiesRemovedInModuleVersion = (
  configurations,
) => {
  const {
    currentUiConfig = {},
    latestUiConfig = {},
  } = configurations || {};
  const currentBuilderProperties = [
    ...(currentUiConfig?.sections?.inputs || []),
    ...(currentUiConfig?.sections?.configurations || []),
  ].filter((input) => input.workflowKey.startsWith('builderProperties[-]'));
  const latestBuilderProperties = [
    ...(latestUiConfig?.sections?.inputs || []),
    ...(latestUiConfig?.sections?.configurations || []),
  ].filter((input) => input.workflowKey.startsWith('builderProperties[-]'));

  const latestBuilderPropertiesMap = latestBuilderProperties
    .reduce((acc, input) => {
      acc[input.workflowKey] = input;
      return acc;
    }, {});

  const removedOrChangedBuilderProperties = currentBuilderProperties
    .filter((property) => !(property.workflowKey in latestBuilderPropertiesMap)
      || !deepEqualIgnoringArrayOrder(property, latestBuilderPropertiesMap[property.workflowKey]));

  if (removedOrChangedBuilderProperties.length > 0) {
    return {
      hasDifferences: true,
      logs: generateLogs(removedOrChangedBuilderProperties, latestBuilderPropertiesMap),
      extraData: {
        removedOrChangedBuilderProperties,
      },
    };
  }

  return {
    hasDifferences: false,
    logs: [],
    extraData: {},
  };
};

export default checkBuilderPropertiesRemovedInModuleVersion;
