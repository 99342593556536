import HVError, { errorCodes, errorMessages } from '../../utils/error';

export const containDuplicates = (array) => new Set(array).size !== array.length;

const validateNoDuplicateIds = (moduleConfig) => {
  const moduleIds = moduleConfig.library.modules.map(({ id }) => id);
  const conditionIds = Object.keys(moduleConfig.library.conditions);
  const conditionalVariableIds = Object.keys(moduleConfig.library.conditionalVariables);
  const allIds = [...moduleIds, ...conditionIds, ...conditionalVariableIds];
  if (containDuplicates(allIds)) {
    throw new HVError({
      code: errorCodes.invalidModuleConfig,
      message: errorMessages.foundDuplicatesInIds,
    });
  }
};

export default validateNoDuplicateIds;
