import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import SingleSelectDropdown from '../Common/SingleSelectDropdown';

function BrandingNavBar(props) {
  const {
    activeTab,
    handleClick,
    handleSave,
    isSaving,
    languagesSupported,
    onLanguageChange,
    selectedLanguage,
  } = props;

  return (
    <div className="edit-branding-popup__header-div">
      <div className="edit-branding-popup__header__ribbon">
        <button
          className={`edit-branding-popup__header__button 
                ${activeTab === 1 ? 'edit-branding-popup__header__active' : ''}`}
          onClickCapture={() => handleClick(1)}
          type="button"
        >
          Branding
        </button>
        <button
          className={`edit-branding-popup__header__button 
                ${activeTab === 2 ? 'edit-branding-popup__header__active' : ''}`}
          type="button"
          onClickCapture={() => handleClick(2)}
        >
          Screen Edits
        </button>
      </div>
      <div className="edit-branding-popup__header__save-close-div">
        <div className="edit-branding-popup__header__save-lang">
          <SingleSelectDropdown
            items={languagesSupported}
            onElementSelect={onLanguageChange}
            defaultValue={selectedLanguage}
          />
        </div>
        <button
          className="edit-branding-popup__header__save__button_form"
          type="button"
          onClick={handleSave}
          disabled={isSaving}
        >
          {isSaving
            ? (
              <>
                <CircularProgress size="1rem" />
                <span>
                  Saving
                </span>
              </>
            )
            : (
              <span>
                Save Form
              </span>
            )}
        </button>
      </div>
    </div>
  );
}

BrandingNavBar.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  isSaving: PropTypes.bool.isRequired,
  languagesSupported: PropTypes.array.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

export default BrandingNavBar;
