import PropTypes from 'prop-types';
import { useState } from 'react';
import plus from '../../assests/icons/Plus.svg';
import ModuleInputsDrawer from './ModuleInputsDrawer';
import ModuleInputRow from './ModuleInputRow';
import generateUniqueID from '../../utils/generateUniqueId';

function ModuleConfigurations(props) {
  const {
    moduleInputs,
    moduleIdsDropdown,
    pageHeading,
    pageDescription,
    addButtonText,
    moduleConfigPosition,
    configurationDrawerHeading,
    onAdd,
    onDelete,
    menuOptions,
    onDragDrop,
  } = props;

  const [selectedInputIdx, setSelectedInputIdx] = useState(null);

  const createNewInput = () => {
    const newInputIdx = moduleInputs.length;
    setSelectedInputIdx(newInputIdx);
  };

  const onSelectModuleInput = (moduleInputIdx) => {
    setSelectedInputIdx(moduleInputIdx);
  };

  const closeConfigDrawer = () => {
    setSelectedInputIdx(null);
  };

  const getDefaultInputConfig = () => ({
    id: generateUniqueID(),
    configSectionName: moduleConfigPosition,
    uiConfigSectionName: 'inputs',
  });

  const addInput = (inputConfig) => {
    onAdd(selectedInputIdx, inputConfig);
    closeConfigDrawer();
  };

  const deleteInput = (inputIdx) => {
    onDelete(inputIdx);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('draggedItemIndex', index);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow dropping
  };

  const handleDrop = (e, dropIndex) => {
    const draggedItemIndex = e.dataTransfer.getData('draggedItemIndex');
    if (draggedItemIndex === null || draggedItemIndex === dropIndex) return;
    onDragDrop(draggedItemIndex, dropIndex);
  };

  return (
    <>
      <div className="workflowInput__topContent">
        <div className="workflowInput__topContent_heading">
          {pageHeading}
        </div>
        <div className="workflowInput__topContent_subheading">
          {pageDescription}
        </div>
      </div>
      <div className="module_builder__input_button_container">
        {(moduleInputs || []).map((input, idx) => (
          <ModuleInputRow
            key={input.id}
            name={input.workflowKey}
            onClick={() => onSelectModuleInput(idx)}
            onDelete={() => deleteInput(idx)}
            handleDragStart={(e) => handleDragStart(e, idx)}
            handleDragOver={handleDragOver}
            handleDrop={(e) => handleDrop(e, idx)}
          />
        ))}
      </div>
      <div className="workflowOutput_buttonContainer">
        <button type="button" className="workflowOutput_addBtn" onClick={createNewInput}>
          <img alt="plus" src={plus} />
          <div>
            {addButtonText}
          </div>
        </button>
      </div>
      {selectedInputIdx !== null && (
        <ModuleInputsDrawer
          moduleIdsDropdown={moduleIdsDropdown}
          onClose={closeConfigDrawer}
          onAddInput={addInput}
          currInput={
            moduleInputs[selectedInputIdx] || getDefaultInputConfig()
          }
          configurationDrawerHeading={configurationDrawerHeading}
          menuOptions={menuOptions}
        />
      )}
    </>
  );
}

ModuleConfigurations.propTypes = {
  moduleInputs: PropTypes.array.isRequired,
  moduleIdsDropdown: PropTypes.shape({
    items: PropTypes.array.isRequired,
  }).isRequired,
  pageHeading: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  addButtonText: PropTypes.string.isRequired,
  moduleConfigPosition: PropTypes.string.isRequired,
  configurationDrawerHeading: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  menuOptions: PropTypes.object.isRequired,
  onDragDrop: PropTypes.func.isRequired,
};

export default ModuleConfigurations;
