const standartWorkflow = {
  properties: {
    name: 'Default Name',
    description: 'Default Description',
    builtOnBuilder: true,
    sdkVersions: {
      mobile: {
        maximum: '10.0.0',
        minimum: '0.3.0',
      },
      web: {
        maximum: '10.0.0',
        minimum: '5.0.0',
      },
    },
    useWebForm: true,
    web: {
      redirectToMobile: 'optional',
      returnPartialSDKResponse: true,
    },
    serverSideResume: {
      enable: false,
      exitOnEndStates: [],
    },
  },
  conditions: {},
  modules: [
    {
      type: 'countries',
      subType: 'countries',
      id: 'module_countryPicker',
      nextStep: 'approve',
      properties: {
        countriesSupported: [
          'ind',
        ],
      },
    },
  ],
};

export default standartWorkflow;
