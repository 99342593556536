import PropTypes from 'prop-types';
import './FontPicker.scss';

function FontPicker(props) {
  const {
    supportedFontValues,
    fontValue,
    onFontValueChange,
    pickerLabel,
  } = props;
  const handleFontChange = (event) => {
    onFontValueChange(event.target.value);
  };

  return (
    <div className="font-picker">
      <label htmlFor="font-style" className="font-picker_label">
        {pickerLabel}
        <select
          id="font-style"
          value={fontValue}
          onChange={handleFontChange}
        >
          {
        supportedFontValues.map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))
      }
        </select>
      </label>
    </div>
  );
}

FontPicker.propTypes = {
  supportedFontValues: PropTypes.arrayOf(PropTypes.object).isRequired,
  fontValue: PropTypes.string.isRequired,
  pickerLabel: PropTypes.string.isRequired,
  onFontValueChange: PropTypes.func.isRequired,
};

export default FontPicker;
