import { useState, useCallback } from 'react';
import { cloneDeep, merge } from 'lodash';
import HVError, { errorCodes, errorMessages } from './error';

import useApiHooks from '../hooks/api/useApiHooks';

const useFetchRequiredModules = (initialVersionedModules) => {
  const { fetchModuleByVersion } = useApiHooks();
  const [versionedModules, setVersionedModules] = useState(initialVersionedModules);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchRequiredModules = useCallback(async (requiredModuleVersions) => {
    setLoading(true);
    setError(null);

    try {
      const fetchModuleConfigPromises =
      Object.entries(requiredModuleVersions).map(([, { moduleType, moduleVersion }]) => {
        if (!versionedModules[moduleType]) {
          // eslint-disable-next-line no-console
          console.error(`Error fetching module for subType ${moduleType}. Please change it to a valid subType.`);
          throw new HVError({
            code: errorCodes.unknownSubType,
            message: `${errorMessages.unknownSubType} : ${moduleType}`,
          });
        }
        if (!versionedModules[moduleType][moduleVersion]) {
          return fetchModuleByVersion(moduleType, moduleVersion);
        }
        return null;
      }).filter((promise) => promise !== null);

      const requiredModuleConfigs = await Promise.all(fetchModuleConfigPromises);

      const newVersionedModules = {};
      requiredModuleConfigs.forEach(({ module }) => {
        const { version = 'v1', config, uiConfig } = module;
        newVersionedModules[config.subType] = {
          ...newVersionedModules[config.subType],
          [version]: { config, uiConfig },
        };
      });

      const updatedVersionedModules = cloneDeep(versionedModules);
      merge(updatedVersionedModules, newVersionedModules);

      setVersionedModules(updatedVersionedModules);
      return updatedVersionedModules;
    } catch (err) {
      throw new HVError({
        code: err?.code || errorCodes.somethingWentWrong,
        message: err?.message || errorMessages.errorFetchingModules,
        originalError: err instanceof HVError ? err.originalError : err,
      });
    } finally {
      setLoading(false);
    }
  }, [versionedModules]);

  return {
    versionedModules, fetchRequiredModules, loading, error,
  };
};

export default useFetchRequiredModules;
