import { cloneDeep } from 'lodash';

const addResumeFromToHighLevelWorkflowConditions = (
  highLevelWorkflowConditions,
  resumeFromMap,
) => {
  const conditionEntries =
  Object.entries(highLevelWorkflowConditions || {}).map(([conditionId, condition]) => {
    const clonnedCondition = cloneDeep(condition);
    const resumeFromFalse = resumeFromMap.ifFalseConfigs[conditionId];
    if (resumeFromFalse) clonnedCondition.ifFalseConfigs.resumeFrom = resumeFromFalse;

    const resumeFromTrue = resumeFromMap.ifTrueConfigs[conditionId];
    if (resumeFromTrue) clonnedCondition.ifTrueConfigs.resumeFrom = resumeFromTrue;

    return [conditionId, clonnedCondition];
  });

  return Object.fromEntries(conditionEntries);
};

export default addResumeFromToHighLevelWorkflowConditions;
