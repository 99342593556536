import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './CommonTableV2.scss';
import { Pagination, Stack } from '@mui/material';
import LoadingState from './LoadingState';

function CommonTableV2({ children, tableHeaders, isLoading }) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const clickWorkflow = (child) => {
    navigate(`/view?id=${child.key}`);
  };
  const loadingStateInfo = { rowSize: 5, colSize: 4 };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(children.length / itemsPerPage);
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  return (
    isLoading ? <LoadingState loadingStateInfo={loadingStateInfo} /> : (
      <div className="common__table_container">
        <div className="common__table_overflow">
          <div className="common__table_wrapper">
            <table>
              <thead>
                <tr>
                  {Object.keys(tableHeaders).map((key) => (
                    <th key={key} style={{ width: tableHeaders[key].width }}>
                      {tableHeaders[key].label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {children
                  .slice(startIndex, endIndex)
                  .map((child) => (
                    <tr
                      key={child.key}
                      onClick={() => clickWorkflow(child)}
                      className="common__table_container"
                    >
                      <td>{child.props.children[0]}</td>
                      <td>{child.props.children[1]}</td>
                      <td>{child.props.children[2]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <Stack spacing={2} className="common__table_pagination">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            shape="rounded"
          />
        </Stack>
      </div>
    )
  );
}

export default CommonTableV2;

CommonTableV2.propTypes = {
  children: PropTypes.node.isRequired,
  tableHeaders: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
