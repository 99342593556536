import { cloneDeep } from 'lodash';

const getPredefinedValuesForRightSideDropdown = (
  leftOperand,
  rightSideDropdownElements,
  preDefinedValues,
) => {
  const dropdownElements = cloneDeep(rightSideDropdownElements);
  const preDefinedValueDropdown = dropdownElements.find((item) => item.label === 'Predefined Values');
  if (preDefinedValueDropdown) {
    preDefinedValueDropdown.disabled = true;
    preDefinedValueDropdown.items = [];
  }
  const trimmedLhs = leftOperand.trim();
  const [moduleId, ...splitVariable] = trimmedLhs.split('.');
  const variable = splitVariable.join('.');
  const availablePredefinedValuesModule = preDefinedValues
    .find((preDefinedValue) => preDefinedValue?.moduleId === moduleId &&
      preDefinedValue.variableName === variable);
  if (availablePredefinedValuesModule && preDefinedValueDropdown) {
    preDefinedValueDropdown.disabled = false;
    const preDefinedValuesToChooseFrom = availablePredefinedValuesModule.predefinedValues
      ?.map((value) => ({
        label: value.label,
        value: value.value,
        displayLabel: value.label,
      }));
    preDefinedValueDropdown.items = preDefinedValuesToChooseFrom;
  }
  return dropdownElements;
};

export default getPredefinedValuesForRightSideDropdown;
