import PropTypes from 'prop-types';
import { Button } from '@mui/base';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TryOnMobile from './TryOnMobile';
import TryOnWeb from './TryOnWeb';
import '../Container.scss';
import TryOnHunter from './TryOnHunter';
import TryModuleOnHunter from './TryModuleOnHunter';

function TryWorkflow({
  workflowId,
  allowedToShowDropDown: showMenu,
  showDropdown,
  moduleBuilderMode,
}) {
  const handleClick = () => {
    showDropdown(!showMenu);
  };

  return (
    <div className="try-workflow">
      <Button className={`try-workflow__btn ${showMenu ? 'try-workflow__btn--clicked' : ''}`} onClick={handleClick}>
        <span className="try-workflow__btn-text">Try Workflow</span>
        <ExpandMoreIcon className="try-workflow__chevron-down-icon" />
      </Button>
      {showMenu && (
        <div className="try-workflow__options">
          <TryOnWeb workflowId={workflowId} />
          <TryOnMobile />
          {
            moduleBuilderMode ? (
              <TryModuleOnHunter workflowId={workflowId} />
            ) : (
              <TryOnHunter workflowId={workflowId} />
            )
          }
        </div>
      )}
      {showMenu && (
        <div
          className="try-workflow__bg"
          onClick={() => showDropdown(!showMenu)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') showDropdown(!showMenu);
          }}
          tabIndex="0"
          role="button"
          aria-expanded="true"
          aria-label="Close menu"
        />
      )}
    </div>
  );
}

TryWorkflow.propTypes = {
  workflowId: PropTypes.string.isRequired,
  allowedToShowDropDown: PropTypes.bool.isRequired,
  showDropdown: PropTypes.func.isRequired,
  moduleBuilderMode: PropTypes.bool.isRequired,
};

export default TryWorkflow;
