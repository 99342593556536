import { cloneDeep, set } from 'lodash';
import generateUniqueID from '../../utils/generateUniqueId';

const convertPropertiesArrayToObject = (properties = []) => (
  properties.reduce((acc, { id, ...property }) => {
    acc[id] = property;
    return acc;
  }, {})
);

export const convertModuleBuilderConfigurationsToObject = (workflow) => {
  const clonnedWorkflow = cloneDeep(workflow);
  const { moduleBuilder = {} } = clonnedWorkflow.properties || {};

  if (moduleBuilder?.builderProperties) {
    const builderPropertiesObject =
      convertPropertiesArrayToObject(
        clonnedWorkflow.properties.moduleBuilder.builderProperties,
      );

    set(
      clonnedWorkflow,
      'properties.moduleBuilder.builderProperties',
      builderPropertiesObject,
    );
  }
  if (moduleBuilder?.moduleInputs) {
    const moduleInputsObject =
      convertPropertiesArrayToObject(
        clonnedWorkflow.properties.moduleBuilder.moduleInputs,
      );

    set(
      clonnedWorkflow,
      'properties.moduleBuilder.moduleInputs',
      moduleInputsObject,
    );
  }

  return clonnedWorkflow;
};

const convertPropertiesObjectToArray = (builderProperties = {}) => (
  Object.values(builderProperties).map((property) => ({ id: generateUniqueID(), ...property }))
);

export const convertModuleBuilderConfigurationsToArray = (workflow) => {
  const clonnedWorkflow = cloneDeep(workflow);
  const { moduleBuilder = {} } = clonnedWorkflow.properties || {};
  // Replace the moduleBuilder.builderProperties and moduleBuilder.moduleInputs with array
  if (moduleBuilder?.builderProperties) {
    const builderPropertiesArray = convertPropertiesObjectToArray(
      clonnedWorkflow.properties.moduleBuilder.builderProperties,
    );
    set(
      clonnedWorkflow,
      'properties.moduleBuilder.builderProperties',
      builderPropertiesArray,
    );
  }
  if (moduleBuilder?.moduleInputs) {
    const moduleInputsArray = convertPropertiesObjectToArray(
      clonnedWorkflow.properties.moduleBuilder.moduleInputs,
    );
    set(
      clonnedWorkflow,
      'properties.moduleBuilder.moduleInputs',
      moduleInputsArray,
    );
  }

  return clonnedWorkflow;
};
