export const options1 = [
  { key: '0', name: 'custom' },
  { key: '1', name: 'Name' },
  { key: '2', name: 'Phone Number' },
  { key: '3', name: 'Email Address' },
  { key: '4', name: 'Aadhaar Number' },
  { key: '5', name: 'PAN Number' },
  { key: '6', name: 'Driving License' },
  { key: '7', name: 'GST number' },
  { key: '8', name: 'IFSC code' },
  { key: '9', name: 'Pin Code' },
  { key: '10', name: 'Demat Number' },
  { key: '11', name: 'Bank Account Number' },
  { key: '12', name: 'Corporate PAN Number' },
  { key: '13', name: 'Business PAN' },
];

export const regexValues = [
  {
    key: '0', validationType: 'custom', value: '', errorMsg: '', type: 'regex',
  },
  {
    key: '1', validationType: 'Name', value: '^(?=.{1,60}$)[A-Za-z]+(?: [A-Za-z]+)*(?: [A-Za-z])?$', errorMsg: 'Name should not contain numbers or special characters', type: 'regex',
  },
  {
    key: '2', validationType: 'Phone Number', value: '^[0-9]{10}$', errorMsg: "Hmm, your Phone number doesn't look right. Please check again.", type: 'regex',
  },
  {
    key: '3', validationType: 'Email Address', value: '^([a-zA-Z0-9_]+([\\.-]?[a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([\\.-]?[a-zA-Z0-9_]+)*(\\.[a-zA-Z0-9_]{2,3})+)$', errorMsg: 'Enter a valid email address', type: 'regex',
  },
  {
    key: '4', validationType: 'Aadhaar Number', value: '^[0-9]{12}$', errorMsg: 'Please enter a valid Aadhaar Number', type: 'regex',
  },
  {
    key: '5', validationType: 'PAN Number', value: '^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$', errorMsg: 'The entered value is not a valid PAN Number', type: 'regex',
  },
  {
    key: '6', validationType: 'Driving License', value: '^[A-Z]{2}\\d{2}\\s?(19|20)\\d{9}$', errorMsg: "Hmm, your Driving License number doesn't look right. Please check again.", type: 'regex',
  },
  {
    key: '7', validationType: 'GST number', value: '^[0-9]{2}([a-zA-Z]){5}[0-9]{4}([a-zA-Z]){1}[0-9]{1}([zZ]){1}[0-9]{1}$', errorMsg: 'Please enter a valid GST Number', type: 'regex',
  },
  {
    key: '8', validationType: 'IFSC code', value: '^[A-Z]{4}0[A-Z0-9]{6}$', errorMsg: 'Please enter a valid IFSC code', type: 'regex',
  },
  {
    key: '9', validationType: 'Pin Code', value: '^[1-9][0-9]{5}', errorMsg: 'Please enter a valid pincode', type: 'regex',
  },
  {
    key: '10', validationType: 'Demat Number', value: '^(?:[0-9]{16}|IN[0-9]{14})$', errorMsg: 'Please enter a valid Demat Number', type: 'regex',
  },
  {
    key: '11', validationType: 'Bank Account Number', value: '^\\d{8,18}$', errorMsg: '<No validation provided>', type: 'regex',
  },
  {
    key: '12', validationType: 'Corporate PAN Number', value: '^([a-zA-Z]){3}[a-oq-zA-OQ-Z]{1}([a-zA-Z]){1}[0-9]{4}([a-zA-Z]){1}$', errorMsg: 'Please enter a valid corporate PAN', type: 'regex',
  },
  {
    key: '13', validationType: 'Business PAN', value: '^([a-zA-Z]){3}[a-oq-zA-OQ-Z]{1}([a-zA-Z]){1}[0-9]{4}([a-zA-Z]){1}$', errorMsg: 'Please enter a valid Business PAN', type: 'regex',
  },
];
