const checkSubModuleAddedInModuleVersion = (
  configurations,
) => {
  const {
    currentModuleConfig = {},
    latestModuleConfig = {},
  } = configurations || {};
  const currentSubModuleIdList =
    (currentModuleConfig?.library?.modules || []).map((module) => module.id) || [];
  const latestSubModules = latestModuleConfig?.library?.modules || [];

  const addedSubModules = latestSubModules.filter(
    (subModule) => !currentSubModuleIdList.includes(subModule.id),
  );

  if (addedSubModules.length > 0) {
    return {
      hasDifferences: true,
      logs: addedSubModules.map((subModule) => (
        <span key={subModule.id}>
          Sub-module
          {' '}
          <b>
            &quot;
            {subModule.id}
            &quot;
          </b>
          {' '}
          has been added
        </span>
      )),
      extraData: {
        addedSubModules,
      },
    };
  }

  return {
    hasDifferences: false,
    logs: [],
    extraData: {},
  };
};

export default checkSubModuleAddedInModuleVersion;
