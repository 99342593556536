/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useMemo, useState } from 'react';
import PropTypes, { object } from 'prop-types';

import DocumentsPopup from './DocumentsPopup';
import SelectedDocumentsTable from './SelectedDocumentsTable';
import getSelectedDocuments from './utils/updateSelectedDocuments';
import updateSelectedOptions from './utils/selectedOptions';
import findIndexOfDocumentCountry from './utils/findIndexOfDocumentCountry';
import handleConfigurationChange from './utils/handleConfigurationChange';
import './DocumentsSelect.scss';
import { getModuleFromId } from '../../../containers/FormModule/helper';

function DocumentSelect(props) {
  const {
    options,
    isDisabled,
    documentList,
    documentOverrideOptions,
    selectedWorkflow,
    selectedNodeId,
    handleDocumentsSelection,
  } = props;

  const selectedModule = getModuleFromId(selectedWorkflow, selectedNodeId);
  const selectedCountryIds = useMemo(
    () => selectedWorkflow.modules[0]?.properties?.countriesSupported || [],
    [selectedWorkflow],
  );
  const selectedCountryDetails = useMemo(() => selectedCountryIds.map((id) => {
    const countryData = documentList.find((country) => country.id === id);
    return {
      id,
      name: countryData?.name || id,
    };
  }), [selectedCountryIds, documentList]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState(
    getSelectedDocuments(selectedModule, documentList),
  );

  // Function to toggle the popup state
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const checkcountryWithoutSelectedDocuments = () => {
    const selectedCountriesWithDoc = selectedDocuments.map((doc) => doc.country);
    let ifCountryWithoutDocs = false;
    selectedCountryIds.forEach((country) => {
      if (!selectedCountriesWithDoc.includes(country)) ifCountryWithoutDocs = true;
    });
    return ifCountryWithoutDocs;
  };

  const handleConfirmClick = () => {
    const validSelectedDocuments = selectedDocuments.filter((documents) => documents.country !== '');

    const countryWithoutSelectedDocuments = validSelectedDocuments.filter(
      (document) => document.documents.length === 0,
    )[0];

    if ((countryWithoutSelectedDocuments
       && Object.keys(countryWithoutSelectedDocuments).length > 0)
       || checkcountryWithoutSelectedDocuments()) {
      alert('select atleast one document per country');
    } else {
      togglePopup();
      handleDocumentsSelection(validSelectedDocuments);
    }
  };

  const handleOptionChange = (option, selectedOptionsParam, documentListParam) => {
    const newSelectedOptions = updateSelectedOptions(
      selectedOptionsParam,
      option,
      documentListParam,
    );
    if (newSelectedOptions.length === 0) {
      setSelectedDocuments([{
        country: '',
        countryName: '',
        documents: [],
        documentsName: [],
      }]);
    } else {
      setSelectedDocuments(newSelectedOptions);
    }
  };

  const shouldCheckCountry = (option, selectedOptionsParam) => selectedOptionsParam.some(
    (countryDocs) => (countryDocs.country === option.country && countryDocs.documents.length > 0),
  );

  const shouldSelectCheckBox = (option, selectedOptionsParam) => findIndexOfDocumentCountry(
    selectedOptionsParam,
    option.country,
    option.document,
  ) !== -1;

  const shouldSelectConfigCheckBox = (option, selectedOptionsParam) => {
    const index = findIndexOfDocumentCountry(selectedOptionsParam, option.country, option.document);
    if (index === -1) return false;
    if (option.config.action === 'capture') {
      return selectedOptionsParam[index].configurations?.[option.document]?.sides?.includes(
        option.config.side,
      );
    }
    return selectedOptionsParam[index]
      .configurations
      ?.[option.document]
      ?.sidesConfig
      ?.[option.config.action]
      ?.includes(option.config.side);
  };

  const shouldDisableOption = (option, selectedOptionsParam) => {
    const index = findIndexOfDocumentCountry(selectedOptionsParam, option.country, option.document);
    if (index === -1) return true;
    if (option.config.action === 'capture') {
      return false;
    }
    return !(selectedOptionsParam[index].configurations?.[option.document]?.sides?.includes(
      option.config.side,
    ));
  };

  const handleCountrySelection = (isChecked, country, selectedOptionsParam, documentListParam) => {
    const documentsName = [];
    const documents = [];
    const configurations = {};
    const filteredCountryDocuments = documentListParam.filter(
      (list) => list.id === country.country,
    )[0]?.documents;

    if (isChecked) {
      // select all documents
      filteredCountryDocuments.forEach((doc) => {
        documentsName.push(doc.name);
        documents.push(doc.id);
        configurations[doc.id] = {
          sides: doc.sides,
        };
      });
    }
    const newSelectedOptions = [...selectedOptionsParam];
    const filteredCountryIndex = newSelectedOptions.findIndex(
      (option) => option.country === country.country,
    );

    if (filteredCountryIndex !== -1) {
      newSelectedOptions[filteredCountryIndex] = {
        ...country,
        documents,
        documentsName,
        configurations,
      };
    } else {
      newSelectedOptions.push({
        ...country,
        documents,
        documentsName,
        configurations,
      });
    }
    setSelectedDocuments(newSelectedOptions);
  };

  return (
    <div>
      <SelectedDocumentsTable
        options={options}
        supportedDocuments={getSelectedDocuments(selectedModule, documentList)}
        togglePopup={togglePopup}
        isDisabled={isDisabled}
      />
      {isOpen && (
      <DocumentsPopup
        options={options}
        documentList={documentList}
        selectedCountries={selectedCountryDetails}
        handleOptionChange={(option) => handleOptionChange(option, selectedDocuments, documentList)}
        shouldSelectCheckBox={(option) => shouldSelectCheckBox(option, selectedDocuments)}
        togglePopup={togglePopup}
        handleConfirmClick={handleConfirmClick}
        handleCountrySelection={(isChecked, country) => handleCountrySelection(
          isChecked,
          country,
          selectedDocuments,
          documentList,
        )}
        documentOverrideOptions={documentOverrideOptions}
        shouldSelectConfigCheckBox={(option) => shouldSelectConfigCheckBox(
          option,
          selectedDocuments,
        )}
        shouldDisableOption={(option) => shouldDisableOption(option, selectedDocuments)}
        handleConfigurationChange={(option) => handleConfigurationChange(
          option,
          selectedDocuments,
          documentList,
          setSelectedDocuments,
        )}
        shouldCheckCountry={(option) => shouldCheckCountry(option, selectedDocuments)}
      />
      )}
    </div>
  );
}

DocumentSelect.propTypes = {
  options: PropTypes.instanceOf(object).isRequired,
  isDisabled: PropTypes.bool,
  documentList: PropTypes.array.isRequired,
  documentOverrideOptions: PropTypes.array.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  handleDocumentsSelection: PropTypes.func.isRequired,
};

DocumentSelect.defaultProps = {
  isDisabled: false,
};

export default DocumentSelect;
