import PropTypes from 'prop-types';
import { getSelectedModule } from '../../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import SetNodeName from '../../components/ViewWorkflow/SetNodeName/SetNodeName';
import OutputDrawer from '../../components/ViewWorkflow/Outputs/OutputDrawer';
import TextInput from '../../components/ImportWorkflow/TextInput';
import DropDown from '../../components/ViewWorkflow/DropDown';
import Divider from '../../components/ViewWorkflow/Divider';
import '../../components/Workflow.scss';
import ModuleConfigurationsEditor from './ModuleConfigurationsEditor';
import getPreviousModuleOptions from '../../compilerDecompiler/utils/setPreviousStepInSupermoduleLibrary';

// TODO: Add a validator to check validity of props

function PropertiesTab({
  uiConfig,
  moduleConfig,
  selectedNodeId,
  selectedNodeType,
  selectedNodeVersion,
  selectedWorkflow,
  updateModulePropertyInWorkflow,
  updateGenericApiConfigInWorkflow,
  setPreviousStep,
  versionedModules,
  orderOfModules,
  documentList,
  documentOverrideOptions,
  handleCountriesSelection,
  handleDocumentsSelection,
  moduleBuilderMode,
}) {
  const {
    inputs,
    configurations,
    outputs = null,
    modalConfig = null,
  } = uiConfig.sections;

  const configWithWorkflowKeyExpiresAfter = configurations && configurations.find((config) => config.workflowKey === 'expiresAfter');
  const enableServerSideResume = selectedWorkflow.properties?.enableServerSideResume;
  let modifiedConfigurations = configurations;
  if (!enableServerSideResume && configWithWorkflowKeyExpiresAfter) {
    modifiedConfigurations = configurations.map((config) => {
      if (JSON.stringify(config) === JSON.stringify(configWithWorkflowKeyExpiresAfter)) {
        return {
          ...config,
          enabled: 'true == false',
        };
      }
      return config;
    });
  }

  const getCurrentPreviousStep = (workflow, moduleId) => {
    const module = getSelectedModule(workflow, moduleId);
    if (!module) return '';
    if (module.previousStep === undefined || module.previousStep === null) return 'not-present';
    if (module.previousStep === '') return 'hide-back';
    return module.previousStep;
  };

  return (
    <div>
      <div className="border-box">
        <div className="border-box__content">
          <SetNodeName />
          <TextInput
            label="Node Id"
            setDefaultValue
            readOnly
            placeholder={selectedNodeId}
          />
          <TextInput
            label="Version"
            setDefaultValue
            readOnly
            placeholder={selectedNodeVersion}
          />
          {selectedNodeType !== 'condition' && selectedNodeType !== 'countries' ? (
            <div className="title-and-dropdowns">
              <h2 className="title-and-dropdowns__title">Previous Step</h2>
              <div className="title-and-dropdowns__dropdowns">
                <DropDown
                  allowCustomInput={false}
                  textBoxPlaceholder="value"
                  onChange={({ value }) => {
                    setPreviousStep(selectedNodeId, value);
                  }}
                  defaultValue={getCurrentPreviousStep(selectedWorkflow, selectedNodeId)}
                  items={{
                    label: 'Please Select',
                    items: [
                      {
                        label: 'Default behavior',
                        value: 'not-present',
                        displayLabel: 'Default behavior',
                      },
                      {
                        label: 'Hide back button',
                        value: 'hide-back',
                        displayLabel: 'Hide back button',
                      },
                      {
                        label: 'Previous Module',
                        items: getPreviousModuleOptions(
                          selectedNodeId,
                          selectedWorkflow,
                        ),
                        displayLabel: 'Previous Module',
                      },
                    ],
                  }}
                />
              </div>
            </div>
          )
            : null}
        </div>
        <ModuleConfigurationsEditor
          key={selectedNodeId}
          moduleConfig={moduleConfig}
          inputs={inputs}
          configurations={modifiedConfigurations}
          selectedNodeId={selectedNodeId}
          selectedNodeType={selectedNodeType}
          selectedNodeVersion={selectedNodeVersion}
          selectedWorkflow={selectedWorkflow}
          updateModulePropertyInWorkflow={updateModulePropertyInWorkflow}
          updateGenericApiConfigInWorkflow={updateGenericApiConfigInWorkflow}
          versionedModules={versionedModules}
          orderOfModules={orderOfModules}
          moduleBuilderTestMode={false}
          modalConfig={modalConfig}
          documentList={documentList}
          documentOverrideOptions={documentOverrideOptions}
          handleCountriesSelection={handleCountriesSelection}
          handleDocumentsSelection={handleDocumentsSelection}
          moduleBuilderMode={moduleBuilderMode}
        />
      </div>
      {
        outputs ? (
          <>
            <Divider />
            <OutputDrawer heading="Output from Module" data={outputs} />
          </>
        )
          : null
      }
    </div>
  );
}

PropertiesTab.propTypes = {
  uiConfig: PropTypes.object.isRequired,
  moduleConfig: PropTypes.object.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  selectedNodeType: PropTypes.string.isRequired,
  selectedNodeVersion: PropTypes.string.isRequired,
  updateModulePropertyInWorkflow: PropTypes.func.isRequired,
  updateGenericApiConfigInWorkflow: PropTypes.func.isRequired,
  setPreviousStep: PropTypes.func.isRequired,
  versionedModules: PropTypes.object.isRequired,
  orderOfModules: PropTypes.array.isRequired,
  documentList: PropTypes.array.isRequired,
  documentOverrideOptions: PropTypes.array.isRequired,
  handleCountriesSelection: PropTypes.func.isRequired,
  handleDocumentsSelection: PropTypes.func.isRequired,
  moduleBuilderMode: PropTypes.bool.isRequired,
};

export default PropertiesTab;
