import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';

function ErrorBoundaryWrapper({ resetFunction, children, FallbackComponent }) {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onReset={resetFunction}
    >
      {children}
    </ErrorBoundary>
  );
}

ErrorBoundaryWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  resetFunction: PropTypes.func.isRequired,
  FallbackComponent: PropTypes.func.isRequired,
};

export default ErrorBoundaryWrapper;
