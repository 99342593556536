import { getAllFormComponents } from '../../../../containers/FormModule/helper';

const getExposedVariables = (formComponentList) => formComponentList
  .reduce((exposedVariableList, component) => {
    // eslint-disable-next-line no-param-reassign
    exposedVariableList[component.type] = component.variables;
    return exposedVariableList;
  }, {});

const getFormComponentOutputs = (formComponents, exposedVariableList, formComponentList) => {
  const outputs = [];
  const filteredComponents = formComponents.filter((comp) => comp.type !== 'label');
  filteredComponents.forEach((comp) => {
    const componentType = comp.type === 'list' ? comp.itemsGenerator?.subComponents[0]?.type : comp.type;
    if (componentType === 'vertical' || componentType === 'horizontal') {
      outputs.push(...getFormComponentOutputs(
        comp.subComponents,
        exposedVariableList,
        formComponentList,
      ));
    }
    const exposedVarsOfComponent = exposedVariableList[componentType];
    const primaryBrandingKey = formComponentList
      .find((c) => c.type === componentType)?.primaryBrandingKey;
    const outputsOfComponent = exposedVarsOfComponent?.map((variable) => ({
      name: `${comp[primaryBrandingKey] || comp.id} > ${variable.key}`,
      id: `${comp.id}.${variable.value}`,
    }));
    outputs.push(...(outputsOfComponent || []));
  });
  return outputs;
};

const getFormComponentPredefinedOutputs =
(formComponents, exposedVariableList, formComponentList) => {
  const outputs = [];
  const filteredComponents = formComponents.filter((comp) => comp.type !== 'label');
  filteredComponents.forEach((comp) => {
    const componentType = comp.type === 'list'
      ? comp.itemsGenerator?.subComponents?.[0]?.type
      : comp.type;
    if (componentType === 'vertical' || componentType === 'horizontal') {
      outputs.push(...getFormComponentOutputs(
        comp.subComponents,
        exposedVariableList,
        formComponentList,
      ));
    }
    const exposedVarsOfComponent = exposedVariableList[componentType];
    const outputsOfComponent = exposedVarsOfComponent?.map((variable) => ({
      variableName: `${comp.id}.${variable.value}`,
      predefinedValues: variable.predefinedValues || [],
    })).filter((output) => output.predefinedValues.length);
    outputs.push(...(outputsOfComponent || []));
  });
  return outputs;
};

export const findFormModulePredefinedVariables = (formModule, formComponentList) => {
  const { id, name } = formModule;
  const formComponents = getAllFormComponents(formModule);
  const exposedVariableList = getExposedVariables(formComponentList);
  const formComponentVariableOutputs = getFormComponentPredefinedOutputs(
    formComponents,
    exposedVariableList,
    formComponentList,
  );
  const formComponentsPredefinedVariables =
    formComponentVariableOutputs.map((output) => ({
      moduleId: id,
      moduleName: name,
      ...output,
    }));
  return formComponentsPredefinedVariables;
};

const findFormModuleOutputs = (formModule, formComponentList) => {
  const formComponents = getAllFormComponents(formModule);
  const exposedVariableList = getExposedVariables(formComponentList);
  const formComponentVariableOutputs = getFormComponentOutputs(
    formComponents,
    exposedVariableList,
    formComponentList,
  );
  const defaultVariables = [{ name: 'Attempts', id: 'attempts' }];
  const allOutputs = [...formComponentVariableOutputs, ...defaultVariables];
  return allOutputs;
};

const getVariablesFromComponentConfig = (componentConfig) => {
  const { events = {} } = componentConfig;
  const eventList = Object.keys(events);
  const refreshObjects = eventList.map((event) => events[event]?.refresh || null)
    .filter((obj) => obj !== null);
  const exposedValues = refreshObjects.map((refreshObj) => Object.values(refreshObj));
  return [...new Set(exposedValues.flat(2))];
};

export const findFormModuleV2Outputs = (formModule) => {
  const { componentConfigs = {} } = formModule?.properties || {};
  const componentIds = Object.keys(componentConfigs);
  const allOutputs = componentIds.map((componentId) => {
    const componentConfig = componentConfigs[componentId] || {};
    const exposedOutputs = getVariablesFromComponentConfig(componentConfig);
    return exposedOutputs.map((output) => ({
      name: `${componentId} > ${output}`,
      id: `${componentId}.${output}`,
    }));
  }).flat();
  return allOutputs;
};

export default findFormModuleOutputs;
