import PropTypes from 'prop-types';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'; // Import necessary MUI components
import { CustomCheckbox, CustomCheckbox2 } from './CustomCheckbox';

function CustomCheckboxWithLabel({ label, checked, onChange }) {
  return (
    <FormGroup>
      <FormControlLabel
        control={(
          <Checkbox
            disableRipple
            checked={checked !== undefined ? checked : false}
            onChange={onChange}
            icon={<CustomCheckbox2 />}
            checkedIcon={<CustomCheckbox />}
          />
        )}
        label={label}
        onClick={(e) => e.stopPropagation()}
      />
    </FormGroup>
  );
}

CustomCheckboxWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
CustomCheckboxWithLabel.defaultProps = {
  checked: false,
};
function CustomCheckboxGroup({
  item, items, selectedItems, handleCheckboxChange, toggleLabel,
}) {
  return (
    <FormGroup
      sx={{
        display: 'grid',
        width: '100%',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '8px',
      }}
    >
      {items.map((subItem) => (
        <FormControlLabel
          className="label"
          key={subItem.displayLabel}
          control={(
            <Checkbox
              disableRipple
              iconStyle={{ fill: 'white' }}
              checked={selectedItems.some((val) => val.value === subItem.value)}
              onChange={(e) => handleCheckboxChange(e, item, subItem)}
              icon={<CustomCheckbox2 />}
              checkedIcon={<CustomCheckbox />}
            />
          )}
          label={subItem.label}
          onClick={() => toggleLabel(item.label)}
        />
      ))}
    </FormGroup>
  );
}
CustomCheckboxGroup.propTypes = {
  item: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  toggleLabel: PropTypes.func.isRequired,
};
export { CustomCheckboxWithLabel, CustomCheckboxGroup };
