import { cloneDeep } from 'lodash';
import findIndexOfDocumentCountry from './findIndexOfDocumentCountry';
import updateSelectedOptions from './selectedOptions';

const handleConfigurationChange = (
  option,
  selectedOptionsParam,
  documentListParam,
  setSelectedOptions,
) => {
  const newOption = cloneDeep(option);
  const countryIndex = findIndexOfDocumentCountry(
    selectedOptionsParam,
    option.country,
    option.document,
  );
  const currentConfig = selectedOptionsParam[countryIndex].configurations?.[option.document] || {};
  if (option.config.action === 'capture') {
    const currentSides = currentConfig.sides || [];
    const currSidesConfig = currentConfig.sidesConfig || {};
    const sideIndex = currentSides.findIndex((side) => side === option.config.side);
    if (sideIndex >= 0) {
      const newSides = currentSides.filter((side) => side !== option.config.side);
      const newSidesConfig = Object.entries(currSidesConfig)
        .reduce((acc, [key, value]) => ({
          ...acc,
          [key]: value.filter((side) => side !== option.config.side),
        }), {});

      newOption.newConfig = { ...currentConfig, sides: newSides, sidesConfig: newSidesConfig };
    } else {
      const newSides = [...currentSides, option.config.side];
      newOption.newConfig = { ...currentConfig, sides: newSides };
    }
  } else {
    const currSidesConfig = currentConfig.sidesConfig || {};
    const currSidesConfigOfAction = currSidesConfig[option.config.action] || [];
    const sideIndex = currSidesConfigOfAction.findIndex((side) => side === option.config.side);

    if (sideIndex >= 0) {
      const newActionConfig = currSidesConfigOfAction.filter((side) => side !== option.config.side);
      const newSidesConfig = {
        ...currSidesConfig,
        [option.config.action]: newActionConfig,
      };
      newOption.newConfig = { ...currentConfig, sidesConfig: newSidesConfig };
    } else {
      const newActionConfig = [...currSidesConfigOfAction, option.config.side];
      const newSidesConfig = {
        ...currSidesConfig,
        [option.config.action]: newActionConfig,
      };
      newOption.newConfig = { ...currentConfig, sidesConfig: newSidesConfig };
    }
  }
  const newSelectedOptions = updateSelectedOptions(
    selectedOptionsParam,
    newOption,
    documentListParam,
  );
  setSelectedOptions(newSelectedOptions);
};

export default handleConfigurationChange;
