/* eslint-disable no-unused-vars */
import moduleVersionComparisonConfig from '../../../../constants/moduleVersionComparisonConfig';

const isBreakingChangePresent =
  (comparisonResults) => comparisonResults.some((result) => result?.breakingChange);

const compareModuleVersions = (
  currentVersion,
  latestVersion,
  versionedModule,
) => {
  const currentModuleConfig = versionedModule[currentVersion].config;
  const latestModuleConfig = versionedModule[latestVersion].config;
  const currentUiConfig = versionedModule[currentVersion].uiConfig;
  const latestUiConfig = versionedModule[latestVersion].uiConfig;

  const comparisonResults = moduleVersionComparisonConfig.map((comparison) => {
    const {
      comparisonName, comparisonFn, breakingChange, actionRequired, description,
    } = comparison;

    const response = comparisonFn(
      {
        currentModuleConfig,
        latestModuleConfig,
        currentUiConfig,
        latestUiConfig,
      },
    );

    if (!response?.hasDifferences) return null;

    return {
      comparisonName,
      breakingChange,
      actionRequired,
      description,
      ...response,
    };
  }).filter((result) => result !== null);

  // check if any of the response contains a breaking change
  const hasBreakingChange = isBreakingChangePresent(comparisonResults);

  return {
    hasBreakingChange,
    comparisonResults, // empty array if not update has occurred
  };
};

export default compareModuleVersions;
