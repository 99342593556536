import { cloneDeep } from 'lodash';
import { eventHandlers, endStateKeys as endStates } from '../../components/constants';
import {
  findAndUpdateTheOnlyNextStepInModule,
  updateFormPropertyViaComponentId,
} from '../../components/FormModule/utils';
import { getNextStepForModule } from '../../components/utils';
import { setModuleProperty } from '../../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import { getAllFormComponents, getAllNextSteps } from '../../containers/FormModule/helper';
import generateHighLevelConfig from '../../utils/generateHighLevelConfig';

import {
  addDefaultPropertiesToModule,
  createNewConditionNode,
  createNewModuleNode,
  getNewNextStepFromNodeToBeDeleted,
  getNodeAndType,
  getParentPathAndBranch,
} from '../../containers/workflowOperations';
import {
  logAddCondition,
  logAddNode,
  logDeleteCondition,
  logDeleteNode,
} from '../../logger/logHighLevelWorkflowUpdates';
import { fetchDependencyList } from '../../utils/fetchDependencyList';

export const updateNodeName = (workflowConfig, nodeId, name) => {
  const editedWorkflow = cloneDeep(workflowConfig);
  let success = true;
  if (editedWorkflow.conditions[nodeId]) {
    editedWorkflow.conditions[nodeId].name = name;
  } else {
    const filteredModule = editedWorkflow.modules.filter((node) => node.id === nodeId)?.[0];
    if (filteredModule) {
      filteredModule.name = name;
    } else {
      success = false;
    }
  }
  return { workflow: editedWorkflow, success };
};

export const addNewNodeInWorkflow = ({
  addNodeBetween,
  nodes,
  workflowConfig,
  localOrderOfNodes,
  type,
  node = null,
  countryDocMapping = null,
  defaultFormSections = null,
}) => {
  let workflow = cloneDeep(workflowConfig);
  // Invalid type property
  if (!['condition', 'module'].includes(type)) return { workflow, success: false };
  const { parent: parentId, child } = addNodeBetween;
  const { node: parentNode, type: parentNodeType } = getNodeAndType(parentId, workflowConfig) || {};
  const { parentPath, parentBranch } = getParentPathAndBranch(child, localOrderOfNodes);

  // Check if parent is actual module or condition id
  if (!parentNode || !parentNodeType) return { workflow, success: false };
  if (!['condition', 'module'].includes(parentNodeType)) return { workflow, success: false };
  if (parentNodeType === 'condition' && !parentBranch) return { workflow, success: false };
  if (parentNodeType === 'module' && !parentPath) return { workflow, success: false };

  const newNodeData =
    type === 'condition'
      ? createNewConditionNode(child, nodes)
      : createNewModuleNode(
        node,
        addNodeBetween,
        nodes,
        workflowConfig,
        countryDocMapping,
        defaultFormSections,
      );
  const { id: newNodeId, node: newNode } = newNodeData;
  if (type === 'condition') {
    workflow.conditions = {
      ...(workflow.conditions || {}),
      [newNodeId]: newNode,
    };
  } else {
    workflow.modules.push(newNode);
  }

  if (parentNodeType === 'condition') {
    const clonnedCondition = cloneDeep(parentNode);
    const existingNextNodeType = parentNode.next_node_type || {};
    clonnedCondition[parentBranch] = newNodeId;
    clonnedCondition.next_node_type = {
      ...existingNextNodeType,
      [parentBranch]: '',
    };
    workflow.conditions[parentId] = clonnedCondition;
  } else {
    const parentModule = workflow.modules.find((module) => module.id === parentId);
    const existingNextNodeType = parentModule.next_node_type || {};
    if (parentModule.type === 'dynamicForm') {
      const [componentId, ...nextStepPathArray] = parentPath.split('.');
      const nextStepPath = nextStepPathArray.join('.');
      const updatedModule = updateFormPropertyViaComponentId(
        parentModule,
        componentId,
        nextStepPath,
        newNodeId,
      );
      updatedModule.next_node_type = {
        ...existingNextNodeType,
      };
      delete updatedModule.next_node_type[parentPath];
      const parentModuleIndex = workflow.modules.findIndex((module) => module.id === parentId);
      workflow.modules[parentModuleIndex] = updatedModule;
    } else if (parentModule.type === 'dynamicFormV2') {
      const [componentId, ...nextStepPathArray] = parentPath.split('.');
      const nextStepPath = nextStepPathArray.join('.');
      const keyToEdit = `componentConfigs.${componentId}.${nextStepPath}`;
      const updatedProperties = setModuleProperty(keyToEdit, newNodeId, parentModule?.properties);
      const updatedModule = cloneDeep(parentModule);
      updatedModule.properties = updatedProperties;
      updatedModule.next_node_type = {
        ...existingNextNodeType,
      };
      delete updatedModule.next_node_type[parentPath];
      const parentModuleIndex = workflow.modules.findIndex((module) => module.id === parentId);
      workflow.modules[parentModuleIndex] = updatedModule;
    } else {
      parentModule.nextStep = newNodeId;
      parentModule.next_node_type = { ...existingNextNodeType, default: '' };
    }
  }

  if (type === 'module') workflow = addDefaultPropertiesToModule(newNodeId, workflow, node);
  let highLevelUiConfig = {};
  let highLevelTextConfig = {};
  if (newNode?.type === 'superModule') {
    ({ highLevelUiConfig, highLevelTextConfig } =
      generateHighLevelConfig(newNode, newNodeId, node));
  }
  if (type === 'condition') {
    logAddCondition({
      id: newNodeId,
      parentId: addNodeBetween?.parent,
      childId: addNodeBetween?.child,
    });
  } else {
    logAddNode({
      id: newNodeId,
      parentId: addNodeBetween?.parent,
      childId: addNodeBetween?.child,
      subType: node?.subType,
    });
  }
  return {
    workflow,
    highLevelUiConfig,
    newModule: newNode,
    newNodeId,
    success: true,
    highLevelTextConfig,
  };
};

export const deleteNodeFromWorkflow = (id, parentId, selectedWorkflow) => {
  const workflow = cloneDeep(selectedWorkflow);
  const { node: nodeToDelete, type: nodeType } = getNodeAndType(id, workflow) || {};
  const { node: parentNode, type: parentNodeType } = getNodeAndType(parentId, workflow) || {};
  if (!parentNode || !parentNodeType) return { workflow, success: false };
  if (!['condition', 'module'].includes(parentNodeType)) return { workflow, success: false };

  // TODO: Warning here ?
  if (!nodeToDelete || !nodeType) return { workflow, success: false };

  // Checking if node can be deleted ?
  const { moduleDependencies, conditionDependencies } = fetchDependencyList(id, workflow);
  if (conditionDependencies.length || moduleDependencies.length) {
    // eslint-disable-next-line no-alert
    alert('remove the dependencies');
    return { workflow, success: false };
  }

  if (
    nodeType === 'condition' &&
    !endStates.includes(nodeToDelete.if_false) &&
    !endStates.includes(nodeToDelete.if_true)
  ) {
    alert('remove the dependencies');
    return { workflow, success: false };
  }
  if (nodeType === 'module' && nodeToDelete.type === 'dynamicForm') {
    const allComponents = getAllFormComponents(nodeToDelete);
    const allNextSteps = getAllNextSteps(allComponents, eventHandlers);
    if (allNextSteps.length !== 1) {
      alert('Can not delete form module\nAs it has more than one branches');
      return { workflow, success: false };
    }
  }
  if (nodeType === 'module' && nodeToDelete.type === 'dynamicFormV2') {
    const allNextSteps = getNextStepForModule(nodeToDelete);
    if (allNextSteps.length !== 1) {
      alert('Can not delete formV2 module\nAs it has more than one branches');
      return { workflow, success: false };
    }
  }

  const { nextNodeType: nextNodeTypeOfNodeToDelete, nextStep: newNextStep } =
    getNewNextStepFromNodeToBeDeleted(nodeToDelete, nodeType);
  const { next_node_type: existingNextNodeType = {} } = parentNode;

  if (parentNodeType === 'condition') {
    let nodeInBranch = 'none';
    if (parentNode.if_true === id) nodeInBranch = 'if_true';
    if (parentNode.if_false === id) nodeInBranch = 'if_false';
    if (nodeInBranch === 'none') return workflow;
    workflow.conditions[parentId][nodeInBranch] = newNextStep;
    workflow.conditions[parentId].next_node_type = {
      ...existingNextNodeType,
      [nodeInBranch]: nextNodeTypeOfNodeToDelete,
    };
  } else {
    const parentModule = workflow.modules.find((module) => module.id === parentId);
    if (parentModule.type === 'dynamicForm') {
      // Find the parent of nodeToBeDeleted.
      const {
        module: updatedModule,
        componentId,
        path,
      } = findAndUpdateTheOnlyNextStepInModule(parentModule, id, newNextStep);
      updatedModule.next_node_type = {
        ...existingNextNodeType,
        [`${componentId}.${path}`]: nextNodeTypeOfNodeToDelete,
      };
      const moduleIndex = workflow.modules.findIndex((module) => module.id === parentId);
      workflow.modules[moduleIndex] = updatedModule;
    } else if (parentModule.type === 'dynamicFormV2') {
      const allNextSteps = getNextStepForModule(parentModule);
      const nextStepData = allNextSteps.find(({ nextStep }) => nextStep === id);
      if (!nextStepData) {
        alert('Can not find the parent branch for the next step in formV2');
        return { workflow, success: false };
      }
      const { path } = nextStepData;
      const updatedModule = cloneDeep(parentModule);
      const workflowKey = `componentConfigs.${path}`;
      const updatedProperties = setModuleProperty(
        workflowKey,
        newNextStep,
        parentModule?.properties,
      );
      updatedModule.properties = updatedProperties;
      updatedModule.next_node_type = {
        ...existingNextNodeType,
        [path]: nextNodeTypeOfNodeToDelete,
      };
      const moduleIndex = workflow.modules.findIndex((module) => module.id === parentId);
      workflow.modules[moduleIndex] = updatedModule;
    } else {
      parentModule.nextStep = newNextStep;
      parentModule.next_node_type = nextNodeTypeOfNodeToDelete
        ? { default: nextNodeTypeOfNodeToDelete }
        : {};
    }
  }

  // Deleting module
  if (nodeType === 'module') {
    workflow.modules = workflow.modules.filter((module) => module?.id !== id);
  } else delete workflow.conditions[id];

  if (nodeType === 'condition') {
    logDeleteCondition({
      id,
      parentId,
    });
  } else if (nodeType === 'module') {
    logDeleteNode({
      id,
      parentId,
    });
  }
  return { workflow, success: true };
};
