import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { JsonEditor as Json } from 'json-edit-react';

function JsonEditor(props) {
  const {
    label,
    onChange,
    defaultValue,
    rootName,
  } = props;

  return (
    <Grid container alignItems="center" className="edit-properties-div__text-input-container">
      <Grid item xs={12}>
        <label htmlFor={label} className="edit-properties-div__label">{label}</label>
      </Grid>
      <Grid item xs={12}>
        <Json
          data={defaultValue || {}}
          rootFontSize="12px"
          rootName={rootName}
          onUpdate={(val) => {
            onChange(val.newData);
          }}
          theme={[
            'githubLight',
            {
              iconEdit: 'grey',
              boolean: {
                color: 'red', fontStyle: 'italic', fontWeight: 'light', fontSize: '10%',
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

JsonEditor.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  rootName: PropTypes.string,
};

JsonEditor.defaultProps = {
  rootName: 'events',
};

export default JsonEditor;
