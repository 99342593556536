/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const builderSdkSlice = createSlice({
  name: 'builderSDK',
  initialState: {
    sdkLaunchId: '',
  },
  reducers: {
    updateSdkLaunchId: (state, action) => {
      const { id } = action.payload;
      state.sdkLaunchId = id;
    },
  },
});

export const {
  updateSdkLaunchId,
} = builderSdkSlice.actions;

export const selectSdkLaunchId = (state) => state.builderSDK.sdkLaunchId;

export default builderSdkSlice.reducer;
