import { isEqual } from 'lodash';

const deepEqualIgnoringArrayOrder = (a, b) => {
  // Handle arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;

    // For arrays of primitives, sort and compare
    if (
      a.every((item) => item === null || typeof item !== 'object')
      && b.every((item) => item === null || typeof item !== 'object')
    ) {
      return isEqual([...a].sort(), [...b].sort());
    }

    // For arrays of objects, match items regardless of position
    return a.every((aItem) => b.some((bItem) => deepEqualIgnoringArrayOrder(aItem, bItem)))
      && b.every((bItem) => a.some((aItem) => deepEqualIgnoringArrayOrder(aItem, bItem)));
  }

  // Handle objects (not arrays)
  if (a && b && typeof a === 'object' && typeof b === 'object') {
    const keys1 = Object.keys(a);
    const keys2 = Object.keys(b);

    if (keys1.length !== keys2.length) return false;

    return keys1.every((key) => {
      if (!Object.hasOwn(b, key)) return false;
      return deepEqualIgnoringArrayOrder(a[key], b[key]);
    });
  }

  // Handle primitives
  return isEqual(a, b);
};

export default deepEqualIgnoringArrayOrder;
