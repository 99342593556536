import { CustomAlert } from 'storybook-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { closeAlert } from '../../reducers/alert';

function CustomWorkflowAlert() {
  const dispatch = useDispatch();
  const openAlert = useSelector((state) => state.alert.alertStatus);
  const alertSeverity = useSelector((state) => state.alert.alertSeverity);
  const alertMessage = useSelector((state) => state.alert.message);
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeAlert());
  };
  return (
    <CustomAlert
      openAlert={openAlert}
      alertMessage={alertMessage}
      alertSeverity={alertSeverity}
      handleAlertClose={handleAlertClose}
    />
  );
}

export default CustomWorkflowAlert;
