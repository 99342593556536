import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import generateUniqueID from '../../../utils/generateUniqueId';

import RequestParameter from './RequestParameter';
import isValidData from './validate';

function RequestParameters({
  onParamsChange,
  params,
  nameLabel,
  pathLabel,
  typeLabel,
  default: defaultType,
  values: typeOptionValues = [],
  text: typeOptionLabels = [],
  menuOptions,
  moduleBuilderProperties,
  moduleBuilderMode,
}) {
  const supportedTypes = typeOptionValues
    .map((value, index) => ({ id: value, label: typeOptionLabels[index] || value }));

  const addNewParam = (currentParams) => {
    const newParamsList = cloneDeep(currentParams || []);
    newParamsList.push({
      id: generateUniqueID(),
      data: { name: '', type: defaultType, value: '' },
    });
    onParamsChange(newParamsList);
  };

  const deleteVariable = (currentParams, id) => {
    const newParamsList = cloneDeep(currentParams || []);
    const index = newParamsList.findIndex(({ id: paramId }) => paramId === id);
    if (index === -1) return;
    newParamsList.splice(index, 1);
    if (newParamsList.length === 0) onParamsChange(null);
    else onParamsChange(newParamsList);
  };

  const onChange = (currentParams, newValue, id, key) => {
    const newParamsList = cloneDeep(currentParams || []);
    const index = newParamsList.findIndex(({ id: paramId }) => paramId === id);
    if (index !== -1) newParamsList[index].data[key] = newValue;
    onParamsChange(newParamsList);
  };

  const isValid = (currentParams) => {
    const data = currentParams || [];
    return isValidData(data);
  };

  const isKeyValid = (currentKey, currentParams) => {
    if (currentKey === '') return false;
    const similarKeys = (currentParams || []).filter((item) => item.data.name === currentKey);
    return similarKeys.length === 1;
  };

  return (
    <div className="workflowOutput__bottomContent_body">
      {
          isValid(params) ?
            (params || []).map(({ id, data }) => (
              <RequestParameter
                // TODO: What key can be used here as a key?
                // eslint-disable-next-line react/no-array-index-key
                key={id}
                name={data.name}
                value={data.value}
                type={data.type}
                nameLabel={nameLabel}
                pathLabel={pathLabel}
                typeLabel={typeLabel}
                onNameChange={(newValue) => { onChange(params, newValue, id, 'name'); }}
                onValueChange={(newValue) => { onChange(params, newValue, id, 'value'); }}
                onTypeChange={(newValue) => { onChange(params, newValue, id, 'type'); }}
                onDelete={() => { deleteVariable(params, id); }}
                supportedTypes={supportedTypes}
                menuOptions={menuOptions}
                isKeyNameValid={isKeyValid(data.name, params)}
                errorText={isKeyValid(data.name, params) ? '' : 'invalid key'}
                moduleBuilderProperties={moduleBuilderProperties}
                moduleBuilderMode={moduleBuilderMode}
              />
            ))
            : <p>Opps! Workflow has invalid data</p>
      }
      {
        isValid(params) ? <button type="button" onClick={() => { addNewParam(params); }} className="workflowInput__bottomContent_button">Add new variable</button> : null
      }
    </div>
  );
}

RequestParameters.defaultProps = {
  params: null,
};

RequestParameters.propTypes = {
  params: PropTypes.array, // can be null as well
  onParamsChange: PropTypes.func.isRequired,
  nameLabel: PropTypes.string.isRequired,
  pathLabel: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  default: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  text: PropTypes.array.isRequired,
  menuOptions: PropTypes.object.isRequired,
  moduleBuilderProperties: PropTypes.object.isRequired,
  moduleBuilderMode: PropTypes.bool.isRequired,
};

export default RequestParameters;
