import {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, set } from 'lodash';
import { useSelector } from 'react-redux';
import closeButton from '../../assests/icons/closeButton.svg';
import WorkflowOutput from './WorkflowOutput';
import WorkflowInput from './WorkflowInput';
import { selectSelectedWorkflow, selectVersionedModules } from '../../reducers/workflow';
import DisplayConditionalVariables from './ConditionalVariablesV2/DisplayConditionalVariables';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import withDeletionDependencyCheck from '../../utils/withDeletionDependencyCheck';
import { formComponentList as formComponents } from '../../constants/dynamicFormComponents';
import { fetchDependencyOfField } from '../../utils/fetchDependencyList';
import pushBulkLogs from '../../logger/pushBulkLogs';
import { createNestedMenuData } from './NestedDropdown/utils';
import {
  getConditionalVariables, getAllModuleOutputs, getWorkflowInputVariables, getPredefinedValues,
} from './InputsToModule/utils';
import generateUniqueID from '../../utils/generateUniqueId';
import { workflowOperationsObj as operations } from '../../workflowOperations';
import { selectModuleBuilderMode } from '../../reducers/moduleBuilder';
import ModuleOutput from '../ModuleBuilder/ModuleOutput';
import MODULE_COUNTRY_PICKER from '../../constants/miscellaneous';
import ModuleConfigurations from '../ModuleBuilder/ModuleConfigurations';
import { errorMessages } from '../../utils/error';
import { postProcessModuleBuilderConfigurations } from '../ModuleBuilder/utils/processModuleBuilderConfigurations';

function WorkflowConfigurationModal(props) {
  const { onClose, page, checkDependencies: checkDeletionDependencies } = props;

  const [currentPage, setCurrentPage] = useState(page);
  const [sdkInputs, setSdkInputs] = useState([]);
  const [sdkResponse, setSdkResponse] = useState([]);
  const [conditionalVariables, setConditionalVariables] = useState({});
  const [conditionalVariableLogs, setConditionalVariableLogs] = useState([]);
  const [moduleInputs, setModuleInputs] = useState({});
  const [moduleOutputs, setModuleOutputs] = useState({});
  const [moduleProperties, setModuleProperties] = useState({});

  const workflow = useSelector(selectSelectedWorkflow);
  const versionedModules = useSelector(selectVersionedModules);
  const moduleBuilderMode = useSelector(selectModuleBuilderMode);

  useEffect(() => {
    const inputs = workflow.properties.inputsRequired || {};
    if (inputs) {
      setSdkInputs(
        Object.entries(inputs).map(
          ([key, value]) => ({ id: generateUniqueID(), variable: key, type: value }
          ),
        ),
      );
    }
    const outputs = workflow.sdkResponse || {};
    if (outputs) {
      setSdkResponse(
        Object.entries(outputs).map(
          ([key, value]) => ({ id: generateUniqueID(), variable: key, value }
          ),
        ),
      );
    }

    const condVars = workflow.conditionalVariables || {};
    setConditionalVariables(condVars);

    const {
      moduleInputs: currentModuleInputs = {},
      output = {},
      builderProperties = {},
    } = workflow.properties.moduleBuilder || {};

    setModuleInputs(currentModuleInputs);
    setModuleOutputs(output);
    setModuleProperties(builderProperties);
  }, [workflow]);

  const setVariableName = (id, variable) => {
    setSdkInputs((prevInputs) => prevInputs.map((input) => {
      if (input.id === id) return { ...input, variable };
      return input;
    }));
  };
  const setOutputVariableName = (id, variable) => {
    setSdkResponse((prevOutputs) => prevOutputs.map((output) => {
      if (output.id === id) return { ...output, variable };
      return output;
    }));
  };
  const setOutputValue = (id, value) => {
    setSdkResponse((prevOutputs) => prevOutputs.map((output) => {
      if (output.id === id) return { ...output, value };
      return output;
    }));
  };

  const setType = (id, type) => {
    setSdkInputs((prevInputs) => prevInputs.map((input) => {
      if (input.id === id) return { ...input, type };
      return input;
    }));
  };
  const addNewVariable = () => {
    const id = generateUniqueID();
    const newField = {
      id,
      variable: '',
      type: 'string',
    };
    setSdkInputs((prevInputs) => [...prevInputs, newField]);
  };

  const addNewOutputVariable = () => {
    const id = generateUniqueID();
    const newField = {
      id,
      variable: '',
      value: '',
    };
    setSdkResponse((prevOutputs) => [...prevOutputs, newField]);
  };

  const deleteVariable = (id, selectedWorkflow) => {
    // Check dependencies
    const currInput = sdkInputs.find((input) => input.id === id);
    const isDependent = checkDeletionDependencies({
      variableId: currInput.variable,
      nodeId: 'inputs',
      workflow: selectedWorkflow,
    });
    if (isDependent) return;
    setSdkInputs((prevInputs) => prevInputs.filter((input) => input.id !== id));
  };

  const deleteOutputVariable = (id) => {
    setSdkResponse((prevOutputs) => prevOutputs.filter((output) => output.id !== id));
  };

  const validateInputConfig = () => {
    let isValid = true;
    sdkInputs.forEach((input) => {
      if (!input.variable || input.variable.includes(' ')) {
        isValid = false;
      }
    });
    // eslint-disable-next-line no-alert
    if (!isValid) alert('Variable names cannot be empty or contain spaces!');
    return isValid;
  };

  const validateOutputConfig = () => {
    let isValidVariable = true;
    let isValidValue = true;
    sdkResponse.forEach((output) => {
      if (!output.variable || output.variable.includes(' ')) {
        isValidVariable = false;
      }
      if (!output.value) isValidValue = false;
    });
    // eslint-disable-next-line no-alert
    if (!isValidVariable) alert('Variable names cannot be empty or contain spaces!');
    // eslint-disable-next-line no-alert
    if (!isValidValue) alert('Values cannot be empty!');
    return isValidValue && isValidVariable;
  };

  const saveWorkflowInputs = () => {
    if (validateInputConfig()) {
      // update the workflow
      const newInputs = {};
      sdkInputs.forEach((input) => {
        newInputs[input.variable] = input.type;
      });
      updateWorkflowInState({}, true, {
        operation: operations.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: 'properties.inputsRequired',
          value: newInputs,
        },
      });
      onClose();
    }
  };

  const saveWorkflowOutputs = () => {
    if (validateOutputConfig()) {
      // update the workflow
      const newOutputs = {};
      sdkResponse.forEach((output) => {
        newOutputs[output.variable] = output.value;
      });
      updateWorkflowInState({}, true, {
        operation: operations.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: 'sdkResponse',
          value: newOutputs,
        },
      });
      onClose();
    }
  };

  const canDeleteConditionalVariable = (conVar, condVars, currWorkflow) => {
    const workflowToPass = cloneDeep(currWorkflow);
    workflowToPass.conditionalVariables = condVars;
    return checkDeletionDependencies(
      {
        variableId: conVar,
        nodeId: 'conditionalVariables',
        workflow: workflowToPass,
      },
    );
  };

  const getConditionalVariableDeps = (conVar, condVars, currWorkflow) => {
    const workflowToPass = cloneDeep(currWorkflow);
    workflowToPass.conditionalVariables = condVars;
    return fetchDependencyOfField(conVar, 'conditionalVariables', workflowToPass);
  };
  const setAddVariables = (selectedVal) => {
    setSdkResponse((prevOutputs) => [...prevOutputs, selectedVal]);
  };

  const saveConditionalVariables = (condVars) => {
    pushBulkLogs(conditionalVariableLogs);
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'conditionalVariables',
        value: condVars,
      },
    });
  };

  const saveModuleBuilderProperties = (properties) => {
    const { success } = updateWorkflowInState({}, true, {
      operation: operations.SET_MODULE_BUILDER_PROPERTIES,
      actionData: {
        builderProperties: properties,
        path: 'properties.moduleBuilder.builderProperties',
      },
    });
    if (!success) {
      return { errorMsg: errorMessages.invalidBuilderProperties };
    }
    return { errorMsg: '' };
  };

  const saveModuleBuilderInputs = (inputs) => {
    const { success } = updateWorkflowInState({}, true, {
      operation: operations.SET_MODULE_BUILDER_INPUTS,
      actionData: {
        inputs,
        path: 'properties.moduleBuilder.moduleInputs',
      },
    });
    if (!success) {
      return { errorMsg: errorMessages.invalidModuleInputs };
    }
    return { errorMsg: '' };
  };

  const saveModuleBuilderOutputs = (outputs) => {
    const { success } = updateWorkflowInState({}, true, {
      operation: operations.SET_MODULE_BUILDER_OUTPUTS,
      actionData: {
        outputs,
        path: 'properties.moduleBuilder.output',
      },
    });
    if (!success) {
      return { errorMsg: errorMessages.invalidModuleOutputs };
    }
    return { errorMsg: '' };
  };

  const onSave = () => {
    if (currentPage === 'input') saveWorkflowInputs();
    else if (currentPage === 'output') saveWorkflowOutputs();
    else if (moduleBuilderMode) {
      // TODO: Save is common for all the tabs,
      // Same should be the behavior for the input and output
      const {
        errorMsg: moduleBuilderPropertiesError,
      } = saveModuleBuilderProperties(moduleProperties);
      const {
        errorMsg: moduleBuilderInputsError,
      } = saveModuleBuilderInputs(moduleInputs);
      const {
        errorMsg: moduleBuilderOutputsError,
      } = saveModuleBuilderOutputs(moduleOutputs);
      if (moduleBuilderPropertiesError || moduleBuilderInputsError || moduleBuilderOutputsError) {
        let errorText = '';
        if (moduleBuilderPropertiesError) errorText += `${moduleBuilderPropertiesError}\n`;
        if (moduleBuilderInputsError) errorText += `${moduleBuilderInputsError}\n`;
        if (moduleBuilderOutputsError) errorText += `${moduleBuilderOutputsError}`;
        // eslint-disable-next-line no-alert
        alert(errorText);
      }
    }
    onClose();
  };

  const preDefinedValues = getPredefinedValues(workflow, formComponents);

  const onAddModuleInput = (index, inputData) => {
    const clonedModuleInputs = cloneDeep(moduleInputs);
    const processedInputData = postProcessModuleBuilderConfigurations(inputData);
    set(clonedModuleInputs, index, processedInputData);
    setModuleInputs(clonedModuleInputs);
  };

  const onDeleteModuleInput = (index) => {
    const clonedModuleInputs = cloneDeep(moduleInputs);
    clonedModuleInputs.splice(index, 1);
    setModuleInputs(clonedModuleInputs);
  };

  const onDragDropModuleInput = (draggedItemIndex, dropIndex) => {
    const clonedModuleInputs = cloneDeep(moduleInputs);
    const [draggedItem] = clonedModuleInputs.splice(draggedItemIndex, 1);
    clonedModuleInputs.splice(dropIndex, 0, draggedItem);
    setModuleInputs(clonedModuleInputs);
  };

  const onAddModuleBuilderProperty = (index, properties) => {
    const clonedModuleProperties = cloneDeep(moduleProperties);
    const processedProperties = postProcessModuleBuilderConfigurations(properties);
    set(clonedModuleProperties, index, processedProperties);
    setModuleProperties(clonedModuleProperties);
  };

  const onDeleteBuilderProperty = (index) => {
    const [source, ...rest] = moduleProperties[index].workflowKey.split('.');
    const variableId = rest.join('.');
    const isDependent = checkDeletionDependencies({
      variableId,
      nodeId: source,
      workflow,
    });
    if (isDependent) return;
    const clonedModuleProperties = cloneDeep(moduleProperties);
    clonedModuleProperties.splice(index, 1);
    setModuleProperties(clonedModuleProperties);
  };

  const onDragDropModuleBuilderProperty = (draggedItemIndex, dropIndex) => {
    const clonedModuleProperties = cloneDeep(moduleProperties);
    const [draggedItem] = clonedModuleProperties.splice(draggedItemIndex, 1);
    clonedModuleProperties.splice(dropIndex, 0, draggedItem);
    setModuleProperties(clonedModuleProperties);
  };

  const onAddModuleOutput = (id, outputdata) => {
    const clonedModuleOutputs = cloneDeep(moduleOutputs);
    set(clonedModuleOutputs, id, outputdata);
    setModuleOutputs(clonedModuleOutputs);
  };

  const onDeleteModuleOutput = (id) => {
    const clonedModuleOutputs = cloneDeep(moduleOutputs);
    delete clonedModuleOutputs[id];
    setModuleOutputs(clonedModuleOutputs);
  };

  const menuOptions = useMemo(() => {
    const newWorkflow = cloneDeep(workflow);
    const newInputs = {};
    sdkInputs.forEach((input) => {
      if (input.variable && !input.variable.includes(' ')) newInputs[input.variable] = input.type;
    });
    newWorkflow.properties.inputsRequired = newInputs;
    newWorkflow.conditionalVariables = conditionalVariables;
    const moduleOutputOptions = getAllModuleOutputs(
      newWorkflow,
      formComponents,
      versionedModules,
    );
    const conditionalVariableOptions = getConditionalVariables(newWorkflow);
    const workflowInputOptions = getWorkflowInputVariables(newWorkflow);
    return createNestedMenuData(
      workflowInputOptions,
      conditionalVariableOptions,
      moduleOutputOptions,
      moduleBuilderMode ? moduleProperties : null,
    );
  }, [
    conditionalVariables,
    versionedModules,
    sdkInputs,
    workflow,
    moduleBuilderMode,
    moduleProperties,
  ]);

  const menuOptionsForModuleBuilder = useMemo(() => {
    const clonedWorkflow = cloneDeep(workflow);
    const items = clonedWorkflow.modules
      .map((module) => ({ label: module.name, value: module.id }))
      .filter((module) => module.value !== MODULE_COUNTRY_PICKER);
    return {
      items,
    };
  }, [workflow]);

  const menuOptionsForModuleOutput = useMemo(() => {
    const filteredItems = menuOptions.items.filter((item) => item.label === 'Module Outputs' || item.label === 'Conditional Variables');
    const filteredOptions = {
      ...menuOptions,
      items: filteredItems,
    };

    return filteredOptions;
  }, [menuOptions]);

  return (
    <div className="workflow_configuration_modal__overlay">
      <div className="workflow_configuration_modal__body">
        <div className="workflow_configuration_modal__header">
          <span>
            Input/Output Definition
          </span>
          <button
            type="button"
            className="workflow_configuration_modal__header_button"
            onClick={onSave}
          >
            Save
          </button>
          <button type="button" className="closeModalButton" onClick={onClose}>
            <img
              src={closeButton}
              alt="close"
            />
          </button>
        </div>

        <div className="workflow_configuration_modal__content">
          <div className="workflow_configuration_modal__sidebar">
            <button
              type="button"
              className={currentPage === 'input' ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
              onClick={() => setCurrentPage('input')}
            >
              Workflow Inputs
            </button>
            <button
              type="button"
              className={currentPage === 'output' ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
              onClick={() => setCurrentPage('output')}
            >
              Workflow Outputs
            </button>
            <button
              type="button"
              className={currentPage === 'conditionalVariables' ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
              onClick={() => setCurrentPage('conditionalVariables')}
            >
              Conditional Variables
            </button>
            {moduleBuilderMode ? (
              <>
                <button
                  type="button"
                  className={currentPage === 'moduleOutputs' ?
                    'workflow_configuration_modal__sidebar_button_active' :
                    'workflow_configuration_modal__sidebar_button'}
                  onClick={() => setCurrentPage('moduleOutputs')}
                >
                  Module  Outputs
                </button>
                <button
                  type="button"
                  className={currentPage === 'moduleInputs' ?
                    'workflow_configuration_modal__sidebar_button_active' :
                    'workflow_configuration_modal__sidebar_button'}
                  onClick={() => setCurrentPage('moduleInputs')}
                >
                  Module  Inputs
                </button>
                <button
                  type="button"
                  className={currentPage === 'builderProperties' ?
                    'workflow_configuration_modal__sidebar_button_active' :
                    'workflow_configuration_modal__sidebar_button'}
                  onClick={() => setCurrentPage('builderProperties')}
                >
                  Builder Properties
                </button>
              </>
            ) : ''}
          </div>
          <div className="workflow_configuration_modal__maincontent">
            {
              currentPage === 'output'
                ? (
                  <WorkflowOutput
                    sdkResponse={sdkResponse}
                    setVariableName={setOutputVariableName}
                    addNewVariable={addNewOutputVariable}
                    deleteVariable={deleteOutputVariable}
                    setOutputValue={setOutputValue}
                    onAddVariables={setAddVariables}
                    menuOptions={menuOptions}
                    setSdkResponse={setSdkResponse}
                    onClose={onClose}
                  />
                ) : ''
            }
            {
              currentPage === 'input'
                ? (
                  <WorkflowInput
                    sdkInputs={sdkInputs}
                    setVariableName={setVariableName}
                    setType={setType}
                    addNewVariable={addNewVariable}
                    deleteVariable={(...args) => deleteVariable(...args, workflow)}
                  />
                ) : ''
            }
            {
              (currentPage === 'conditionalVariables') ? (
                <DisplayConditionalVariables
                  conditionalVariables={conditionalVariables}
                  saveConditionalVariables={
                    (condVars) => {
                      saveConditionalVariables(condVars);
                    }
                  }
                  canDeleteConditionalVariable={
                    (conVar) => canDeleteConditionalVariable(
                      conVar,
                      conditionalVariables,
                      workflow,
                    )
                  }
                  dropDownOptions={menuOptions.items}
                  getConditionalVariableDeps={
                    (conVar) => getConditionalVariableDeps(
                      conVar,
                      conditionalVariables,
                      workflow,
                    )
                  }
                  setConditionalVariableLogs={
                    (log) => setConditionalVariableLogs((currLogs) => [...currLogs, log])
                  }
                  preDefinedValues={preDefinedValues}
                />
              )
                : ''
            }
            {
              (currentPage === 'moduleOutputs') ? (
                <ModuleOutput
                  moduleOutputs={moduleOutputs}
                  onAdd={onAddModuleOutput}
                  onDelete={onDeleteModuleOutput}
                  dropdownOptions={menuOptionsForModuleOutput}
                />
              ) : ''
            }
            {
              (currentPage === 'moduleInputs') ? (
                <ModuleConfigurations
                  moduleInputs={moduleInputs}
                  moduleIdsDropdown={menuOptionsForModuleBuilder}
                  pageHeading="Module Inputs"
                  pageDescription="If you want to get inputs to the module, please configure the inputs below"
                  addButtonText="Add Input"
                  moduleConfigPosition="properties"
                  configurationDrawerHeading="Configure Module Input"
                  onAdd={onAddModuleInput}
                  onDelete={onDeleteModuleInput}
                  menuOptions={menuOptions}
                  onDragDrop={onDragDropModuleInput}
                />
              ) : ''
            }
            {
              (currentPage === 'builderProperties') ? (
                <ModuleConfigurations
                  moduleInputs={moduleProperties}
                  moduleIdsDropdown={{ items: [{ label: 'Builder Properties', value: 'builderProperties' }] }}
                  pageHeading="Builder Properties"
                  pageDescription="If you want to add builder properties to the module, please configure them below"
                  addButtonText="Add Builder Properties"
                  moduleConfigPosition="builderProperties"
                  configurationDrawerHeading="Configure Builder Properties"
                  onAdd={onAddModuleBuilderProperty}
                  onDelete={onDeleteBuilderProperty}
                  menuOptions={menuOptions}
                  onDragDrop={onDragDropModuleBuilderProperty}
                />
              ) : ''
            }
          </div>
        </div>
      </div>
    </div>
  );
}

WorkflowConfigurationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  checkDependencies: PropTypes.func.isRequired,
};

export default withDeletionDependencyCheck(WorkflowConfigurationModal);
