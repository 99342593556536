export const getFormV2ComponentOptions = (libraries) => {
  const options = Object.entries(libraries || {})
    .map(([libraryId, { libraryName, components }]) => {
      const ops = (components || {}).map(({ id: formComponentId, label }) => ({
        key: `${libraryId}+${formComponentId}`,
        name: `${libraryName} | ${label}`,
      }));
      return ops;
    })
    .flat();

  const defaultOptions = [
    { key: 'h1', name: 'Heading 1' },
    { key: 'h2', name: 'Heading 2' },
    { key: 'div', name: 'Section' },
    { key: 'span', name: 'Text' },
  ];
  options.push(...defaultOptions);
  return options;
};

export const getLibraryById = (libraries, libraryId) => libraries?.[libraryId] || null;

export const getLibraryForFormComponent = (component, libraries) => {
  const libraryId = component?.componentRef?.libraryId || '';
  return getLibraryById(libraries, libraryId);
};

export const getComponentConfigByIds = (libraries, libraryId, formComponentId) => {
  const componentConfig = (libraries?.[`${libraryId}`]?.components || []).find(
    ({ id }) => id === formComponentId,
  );
  return componentConfig || {};
};

export const getComponentConfigurations = (component, libraries) => {
  const library = getLibraryForFormComponent(component, libraries);
  const formComponentId = component?.componentRef?.formComponentId || '';
  const filteredComponent = (library?.components || []).find(({ id }) => id === formComponentId);
  return filteredComponent?.configurations || [];
};
