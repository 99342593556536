import PropTypes from 'prop-types';
import searchIcon from '../../assests/icons/search.svg';
import './searchBoxStyles.scss';
import ModuleNavigator from '../ViewWorkflow/ModuleNavigator';

function SearchBox(props) {
  const {
    value,
    placeHolder,
    onSearch,
    highlightedNodes,
    selectedNodeId,
    selectedNodeName,
    handleNavigateModule,
    renderSearchResults,
  } = props;

  const onSearchValueChange = (e) => {
    onSearch(e.target.value);
  };

  const searchResults = () => {
    if (value && highlightedNodes.length === 0) {
      return <p className="view_workflow__heading_count">No results found</p>;
    }

    if (highlightedNodes.length > 0) {
      const selectedIndex = highlightedNodes.findIndex(
        (node) => node.name === selectedNodeName && node.id === selectedNodeId,
      ) + 1;
      return (
        <>
          <p className="view_workflow__heading_count">{`${selectedIndex} of ${highlightedNodes.length}`}</p>
          <ModuleNavigator
            highlightedNodes={highlightedNodes}
            selectedNodeId={selectedNodeId}
            onNavigateModule={(val) => handleNavigateModule(val)}
          />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <div className="search_box">
        <img src={searchIcon} alt="" className="search_box__icon" />
        <input type="text" value={value} onChange={onSearchValueChange} placeholder={placeHolder} className="search_box__input" />
        {renderSearchResults ? (
          <div className="search_box__results">
            {searchResults()}
          </div>
        ) : null}
      </div>
    </>
  );
}

SearchBox.propTypes = {
  value: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  highlightedNodes: PropTypes.array,
  selectedNodeId: PropTypes.string,
  selectedNodeName: PropTypes.string,
  handleNavigateModule: PropTypes.func,
  renderSearchResults: PropTypes.bool,
};

SearchBox.defaultProps = {
  highlightedNodes: [],
  selectedNodeId: '',
  selectedNodeName: '',
  handleNavigateModule: () => { },
  renderSearchResults: false,
};

export default SearchBox;
