import PropTypes from 'prop-types';
import './fallBack.scss';

function FallbackComponent({ resetErrorBoundary }) {
  return (
    <div className="fallback">
      <h1 className="fallback__heading">Oops! Something went wrong :( </h1>
      <p className="fallback__text">
        The last action made something go wrong unexpectedly,
        but don&apos;t worry your changes are safe!
      </p>
      <button type="button" className="fallback__button" onClick={resetErrorBoundary}>Go Back</button>
    </div>
  );
}

FallbackComponent.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default FallbackComponent;
