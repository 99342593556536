import Joi from 'joi-browser';

const isValidConfig = (data) => {
  const schema = Joi.object({
    texts: {
      startButton: Joi.string().required(),
      title: Joi.string().required(),
      save: Joi.string().required(),
    },
    panels: Joi.array().items(
      Joi.object({
        texts: {
          name: Joi.string().required(),
          title: Joi.string().required(),
          description: Joi.string().required(),
        },
        tabs: Joi.array().items(
          Joi.alternatives().try(
            Joi.object({
              rootWorkflowKey: Joi.string().required().regex(/^(properties.|variables$)/),
              type: Joi.string().valid('moduleVariables'),
              title: Joi.string().required(),
              nameTitle: Joi.string().required(),
              pathTitle: Joi.string().required(),
              addBtnLabel: Joi.string().required(),
            }),
            Joi.object({
              rootWorkflowKey: Joi.string().required().regex(/^(properties.|variables$)/),
              type: Joi.string().valid('simpleLinearObject'),
              title: Joi.string().required(),
              nameTitle: Joi.string().required(),
              pathTitle: Joi.string().required(),
              typeTitle: Joi.string(),
              addBtnLabel: Joi.string().required(),
            }),
            Joi.object({
              rootWorkflowKey: Joi.string().required().regex(/^(properties.|variables$)/),
              type: Joi.string().valid('typedSimpleLinearObject'),
              title: Joi.string().required(),
              nameTitle: Joi.string().required(),
              pathTitle: Joi.string().required(),
              typeTitle: Joi.string().required(),
              addBtnLabel: Joi.string().required(),
            }),
            Joi.object({
              rootWorkflowKey: Joi.string().required().regex(/^(properties.|variables$)/),
              type: Joi.string().valid('reqParams'),
              title: Joi.string().required(),
              nameTitle: Joi.string().required(),
              pathTitle: Joi.string().required(),
              typeTitle: Joi.string().required(),
              addBtnLabel: Joi.string().required(),
              values: Joi.array().length(Joi.ref('text.length')).required(),
              text: Joi.array().required(),
              default: Joi.string().required().valid(Joi.ref('values')),
            }),
          ),
        ),
      }),
    ),
  });
  const { error } = schema.validate(data);
  return error === null;
};

export default isValidConfig;
