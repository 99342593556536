import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import { getCurrentValueFromWorkflowForModuleInputs } from './utils/updateWorkflow';
import { getConditionalVariables, getWorkflowInputVariables, getModuleOutputs } from './utils';
import { formComponentList as formComponents } from '../../../constants/dynamicFormComponents';
import { createNestedMenuData } from '../NestedDropdown/utils';
import DropDown from '../DropDown';
import PropertiesTooltip from '../Tooltip/Tooltip';
import './InputsDropDown.scss';

function Inputs(props) {
  const {
    input,
    isDisabled,
    selectedNodeId,
    updateValueInWorkflow,
    selectedWorkflow,
    versionedModules,
    orderOfModules,
  } = props;

  const selectedWorkflowModules = selectedWorkflow.modules;
  const [selectedModule, setSelectedModule] = useState(selectedWorkflowModules.find(
    (module) => module.id === selectedNodeId,
  ));
  const [defaultValue, setDefaultValue] = useState('Select a Value');

  const { title, workflowKey, infoButton = null } = input;

  useEffect(() => {
    setSelectedModule(selectedWorkflow.modules.find(
      (module) => module.id === selectedNodeId,
    ));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeId, JSON.stringify(selectedWorkflow)]);

  // Set default values
  useEffect(() => {
    const value = getCurrentValueFromWorkflowForModuleInputs(
      selectedModule,
      workflowKey,
    );
    setDefaultValue(value);
  }, [selectedModule, input]);

  const menuOptions = useMemo(() => {
    const moduleOutputOptions = getModuleOutputs(
      orderOfModules,
      selectedNodeId,
      selectedWorkflow,
      formComponents,
      versionedModules,
    );
    const conditionalVariableOptions = getConditionalVariables(selectedWorkflow);
    const workflowInputOptions = getWorkflowInputVariables(selectedWorkflow);
    return createNestedMenuData(
      workflowInputOptions,
      conditionalVariableOptions,
      moduleOutputOptions,
    );
  }, [
    versionedModules,
    orderOfModules,
    selectedNodeId,
    selectedWorkflow,
  ]);

  const handleOnChange = (outputValue) => {
    updateValueInWorkflow(workflowKey, outputValue.value);
  };

  const handleRemoveSelection = () => {
    updateValueInWorkflow(workflowKey, null);
  };

  return (
    <div className={`title-and-dropdowns ${isDisabled ? 'title-and-dropdowns--disabled' : ''}`}>
      <h2 className="title-and-dropdowns__title">
        {title}
        <PropertiesTooltip infoButton={infoButton} />
      </h2>
      <div className="title-and-dropdowns__dropdowns">
        <DropDown
          allowCustomInput
          textBoxPlaceholder="value"
          onChange={handleOnChange}
          defaultValue={defaultValue}
          items={menuOptions}
          maxLength={45}
          onRemoveSelection={handleRemoveSelection}
        />
      </div>
    </div>
  );
}

Inputs.propTypes = {
  input: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool,
  updateValueInWorkflow: PropTypes.func.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  versionedModules: PropTypes.object.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  orderOfModules: PropTypes.array.isRequired,
};

Inputs.defaultProps = {
  isDisabled: false,
};

export default Inputs;
