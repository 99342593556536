const AlertCode = {
  error: {
    login: 'Please Login Again',
    fetchUser: 'Error in fetching user',
    fetchWorkflows: 'Error in fetching workflows',
    default: 'Something went wrong',
    fetchModules: 'Error in fetching modules',
    fetchFormV2Components: 'Error fetching formV2 components',
    fetchCountries: 'Error in fetching countries',
    docToken: 'Error in fetching doc token',
    fetchWorkflow: 'Error fetching workflow',
    fetchTextConfig: 'Error fetching text config',
    fetchUiConfig: 'Error fetching ui config',
    fetchDefaultConfigs: 'Error fetching default configs',
    updateUiConfigs: 'Error updating ui config',
    countryModuleNotCorrect: 'Error in uploading workflow: Error in country module',
    noEndStateReachable: 'Error in uploading workflow: Workflow doesnot have reachable end states',
    noTerminalsInDynamicForm: 'Error in uploading workflow: Next step of Dynamic form cannot be terminating state',
    errorUploadingWorkflow: 'Failed to upload workflow',
    errorUploadingTextConfig: 'Failed to upload textconfig',
    errorUploadingUiConfig: 'Failed to upload UIconfig',
    errorCreatingWorkflow: 'Failed to create new workflow',
    errorUpdatingWorkflow: 'Failed to update the workflow',
    errorPublishingWorkflow: 'Failed to publish workflow',
    errorUploadingImage: 'Failed to upload image',
    errorUploadingTryOnMobileData: 'Failed to upload the data for the operation',
    undefinedConditionOrModule: 'Undefined module or condition present in config',
    nextStepOfNodePointingToSelf: 'Next step of node pointing to itself',
    nextStepNotPresentinDynamaicForm: 'Next step not present in the dynamic form',
    copyToClipboard: 'Failed to copy to clipboard',
    pasteFromClipboard: 'Failed to paste from clipboard',
    errorUploadingLottie: 'Failed to upload lottie',
    moduleSubtypeChange: 'The following subtypes have been updated',
    invalidSuperModules: 'Below superModules have some issues with them. They have been broken down to the simple modules',
    deletedNextSteps: 'Next steps are not present in the workflow. Changing them to approve',
    builderCompatibility: 'Workflow adjusted: Modifications were made to ensure builder compatibility',
    missingSuperModuleType: 'Required superModuletype are not present in the s3. They have been broken down to the simple modules',
    missingSuperModuleVersion: 'These module versions are unavailable. They have been broken down to the simple modules',
    missingModuleSubType: 'Required subType are not present in the s3.',
    missingModuleVersion: 'These module versions are unavailable.',
    errorInWorkflowSchema: 'Error in schema of the workflow.',
    moduleFetchFailed: 'Module version not found in the s3',
    moduleNotValid: 'Module not found in s3',
    errorInFormComponentSchema: 'Error in schema of the form component',
    errorFetchingModuleUpdates: 'Failed to fetch module update details',
  },
  copiedToClipBoard: 'Copied To Clipboard',

};
export default AlertCode;
