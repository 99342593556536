/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import MultiSelectOptions from './MultiSelectOptions';
import './MultiSelectDropDown.scss';

function MultiSelectDropDown(props) {
  const {
    options,
    value,
    isDisabled,
    onChange,
    placeholder,
  } = props;

  const prevValue = useRef(value);
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [showOptions, setShowOptions] = useState(false);

  const handleClick = () => {
    setShowOptions((show) => !show);
  };

  useEffect(() => {
    prevValue.current = value;
    setSelectedOptions(value);
  }, [value]);

  useEffect(() => {
    if (prevValue.current !== selectedOptions) {
      prevValue.current = selectedOptions;
      onChange(selectedOptions);
    }
  }, [selectedOptions]);

  const buttonRef = useRef(null);

  const handleCheckBoxClicked = (optionSelected) => {
    setSelectedOptions((currentOptions) => {
      const updatedOptions = [...currentOptions];
      const index = updatedOptions.findIndex((option) => option === optionSelected);
      if (index === -1) updatedOptions.push(optionSelected);
      else updatedOptions.splice(index, 1);
      return updatedOptions;
    });
  };

  return (
    <>
      <button
        type="button"
        className="multi-select-dropdown__button"
        ref={buttonRef}
        disabled={isDisabled}
        onClick={handleClick}
      >
        {selectedOptions.map((option) => `${option}`).join(', ').trim() || placeholder}
      </button>
      {showOptions ? (
        <div>
          <MultiSelectOptions
            options={options}
            buttonRef={buttonRef}
            selectedOptions={selectedOptions}
            handleCheckBoxClicked={handleCheckBoxClicked}
          />
          <div className="multi-select__popup__options__modal" onClick={handleClick} />
        </div>
      ) : null}
    </>
  );
}

MultiSelectDropDown.defaultProps = {
  isDisabled: false,
};

MultiSelectDropDown.propTypes = {
  isDisabled: PropTypes.bool,
  value: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default MultiSelectDropDown;
