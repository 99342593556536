import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

function ParametersDisplay({ parameters }) {
  return (
    <Grid container>
      {Object.entries(parameters).map(([key, value]) => (
        <Grid container key={key}>
          <Grid item xs={10}>
            <div className="drawer_content__heading">{value.heading}</div>
            <div className="drawer_content__subheading">{value.description}</div>
          </Grid>
          <Grid item xs={2}>
            <Switch disabled />
          </Grid>
        </Grid>
      ))}
    </Grid>

  );
}

ParametersDisplay.propTypes = {
  parameters: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ParametersDisplay;
