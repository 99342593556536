import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { fetchCurrentValueFromWorkflow, getSelectedModule } from '../InputsToModule/utils/updateWorkflow';
import DebouncedJsonInput from '../../FormModule/Common/DebouncedJsonInput';

function JsonBoxWrapper(props) {
  const {
    element,
    isDisabled,
    updateValueInWorkflow,
    selectedWorkflow,
    selectedNodeId,
  } = props;

  const {
    workflowKey, text: label, placeholder, formatAsJson, infoButton = null,
  } = element;

  const defaultInput = useMemo(() => {
    const selectedModule = getSelectedModule(selectedWorkflow, selectedNodeId);
    const currentValue = fetchCurrentValueFromWorkflow(
      selectedModule,
      workflowKey,
    );
    return currentValue || (formatAsJson ? {} : '');
  }, [selectedNodeId, selectedWorkflow, workflowKey, formatAsJson]);

  const onSave = (text) => {
    updateValueInWorkflow(workflowKey, text);
  };

  return (
    <div className="text-input-wrapper">
      <DebouncedJsonInput
        label={label}
        formatAsJson={formatAsJson}
        onChange={onSave}
        defaultValue={defaultInput}
        readOnly={false}
        placeholder={placeholder}
        isDisabled={isDisabled}
        infoButton={infoButton}
      />
    </div>
  );
}

JsonBoxWrapper.propTypes = {
  element: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool,
  updateValueInWorkflow: PropTypes.func.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
};

JsonBoxWrapper.defaultProps = {
  isDisabled: false,
};

export default JsonBoxWrapper;
