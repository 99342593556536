import { cloneDeep } from 'lodash';
import uiConfigPostProcessors from '../../../constants/moduleInputPostProcess';

export const postProcessModuleBuilderConfigurations = (configurations) => {
  const { uiConfig = {} } = configurations;
  const processors = uiConfigPostProcessors[uiConfig?.type] || [];
  let clonedUiConfig = cloneDeep(uiConfig);
  processors.forEach((processor) => {
    if (processor.post) {
      clonedUiConfig = processor.post(clonedUiConfig);
    }
  });
  return {
    ...configurations,
    uiConfig: clonedUiConfig,
  };
};

export const preProcessModuleBuilderConfigurations = (configurations) => {
  const { uiConfig = {} } = configurations;
  const processors = uiConfigPostProcessors[uiConfig?.type] || [];
  let clonedUiConfig = cloneDeep(uiConfig);
  processors.forEach((processor) => {
    if (processor.pre) {
      clonedUiConfig = processor.pre(clonedUiConfig);
    }
  });
  return {
    ...configurations,
    uiConfig: clonedUiConfig,
  };
};
