import { cloneDeep } from 'lodash';
import { setNodeIdsInHtmlString } from '../../containers/FormModule/helper';

export const setDomIdsInFormV2 = (workflow) => {
  const { modules = [] } = workflow;
  const editedWorkflow = cloneDeep(workflow);
  const clonedModules = cloneDeep(modules);
  editedWorkflow.modules = clonedModules;
  modules.forEach((module, index) => {
    if (module.type === 'dynamicFormV2') {
      const htmlContent = module?.properties?.content || '';
      if (htmlContent) {
        const updatedContent = setNodeIdsInHtmlString(htmlContent);
        clonedModules[index].properties.content = updatedContent;
      }
    }
  });
  return editedWorkflow;
};

export const clubCssInFormV2 = (workflow) => {
  const { modules = [] } = workflow;
  const editedWorkflow = cloneDeep(workflow);
  const clonedModules = cloneDeep(modules);
  modules.forEach((module, index) => {
    if (module.type === 'dynamicFormV2') {
      const { componentConfigs = {} } = module?.properties || {};
      const clubbedStyles = Object.entries(componentConfigs).reduce(
        (acc, [componentId, { css, styles }]) => {
          let cssStringForStyleVariables = '';
          const styleVariables = Object.keys(styles || {});
          if (styleVariables.length) {
            const stylesString = styleVariables
              .map((styleVariable) => `${styleVariable}: ${styles[styleVariable]};`)
              .join(' ');
            cssStringForStyleVariables = `#${componentId} { ${stylesString} }`;
          }
          return `${acc} ${css || ''} ${cssStringForStyleVariables}`;
        },
        '',
      );
      clonedModules[index].properties = {
        ...clonedModules[index].properties,
        styles: clubbedStyles,
      };
    }
  });
  editedWorkflow.modules = clonedModules;
  return editedWorkflow;
};
