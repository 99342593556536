import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import FormModuleEditPropertiesV2 from '../../components/FormModule/FormModuleEditPropertiesV2';
import ListFormModuleV2 from '../../components/FormModule/ListFormModuleV2';
import { attachBorder } from '../../utils/domSdkEffects';
import { getFormComponentsFromHtmlString, getFormHtmlStringForV2, getTagNameById } from './helper';

function FormModuleDrawerV2({
  formModule,
  formV2Components,
  onAddComponent,
  onDeleteComponent,
  onCloneComponent,
  onCopyComponent,
  onUpdateComponentTagName,
  onDragComponent,
  onUpdateComponent,
  onPasteComponent,
  isFormPartOfSuperModule,
}) {
  const [selectedItem, setSelectedItem] = useState({
    basePath: '',
    componentId: '',
  });

  const onItemSelect = (componentId, basePath) => {
    setSelectedItem({ basePath, componentId });
  };

  useEffect(() => {
    const wrapperClassName = 'hv-builder-click-border-wrapper';
    const borderStyle = '1px dotted rgb(24, 129, 24)';
    attachBorder(selectedItem.componentId, wrapperClassName, borderStyle);
  }, [selectedItem]);

  const getComponentLists = (module) => {
    const htmlString = getFormHtmlStringForV2(module);
    const formComponents = getFormComponentsFromHtmlString(htmlString);
    return [
      {
        id: 'someId',
        title: 'COMPONENT LIST V3',
        components: formComponents,
        // components: [{
        //   id: 'testId',
        //   title: 'Item text',
        //   isContainer: true,
        //   subComponents: [
        //     {
        //       tagName: 'tagName2',
        //       id: 'textId2',
        //       text: 'Item text 2',
        //     },
        //   ],
        // }],
        enableAddButton: isFormPartOfSuperModule !== true,
        enableCopyButton: isFormPartOfSuperModule !== true,
        enableDeleteButton: isFormPartOfSuperModule !== true,
        enablePasteButton: isFormPartOfSuperModule !== true,
        enableDrag: isFormPartOfSuperModule !== true,
      }];
  };

  const handleOnClick = (componentId, basePath) => {
    onItemSelect(componentId, basePath);
  };

  const handleOnDrag = (pickId, dropId) => {
    onDragComponent(pickId, dropId);
  };

  const handleOnDelete = (componentId) => {
    onItemSelect('', '');
    onDeleteComponent(componentId);
  };

  const getSelectedComponent = (
    moduleProperties,
    workflowKey,
  ) => get(moduleProperties, workflowKey, {});

  return (
    <div className="master">
      <div className="component-list-div">
        <ListFormModuleV2
          componentLists={getComponentLists(formModule)}
          selectedComponentLocation={selectedItem}
          handleOnClick={handleOnClick}
          handleOnDelete={(componentId) => handleOnDelete(componentId)}
          handleOnCopy={(componentId) => onCopyComponent(componentId)}
          handleOnClone={(componentId) => onCloneComponent(componentId)}
          handleOnAdd={(componentId) => onAddComponent(componentId)}
          handleOnDrag={(pickId, dropId) => handleOnDrag(pickId, dropId)}
          handleOnPaste={() => { onPasteComponent(); }}
        />
      </div>
      {
        selectedItem.componentId !== '' ? (
          <FormModuleEditPropertiesV2
            formComponentLibraries={formV2Components}
            selectedComponentTagName={getTagNameById(formModule, selectedItem?.componentId)}
            selectedModuleId={formModule?.id}
            selectedComponent={getSelectedComponent(
              formModule?.properties,
              `componentConfigs['${selectedItem?.componentId}']`,
            )}
            selectedComponentId={selectedItem?.componentId}
            onUpdateComponent={(property, value) => {
              onUpdateComponent({
                componentId: selectedItem?.componentId,
                property,
                value,
              });
            }}
            isFormPartOfSuperModule={isFormPartOfSuperModule === true}
            onTagNameUpdate={(
              newTagName,
              libraryUrl,
              styleSheetsUrl,
              defaults,
              componentRef,
            ) => {
              onUpdateComponentTagName(
                selectedItem?.componentId,
                newTagName,
                libraryUrl,
                styleSheetsUrl,
                defaults,
                componentRef,
              );
            }}
          />
        ) : (
          <div className="edit-properties-div">
            <div className="edit-properties-div__property">
              <p>Please select a component</p>
            </div>
          </div>
        )
      }
    </div>
  );
}

FormModuleDrawerV2.propTypes = {
  formModule: PropTypes.object.isRequired,
  formV2Components: PropTypes.object.isRequired,
  onAddComponent: PropTypes.func.isRequired,
  onDeleteComponent: PropTypes.func.isRequired,
  onCloneComponent: PropTypes.func.isRequired,
  onCopyComponent: PropTypes.func.isRequired,
  onUpdateComponentTagName: PropTypes.func.isRequired,
  onDragComponent: PropTypes.func.isRequired,
  onUpdateComponent: PropTypes.func.isRequired,
  onPasteComponent: PropTypes.func.isRequired,
  isFormPartOfSuperModule: PropTypes.bool.isRequired,
};

export default FormModuleDrawerV2;
