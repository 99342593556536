import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import './Container.scss';
import { updateUserInfo, updateAppIdKeyToken } from '../reducers/user';
import { updateVersionedModules } from '../reducers/workflow';
import { toggleModuleBuilderMode } from '../reducers/moduleBuilder';
import AlertCode from '../constants/alertCodes';
import CustomWorkflowAlert from '../components/Common/CustomWorkflowAlert';
import useApiHooks from '../hooks/api/useApiHooks';
import useShowErrorAlert from '../hooks/custom/useCustomAlert';
import ReloadModal from './ReloadModal';

axios.defaults.withCredentials = true;

function Main() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [showReloadModal, setShowReloadModal] = useState(false);

  const showErrorAlert = useShowErrorAlert();
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const {
    fetchModules,
    fetchV2Modules,
    fetchGlobalConfig,
    fetchFormV2Components,
    fetchDocToken,
    fetchGoogleFontsList,
    fetchUser,
    logoutUser,
  } = useApiHooks();

  const fetchAppIdToken = async () => {
    try {
      const userRes = await axios({
        method: 'GET',
        headers: { appid: currentAppId || '55a3a6' },
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/demoToken`,
      });
      const { token } = userRes.data.result;
      dispatch(updateAppIdKeyToken({ token }));
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchUser });
    }
  };

  const convertModulesToVersionedStructure = (modules) => {
    // Map through each module object inside modules object
    // and convert it to a versioned structure
    // {
    //   'moduleName': {
    //     latestVersion: version,
    //     version : {
    //      config: {
    //        version,
    //      },
    //      uiConfig: {},
    //     }
    //   }
    // }
    const versionedModules = {};
    Object.entries(modules).forEach(([moduleName, config]) => {
      if (moduleName.endsWith('_uiConfig')) return;
      const version = config.version || 'v1';
      const uiConfig = modules[`${moduleName}_uiConfig`];

      versionedModules[moduleName] = {
        latestVersion: version,
        [version]: {
          config,
        },
      };
      if (uiConfig) {
        versionedModules[moduleName][version].uiConfig = uiConfig;
      }
    });
    return versionedModules;
  };

  useEffect(() => {
    // TODO: Stop execution and show appropriate error on the screen if an API call fails.
    const startApp = async () => {
      setIsLoading(true);
      fetchDocToken();
      const { isSuccessful, data: userResponse = null } = await fetchUser();
      if (isSuccessful && userResponse) {
        dispatch(updateUserInfo(userResponse.user));
      }
      await fetchAppIdToken();
      const [v1Modules, v2Modules] = await Promise.all([fetchModules(), fetchV2Modules()]);
      if (v2Modules) {
        const updatedModules = { ...v1Modules, ...v2Modules };
        const versionedModules = convertModulesToVersionedStructure(updatedModules);
        dispatch(updateVersionedModules({ modules: versionedModules }));
      }
      const currClientId = userResponse?.user?.clientId;
      if (currClientId === process.env.REACT_APP_MODULE_BUILDER_CLIENT_ID) {
        dispatch(toggleModuleBuilderMode({ mode: true }));
      }
      await Promise.all([fetchGoogleFontsList(), fetchGlobalConfig()]);
      await fetchFormV2Components();
      setIsLoading(false);
    };
    startApp();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      // call the user api
      const responseData = await fetchUser();
      const { isSuccessful, data = null } = responseData;

      if (!isSuccessful || !data) {
        // something went wrong in fetching the user
        return;
      }

      const { user } = data;
      // check if session storage is empty
      // this part is executed when the tab is closed and then opened again without logging out
      if (!sessionStorage.getItem('lastLoggedInUser')) {
        // set the user email in session storage
        sessionStorage.setItem('lastLoggedInUser', user.email);
      } else if (user.email !== sessionStorage.getItem('lastLoggedInUser')) {
        // if the user is not the same as the last logged in user, redirect to the dashboard
        // Cookie is overwritten by a different credential from a different tab using same backend
        sessionStorage.removeItem('lastLoggedInUser');
        setShowReloadModal(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [fetchUser]);

  const onReload = () => {
    setShowReloadModal(false);
    logoutUser();
    window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}`);
  };

  return (
    <>
      {showReloadModal && (
        <ReloadModal
          onReload={onReload}
        />
      )}
      {!isLoading && (
        <>
          <Outlet />
          <CustomWorkflowAlert />
        </>
      )}
      {isLoading && (<div className="reload-loading">loading please wait</div>)}
    </>
  );
}

export default Main;
