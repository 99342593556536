import Joi from 'joi-browser';

const isValidData = (data) => {
  const schema = Joi.array().items(
    Joi.object({
      id: Joi.string().required(),
      data: Joi.object({
        name: Joi.string().required().allow(''),
        value: Joi.string().required().allow(''),
        type: Joi.string().required().allow(''),
      }),
    }),
  );
  const { error } = schema.validate(data);
  return error === null;
};

export default isValidData;
