/* eslint-disable react/require-default-props */
import { Grid } from '@mui/material';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './TextInput.scss';

function TextInput({
  label, onChange, placeholder, isInvalid,
  setDefaultValue, disabled, readOnly, value, gridItemStyle,
  gridLabelStyle,
}) {
  const ref = useRef(null);
  useEffect(() => {
    if (typeof ref?.current?.value === 'string') {
      ref.current.value = setDefaultValue ? placeholder || '' : value || '';
    }
  }, [placeholder, label, value]);

  const onValueChange = (e) => {
    const updatedValue = e.target.value || '';
    onChange(updatedValue.trim());
  };

  return (
    <div className="text-input-div">
      <Grid container alignItems="center" rowSpacing={1}>
        {label && <Grid item xs={12} style={gridLabelStyle}>{label}</Grid>}
        <Grid item xs={12} style={gridItemStyle}>
          <input
            ref={ref}
            placeholder={placeholder}
            type="text"
            className={`text-input-div__text-input${isInvalid ? '-invalid' : ''}`}
            onChange={onValueChange}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
    </div>
  );
}
export default TextInput;

TextInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  setDefaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  gridItemStyle: PropTypes.object,
  gridLabelStyle: PropTypes.object,
};

TextInput.defaultProps = {
  disabled: false,
  readOnly: false,
};
