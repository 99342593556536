import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import QRCode from 'react-qr-code';

import { v4 as uuidv4 } from 'uuid';
import { CircularProgress } from '@mui/material';
import useApiHooks from '../../hooks/api/useApiHooks';
import compile from '../../compilerDecompiler/compiler';
import WorkflowInputsModal from '../../components/ViewWorkflow/WorkflowInputsModal';
import {
  selectDefaultTextConfig,
  selectAllCustomTextConfigs,
  selectCustomUIConfig,
} from '../../reducers/editBranding';
import { compileUiConfig } from '../uiConfigOperations';
import { selectSelectedWorkflow, selectVersionedModules } from '../../reducers/workflow';
import Modal from '../../components/Common/Modal';
import MobileIcon from '../../assests/icons/mobileIcon.svg';
import { formComponentList } from '../../constants/dynamicFormComponents';
import { getNewTransactionId } from '../../utils/generateUniqueId';

const MOBILE_APP_URL = process.env.REACT_APP_MOBILE_APP_URL || 'https://drive.google.com/file/d/1-6PKGw_fMXCgBa7mGs_iAm7Kiv8Puylt/view';

// TODO: Unify the try on mobile and try on web components under a single smart parent container
function TryOnMobile() {
  const jwtToken = useSelector((state) => state.user.appIdKeyToken);
  const versionedModules = useSelector(selectVersionedModules);
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const uiConfig = useSelector(selectCustomUIConfig);
  const customTextConfigList = useSelector(selectAllCustomTextConfigs);
  const defaultTextConfigList = useSelector(selectDefaultTextConfig);

  const [isLoading, setIsLoading] = useState(false);
  const [showWorkflowInputModal, setShowWorkflowInputModal] = useState(false);
  const [inputs, setInputs] = useState(null);
  const [displayMobileQR, setDisplayMobileQR] = useState(false);
  const [QRData, setQRData] = useState('');

  const customLanguageTextConfig = customTextConfigList?.en;
  const defaultLanguageTextConfig = defaultTextConfigList.default_en_text_config;
  const textConfig = (Object.keys(customLanguageTextConfig || {}).length > 0)
    ? customLanguageTextConfig
    : defaultLanguageTextConfig;

  const { fetchAppIdToken, publishDataForTryWorkflowOnMobile } = useApiHooks();

  useEffect(() => {
    if (displayMobileQR) {
      const workflowConfig = compile(selectedWorkflow, versionedModules, formComponentList);
      const compiledUiConfig = compileUiConfig(uiConfig, selectedWorkflow);
      publishDataForTryWorkflowOnMobile(workflowConfig, textConfig, compiledUiConfig, inputs)
        .then((res) => {
          const {
            isSuccessful,
            data: responseData,
          } = res;
          setIsLoading(false);
          if (isSuccessful) {
            const { sessionId } = responseData;
            const transactionId = getNewTransactionId();
            const data = JSON.stringify({
              uuid: sessionId,
              accessToken: jwtToken,
              transactionId,
              uniqueId: uuidv4(), // uniqueId for the Cross Platform Resume\
            });
            setQRData(data);
          } else {
            setDisplayMobileQR(false);
          }
        });
    }
  }, [displayMobileQR, inputs, jwtToken, selectedWorkflow, textConfig, uiConfig, versionedModules]);

  const onModalClose = () => {
    setDisplayMobileQR(false);
    setQRData('');
  };

  const onTryWorkflowClick = async () => {
    setIsLoading(true);
    setQRData('');
    const { properties } = selectedWorkflow;
    if (!jwtToken) await fetchAppIdToken();
    if (Object.keys(properties?.inputsRequired || {}).length) {
      setShowWorkflowInputModal(true);
    } else {
      setDisplayMobileQR(true);
    }
  };

  return (
    <>
      {
        showWorkflowInputModal
          ? (
            <WorkflowInputsModal
              onClose={() => setShowWorkflowInputModal(false)}
              inputsRequired={selectedWorkflow.properties.inputsRequired}
              onSaveInputs={(inputsReceived) => {
                setShowWorkflowInputModal(false);
                setInputs(inputsReceived);
                setDisplayMobileQR(true);
              }}
            />
          ) : null
      }
      <div>
        <Button className="try-workflow__option" onClick={onTryWorkflowClick} disabled={isLoading} data-testid="try-on-mobile-btn">
          <img className="try-workflow__option-icon" src={MobileIcon} alt="Mobile Icon" />
          <span className="try-workflow__option-text">Mobile</span>
          {
            isLoading
              ? <CircularProgress size="1rem" sx={{ color: 'rgba(5, 5, 82, 0.8)' }} />
              : null
          }
        </Button>
        <Modal
          isOpen={!!QRData}
          onClose={onModalClose}
          headerText="Please scan the QR below on the mobile app"
          data-testid="try-workflow__qr-modal"
        >
          <div className="try-workflow__qr">
            <QRCode
              size={256}
              style={{
                height: '100%', width: '100%',
              }}
              value={QRData}
              viewBox="0 0 256 256"
            />
          </div>
          <div className="try-workflow__qr__footer">
            <div className="try-workflow__qr__footer_link">
              {"Don't have the app?\t"}
              <a href={MOBILE_APP_URL} target="_blank" rel="noopener noreferrer">
                Open this link on your mobile to install the app.
              </a>
            </div>
            <div className="try-workflow__qr__footer_instructions">
              Follow these instructions to try workflow on mobile:
              <ol className="try-workflow__qr__footer_instructions_list">
                <li>Open the demo app.</li>
                <li>
                  {'Sign in using your\t'}
                  <a href="https://beta.dashboard.hyperverge.co/" target="_blank" rel="noopener noreferrer">
                    beta.dashboard
                  </a>
                  {'\tcredentials.'}
                </li>
                <li>Click on &ldquo;Start Verification&ldquo;.</li>
                <li>Click on the QR code Scanner on the top right.</li>
              </ol>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default TryOnMobile;
