import PropTypes from 'prop-types';
import closeIcon from '../../assests/icons/close.svg';
import '../../components/Workflow.scss';

function ConfirmForceStopSDK({ onProceed, onCloseClick }) {
  return (
    <div className="modal__drawer_background">
      <div className="modal__drawer">
        <div className="modal__drawer__heading_container">
          <div>
            Force Quit Workflow?
          </div>
          <button type="button" className="modal__drawer__heading_container__close_button" onClick={onCloseClick}>
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <div className="modal__drawer__body">
          <p>
            Force quitting allows you to exit a workflow in case of loading issues.
            Do you wish to continue?
          </p>
        </div>
        <div className="modal__bottom_content">
          <div className="modal__bottom_content__button">
            <button type="button" onClick={onCloseClick} className="modal__bottom_content__button__secondary_button">Cancel</button>
            <button type="button" onClick={onProceed} className="modal__bottom_content__button__primary_button">Continue</button>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmForceStopSDK.propTypes = {
  onProceed: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default ConfirmForceStopSDK;
