import { cloneDeep } from 'lodash';
import { getInitialModuleOfSuperModule } from '../../utils/helper';

const addPrevStepToHighLevelWorkflowModules = (
  highLevelWorkflowModules,
  versionedModuleConfigs,
  previousStepMap,
) => {
  const highLevelWorkflowModuleswithPrevstep = highLevelWorkflowModules.map((module) => {
    const clonnedModule = cloneDeep(module);
    let previousStep;
    if (module.type === 'superModule') {
      const superModuleId = module.id;
      const superModuleType = module?.subType;
      const superModuleVersion = module?.version || 'v1';
      const superModuleConfig = versionedModuleConfigs
        ?.[superModuleType]?.[superModuleVersion]?.config;
      const { library, initialStep: initialStepValue } = superModuleConfig;
      const initialStep = initialStepValue || library.modules[0].id;
      const initialModuleId = getInitialModuleOfSuperModule(
        initialStep,
        library,
        module,
      );
      previousStep = previousStepMap[superModuleId]?.[initialModuleId];
    } else {
      previousStep = previousStepMap[module.id]?.default;
    }
    if (previousStep === '') clonnedModule.previousStep = '';
    else if (previousStep) clonnedModule.previousStep = previousStep;
    return clonnedModule;
  });

  return highLevelWorkflowModuleswithPrevstep;
};

export default addPrevStepToHighLevelWorkflowModules;
