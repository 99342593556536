import { getHighLevelTextConfig, getHighLevelUiConfig } from '../containers/uiConfigOperations';

const generateHighLevelConfig = (newNode, newNodeId, node) => {
  let highLevelUiConfig = {};
  let highLevelTextConfig = {};

  if (newNode?.type === 'superModule') {
    const defaultSuperModuleUIConfig = node?.library?.uiFormatting || {};
    const defaultSuperModuleTextConfig = node?.library?.textFormatting || {};
    const modules = node?.library?.modules || {};

    highLevelUiConfig = getHighLevelUiConfig(newNodeId, defaultSuperModuleUIConfig);
    highLevelTextConfig = getHighLevelTextConfig(
      newNodeId,
      defaultSuperModuleTextConfig,
      modules,
    );
  }

  return {
    highLevelUiConfig,
    highLevelTextConfig,
  };
};

export default generateHighLevelConfig;
