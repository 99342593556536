import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import conditionDeleteIcon from '../../../assests/icons/conditionDeleteIcon.svg';
import DropDown from '../DropDown';
import { getTypeDropdownItems } from '../NestedDropdown/utils';

function RequestParameter({
  name: defaultName,
  value: defaultValue,
  type: defaultType,
  onNameChange, onValueChange, onTypeChange, onDelete,
  supportedTypes,
  nameLabel,
  pathLabel,
  typeLabel,
  menuOptions,
  isKeyNameValid,
  errorText,
  moduleBuilderProperties,
  moduleBuilderMode,
}) {
  const handleNameChange = (event) => {
    const { value } = event.target;
    onNameChange(value);
  };

  return (
    <div className="workflow_output_field">
      <div className="workflow_output_field_key">
        <TextField
          label={nameLabel}
          variant="outlined"
          value={defaultName}
          onChange={handleNameChange}
          error={!isKeyNameValid}
          helperText={errorText}
          size="small"
          className="workflow_output_field_textfield"
        />
      </div>
      <div className="workflow_output_field_value">
        <div className="workflow_output_field_value_item">

          <DropDown
            allowCustomInput
            noSelectLabel={pathLabel}
            textBoxPlaceholder="Enter value"
            defaultValue={defaultValue || null}
            onChange={(data) => {
              const value = data.value === null ? '' : data.value;
              onValueChange(value);
            }}
            items={menuOptions}
            className="workflow_output_field_select"
            data-test-id="RequestParameter:NestedDropdownWithInputsForValue"
            maxLength={30}
          />
        </div>
        <div className="workflow_output_field_typeSelectionDiv">
          <DropDown
            allowCustomInput={false}
            noSelectLabel={typeLabel}
            textBoxPlaceholder="Select"
            defaultValue={defaultType}
            onChange={(data) => {
              onTypeChange(data.value);
            }}
            items={{
              label: 'Type',
              items: getTypeDropdownItems(
                moduleBuilderMode,
                supportedTypes,
                moduleBuilderProperties,
              ),
            }}
            data-test-id="RequestParameter:NestedDropdownWithInputsForType"
          />
        </div>
      </div>

      <div className="workflow_output_field_deleteButtonDiv">
        <button type="button" onClick={onDelete} className="workflow_output_field_deleteButton">
          <img
            src={conditionDeleteIcon}
            alt="delete"
          />
        </button>
      </div>
    </div>
  );
}

RequestParameter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  nameLabel: PropTypes.string.isRequired,
  pathLabel: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  supportedTypes: PropTypes.array.isRequired,
  menuOptions: PropTypes.object.isRequired,
  isKeyNameValid: PropTypes.bool.isRequired,
  errorText: PropTypes.string.isRequired,
  moduleBuilderProperties: PropTypes.object.isRequired,
  moduleBuilderMode: PropTypes.bool.isRequired,
};

export default RequestParameter;
