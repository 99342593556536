import PropTypes from 'prop-types';
import conditionDeleteIcon from '../../assests/icons/conditionDeleteIcon.svg';

function ModuleInputRow({
  name,
  onDelete,
  onClick,
  handleDragStart,
  handleDragOver,
  handleDrop,
}) {
  return (
    <div
      className="workflow_output_field"
      draggable
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <button
        type="button"
        onClick={onClick}
        className="module_builder__input_button"
      >
        {name}
      </button>
      <button type="button" onClick={onDelete} className="workflow_output_field_deleteButton">
        <img
          src={conditionDeleteIcon}
          alt="delete"
        />
      </button>
    </div>
  );
}

ModuleInputRow.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  handleDragStart: PropTypes.func.isRequired,
  handleDragOver: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
};

export default ModuleInputRow;
