import PropTypes from 'prop-types';
import SingleSelectDropdown from '../Common/SingleSelectDropdown';
import Modal from '../Common/Modal';
import '../../containers/Container.scss';

function LanguageSelectModal(props) {
  const {
    languagesSupported,
    onProceed,
    selectedLanguage,
    showLanguageSelectModal,
    onChangeLanguage,
    onClose,
  } = props;

  return (
    <Modal
      isOpen={showLanguageSelectModal}
      headerText="Select language:"
      onClose={onClose}
      onSave={onProceed}
      buttonText="Proceed"
    >
      <div className="language-select-modal__content">
        <SingleSelectDropdown
          items={languagesSupported}
          onElementSelect={onChangeLanguage}
          defaultValue={selectedLanguage}
          overrideDropdownStyles="language-select-modal__dropdown"
        />
      </div>
    </Modal>
  );
}

LanguageSelectModal.propTypes = {
  languagesSupported: PropTypes.array.isRequired,
  onProceed: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  showLanguageSelectModal: PropTypes.bool.isRequired,
  onChangeLanguage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LanguageSelectModal;
