import { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { useDispatch, useSelector } from 'react-redux';
// import CustomTooltip from '../Common/CustomTooltip';
import AlertCode from '../../constants/alertCodes';
import { useShowCustomAlert } from '../../hooks/custom/useCustomAlert';
import { formatDateWithDateMonth } from '../../utils/dateFormats';
// import { updateCloseBrandingWindow } from '../../reducers/editBranding';
// import EditBrandingBaseDrawer from '../EditBranding/EditBrandingBaseDrawer';

function WorkflowProperties({ workflowCreatedAt, workflowUpdatedAt, workflowId }) {
  const [workflowIdCopied, setWorkflowIdCopied] = useState(false);
  const showCustomAlert = useShowCustomAlert();

  return (
    <>
      <Grid container className="default_drawer_summary_container">
        <Grid item xs={12}>
          <div className="drawer_content__heading">Summary</div>
        </Grid>
        <Grid item xs={12}>
          <div className="default_drawer_detail_sub_container">
            <div id="default_drawer_workflow_id__text_container">
              <span className="default_drawer_content__left_text">Workflow ID: </span>
              <span className="default_drawer_content__right_text" id="default_drawer_workflow_id__text">
                {workflowId}
                &ensp;
                {workflowIdCopied ? <span className="tooltiptext">copied</span> : (
                  <CopyToClipboard
                    text={workflowId}
                    onCopy={() => {
                      const payload = {
                        message: AlertCode.copiedToClipBoard,
                      };
                      showCustomAlert(payload);
                      setWorkflowIdCopied(true);
                    }}
                  >
                    <span className="tooltiptext">copy</span>
                  </CopyToClipboard>
                )}
              </span>
            </div>
          </div>
        </Grid>
      </Grid>

      {(workflowCreatedAt || workflowUpdatedAt) ? (
        <Grid container>
          <Grid item xs={12}>
            <div className="drawer_content__heading">Timeline</div>
          </Grid>
          {workflowCreatedAt
            ? (
              <Grid item xs={12}>
                <div className="default_drawer_detail_sub_container">
                  <div>
                    <span className="default_drawer_content__left_text">Created At: </span>
                    <span className="default_drawer_content__right_text">{formatDateWithDateMonth(workflowCreatedAt)}</span>
                  </div>
                </div>
              </Grid>
            ) : '' }
          {workflowUpdatedAt
            ? (
              <Grid item xs={12}>
                <div>
                  <div className="default_drawer_detail_sub_container">
                    <span className="default_drawer_content__left_text">Date Last Modified: </span>
                    <span className="default_drawer_content__right_text">{formatDateWithDateMonth(workflowUpdatedAt)}</span>
                  </div>

                </div>
              </Grid>
            ) : ''}
        </Grid>
      ) : '' }
    </>
  );
}

WorkflowProperties.propTypes = {
  workflowUpdatedAt: PropTypes.string,
  workflowId: PropTypes.string.isRequired,
  workflowCreatedAt: PropTypes.string,
};

WorkflowProperties.defaultProps = {
  workflowUpdatedAt: '',
  workflowCreatedAt: '',
};

export default WorkflowProperties;
