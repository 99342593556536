import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import JSZip from 'jszip';

import Button from '@mui/material/Button';

import '../../containers/Container.scss';
import '../WorkflowList/WorkflowList.scss';
import Modal from '../Common/Modal';
import {
  selectSelectedWorkflow, selectVersionedModules, resetIsEditedWorkflow, updateVersionedModules,
} from '../../reducers/workflow';
import compile from '../../compilerDecompiler/compiler';
import {
  generateModuleConfigsFromWorkflow,
  generateModuleUiConfigFromWorkflow,
} from '../../compilerDecompiler/workflowModuleTransformer';
import { selectModuleBuilderMode } from '../../reducers/moduleBuilder';
import { selectCustomTextConfig, selectCustomUIConfig, selectSelectedLanguage } from '../../reducers/editBranding';
import { compileUiConfig } from '../../containers/uiConfigOperations';
import DownloadIcon from '../../assests/icons/downloadIcon.svg';
import { formComponentList } from '../../constants/dynamicFormComponents';
import useFetchWorkflow from '../../hooks/workflow/fetchWorkflow';
import useApiHooks from '../../hooks/api/useApiHooks';
import useShowErrorAlert from '../../hooks/custom/useCustomAlert';

function DownloadWorkflow({ id, isDownloadTriggeredFromTable }) {
  const [showDownloadReasonModal, setShowDownloadReasonModal] = useState(false);
  const [downloadReasonData, setDownloadReasonData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    fetchCustomTextConfig,
    fetchCustomUiConfig,
    saveModuleDownloadReasonForm,
  } = useApiHooks();
  const { search } = useLocation();
  const selectedWorkflowFromState = useSelector(selectSelectedWorkflow);
  const customTextConfigFromState = useSelector(selectCustomTextConfig);
  const customUiConfigFromState = useSelector(selectCustomUIConfig);
  const versionedModules = useSelector(selectVersionedModules);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const moduleBuilderMode = useSelector(selectModuleBuilderMode);
  const workflowId = new URLSearchParams(search).get('id') || id;
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const showErrorAlert = useShowErrorAlert();

  const fetchWorkflow = useFetchWorkflow(currentAppId, selectedWorkflowFromState, versionedModules);

  const getConfig = async (stateConfig, fetchFunction, ...args) => {
    if (isDownloadTriggeredFromTable) {
      return fetchFunction(...args);
    }
    return stateConfig;
  };

  const getWorkflowZippedBlob = async (compiledWorkflow, customUiConfig, customTextConfig) => {
    const zip = new JSZip();
    // Add JSON files to the ZIP
    zip.file(`${workflowId}.json`, JSON.stringify(compiledWorkflow));
    if (Object.keys(customUiConfig).length > 0) {
      const compiledUiConfig = compileUiConfig(customUiConfig, compiledWorkflow);
      zip.file(`${workflowId}_ui_config.json`, JSON.stringify(compiledUiConfig));
    }
    if (Object.keys(customTextConfig).length > 0) {
      zip.file(`${workflowId}_${selectedLanguage || 'en'}_text_config.json`, JSON.stringify(customTextConfig));
    }

    // Generate the ZIP blob
    const content = await zip.generateAsync({ type: 'blob' });
    return content;
  };

  const getModuleZippedBlob = async (compiledWorkflow, customUiConfig, customTextConfig) => {
    const zip = new JSZip();
    const moduleConfig = generateModuleConfigsFromWorkflow(
      compiledWorkflow,
      customUiConfig,
      customTextConfig,
    );
    const uiConfig = generateModuleUiConfigFromWorkflow(compiledWorkflow);
    // Add JSON files to the ZIP
    zip.file('moduleConfig.json', JSON.stringify(moduleConfig));
    zip.file('uiConfig.json', JSON.stringify(uiConfig));
    // Generate the ZIP blob
    const content = await zip.generateAsync({ type: 'blob' });
    return content;
  };

  const downloadContent = (content) => {
    const url = URL.createObjectURL(content);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${workflowId}.zip`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const getWorkflowAndConfigs = async () => {
    const [workflow, customTextConfig, customUiConfig] = await Promise.all([
      getConfig(selectedWorkflowFromState, fetchWorkflow, workflowId),
      getConfig(customTextConfigFromState, fetchCustomTextConfig, workflowId, selectedLanguage),
      getConfig(customUiConfigFromState, fetchCustomUiConfig, workflowId),
    ]);

    if (workflow && isDownloadTriggeredFromTable) {
      dispatch(updateVersionedModules({ modules: workflow.modules }));
      dispatch(resetIsEditedWorkflow());
    }

    const selectedWorkflow = workflow?.workflow ? workflow.workflow : workflow;

    const compiledWorkflow = compile(selectedWorkflow, versionedModules, formComponentList);

    return { compiledWorkflow, customTextConfig, customUiConfig };
  };

  const downloadModule = async () => {
    const { compiledWorkflow, customTextConfig, customUiConfig } = await getWorkflowAndConfigs();
    const content = await getModuleZippedBlob(
      compiledWorkflow,
      customUiConfig,
      customTextConfig,
    );
    downloadContent(content);
  };

  const downloadWorkflow = async () => {
    const { compiledWorkflow, customTextConfig, customUiConfig } = await getWorkflowAndConfigs();
    const content = await getWorkflowZippedBlob(
      compiledWorkflow,
      customUiConfig,
      customTextConfig,
    );
    downloadContent(content);
  };

  const handleClick = async (e) => {
    e.stopPropagation();

    if (moduleBuilderMode) {
      if (process.env.REACT_APP_MODULE_DOWNLOAD_REASON_FORM_CONFIG) {
        setShowDownloadReasonModal(true);
      } else {
        downloadModule();
      }
    } else {
      downloadWorkflow();
    }
  };

  const onDownloadReasonModalClose = () => {
    setShowDownloadReasonModal(false);
    setDownloadReasonData({
      reason: '',
      name: '',
    });
  };

  const validateReasonData = (reasonData) => reasonData?.reason && reasonData?.name;

  const onConfirmDownloadReasonModal = async (reasonData, workflow) => {
    setIsLoading(true);
    const { error, message } = await saveModuleDownloadReasonForm(
      reasonData.reason,
      reasonData.name,
      workflow,
      workflowId,
    );
    setIsLoading(false);
    if (error) {
      showErrorAlert({
        error,
        message,
      });
      return;
    }
    onDownloadReasonModalClose();
    downloadModule();
  };

  return (
    <>
      <Modal
        isOpen={showDownloadReasonModal}
        onClose={onDownloadReasonModalClose}
        onSave={() => onConfirmDownloadReasonModal(downloadReasonData, selectedWorkflowFromState)}
        headerText="Please provide a reason for downloading the workflow"
        buttonText="Confirm"
        disableSaveButton={!validateReasonData(downloadReasonData)}
        isSaveButtonLoading={isLoading}
        loadingText="Saving..."
      >
        <div className="download_reason_form">
          <div className="download_reason_form__row">
            <span className="download_reason_form__label">
              Your Name
            </span>
            <input
              type="text"
              value={downloadReasonData?.name || ''}
              onChange={(e) => setDownloadReasonData((currentData) => ({
                ...currentData,
                name: e.target.value,
              }))}
            />
          </div>
          <div className="download_reason_form__row">
            <span className="download_reason_form__label">
              Reason
            </span>
            <textarea
              id="moduleDownloadReason"
              value={downloadReasonData?.reason || ''}
              onChange={(e) => setDownloadReasonData((currentData) => ({
                ...currentData,
                reason: e.target.value,
              }))}
            />
          </div>
        </div>
      </Modal>
      {!id ? (
        <Button
          variant="outlined"
          onClick={handleClick}
          className="more-options__option"
        >
          <img className="more-options__option-icon" src={DownloadIcon} alt="Download Icon" />
          <span className="more-options__option-text">Download Workflow</span>
        </Button>
      ) : (
        <button
          type="button"
          onClick={handleClick}
          id="workflow_table__downloadIcon"
        >
          <img id="workflow_table__downloadIcon-img" src={DownloadIcon} alt="Download Icon" />
        </button>
      )}
    </>
  );
}

DownloadWorkflow.propTypes = {
  id: PropTypes.string,
  isDownloadTriggeredFromTable: PropTypes.bool,
};

DownloadWorkflow.defaultProps = {
  id: '',
  isDownloadTriggeredFromTable: false,
};

export default DownloadWorkflow;
