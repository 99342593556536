import { calculatePathArray } from '../workflowOperations/workflowOperation';
import { getModuleFromId, getFormComponents, getComponentFromPath } from '../containers/FormModule/helper';
import { getChildrenNodesFromNode } from './workflowTraversal';
import { endStates } from '../components/constants';

const doesComponentHaveChildrenNodes = (workflow, moduleId, componentId, rootPath) => {
  const module = getModuleFromId(workflow, moduleId);
  const pathArray = calculatePathArray(module, rootPath, componentId);
  const components = getFormComponents(module, rootPath);
  const componentToBeDeleted = getComponentFromPath(components, pathArray);
  const nextStepId =
  componentToBeDeleted?.onClick?.nextStep ? componentToBeDeleted?.onClick?.nextStep : null;
  const deletionCheckDepsResult = nextStepId ?
    getChildrenNodesFromNode(nextStepId, workflow, endStates) : [];
  if (deletionCheckDepsResult.length > 0) {
    return deletionCheckDepsResult;
  }
  return [];
};

export default doesComponentHaveChildrenNodes;
