/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { isNumber } from 'lodash';
import tinycolor from 'tinycolor2';

import './ColorPicker.scss';

function ColorPicker(props) {
  const {
    type, name, color, onChange,
  } = props;

  const extractHexAndOpacity = (defaultColor) => {
    const colorObject = tinycolor(defaultColor);
    const hexString = colorObject.toHexString();
    const opacity = colorObject.getAlpha();
    return { hexString, opacity: (opacity * 100).toFixed(0) };
  };

  const { hexString: initialHex, opacity: initialOpacity } = extractHexAndOpacity(color);
  const [colorState, setColorState] = useState({
    hexColor: initialHex || '#FFFFFF',
    opacityVal: initialOpacity || 100,
  });
  const [showPicker, setShowPicker] = useState(false);
  const isValidHex = (hex) => hex.length !== 5 && tinycolor(hex).isValid();

  useEffect(() => {
    if (color && color !== colorState.hexColor && isValidHex(color)) {
      const { hexString, opacity } = extractHexAndOpacity(color);
      setColorState({ hexColor: hexString, opacityVal: opacity });
    }
  }, [color]);

  const convertToHex8String = (hex, opacity) => tinycolor(hex).setAlpha(opacity).toHex8String();

  const handleColorChange = (newColor) => {
    setColorState({
      hexColor: newColor.hex,
      opacityVal: (newColor.rgb.a * 100).toFixed(0),
    });
    const toHex8String = tinycolor(newColor.hex).setAlpha(newColor.rgb.a).toHex8String();
    onChange(type, toHex8String);
  };
  const handleOpacityChange = (event) => {
    const inputValue = event.target.value;

    if (!isNumber(inputValue) && (inputValue === '' || (parseInt(inputValue, 10) >= 0 && parseInt(inputValue, 10) <= 100))) { // checks that the value entered in the percentage field is a valid value
      setColorState((prevState) => ({
        ...prevState,
        opacityVal: event.target.value,
      }));
      const hex8String = tinycolor(colorState.hexColor).setAlpha(inputValue / 100).toHex8String();
      onChange(type, hex8String);
    }
  };
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setColorState((prevState) => ({
      ...prevState,
      hexColor: inputValue,
    }));
    if (isValidHex(inputValue)) {
      const colorObject = tinycolor(inputValue);
      const opacity = colorObject.getAlpha();
      setColorState((prevState) => ({
        ...prevState,
        opacityVal: (opacity * 100).toFixed(0),
      }));
      onChange(type, inputValue);
    }
  };

  const openPicker = () => setShowPicker(true);
  const closePicker = () => setShowPicker(false);

  return (
    <div className="color-picker-container">
      <div
        className={type ? 'color-picker' : 'button-color-picker'}
        onMouseLeave={closePicker}
      >
        <div className={type ? 'color-picker__container' : 'button-color-picker__container'}>
          <div className={type ? 'color-picker__label' : 'button-color-picker__label'}>
            {name}
          </div>
          <div className="color-picker__subcontainer" onClick={openPicker}>
            <div
              className="color-picker__preview"
            >
              <div
                className="color-picker__preview__body"
                style={{ backgroundColor: color }}
              />
            </div>
            <input
              type="text"
              className="color-input"
              value={colorState.hexColor}
              onChange={handleInputChange}
            />
            <div className="color-picker__preview__opacity">
              <input
                type="number"
                className="color-input2"
                value={colorState.opacityVal}
                onChange={handleOpacityChange}
              />
              %
            </div>

          </div>
          {showPicker && (
            <ChromePicker
              color={convertToHex8String(colorState.hexColor, colorState.opacityVal / 100) || '#FFFFFF'}
              className="color-picker__chrome-picker"
              onChangeComplete={handleColorChange}
            />
          )}
        </div>
      </div>

    </div>
  );
}
ColorPicker.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

ColorPicker.defaultProps = {
  type: '',
};

export default ColorPicker;
