import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import NestedDropdownWithInputs from '../ViewWorkflow/NestedDropdown/NestedDropdown';
import TextInput from '../ImportWorkflow/TextInput';

function EditConfigurationBase(props) {
  const {
    moduleIdsDropdown,
    uiConfigSectionName,
    onUiConfigPositionChange,
    inputModuleId,
    inputModuleKey,
    updateWorkflowKey,
    currentInputType,
    setInputType,
    moduleInputConfigurations,
  } = props;

  const inputTypeOptions = moduleInputConfigurations.map((config) => ({
    label: config.label,
    value: config.type,
  }));

  return (
    <>
      <div className="module_builder__inputs_container_dropdown">
        <div className="text-input-div">
          <Grid container alignItems="center" rowSpacing={1}>
            <Grid item xs={12}>Position</Grid>
            <Grid item xs={12}>
              <NestedDropdownWithInputs
                defaultValue={uiConfigSectionName}
                items={{
                  items: [{
                    label: 'Input',
                    value: 'inputs',
                  },
                  {
                    label: 'Configuration',
                    value: 'configurations',
                  }],
                }}
                noSelectLabel="Select Category type"
                onChange={(selectedItem) => onUiConfigPositionChange(selectedItem.value)}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="module-builder-inputs-container-dropdown">
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <div className="text-input-div">
            <Grid container alignItems="center" rowSpacing={1}>
              <Grid item xs={12}>Module</Grid>
              <Grid item xs={12}>
                <NestedDropdownWithInputs
                  onChange={(selectedItem) => updateWorkflowKey(
                    selectedItem.value,
                    inputModuleKey,
                  )}
                  noSelectLabel="Select Module Id"
                  items={moduleIdsDropdown}
                  defaultValue={inputModuleId || null}
                />
              </Grid>
            </Grid>
          </div>
          <TextInput
            label="Workflow Key"
            value={inputModuleKey}
            isInvalid={!inputModuleKey?.length}
            onChange={(value) => updateWorkflowKey(inputModuleId, value)}
          />
        </div>
      </div>
      <div className="module-builder-inputs-container-dropdown">
        <div className="text-input-div">
          <Grid container alignItems="center" rowSpacing={1}>
            <Grid item xs={12}>Input type</Grid>
            <Grid item xs={12}>
              <NestedDropdownWithInputs
                defaultValue={currentInputType}
                items={{
                  items: inputTypeOptions,
                }}
                noSelectLabel="Select Input type"
                onChange={(selectedItem) => setInputType(selectedItem.value)}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

EditConfigurationBase.propTypes = {
  moduleIdsDropdown: PropTypes.shape({
    items: PropTypes.array.isRequired,
  }).isRequired,
  uiConfigSectionName: PropTypes.string.isRequired,
  onUiConfigPositionChange: PropTypes.func.isRequired,
  inputModuleId: PropTypes.string.isRequired,
  inputModuleKey: PropTypes.string.isRequired,
  updateWorkflowKey: PropTypes.func.isRequired,
  currentInputType: PropTypes.string.isRequired,
  setInputType: PropTypes.func.isRequired,
  moduleInputConfigurations: PropTypes.array.isRequired,
};

export default EditConfigurationBase;
