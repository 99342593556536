const tableHeaders = {
  name: {
    label: 'Workflow',
    width: '25%',
  },
  description: {
    label: 'Details',
    width: '30%',
  },
  modules: {
    label: 'Modules Included',
    width: '45%',
  },
};
export default tableHeaders;
