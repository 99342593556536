import PropTypes from 'prop-types';
import { NestedDropdown } from 'mui-nested-menu';
import { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextInputWithButton from '../TextInputWithBtn/TextInputWithBtn';
import { getLabel, getMenuItem } from './helper';

import './NestedDropdown.scss';

const theme = createTheme({
  typography: {
    fontFamily: 'Arial',
    fontSize: 10.5,
    fontWight: 'medium',
    fontColor: 'black',
    margin: 0,
    spacing: 8,
    button: {
      height: '2rem',
      textTransform: 'none',
      justifyContent: 'space-between',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

function NestedDropdownWithInputs({
  items,
  allowCustomInput,
  onChange,
  defaultValue,
  noSelectLabel,
  textBoxPlaceholder,
  alignleft,
}) {
  const [currentValue, setValue] = useState(defaultValue);
  const shouldShowDropdown = defaultValue === null || getLabel(defaultValue, items) !== null;
  const [showDropDown, setShowDropDown] = useState(shouldShowDropdown);

  const handleValueChange = (newValue, fromCustomInput = false) => {
    setValue(newValue);
    onChange({
      value: newValue,
      isCustomInput: fromCustomInput,
    });
  };

  const handleOptionSelect = (val) => {
    if (val === 'builder_customInput') {
      setShowDropDown(false);
      handleValueChange(null);
    } else handleValueChange(val);
  };

  const handleBtnClick = () => {
    setShowDropDown(true);
    handleValueChange(null);
  };

  // eslint-disable-next-line no-shadow
  const getMainLabel = (currentValue, items) => (currentValue === null ?
    noSelectLabel || 'Please Select' :
    getLabel(currentValue, items, '') || 'Invalid Item');

  return (
    <div>
      {
      showDropDown ? (
        <ThemeProvider theme={theme}>
          <NestedDropdown
            className="custom_nested_dropdown"
            menuItemsData={
                getMenuItem(
                  {
                    options: items,
                    cb: handleOptionSelect,
                    isStartNode: true,
                    mainLabel: getMainLabel(currentValue, items),
                    addCustomInput: allowCustomInput,
                  },
                )
            }
            MenuProps={{
              elevation: 3,
              ...(alignleft && {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              }),
            }}
            ButtonProps={{
              variant: 'outlined',
            }}
          />
        </ThemeProvider>
      )
        : (
          <TextInputWithButton
            placeholder={textBoxPlaceholder}
            initialValue={defaultValue}
            onChange={(value) => { handleValueChange(value, true); }}
            onBtnClick={handleBtnClick}
            showBtn
          />
        )
    }
    </div>
  );
}

NestedDropdownWithInputs.defaultProps = {
  allowCustomInput: false,
  textBoxPlaceholder: 'Enter custom value',
  noSelectLabel: 'Please Select',
  alignleft: false,
};

NestedDropdownWithInputs.propTypes = {
  items: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.any.isRequired,
  allowCustomInput: PropTypes.bool,
  noSelectLabel: PropTypes.string,
  textBoxPlaceholder: PropTypes.string,
  alignleft: PropTypes.bool,
};

export default NestedDropdownWithInputs;
