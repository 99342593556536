import { cloneDeep } from 'lodash';

const updateWorkflowDetails = (workflow, name, description, subType) => {
  const updatedWorkflow = cloneDeep(workflow);
  updatedWorkflow.properties.name = name;
  updatedWorkflow.properties.description = description;
  if (subType && updatedWorkflow.properties.moduleBuilder) {
    updatedWorkflow.properties.moduleBuilder.subType = subType;
  }
  return { workflow: updatedWorkflow, success: true };
};

export default updateWorkflowDetails;
