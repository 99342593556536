import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import PropertiesTooltip from '../../ViewWorkflow/Tooltip/Tooltip';
import MultiSelectDropDown from './MultiSelectDropDown';

function MultiSelectDropDownWithLabel(props) {
  const {
    options,
    value,
    isDisabled,
    onChange,
    placeholder,
    label,
    infoButton,
  } = props;

  return (
    <Grid container alignItems="center" className="edit-properties-div__dropdown-container">
      <Grid item xs={4}>
        <label htmlFor={label} className="edit-properties-div__label">
          {label}
        </label>
        <PropertiesTooltip infoButton={infoButton} />
      </Grid>
      <Grid item xs={8} container className="edit-settings-div__dropdown">
        <MultiSelectDropDown
          options={options}
          value={value}
          isDisabled={isDisabled}
          onChange={onChange}
          placeholder={placeholder}
        />
      </Grid>
    </Grid>
  );
}

MultiSelectDropDownWithLabel.propTypes = {
  isDisabled: PropTypes.bool,
  value: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  infoButton: PropTypes.object,
};

MultiSelectDropDownWithLabel.defaultProps = {
  isDisabled: false,
  infoButton: null,
};

export default MultiSelectDropDownWithLabel;
