import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import closeIcon from '../../assests/icons/CloseIcon.svg';
import TextInput from './TextInput';

function UpdateModal({
  onUpdateWorkflow,
  initialData,
  onClose,
  allowEditSubType,
}) {
  const [workflowData, setWorkflowData] = useState(initialData);
  const [enableUpdate, setEnableUpdate] = useState(false);

  const gridLabelStyle = {
    padding: '0px',
    fontFamily: 'Inter',
    fontSize: '10px',
    color: 'rgba(5, 5, 82, 0.6)',
  };

  const gridItemStyle = {
    paddingTop: '4px',
    fontFamily: 'Inter',
    fontSize: '10px',
  };

  const onValueChange = (value, key) => {
    setWorkflowData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  useEffect(() => {
    const trimmedName = workflowData?.name?.trim();
    const trimmedDescription = workflowData?.description?.trim();
    const trimmedSubType = workflowData?.moduleBuilderSubType?.trim();

    const hasNameChanged = trimmedName !== initialData.name;
    const hasDescriptionChanged = trimmedDescription !== initialData.description;
    const hasSubTypeChanged = trimmedSubType !== initialData?.moduleBuilderSubType;

    const hasBaseDetailsChanged = hasNameChanged || hasDescriptionChanged || hasSubTypeChanged;
    const isBaseDetailsNonEmpty = allowEditSubType
      ? (trimmedName !== '' && trimmedDescription !== '' && trimmedSubType !== '')
      : (trimmedName !== '' && trimmedDescription !== '');

    const isDifferent = hasBaseDetailsChanged && isBaseDetailsNonEmpty;

    setEnableUpdate(isDifferent);
  }, [workflowData, initialData, allowEditSubType]);

  const handleUpdateButton = () => {
    onUpdateWorkflow(workflowData);
    onClose();
  };
  return (
    <div>
      <div className="edit_workflow__drawer_background">
        <div className="edit_workflow__drawer">
          <div className="edit_workflow__drawer__heading_container">
            <div>
              Workflow Details
            </div>
            <button
              type="button"
              className="edit_workflow__drawer__heading_container__close_button"
              onClick={onClose}
            >
              <img src={closeIcon} alt="close" />
            </button>
          </div>
          <div className="edit_workflow__drawer__body">
            <TextInput
              label="Name"
              placeholder="Edit name of your workflow"
              onChange={(value) => onValueChange(value, 'name')}
              value={workflowData.name || ''}
              gridItemStyle={gridItemStyle}
              gridLabelStyle={gridLabelStyle}
              data-testid="name-input"
            />
            <TextInput
              label="Description"
              placeholder="Edit description of your workflow"
              onChange={(value) => onValueChange(value, 'description')}
              value={workflowData.description || ''}
              gridItemStyle={gridItemStyle}
              gridLabelStyle={gridLabelStyle}
              data-testid="description-input"
            />
            {
              allowEditSubType && (
                <TextInput
                  label="SubType"
                  placeholder="Edit subType of your module"
                  onChange={(value) => onValueChange(value, 'moduleBuilderSubType')}
                  value={workflowData?.moduleBuilderSubType || ''}
                  gridItemStyle={gridItemStyle}
                  gridLabelStyle={gridLabelStyle}
                  data-testid="subtype-input"
                />
              )
            }
          </div>
          <div className="edit_workflow__bottom_content">
            <div className="edit_workflow__bottom_content__button">
              <button
                type="button"
                className="edit_workflow__bottom_content__button__secondary_button"
                onClick={onClose}
                data-testid="cancel-button"
              >
                Cancel
              </button>
              <button
                type="button"
                className="edit_workflow__bottom_content__button__primary_button"
                onClick={handleUpdateButton}
                disabled={!enableUpdate}
                data-testid="update-button"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateModal;

UpdateModal.propTypes = {
  onUpdateWorkflow: PropTypes.func.isRequired,
  initialData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  allowEditSubType: PropTypes.bool.isRequired,
};
