import PropTypes from 'prop-types';
import calculateButtonPosition from './utils/calculateButtonPosition';
import './MultiSelectDropDown.scss';

function MultiSelectOptions(props) {
  const {
    options,
    handleCheckBoxClicked,
    selectedOptions,
    buttonRef,
  } = props;
  const buttonPosition = calculateButtonPosition(buttonRef);

  const shouldSelectCheckBox = (value, currentOptions) => currentOptions.includes(value);

  return (
    <div>
      <div
        className="multi-select__popup"
        style={{
          top: buttonPosition.top + 8,
          left: buttonPosition.left,
        }}
      >
        {options.map((value) => (
          <div
            key={value.value}
            className="multi-select__popup__options"
          >
            <input
              type="checkbox"
              id={value.value}
              className="multi-select__popup__options__checkbox"
              onChange={() => handleCheckBoxClicked(value.value)}
              checked={shouldSelectCheckBox(value.value, selectedOptions)}
            />
            <label
              htmlFor={value.value}
              className="multi-select__popup__options__input"
            >
              {value.label}
              {' '}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

MultiSelectOptions.propTypes = {
  options: PropTypes.array.isRequired,
  handleCheckBoxClicked: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  buttonRef: PropTypes.any.isRequired,
};
export default MultiSelectOptions;
