import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import CustomTooltip from '../Common/CustomTooltip';
import BaseDrawer from './BaseDrawer';
import '../Workflow.scss';
import WorkflowConfigurationModal from './WorkflowConfigurationModal';
import DisplayWorkflowConfigurations from './DisplayWorkflowConfigurations';

function StartDrawer({
  workflowConfig,
}) {
  const [activeTab, setActiveTab] = useState(1);
  const [sdkInputs, setSdkInputs] = useState([]);
  const [showModalType, setShowModalType] = useState('');

  const generateUniqueID = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueID = '';

    for (let i = 0; i < 6; i += 1) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueID += characters.charAt(randomIndex);
    }

    return uniqueID;
  };

  useEffect(() => {
    const { properties } = workflowConfig;
    if (properties) {
      if (properties.inputsRequired) {
        setSdkInputs(
          Object.entries(properties.inputsRequired).map(
            ([key, value]) => ({ id: generateUniqueID(), variable: key, type: value }
            ),
          ),
        );
      }
    }
  }, [JSON.stringify(workflowConfig)]);

  const handleClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const displayInputs = () =>
    // eslint-disable-next-line no-unused-vars, implicit-arrow-linebreak
    sdkInputs.map(({ variable, type, id }) => (
      <div key={id} className="workflow_input_field">
        <TextField
          label="variable name"
          variant="outlined"
          value={variable}
          className="workflow_input_field_textfield"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          className="workflow_input_field_select"
          variant="outlined"
          value={type}
          label="Type"
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    ));

  return (
    <>
      { showModalType
        ? (
          <WorkflowConfigurationModal
            onClose={() => setShowModalType('')}
            page={showModalType}
          />
        )
        : null}
      <BaseDrawer
        heading="Start"
        subHeading="An overview to get you started"
      >
        <div className="options-ribbon">
          <button
            type="button"
            className={`options-ribbon__tab ${activeTab === 1 ? 'options-ribbon__active' : ''}`}
            onClickCapture={() => handleClick(1)}
          >
            Properties
          </button>
          <button
            type="button"
            className={`options-ribbon__tab__info ${activeTab === 2 ? 'options-ribbon__active' : ''}`}
            // onClickCapture={() => handleClick(2)}
          >
            UI Screens
          </button>
          <button
            type="button"
            className={`options-ribbon__tab__info ${activeTab === 3 ? 'options-ribbon__active' : ''}`}
            // onClickCapture={() => handleClick(3)}
          >
            Info
          </button>
        </div>
        {activeTab === 1
          ? (
            <div>
              <div className="default_drawer_content_sub_container">
                <DisplayWorkflowConfigurations workflowConfig={workflowConfig} />
              </div>
              <div className="default_drawer_content_sub_container">
                <div className="drawer_content__heading_default">
                  INPUTS TO WORKFLOW
                  <CustomTooltip
                    title={(
                      <>
                        These are fields that have to be passed to the SDK
                        at the time of integration.
                        <a href="https://developer.hyperverge.co/global-beta/kyc/sdk-inputs" className="drawer_tooltip__link">Learn More</a>
                      </>
                  )}
                    placement="top"
                    arrow
                  >
                    <div className="drawer_info__icon">
                      i
                    </div>
                  </CustomTooltip>
                </div>
                <div className="drawer_content__subheading_default">
                  Variables that workflow accepts
                </div>
                {
                  Object.keys(sdkInputs).length !== 0 ? (
                    <div className="workflow_input_form_drawer">
                      {displayInputs()}
                    </div>
                  ) : null
                }
                <button type="button" onClick={() => setShowModalType('input')}>Add Global Variables</button>

              </div>
            </div>
          )
          : <div>Work in progress</div>}
      </BaseDrawer>
    </>

  );
}

StartDrawer.propTypes = {
  workflowConfig: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default StartDrawer;
