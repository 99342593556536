import PropTypes from 'prop-types';
import './Description.scss';

function Description({ text }) {
  return (
    <div className="description">
      {text}
    </div>
  );
}

Description.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Description;
