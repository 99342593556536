import PropTypes from 'prop-types';

function PanelItem({ text, selected, onSelect }) {
  return (
    <button
      type="button"
      className={selected ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
      onClick={onSelect}
    >
      {text || 'Panel'}
    </button>
  );
}

PanelItem.propTypes = {
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PanelItem;
