import HVError, { errorCodes, errorMessages } from '../../utils/error';

const validateInitialStep = (moduleConfig) => {
  const moduleIds = moduleConfig.library.modules.map(({ id }) => id);
  const conditionIds = Object.keys(moduleConfig.library.conditions);
  const allIds = [...moduleIds, ...conditionIds];
  if (!allIds.includes(moduleConfig.initialStep)) {
    throw new HVError({
      code: errorCodes.invalidModuleConfig,
      message: errorMessages.invalidInitialStep,
    });
  }
};

export default validateInitialStep;
