import PropTypes from 'prop-types';
import GenericModal from './GenericModal';

import CloseIcon from '../../assests/icons/close.svg';
import './ModalStyles.scss';

function Modal({
  isOpen, onClose, onSave, headerText, headerContent,
  className, children, buttonText, 'data-testid': dataTestId,
  disableSaveButton, isSaveButtonLoading, loadingText,
}) {
  return (
    <GenericModal isOpen={isOpen} onClose={onClose} data-testid={dataTestId}>
      <div className={`modal-content ${className}`}>
        <div className="modal-header">
          {headerContent ? (
            <div className="modal-header-content">{headerContent}</div>
          ) : (
            <h2>{headerText}</h2>
          )}
          <button type="button" className="modal-close" onClick={onClose}>
            <img className="modal-close-icon" src={CloseIcon} alt="Close" />
          </button>
        </div>
        <div className="modal-body">{children}</div>
        {onSave && (
        <div className="modal-footer">
          <button
            type="button"
            className="modal-save"
            onClick={onSave}
            disabled={disableSaveButton || isSaveButtonLoading}
          >
            {isSaveButtonLoading ? loadingText : buttonText}
          </button>
        </div>
        )}
      </div>
    </GenericModal>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  headerText: PropTypes.string,
  headerContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  'data-testid': PropTypes.string,
  disableSaveButton: PropTypes.bool,
  isSaveButtonLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  className: PropTypes.string,
};

Modal.defaultProps = {
  onSave: null,
  buttonText: 'Save',
  'data-testid': 'modal_testID',
  disableSaveButton: false,
  isSaveButtonLoading: false,
  loadingText: 'Saving...',
  headerText: '',
  headerContent: null,
  className: '',
};

export default Modal;
