import generateUniqueID, { generateTimestamp } from '../../utils/generateUniqueId';

const createWorkflowId = () => {
  const uuid = generateUniqueID();
  const timestamp = generateTimestamp();
  const workflowId = `${uuid}_${timestamp}`;
  return workflowId;
};

export default createWorkflowId;
