import PropTypes from 'prop-types';
import Checkbox from '../../Common/Checkbox';
import Button from '../../Common/Button';

function ModuleVersionUpdateConfirmation(props) {
  const {
    selectedModule,
    onModuleUpdateCancel,
    updateConsent,
    setUpdateConsent,
    onModuleUpdateConfirm,
  } = props;
  return (
    <>
      <div className="version-update__selected-item-details">
        {(selectedModule?.versionChangeDetails || []).map((detail) => (
          detail?.logs?.length > 0 && (
          <div key={`${selectedModule.moduleId}-${detail.comparisonName}`} className="version-update__selected-item-details-wrapper">
            <div className="version-update__selected-item-details-description">
              {detail.description}
            </div>
            <ul className="version-update__selected-item-details-list">
              {detail.logs.map((log, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`${selectedModule.moduleId}-${detail.comparisonName}-${index}`} className="version-update__selected-item-details-list-item">
                  {log}
                </li>
              ))}
            </ul>
          </div>
          )
        ))}
      </div>
      <div className="version-update__selected-item-footer">
        <Checkbox
          checked={updateConsent}
          className="version-update__selected-item-update-consent"
          id="version-update-consent-checkbox"
          label="I acknowledge this update may lead to results API integration
              failures and will inform clients of the same."
          onChange={(value) => setUpdateConsent(value)}
        />
        <div className="version-update__selected-item-footer-actions">
          <Button buttonType="secondary" className="version-update__selected-item-cancel" onClick={onModuleUpdateCancel}>
            Cancel
          </Button>
          <Button
            buttonType="primary"
            className="version-update__selected-item-update"
            disabled={!updateConsent}
            onClick={onModuleUpdateConfirm}
          >
            Confirm Update
          </Button>
        </div>
      </div>
    </>
  );
}

ModuleVersionUpdateConfirmation.propTypes = {
  selectedModule: PropTypes.object.isRequired,
  onModuleUpdateCancel: PropTypes.func.isRequired,
  updateConsent: PropTypes.bool.isRequired,
  setUpdateConsent: PropTypes.func.isRequired,
  onModuleUpdateConfirm: PropTypes.func.isRequired,
};

export default ModuleVersionUpdateConfirmation;
