import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './SingleSelectDropDown.scss';
import {
  getCurrentValueFromWorkflowForSingleSelectDropDown as getCurrentValue,
} from '../InputsToModule/utils/updateWorkflow';
import PropertiesTooltip from '../Tooltip/Tooltip';

function SingleSelectDropDown(props) {
  const {
    options, isDisabled, selectedWorkflow, selectedNodeId, updateValueInWorkflow,
  } = props;

  const {
    title, values, text, workflowKey, infoButton = null,
  } = options;

  const selectedWorkflowModules = selectedWorkflow.modules;
  const [selectedModule, setSelectedModule] = useState(
    selectedWorkflowModules.find((module) => module.id === selectedNodeId),
  );

  const [selectedOption, setSelectedOption] = useState(
    getCurrentValue(selectedModule, workflowKey),
  );

  useEffect(() => {
    // Temporary fix to render exact state until this component is refactored
    setSelectedModule(selectedWorkflowModules.find((module) => module.id === selectedNodeId));
  }, [JSON.stringify(selectedWorkflow)]);

  useEffect(() => {
    setSelectedModule(selectedWorkflowModules?.find((module) => module.id === selectedNodeId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeId]);

  useEffect(() => {
    setSelectedOption(getCurrentValue(selectedModule, workflowKey, options, values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModule]);

  const handleChange = (event) => {
    const selectedIndex = parseInt(event.target.value || '-1', 10);
    if (selectedIndex === -1) {
      setSelectedOption(null);
    } else {
      const changedValue = values[selectedIndex];
      setSelectedOption(changedValue);
    }
  };

  useEffect(() => {
    updateValueInWorkflow(workflowKey, selectedOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const isCurrentOptionSelected = (optionValue, selectedValue) => {
    const isEqual = JSON.stringify(optionValue) === JSON.stringify(selectedValue);
    return isEqual;
  };

  return (
    <div className="single-select-dropdown">
      <h2 className="single-select-dropdown__label">
        {title}
        <PropertiesTooltip infoButton={infoButton} />
      </h2>
      <select className="single-select-dropdown__options" onChange={handleChange} disabled={isDisabled}>
        <option
          value="-1"
          selected={selectedOption === null}
        >
          {selectedOption === null ? 'Please select an option' : 'Remove selection'}
        </option>
        {values.map((option, index) => (
          <option
            key={`${option}select`}
            value={`${index}`}
            selected={isCurrentOptionSelected(option, selectedOption)}
          >
            {text[index]}
          </option>
        ))}
      </select>
    </div>
  );
}

SingleSelectDropDown.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool,
  updateValueInWorkflow: PropTypes.func.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
};

SingleSelectDropDown.defaultProps = {
  isDisabled: false,
};

export default SingleSelectDropDown;
