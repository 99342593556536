import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

// Tooltip over material ui tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
      // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    classes={{ popper: className }}
  />
))(() => ({
  // eslint-disable-next-line no-undef
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: '#15155D',
    maxWidth: 200,
    border: '1px solid #dadde9',
    padding: '16px',
    fontWeight: '350',
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: 'white',
    border: '1px solid #dadde9',
  },
}));

export default CustomTooltip;
