import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './DebouncedTextEditor.scss';
import useDebounce from '../../utils/useDebounce';

function DebouncedTextEditor(props) {
  const { text, onTextChange } = props;
  const [localText, setLocalText] = useState(text);
  const debouncedText = useDebounce(localText, 500);

  const handleTextChange = (event) => {
    const { value = '' } = event.target;
    setLocalText(value);
  };
  useEffect(() => {
    if (text) setLocalText(text);
  }, [text]);
  useEffect(() => {
    onTextChange(debouncedText);
  }, [debouncedText]);

  return (
    <div>
      <textarea
        className="inputText"
        rows="5"
        cols="30"
        value={localText}
        onChange={handleTextChange}
      />
    </div>
  );
}

DebouncedTextEditor.propTypes = {
  text: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
};

export default DebouncedTextEditor;
