import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useState } from 'react';
import useDebounce from '../../../utils/useDebounce';
import { useDidMountEffect } from '../../../hooks/custom/useCustomAlert';

function NumberInput(props) {
  const {
    defaultValue,
    onChange,
    label,
    min,
    max,
    placeholder,
    returnInteger = false,
  } = props;

  const [numberInput, setNumberInput] = useState(defaultValue || '');
  const debouncedNumberInput = useDebounce(numberInput, 500);
  const onValueChange = (e) => {
    const updatedValue = e.target.value;
    const returnValue = returnInteger ? parseInt(updatedValue, 10) : updatedValue;
    setNumberInput(returnValue);
  };
  useDidMountEffect(() => {
    onChange(debouncedNumberInput);
  }, [debouncedNumberInput]);

  return (
    <Grid container alignItems="center" className="edit-properties-div__number-input-container">
      <Grid item xs={4}>
        <label htmlFor={label} className="edit-properties-div__label">{label}</label>
      </Grid>
      <Grid item xs={8}>
        <input
          className="edit-properties-div__number-input"
          type="number"
          id={label}
          min={min}
          max={max}
          value={returnInteger ? parseInt(numberInput, 10) : numberInput}
          onChange={onValueChange}
          placeholder={placeholder}
        />
      </Grid>
    </Grid>
  );
}

NumberInput.propTypes = {
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  placeholder: PropTypes.string,
  returnInteger: PropTypes.bool,
};

NumberInput.defaultProps = {
  min: -Infinity,
  max: Infinity,
  placeholder: '',
  returnInteger: false,
};

export default NumberInput;
