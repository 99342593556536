import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moduleUpdateIcon from '../../../assests/icons/moduleUpdateIcon.svg';

import './ModuleVersionUpdate.scss';
import Modal from '../../Common/Modal';
import VersionUpdateModalContent from './VersionUpdateModalContent';
import Button from '../../Common/Button';
import useShowErrorAlert from '../../../hooks/custom/useCustomAlert';
import { errorMessages } from '../../../utils/error';

function ModuleVersionUpdate(props) {
  const { modulesTobeUpdated, versionedModules, workflow } = props;

  const [showModuleUpdateModal, setShowModuleUpdateModal] = useState(false);
  const [moduleToBeUpdatedIndex, setModuleToBeUpdatedIndex] = useState(null);
  const [hasFetchModuleUpdatesError, setHasFetchModuleUpdatesError] = useState(false);
  const showErrorAlert = useShowErrorAlert();

  useEffect(() => {
    if (hasFetchModuleUpdatesError) {
      // TODO: to be handled using global error handler
      alert('Failed to fetch module update details');
      showErrorAlert({
        error: new Error('Failed to fetch module update details'),
        message: errorMessages.errorFetchingModuleUpdates,
      });

      setShowModuleUpdateModal(false);
      setHasFetchModuleUpdatesError(false);
      setModuleToBeUpdatedIndex(null);
    }
  }, [hasFetchModuleUpdatesError]);

  const getModuleUpdateHeaderContent = () => {
    if (moduleToBeUpdatedIndex === null) {
      return (
        <div className="module-update-modal__header">
          <h1 className="module-update-modal__header-title">Module Release Updates</h1>
          <p className="module-update-modal__header-subtitle">
            Update the following modules to get latest versions,
            bug fixes and additional functionality
          </p>
        </div>
      );
    }

    const moduleDetails = modulesTobeUpdated[moduleToBeUpdatedIndex];
    const moduleName = moduleDetails.name;
    const currentVersion = moduleDetails.version || 'v1';
    const { latestVersion } = versionedModules[moduleDetails.subType];

    return (
      <h1 className="module-update-modal__header-title">
        {`Update ${moduleName} from ${currentVersion} to ${latestVersion}?`}
      </h1>
    );
  };

  return (
    <div className="module-update">
      {(modulesTobeUpdated || []).length > 0 && (
        <div className="module-update__notification" />
      )}
      <Button
        buttonType="secondary"
        className="module-update__button"
        onClick={() => setShowModuleUpdateModal(true)}
      >
        <div className="module-update__content">
          <img className="module-update__icon" src={moduleUpdateIcon} alt="Updates" />
          <span>
            Updates (
            {(modulesTobeUpdated || []).length}
            )
          </span>
        </div>
      </Button>
      {showModuleUpdateModal && (
      <Modal
        isOpen={showModuleUpdateModal}
        onClose={() => {
          setShowModuleUpdateModal(false);
          setModuleToBeUpdatedIndex(null);
        }}
        headerContent={getModuleUpdateHeaderContent()}
        className="module-update-modal"
      >
        <VersionUpdateModalContent
          modulesTobeUpdated={modulesTobeUpdated}
          versionedModules={versionedModules}
          selectedModuleIndex={moduleToBeUpdatedIndex}
          selectModuleToBeUpdated={(index) => setModuleToBeUpdatedIndex(index)}
          setShowModuleUpdateModal={setShowModuleUpdateModal}
          hasFetchModuleUpdatesError={setHasFetchModuleUpdatesError}
          workflow={workflow}
        />
      </Modal>
      )}
    </div>
  );
}

ModuleVersionUpdate.propTypes = {
  modulesTobeUpdated: PropTypes.array.isRequired,
  versionedModules: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
};

export default ModuleVersionUpdate;
