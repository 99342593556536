const checkOutputsRemovedInModuleVersion = (
  configurations,
) => {
  const {
    currentModuleConfig = {},
    latestModuleConfig = {},
  } = configurations || {};
  const latestOutputs = latestModuleConfig?.variables;
  const currentOutputs = currentModuleConfig?.variables;

  const latestVersionOutputMap = latestOutputs.reduce(
    (map, variable) => ({
      ...map,
      [variable.name]: variable.path,
    }),
    {},
  );

  const removedOrChangedOutputs = currentOutputs.filter(
    (output) => !latestVersionOutputMap[output.name]
      || latestVersionOutputMap[output.name] !== output.path,
  );

  const logs = removedOrChangedOutputs.map(
    (output) => (
      <span key={output.name}>
        Output
        {' '}
        <b>
          &quot;
          {output.name}
          &quot;
        </b>
        {' '}
        {latestVersionOutputMap[output.name] ? 'has changed path' : 'has been removed'}
      </span>
    ),
  );

  if (removedOrChangedOutputs.length > 0) {
    return {
      hasDifferences: true,
      logs,
      extraData: {
        removedOrChangedOutputs,
      },
    };
  }

  return {
    hasDifferences: false,
    logs: [],
    extraData: {},
  };
};

export default checkOutputsRemovedInModuleVersion;
