import { useState } from 'react';
import PropTypes from 'prop-types';

import kebabMenuIcon from '../../../assests/icons/kebabMenuIcon.svg';

import './TextInputWithBtn.scss';

function TextInputWithButton({
  placeholder,
  initialValue,
  onChange,
  onBtnClick,
  showBtn,
}) {
  const [currentValue, setValue] = useState(initialValue);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setValue(value);
    onChange(value);
  };

  return (
    <>
      <div
        className="text_input"
      >
        <input
          type="text"
          className="text_input__box"
          placeholder={placeholder || 'Enter value'}
          value={currentValue}
          onChange={handleInputChange}
        />
        {
            showBtn ? (
              <button
                className="condition__content__var__textBox__more_button"
                type="button"
                onClick={() => onBtnClick()}
              >
                <img src={kebabMenuIcon} alt="more" />
              </button>
            ) : null
        }
      </div>
    </>
  );
}

TextInputWithButton.propTypes = {
  placeholder: PropTypes.string.isRequired,
  initialValue: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onBtnClick: PropTypes.func.isRequired,
  showBtn: PropTypes.bool.isRequired,
};

export default TextInputWithButton;
