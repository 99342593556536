import { useState } from 'react';
import PropTypes from 'prop-types';
import ModuleInputRow from './ModuleInputRow';
import generateUniqueID from '../../utils/generateUniqueId';
import plus from '../../assests/icons/Plus.svg';
import ModuleOutputsDrawer from './ModuleOutputsDrawer';

function ModuleOutput({
  moduleOutputs,
  onAdd,
  onDelete,
  dropdownOptions,
}) {
  const [showOutputsDrawer, setShowOutputsDrawer] = useState(false);
  const [selectedOutputId, setSelectedOutputId] = useState('');

  const createNewOutput = () => {
    const newOutputId = `Output_${generateUniqueID()}`;
    setSelectedOutputId(newOutputId);
    setShowOutputsDrawer(true);
  };

  const onSelectModuleOutput = (moduleOutputId) => {
    setSelectedOutputId(moduleOutputId);
    setShowOutputsDrawer(true);
  };

  const closeConfigDrawer = () => {
    setSelectedOutputId('');
    setShowOutputsDrawer(false);
  };

  const addOutput = (outputConfig) => {
    onAdd(selectedOutputId, outputConfig);
    closeConfigDrawer();
  };

  const deleteOutput = (outputId) => {
    onDelete(outputId);
  };

  const getDefaultOutputConfig = () => ({
    type: 'outputItem',
  });

  return (
    <>
      <div className="workflowInput__topContent">
        <div className="workflowInput__topContent_heading">Module Outputs</div>
        <div className="workflowInput__topContent_subheading">
          If you want to get outputs from the module, please configure them below
        </div>
      </div>
      <div className="module_builder__input_button_container">
        {Object.keys(moduleOutputs || {}).map((output) => (
          <ModuleInputRow
            id={output}
            name={moduleOutputs[output].displayName || moduleOutputs[output].name}
            onClick={() => onSelectModuleOutput(output)}
            onDelete={() => deleteOutput(output)}
            key={output}
          />
        ))}
      </div>
      <div className="workflowOutput_buttonContainer">
        <button type="button" className="workflowOutput_addBtn" onClick={createNewOutput}>
          <img alt="plus" src={plus} />
          <div>
            Add Module Output
          </div>
        </button>
      </div>
      {showOutputsDrawer && (
        <ModuleOutputsDrawer
          dropDownOptions={dropdownOptions}
          currOutput={moduleOutputs[selectedOutputId] || getDefaultOutputConfig()}
          onClose={closeConfigDrawer}
          onAddOutput={addOutput}
        />
      )}
    </>
  );
}

ModuleOutput.propTypes = {
  moduleOutputs: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
};

export default ModuleOutput;
