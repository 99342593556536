import { cloneDeep } from 'lodash';
import {
  fetchCurrentValueFromWorkflow,
  setModulePropertyInWorkflow,
  setModuleVariablesInWorkflow,
  unsetModulePropertyInWorkflow,
} from '../InputsToModule/utils/updateWorkflow';

import {
  attachUniqueIdToArrayElements,
  reshapeObjectDataToArrayState,
} from '../../../utils/helper';

import generateUniqueID from '../../../utils/generateUniqueId';

export const getWorkflowValueForRootKey = (rootKey, module) => {
  if (rootKey.startsWith('properties.')) {
    const workflowKey = rootKey.slice(11, rootKey.length);
    const data = fetchCurrentValueFromWorkflow(
      module,
      workflowKey,
    );
    return typeof data === 'undefined' ? null : data;
  }
  if (rootKey === 'variables') {
    const { variables } = module;
    return typeof variables === 'undefined' ? null : cloneDeep(variables);
  }
  return null;
};

export const setValueInWorkflow = (workflow, data, rootKey, moduleId, moduleConfig) => {
  if (rootKey.startsWith('properties.')) {
    const workflowKey = rootKey.slice(11, rootKey.length);
    const editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      workflowKey,
      data,
      moduleConfig,
    );
    return editedWorkflow;
  }
  if (rootKey === 'variables') {
    const editedWorkflow = setModuleVariablesInWorkflow(
      workflow,
      moduleId,
      data,
    );
    return editedWorkflow;
  }
  return workflow;
};

// Assumption: rootKey follows the pattern properties.<some path>
export const getWorkflowKeyForProperties = (rootKey) => rootKey.slice(11, rootKey.length);

export const unSetValueInWorkflow = (workflow, rootKey, moduleId) => {
  if (rootKey.startsWith('properties.')) {
    const workflowKey = getWorkflowKeyForProperties(rootKey);
    const editedWorkflow = unsetModulePropertyInWorkflow(
      workflow,
      moduleId,
      workflowKey,
    );
    return editedWorkflow;
  }
  if (rootKey === 'variables') {
    const editedWorkflow = setModuleVariablesInWorkflow(
      workflow,
      moduleId,
      [],
    );
    return editedWorkflow;
  }
  return workflow;
};

export const ifOnlyReqParamsAndBodyInPanel = (panel) => {
  if (panel?.tabs?.length === 2) {
    const rootKey1 = panel?.tabs[0]?.rootWorkflowKey;
    const rootKey2 = panel?.tabs[1]?.rootWorkflowKey;
    const paramKey = 'properties.requestParameters';
    const bodyKey = 'properties.requestBody';
    if (rootKey1 === paramKey && rootKey2 === bodyKey) return true;
  }
  return false;
};

export const getInitialGenericApiConfig = (currentPanels, module) => {
  // Assumption: No config has interfering targets
  // i.e, We have no two configs that are settig a.b and a.b.c separately
  const initialData = {};
  if (currentPanels) {
    currentPanels.forEach(({ tabs }, panelI) => {
      tabs.forEach(({ rootWorkflowKey, type }, tabI) => {
        const rawData = getWorkflowValueForRootKey(rootWorkflowKey, module);
        if (type === 'moduleVariables' || type === 'reqParams') {
          initialData[`${panelI}_${tabI}`] = attachUniqueIdToArrayElements(rawData);
        } else if (type === 'typedSimpleLinearObject' || type === 'simpleLinearObject') {
          initialData[`${panelI}_${tabI}`] = reshapeObjectDataToArrayState(rawData, type === 'typedSimpleLinearObject');
        } else {
          initialData[`${panelI}_${tabI}`] = rawData;
        }
        if ((rootWorkflowKey === 'properties.requestParameters') &&
          initialData[`${panelI}_${tabI}`]
        ) {
          initialData[`${panelI}_${tabI}`] = initialData[`${panelI}_${tabI}`]?.map((item) => ({
            ...item,
            data: item.data?.value === '' ? { ...item.data, value: 'emptyString_b1ed2668-297a' } : item.data,
          }));
        }
        if ((rootWorkflowKey === 'properties.requestBody') &&
          initialData[`${panelI}_${tabI}`]
        ) {
          initialData[`${panelI}_${tabI}`] = initialData[`${panelI}_${tabI}`]?.map((item) => ({
            ...item,
            value: item?.value === '' ? 'emptyString_b1ed2668-297a' : item?.value,
          }));
        }
      });
    });
  }
  return initialData;
};

export function convertBodyDataToObject(inputString) {
  const withoutComments = inputString
    .replace(/\/\*[\s\S]*?\*\//g, '') // to remove multi-line comments
    .replace(/(?<!:)\s*\/\/[^\r\n]*/g, '') // to remove single-line comments except for urls
    .trim();
  const cleanedString = withoutComments
    .replace(/↵/g, '')
    .replace(/(\r\n|\n|\r)/gm, '')
    .trim();

  try {
    const parsedObject = JSON.parse(cleanedString);
    return parsedObject;
  } catch (jsonError) {
    const resultObject = {};

    const keyValuePairs = cleanedString.replace(/{/g, '').replace(/}/g, '').split(/\s*,\s*/);
    const keyValueRegex = /([^:]+)\s*:\s*(.+)/;

    keyValuePairs.forEach((pair) => {
      const match = pair.match(keyValueRegex);
      if (match) {
        const [, key, value] = match;
        const cleanedKey = key.trim().replace(/^"(.*)"$/, '$1');
        const cleanedValue = value.trim().replace(/^"(.*)"$/, '$1');
        resultObject[cleanedKey] = cleanedValue;
      }
    });

    return resultObject;
  }
}

export const formatPanelEntries = (entries, currentPanelIndex, currentTabIndex, currentState) => {
  const formattedEntries = [];
  Object.entries(entries).forEach(([key, value]) => {
    formattedEntries.push({ id: generateUniqueID(), key, value });
  });

  return {
    ...currentState,
    [`${currentPanelIndex}_${currentTabIndex}`]: formattedEntries,
  };
};

export const handleImportCurl = (curlInput, panelConfig) => {
  const parsedCurlData = curlInput;
  if (parsedCurlData) {
    const {
      header: headers, data: bodyData, form: formData, params: queryParams,
    } = parsedCurlData;
    let updatedStoreState = {};

    if (panelConfig) {
      panelConfig.forEach(({ panel, currentPanelIndex, currentTabIndex }) => {
        switch (panel) {
          case 'headers':
            // Example Input:
            // headers = { "Content-Type": "application/json", "Authorization": "Bearer token" }
            // Process: Converts headers object into an array of key-value pairs with unique IDs.
            if (headers) {
              updatedStoreState =
                formatPanelEntries(headers, currentPanelIndex, currentTabIndex, updatedStoreState);
            } else {
              // If no headers, initialize an empty array for the specified panel and tab.
              updatedStoreState = { ...updatedStoreState, [`${currentPanelIndex}_${currentTabIndex}`]: [] };
            }
            break;

          case 'bodyData':
            // Example Input:
            // bodyData (string): '{"key1": "value1", "key2": "value2"}'
            // bodyData (object): { key1: "value1", key2: undefined } -> JSON parsing fallback
            // Process: Parses string or object, reshapes into an array format, and stores it.
            if (bodyData) {
              let parsedData;
              try {
                if (typeof bodyData === 'string') {
                  parsedData = JSON.parse(bodyData);
                } else if (
                  typeof bodyData === 'object' &&
                  Object.keys(bodyData).length === 1 &&
                  Object.values(bodyData)[0] === undefined
                ) {
                  const key = Object.keys(bodyData)[0];
                  parsedData = JSON.parse(key);
                } else {
                  parsedData = bodyData;
                }
              } catch (error) {
                const fallbackString =
                  typeof bodyData === 'string' ? bodyData : Object.keys(bodyData)[0];
                parsedData = convertBodyDataToObject(fallbackString);
              }

              const reshapedBodyData = reshapeObjectDataToArrayState(parsedData, true);
              updatedStoreState = { ...updatedStoreState, [`${currentPanelIndex}_${currentTabIndex}`]: reshapedBodyData };
            } else {
              // If no body data, set the panel and tab to null.
              updatedStoreState = { ...updatedStoreState, [`${currentPanelIndex}_${currentTabIndex}`]: null };
            }
            break;

          case 'formData':
            // Example Input:
            // formData (string): "key1=value1\nkey2=value2\nfileKey=@path/to/file.png"
            // Process: Splits entries by newline, parses key-value pairs, determines type.
            if (formData) {
              const formDataArray = typeof formData === 'string' ? formData.split('\n') : formData;
              const processedFormEntries = formDataArray.map((item) => {
                const trimmedItem = item.trim();

                if (trimmedItem.includes('=')) {
                  const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.svg'];
                  const [key, valueString = ''] = trimmedItem.split('=').map((part) => part.trim());
                  let type = 'string';
                  let value = valueString;

                  if (valueString.includes('@')) {
                    value = valueString.split('@')[1].trim();
                    if (value.endsWith('.pdf')) {
                      type = 'pdf';
                    } else if (imageExtensions.some((ext) => value.endsWith(ext))) {
                      type = 'image';
                    } else {
                      type = 'files';
                    }
                  }
                  return {
                    id: generateUniqueID(),
                    data: { name: key, type, value },
                  };
                }
                return trimmedItem ? {
                  id: generateUniqueID(),
                  data: { name: trimmedItem, type: 'string', value: '' },
                } : null;
              }).filter(Boolean);

              updatedStoreState = {
                ...updatedStoreState,
                [`${currentPanelIndex}_${currentTabIndex}`]: processedFormEntries,
              };
            } else {
              // If no form data, set the panel and tab to null.
              updatedStoreState = {
                ...updatedStoreState,
                [`${currentPanelIndex}_${currentTabIndex}`]: null,
              };
            }
            break;

          case 'queryParams':
            // Example Input:
            // queryParams = { "param1": "value1", "param2": "value2" }
            // Process: Turn queryParams object into an array of key-value pairs with unique IDs.
            if (queryParams) {
              updatedStoreState = formatPanelEntries(
                queryParams,
                currentPanelIndex,
                currentTabIndex,
                updatedStoreState,
              );
            } else {
              // If no query params, initialize an empty array for the specified panel and tab.
              updatedStoreState = { ...updatedStoreState, [`${currentPanelIndex}_${currentTabIndex}`]: [] };
            }
            break;

          case 'empty':
            // Example Input:
            // No data for the panel
            // Process: Initializes an empty array for the specified panel and tab.
            updatedStoreState = { ...updatedStoreState, [`${currentPanelIndex}_${currentTabIndex}`]: [] };
            break;

          default:
            break;
        }
      });
    }

    return updatedStoreState;
  }
  return {};
};
