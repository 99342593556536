const checkBuilderPropertiesAddedInModuleVersion = (
  configurations,
) => {
  const {
    currentUiConfig = {},
    latestUiConfig = {},
  } = configurations || {};
  const currentInputs = [
    ...(currentUiConfig?.sections?.inputs || []),
    ...(currentUiConfig?.sections?.configurations || []),
  ];
  const currentBuilderPropertiesMap = currentInputs
    .filter((input) => input?.workflowKey?.startsWith('builderProperties[-]'))
    .reduce((acc, input) => {
      acc[input?.workflowKey] = input;
      return acc;
    }, {});

  const latestInputs = [
    ...(latestUiConfig?.sections?.inputs || []),
    ...(latestUiConfig?.sections?.configurations || []),
  ];
  const latestBuilderProperties = latestInputs
    .filter((input) => input?.workflowKey?.startsWith('builderProperties[-]'));

  const addedBuilderProperties = latestBuilderProperties.filter(
    (property) => !(property.workflowKey in currentBuilderPropertiesMap),
  );

  if (addedBuilderProperties.length > 0) {
    return {
      hasDifferences: true,
      logs: addedBuilderProperties.map((property) => (
        <span key={property.workflowKey}>
          Builder property
          {' '}
          <b>
            &quot;
            {property.workflowKey.split('[-]')[1]}
            &quot;
          </b>
          {' '}
          has been added
        </span>
      )),
      extraData: {
        addedBuilderProperties,
      },
    };
  }

  return {
    hasDifferences: false,
    logs: [],
    extraData: {},
  };
};

export default checkBuilderPropertiesAddedInModuleVersion;
