import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import './FileUpload.scss';
import upload from '../../../assests/icons/upload.svg';
import deleteIcon from '../../../assests/icons/Delete.svg';
import generateUniqueID from '../../../utils/generateUniqueId';

function FileUpload(props) {
  const {
    onUpload,
    label,
    type = 'logo',
    initialPreview,
    acceptedFileTypes = '.jpeg, .png, .jpg',
    uploadLabel = 'Upload File',
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(initialPreview);
  const fileInputRef = useRef(null);
  const uniqueId = useRef(generateUniqueID()).current;

  useEffect(() => {
    setPreviewUrl(initialPreview);
  }, [initialPreview]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      if (type === 'logo' || type === 'icon') {
        setPreviewUrl(URL.createObjectURL(file));
      }
    }
  };

  const handleReset = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    onUpload(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: selectedFile.type });
        onUpload(blob);
      };
      reader.readAsArrayBuffer(selectedFile);
    }
  }, [selectedFile]);

  const renderPreview = () => {
    if (!previewUrl && !initialPreview) return null;

    return (
      <div className="preview-container">
        <div className="preview">
          {type === 'logo' || type === 'icon' ? (
            <img src={previewUrl} alt="logo" className="preview-logo" />
          ) : (
            <div className="preview-lottie">{initialPreview}</div>
          )}
        </div>
        <div className="delete">
          <button type="button" onClick={handleReset} className="delete-button">
            <img src={deleteIcon} alt="Delete" className="delete-image" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="add-logo-div__container">
      <div className="add-logo-div">
        <div className="add-logo-div__name">{label}</div>
        <div className="add-logo-div__layout">
          <label htmlFor={uniqueId} className="add-logo-div__upload">
            <div className="add-logo-div__upload__logo">
              <img src={upload} alt="upload" />
            </div>
            <div className="add-logo-div__container">
              <div className="add-logo-div__upload__label1">
                {uploadLabel}
              </div>
              <input
                id={uniqueId}
                ref={fileInputRef}
                className="add-logo-div__upload-button"
                type="file"
                accept={acceptedFileTypes}
                onChange={handleFileUpload}
              />
            </div>
          </label>
          {renderPreview()}
        </div>
      </div>
    </div>
  );
}

FileUpload.propTypes = {
  onUpload: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['logo', 'lottie']),
  initialPreview: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  acceptedFileTypes: PropTypes.string,
  uploadLabel: PropTypes.string,
};

FileUpload.defaultProps = {
  type: 'logo',
  initialPreview: null,
  acceptedFileTypes: '.jpeg, .png, .jpg',
  uploadLabel: 'Upload File',
};

export default FileUpload;
