// import PropTypes from 'prop-types';
import './Workflow.scss';
import { Handle, Position } from 'reactflow';
import start from '../assests/icons/start.svg';

function StartNode() {
  return (
    <div className="custom_node">
      <Handle type="source" position={Position.Bottom} isConnectable />
      <div className="custom_node__icon">
        <img
          src={start}
          alt="start"
          className="custom_edge_button__img"
        />
      </div>
      <div className="custom_node__body">
        <p className="custom_node__heading">
          Start
        </p>
      </div>
    </div>
  );
}

// StartNode.propTypes = {
//   // subHeading: PropTypes.string.isRequired,
//   type: PropTypes.string.isRequired,
// };

export default StartNode;
