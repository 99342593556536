import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import useDebounce from '../../../utils/useDebounce';
import generateUniqueID from '../../../utils/generateUniqueId';
import DropDown from '../../ViewWorkflow/DropDown';
import { createNestedMenuData } from '../../ViewWorkflow/NestedDropdown/utils';

function ListItemEditor(props) {
  const {
    label,
    onChange,
    defaultDataArray,
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
  } = props;

  const getDefaultData = (data) => data.map((dataItem) => ({
    ...dataItem,
    id: generateUniqueID(),
  }));
  const prevValue = useRef(getDefaultData(defaultDataArray));
  const [dataArray, setDataArray] = useState(getDefaultData(defaultDataArray));
  const debouncedDataArray = useDebounce(dataArray, 500);

  const handleDeleteClick = (reference) => {
    const local = dataArray.map((data) => ({ ...data }));
    local.splice(reference, 1);
    setDataArray(local);
  };

  const handleTextValueOnChange = (value, type, reference) => {
    setDataArray((existingDataArray) => {
      const local = existingDataArray.map((data) => ({ ...data }));
      local[reference][type] = value;
      return local;
    });
  };

  const handleAddChip = () => {
    const local = dataArray.map((data) => ({ ...data }));
    local.push({
      text: '',
      val: '',
      id: generateUniqueID(),
    });
    setDataArray(local);
  };

  const ifValueChanged = (currentValue, newValue) => {
    const newWithoutId = newValue.map(({ text, val }) => ({ text, val }));
    const currentWithoutId = currentValue.map(({ text, val }) => ({ text, val }));
    return JSON.stringify(newWithoutId) !== JSON.stringify(currentWithoutId);
  };

  useEffect(() => {
    if (ifValueChanged(prevValue.current, debouncedDataArray)) onChange(debouncedDataArray);
    prevValue.current = debouncedDataArray;
  }, [debouncedDataArray]);

  return (
    <div className="edit-settings-div">
      <div className="edit-settings-div__title">
        <label htmlFor={label} className="edit-properties-div__label">{label}</label>
      </div>
      <Grid
        container
        className="edit-settings-div__label"
        alignItems="center"
        columnSpacing={1}
      >
        <Grid item xs={5}>
          <div className="edit-settings-div__subtitle">
            LABEL
          </div>
        </Grid>
        <Grid item xs={5}>
          <div className="edit-settings-div__subtitle">
            VALUE
          </div>
        </Grid>
      </Grid>
      {defaultDataArray && defaultDataArray.map((data, index) => (
        <div key={data.id}>
          <Grid
            container
            className="edit-settings-div__dropdown-options-row"
            alignItems="center"
            columnSpacing={1}
          >
            <Grid item xs={5}>
              <DropDown
                allowCustomInput
                noSelectLabel="Please select"
                textBoxPlaceholder="Enter Label"
                defaultValue={data.text}
                onChange={(updatedText) => {
                  handleTextValueOnChange(updatedText.value, 'text', index);
                }}
                items={createNestedMenuData(
                  workflowInputs,
                  conditionalVariables,
                  moduleOutputs,
                )}
                maxLength={12}
              />
            </Grid>
            <Grid item xs={5}>
              <DropDown
                allowCustomInput
                noSelectLabel="Please select"
                textBoxPlaceholder="Enter value"
                defaultValue={data.val}
                onChange={(updatedValue) => {
                  handleTextValueOnChange(updatedValue.value, 'val', index);
                }}
                items={createNestedMenuData(
                  workflowInputs,
                  conditionalVariables,
                  moduleOutputs,
                )}
                maxLength={12}
              />
            </Grid>
            <Grid item xs={2}>
              <button
                className="edit-branding-popup__header__save__button"
                type="button"
                onClick={() => handleDeleteClick(index)}
              >
                Delete
              </button>
            </Grid>
          </Grid>
        </div>
      ))}
      <button
        className="edit-settings-div__condition_button"
        type="button"
        onClick={handleAddChip}
      >
        + add chip
      </button>
    </div>
  );
}

ListItemEditor.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultDataArray: PropTypes.array.isRequired,
  workflowInputs: PropTypes.array.isRequired,
  conditionalVariables: PropTypes.array.isRequired,
  moduleOutputs: PropTypes.array.isRequired,
};

export default ListItemEditor;
