import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectVersionedModules } from '../../reducers/workflow';

function ModuleInput({ nodeConfig, workflowConfig }) {
  const [requiredInputs, setRequiredInputs] = useState([]);
  const versionedModules = useSelector(selectVersionedModules);

  useEffect(() => {
    setRequiredInputs([]);
    if (nodeConfig?.properties?.requestParameters) {
      // remove requestParameters without module
      const requestParameters = nodeConfig.properties.requestParameters
        .filter((parameter) => parameter.module);

      const inputParameters = requestParameters.map((parameter) => {
        const { module } = parameter;
        const modifiedParam = parameter;
        const moduleInWorkflow = workflowConfig.modules.find((mod) => mod.id === module);
        if (moduleInWorkflow) {
          // Assuming only for old modules
          const foundModuleDetails = versionedModules[moduleInWorkflow.subType];
          const { uiConfig } = foundModuleDetails?.[moduleInWorkflow.version || 'v1']?.config || {};
          const sourceModuleDetails = versionedModules[nodeConfig.subType];
          const sourceModule = sourceModuleDetails?.[nodeConfig.version || 'v1']?.config;
          if (uiConfig) {
            modifiedParam.module = uiConfig.node.heading;
            modifiedParam.value = uiConfig.moduleOutput[modifiedParam.key].name;
            modifiedParam.keyName = sourceModule.uiConfig.moduleInput[modifiedParam.key].name;
          }
        }
        return modifiedParam;
      });
      setRequiredInputs(inputParameters);
    }
  }, [JSON.stringify(nodeConfig)]);

  if (requiredInputs.length === 0) {
    return (
      <Grid item xs={12} style={{ marginBottom: '32px' }}>
        <Grid container>
          <Grid item xs={12}>
            <div className="drawer_content__heading">Module Input</div>
          </Grid>
          <Grid item xs={12}>
            <div className="drawer_content__subheading">This Module is self sufficient and does not take any inputs from any previous modules</div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} style={{ marginBottom: '32px' }}>
      {requiredInputs.map((input) => (
        <Grid container key={input.key}>
          <Grid item xs={12}>
            <div className="drawer_content__heading">{input.keyName}</div>
          </Grid>
          <Grid container className="drawer_required_input_text__container">
            <Grid item xs={6}>
              <span className="drawer_small_grey__text">
                Source module
              </span>
              <div className="drawer_input_box">{input.module}</div>
            </Grid>
            <Grid item xs={6}>
              <span className="drawer_small_grey__text">
                Source&apos; Output field
              </span>
              <div className="drawer_input_box">{input.value}</div>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

ModuleInput.propTypes = {
  nodeConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  workflowConfig: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ModuleInput;
