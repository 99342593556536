import { validateJoiSchemaForModuleConfig, validateJoiSchemaForModuleUiConfig } from './joiValidations';
import validateInitialStep from './validateInitialStep';
import validateNoDuplicateIds from './validateNoDuplicateIdsInModuleConfig';
import validateUniqueOutputNames from './validateUniqueOutputNames';

const validate = (config, validators) => {
  validators.forEach(({ fn: validator }) => {
    validator(config);
  });
};

export const validateModuleConfig = (moduleConfig) => {
  const validators = [
    {
      code: 'joiSchemaValidation',
      fn: validateJoiSchemaForModuleConfig,
    },
    {
      code: 'uniqueOutputNames',
      fn: validateUniqueOutputNames,
    },
    {
      code: 'noDuplicateIds',
      fn: validateNoDuplicateIds,
    },
    {
      code: 'initialStepValidation',
      fn: validateInitialStep,
    },
  ];
  validate(moduleConfig, validators);
};

export const validateModuleUiConfig = (moduleUiConfig) => {
  const validators = [
    {
      code: 'joiSchemaValidation',
      fn: validateJoiSchemaForModuleUiConfig,
    },
  ];
  validate(moduleUiConfig, validators);
};
