// import React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { TextField, FormControlLabel, Switch } from '@mui/material';

function WorkflowInputsModal({ onClose, inputsRequired, onSaveInputs }) {
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    Object.entries(inputsRequired).forEach(
      ([variable, type]) => {
        let value;
        if (type === 'string') value = '';
        if (type === 'boolean') value = false;
        if (type === 'image') value = '';
        setInputs((prevInputs) => ({
          ...prevInputs,
          [variable]: value,
        }));
      },
    );
  }, [inputsRequired]);

  const onTextfieldChange = (variable, value) => {
    setInputs((prevInputs) => ({ ...prevInputs, [variable]: value }));
  };
  const onBooleanFieldChange = (variable, value) => {
    setInputs((prevInputs) => ({ ...prevInputs, [variable]: value }));
  };
  const handleImageUpload = (event, variable) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const value = reader.result;
        setInputs((prevInputs) => ({ ...prevInputs, [variable]: value }));
      };
      reader.readAsDataURL(file);
    }
  };

  const displayInputItem = (variable, type) => {
    if (type === 'string') {
      return (
        <TextField label={variable} variant="outlined" value={inputs[variable] || ''} onChange={(event) => onTextfieldChange(variable, event.target.value)} key={variable} size="small" fullWidth />
      );
    }
    if (type === 'boolean') {
      return (
        <FormControlLabel control={<Switch checked={inputs[variable] || false} onChange={(event) => onBooleanFieldChange(variable, event.target.checked)} />} label={variable} labelPlacement="start" />
      );
    }

    if (type === 'image') {
      return (
        // <button type="button">{variable}</button>
        <input
          type="file"
          accept="image/*"
          onChange={(event) => handleImageUpload(event, variable)}
        />
      );
    }
    return null;
  };

  const isValueEmpty = () => {
    let empty = false;
    Object.entries(inputs).forEach(
      ([, value]) => {
        if (value === '') empty = true;
      },
    );
    return empty;
  };

  const onSubmit = () => {
    onSaveInputs(inputs);
  };
  return (
    <div className="workflow_input_modal__overlay" data-testid="workflow_input_modal__overlay">
      <div className="workflow_input_modal__body">
        <div className="workflow_input_modal__header">
          <span>
            Enter workflow inputs
          </span>
        </div>
        <div className="workflow_input_modal__content">
          {Object.entries(inputsRequired).map(
            ([variable, type]) => displayInputItem(variable, type),
          )}

          <div className="workflow_input_modal__content_buttons">
            <button type="button" className="workflow_input_modal__content_buttons_cancel" onClick={onClose}>
              Cancel
            </button>
            <button type="button" onClick={onSubmit} disabled={isValueEmpty()} className="workflow_input_modal__content_buttons_save">Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
}

WorkflowInputsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  inputsRequired: PropTypes.object.isRequired,
  onSaveInputs: PropTypes.func.isRequired,
};

export default WorkflowInputsModal;
